import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { empty, cons, singleton, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { int32ToString, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { View_DUSelect, View_checkBoxField } from "../Common.fs.js";
import { WorkoutType_SpeedInfo, WorkoutType_MobilityInfo, WorkoutType_StrengthInfo, WorkoutType_EnduranceInfo, Shape_parseShapeStr, Shape_strShape, Shape_list, SpeedActivity_parseSpeedActivityStr, SpeedActivity_strSpeedActivity, SpeedActivity_list, StrengthActivity_parseStrengthActivityStr, StrengthActivity_strStrengthActivity, StrengthActivity_list, EnduranceIntensity_parseEnduranceIntensityStr, EnduranceIntensity_strEnduranceIntensity, EnduranceIntensity_list, EnduranceActivity_parseEnduranceActivityStr, EnduranceActivity_strEnduranceActivity, Common_str, EnduranceActivity_list, Common_parseIntOrZero, DurationModule_fromInt, DurationModule_toHMS } from "../../Shared/Shared.fs.js";

export function WorkoutPartHeader(workoutPartHeaderInputProps) {
    let props_9, props_1, props_3, props_7;
    const onDeleted = workoutPartHeaderInputProps.onDeleted;
    const isPrimaryChanged = workoutPartHeaderInputProps.isPrimaryChanged;
    const isPrimary = workoutPartHeaderInputProps.isPrimary;
    const title = workoutPartHeaderInputProps.title;
    const props_11 = ofArray([["className", "is-size-7"], ["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-vcentered"], ["style", {
        borderBottom: (((1 + "px ") + "dashed") + " ") + "#226699",
    }], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", "py-1"], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        className: "",
        children: title,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1)))), (props_3 = ofArray([["className", "py-1"], ["className", "has-text-right"], ["className", "pr-4"], ["children", Interop_reactApi.Children.toArray([View_checkBoxField("Huvuddel", isPrimary, isPrimaryChanged)])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_7 = ofArray([["className", "py-1"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("delete", singleton(["onClick", (_arg1) => {
        onDeleted();
    }]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_9))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_11)));
}

export function DurationEditor(duration, onChange) {
    let props_2, elms;
    const patternInput = DurationModule_toHMS(duration);
    const durationSeconds = patternInput[2] | 0;
    const durationMinutes = patternInput[1] | 0;
    const durationHours = patternInput[0] | 0;
    const durationHoursStr = int32ToString(durationHours);
    const durationMinutesStr = int32ToString(durationMinutes);
    const durationSecondsStr = int32ToString(durationSeconds);
    const props_15 = singleton(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["className", "has-text-grey"], ["children", "Längd"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = ofArray([createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["className", "is-small"], ["defaultValue", durationHoursStr], ["onChange", (ev) => {
        onChange(DurationModule_fromInt(((Common_parseIntOrZero(ev.target.value) * 3600) + (durationMinutes * 60)) + durationSeconds));
    }]]))))), createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "px-3"], ["className", "pt-1"], ["className", "has-text-weight-bold"], ["children", ":"]])))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["className", "is-small"], ["defaultValue", durationMinutesStr], ["onChange", (ev_1) => {
        onChange(DurationModule_fromInt(((durationHours * 3600) + (Common_parseIntOrZero(ev_1.target.value) * 60)) + durationSeconds));
    }]]))))), createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "px-3"], ["className", "pt-1"], ["className", "has-text-weight-bold"], ["children", ":"]])))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["className", "is-small"], ["defaultValue", durationSecondsStr], ["onChange", (ev_2) => {
        onChange(DurationModule_fromInt(((durationHours * 3600) + (durationMinutes * 60)) + Common_parseIntOrZero(ev_2.target.value)));
    }]])))))]), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_15)));
}

export function EnduranceActivityEditor(value, onChange) {
    return View_DUSelect("Aktivitet", EnduranceActivity_list, (x) => Common_str(x), (_arg1) => EnduranceActivity_strEnduranceActivity(_arg1), (_arg1_1) => EnduranceActivity_parseEnduranceActivityStr(_arg1_1), value, onChange);
}

export function EnduranceIntensityEditor(value, onChange) {
    return View_DUSelect("Intensitet", EnduranceIntensity_list, (x) => Common_str(x), (_arg1) => EnduranceIntensity_strEnduranceIntensity(_arg1), (_arg1_1) => EnduranceIntensity_parseEnduranceIntensityStr(_arg1_1), value, onChange);
}

export function StrengthActivityEditor(value, onChange) {
    return View_DUSelect("Aktivitet", StrengthActivity_list, (x) => Common_str(x), (_arg1) => StrengthActivity_strStrengthActivity(_arg1), (_arg1_1) => StrengthActivity_parseStrengthActivityStr(_arg1_1), value, onChange);
}

export function SpeedActivityEditor(value, onChange) {
    return View_DUSelect("Aktivitet", SpeedActivity_list, (x) => Common_str(x), (_arg1) => SpeedActivity_strSpeedActivity(_arg1), (_arg1_1) => SpeedActivity_parseSpeedActivityStr(_arg1_1), value, onChange);
}

export function ShapeEditor(value, onChange) {
    return View_DUSelect("Form", Shape_list, (x) => Common_str(x), (_arg1) => Shape_strShape(_arg1), (_arg1_1) => Shape_parseShapeStr(_arg1_1), value, onChange);
}

export function SpeedAmountEditor(amount, onChange) {
    let props_2, elms, props_4;
    const props_7 = singleton(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["children", "Antal"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton((props_4 = ofArray([["className", "is-small"], ["defaultValue", Common_str(amount)], ["onChange", (ev) => {
        onChange(Common_parseIntOrZero(ev.target.value));
    }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_7)));
}

export function Endurance(enduranceInputProps) {
    let props_2, elms, elms_1, props_6, props_4;
    const onDeleted = enduranceInputProps.onDeleted;
    const isPrimaryChanged = enduranceInputProps.isPrimaryChanged;
    const isPrimary = enduranceInputProps.isPrimary;
    const title = enduranceInputProps.title;
    const infoChanged = enduranceInputProps.infoChanged;
    const info = enduranceInputProps.info;
    const props_8 = ofArray([["className", "is-size-7"], ["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement(WorkoutPartHeader, {
        title: title,
        isPrimary: isPrimary,
        isPrimaryChanged: isPrimaryChanged,
        onDeleted: onDeleted,
    }), (props_2 = singleton(["children", Interop_reactApi.Children.toArray([(elms = singleton(EnduranceActivityEditor(info.Activity, (x) => {
        infoChanged(new WorkoutType_EnduranceInfo(x, info.Intensity, info.Duration));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton(EnduranceIntensityEditor(info.Intensity, (x_1) => {
        infoChanged(new WorkoutType_EnduranceInfo(info.Activity, x_1, info.Duration));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_2)))), (props_6 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-half"], ["children", Interop_reactApi.Children.toArray([DurationEditor(info.Duration, (x_2) => {
        infoChanged(new WorkoutType_EnduranceInfo(info.Activity, info.Intensity, x_2));
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_8)));
}

export function Strength(strengthInputProps) {
    let props_3, props, elms;
    const onDeleted = strengthInputProps.onDeleted;
    const isPrimaryChanged = strengthInputProps.isPrimaryChanged;
    const isPrimary = strengthInputProps.isPrimary;
    const title = strengthInputProps.title;
    const infoChanged = strengthInputProps.infoChanged;
    const info = strengthInputProps.info;
    const props_5 = ofArray([["className", "is-size-7"], ["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement(WorkoutPartHeader, {
        title: title,
        isPrimary: isPrimary,
        isPrimaryChanged: isPrimaryChanged,
        onDeleted: onDeleted,
    }), (props_3 = singleton(["children", Interop_reactApi.Children.toArray([(props = singleton(["children", Interop_reactApi.Children.toArray([StrengthActivityEditor(info.Activity, (x) => {
        infoChanged(new WorkoutType_StrengthInfo(x, info.Duration));
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (elms = singleton(DurationEditor(info.Duration, (x_1) => {
        infoChanged(new WorkoutType_StrengthInfo(info.Activity, x_1));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_3))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_5)));
}

export function Mobility(mobilityInputProps) {
    let props_4, props;
    const onDeleted = mobilityInputProps.onDeleted;
    const isPrimaryChanged = mobilityInputProps.isPrimaryChanged;
    const isPrimary = mobilityInputProps.isPrimary;
    const title = mobilityInputProps.title;
    const infoChanged = mobilityInputProps.infoChanged;
    const info = mobilityInputProps.info;
    const props_6 = ofArray([["className", "is-size-7"], ["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement(WorkoutPartHeader, {
        title: title,
        isPrimary: isPrimary,
        isPrimaryChanged: isPrimaryChanged,
        onDeleted: onDeleted,
    }), (props_4 = singleton(["children", Interop_reactApi.Children.toArray([(props = singleton(["children", Interop_reactApi.Children.toArray([DurationEditor(info.Duration, (x) => {
        infoChanged(new WorkoutType_MobilityInfo(x));
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props)))), createElement("div", createObj(Helpers_combineClasses("column", empty())))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_4))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_6)));
}

export function Speed(speedInputProps) {
    let props_4, props, props_2;
    const onDeleted = speedInputProps.onDeleted;
    const isPrimaryChanged = speedInputProps.isPrimaryChanged;
    const isPrimary = speedInputProps.isPrimary;
    const title = speedInputProps.title;
    const infoChanged = speedInputProps.infoChanged;
    const info = speedInputProps.info;
    const props_6 = ofArray([["className", "is-size-7"], ["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement(WorkoutPartHeader, {
        title: title,
        isPrimary: isPrimary,
        isPrimaryChanged: isPrimaryChanged,
        onDeleted: onDeleted,
    }), (props_4 = singleton(["children", Interop_reactApi.Children.toArray([(props = singleton(["children", Interop_reactApi.Children.toArray([SpeedActivityEditor(info.Activity, (x) => {
        infoChanged(new WorkoutType_SpeedInfo(x, info.Amount));
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = singleton(["children", Interop_reactApi.Children.toArray([SpeedAmountEditor(info.Amount, (x_1) => {
        infoChanged(new WorkoutType_SpeedInfo(info.Activity, x_1));
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props_2))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_4))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_6)));
}

export function Rest(restInputProps) {
    const onDeleted = restInputProps.onDeleted;
    const isPrimaryChanged = restInputProps.isPrimaryChanged;
    const isPrimary = restInputProps.isPrimary;
    const title = restInputProps.title;
    const props = ofArray([["className", "is-size-7"], ["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement(WorkoutPartHeader, {
        title: title,
        isPrimary: isPrimary,
        isPrimaryChanged: isPrimaryChanged,
        onDeleted: onDeleted,
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props)));
}

