import { Union, Record } from "./.fable/fable-library.3.2.1/Types.js";
import { WorkoutTemplates_WorkoutTemplateInfo$reflection } from "../Shared/Shared.fs.js";
import { class_type, record_type, union_type, string_type, list_type } from "./.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.1/Choice.js";
import { View_Cmd_toastError, Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "./Extensions.fs.js";
import { singleton } from "./.fable/fable-library.3.2.1/AsyncBuilder.js";
import { workoutTemplatesApi } from "./Communication.fs.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofArray, filter, append, map, exists, singleton as singleton_1 } from "./.fable/fable-library.3.2.1/List.js";
import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.51.0/React.fs.js";
import { map as map_1, empty, singleton as singleton_2, append as append_1, delay, toList } from "./.fable/fable-library.3.2.1/Seq.js";
import { Interop_reactApi } from "./.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "./.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { join } from "./.fable/fable-library.3.2.1/String.js";
import { Render } from "./Components/WorkoutTemplateEdit.fs.js";
import { View_renderNotification, View_RenderDeleteWindow } from "./Common.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "./Loader.fs.js";

export class Types_State extends Record {
    constructor(WorkoutTemplates) {
        super();
        this.WorkoutTemplates = WorkoutTemplates;
    }
}

export function Types_State$reflection() {
    return record_type("PassList.Types.State", [], Types_State, () => [["WorkoutTemplates", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WorkoutTemplates_WorkoutTemplateInfo$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(WorkoutTemplates_WorkoutTemplateInfo$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadWorkoutTemplates", "SavedWorkoutTemplate", "DeleteWorkoutTemplateClick", "DeleteWorkoutTemplate"];
    }
}

export function Types_Msg$reflection() {
    return union_type("PassList.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WorkoutTemplates_WorkoutTemplateInfo$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(WorkoutTemplates_WorkoutTemplateInfo$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", WorkoutTemplates_WorkoutTemplateInfo$reflection()]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]]]);
}

export const Cmd_load = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(workoutTemplatesApi().listUser()), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_delete(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(workoutTemplatesApi().delete(id)), (_arg1) => {
        const result = _arg1;
        return singleton.Return((result.tag === 0) ? (new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, id)))) : (new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))));
    })), (_arg2) => singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export const State_init = [new Types_State(new DeferredResult$1(1)), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Types_Msg(0, new AsyncOperationStatus$1(0)))))];

export function State_update(msg, state) {
    let matchValue, isUpdated, matchValue_1;
    if (msg.tag === 1) {
        return [new Types_State((matchValue = state.WorkoutTemplates, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, (isUpdated = ((x_1) => (x_1.Id === msg.fields[0].Id)), exists(isUpdated, matchValue.fields[0].fields[0]) ? map((x_2) => (isUpdated(x_2) ? msg.fields[0] : x_2), matchValue.fields[0].fields[0]) : append(singleton_1(msg.fields[0]), matchValue.fields[0].fields[0]))))) : state.WorkoutTemplates) : state.WorkoutTemplates)), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [state, Cmd_fromAsync(Cmd_delete(msg.fields[0]))];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [state, View_Cmd_toastError(msg.fields[0].fields[0].fields[0])];
            }
            else {
                return [new Types_State((matchValue_1 = state.WorkoutTemplates, (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, filter((x_3) => (x_3.Id !== msg.fields[0].fields[0].fields[0]), matchValue_1.fields[0].fields[0])))) : state.WorkoutTemplates) : state.WorkoutTemplates)), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
        else {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
    }
    else {
        return [new Types_State(new DeferredResult$1(1)), Cmd_fromAsync(Cmd_load)];
    }
}

export function View_WorkoutTemplateRow(view_WorkoutTemplateRowInputProps) {
    const dispatch = view_WorkoutTemplateRowInputProps.dispatch;
    const isTeacher = view_WorkoutTemplateRowInputProps.isTeacher;
    const workoutTemplate = view_WorkoutTemplateRowInputProps.workoutTemplate;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setEditHover = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteHover = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsEditWindow = patternInput_2[1];
    const isEditWindow = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_3[1];
    const isDeleteWindow = patternInput_3[0];
    const children_2 = toList(delay(() => {
        let props_18, elms_1, elms, props_5, props_3, props_15, props_8, props_12;
        return append_1(singleton_2((props_18 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
            border: (((1 + "px ") + "solid") + " ") + "#808080",
            alignContent: "space-between",
        }], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton_1(createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-primary"], ["children", workoutTemplate.Workout.Title]]))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_5 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append_1(singleton_2(["className", "is-size-7"]), delay(() => append_1(patternInput[0] ? singleton_2(["children", "Ändra egenskaper"]) : empty(), delay(() => (patternInput_1[0] ? singleton_2(["children", "Ta bort gruppen"]) : empty()))))))), createElement("span", createObj(Helpers_combineClasses("", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_15 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            className: "px-2",
            onMouseEnter: (_arg1) => {
                setEditHover(true);
            },
            onMouseLeave: (_arg2) => {
                setEditHover(false);
            },
            onClick: (_arg3) => {
                setIsEditWindow(!isEditWindow);
            },
            children: Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-pencil-alt"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8))))]),
        }), createElement("a", {
            className: "px-2",
            onMouseEnter: (_arg4) => {
                setDeleteHover(true);
            },
            onMouseLeave: (_arg5) => {
                setDeleteHover(false);
            },
            onClick: (_arg6) => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-times"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_12))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_18))))), delay(() => append_1(isEditWindow ? singleton_2(createElement(Render, {
            workoutTemplate: workoutTemplate,
            withIsGlobal: isTeacher,
            onSaved: (workoutTemplate_1) => {
                dispatch(new Types_Msg(1, workoutTemplate_1));
                setIsEditWindow(false);
            },
            onCancel: () => {
                setIsEditWindow(false);
            },
        })) : empty(), delay(() => (isDeleteWindow ? singleton_2(createElement(View_RenderDeleteWindow, {
            confirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
                dispatch(new Types_Msg(2, workoutTemplate.Id));
            },
            notConfirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            text: "Är du säker på att du vill ta bort detta pass?",
        })) : empty())))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function View_Render(user) {
    let props_6, elms, elms_1;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init, (msg, state) => State_update(msg, state), []);
    const dispatch = patternInput[1];
    const isTeacher = user.Role === 2;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsCreateWindow = patternInput_1[1];
    const props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Pass"]]))))), delay(() => {
        const matchValue = patternInput[0].WorkoutTemplates;
        let pattern_matching_result, workoutTemplates, e;
        if (matchValue.tag === 1) {
            pattern_matching_result = 0;
        }
        else if (matchValue.tag === 2) {
            if (matchValue.fields[0].tag === 1) {
                pattern_matching_result = 2;
                e = matchValue.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
                workoutTemplates = matchValue.fields[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 0;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton_2(loader("big"));
            }
            case 1: {
                return map_1((workoutTemplate) => createElement(View_WorkoutTemplateRow, {
                    workoutTemplate: workoutTemplate,
                    isTeacher: isTeacher,
                    dispatch: dispatch,
                }), workoutTemplates);
            }
            case 2: {
                return singleton_2(View_renderNotification(["className", "is-danger"], e));
            }
        }
    })))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = toList(delay(() => append_1(singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "py-2"], ["className", "is-success"], ["children", "Lägg till pass"], ["onClick", (_arg1) => {
        setIsCreateWindow(true);
    }]]))))), delay(() => (patternInput_1[0] ? singleton_2(createElement(Render, {
        workoutTemplate: void 0,
        withIsGlobal: isTeacher,
        onSaved: (workoutTemplate_1) => {
            dispatch(new Types_Msg(1, workoutTemplate_1));
            setIsCreateWindow(false);
        },
        onCancel: () => {
            setIsCreateWindow(false);
        },
    })) : empty()))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_8)));
}

