import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { Render as Render_1 } from "./WorkoutEdit.fs.js";
import { DayPlan_DayPlan } from "../../Shared/Shared.fs.js";
import { View_RenderDeleteWindow } from "../Common.fs.js";

export function Render(renderInputProps) {
    const onCancel = renderInputProps.onCancel;
    const onDelete = renderInputProps.onDelete;
    const onImplement = renderInputProps.onImplement;
    const onSave = renderInputProps.onSave;
    const dayPlan = renderInputProps.dayPlan;
    const patternInput = useFeliz_React__React_useState_Static_1505(dayPlan);
    const editModel = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_1[1];
    const isDeleteWindow = patternInput_1[0];
    const props_20 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg1) => {
        onCancel();
    }]))))), delay(() => {
        let elms_1, elms, props_7, props_15;
        return append(singleton((elms_1 = ofArray([(elms = ofArray([createElement("p", createObj(Helpers_combineClasses("modal-card-title", ofArray([["className", "is-size-6"], ["className", "has-text-weight-bold"], ["children", dayPlan.Workout.Title]])))), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg2) => {
            onCancel();
        }]))))]), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_7 = ofArray([["style", {
            overflowY: "scroll",
        }], ["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray([createElement(Render_1, {
            workout: editModel.Workout,
            onChange: (newWorkout) => {
                patternInput[1](new DayPlan_DayPlan(editModel.DaySegment, newWorkout));
            },
            children: null,
        })])]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_7)))), (props_15 = ofArray([["style", {
            justifyContent: "flex-end",
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let onImplement_1;
            return append((onImplement == null) ? singleton(null) : (onImplement_1 = onImplement, singleton(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg3) => {
                onImplement_1();
            }], ["children", "Genomför"]])))))), delay(() => append(singleton(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg4) => {
                setIsDeleteWindow(!isDeleteWindow);
            }], ["className", "has-text-danger"], ["children", "Ta bort"]]))))), delay(() => singleton(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg5) => {
                onSave(editModel);
            }], ["className", "is-success"], ["children", "Spara"]])))))))));
        }))))]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_15))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => append(singleton(createElement("button", createObj(Helpers_combineClasses("modal-close", singleton_1(["onClick", (_arg6) => {
            onCancel();
        }]))))), delay(() => (isDeleteWindow ? singleton(createElement(View_RenderDeleteWindow, {
            confirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
                onDelete();
            },
            notConfirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            text: "Är du säker på att du vill ta bort detta pass?",
        })) : empty())))));
    }))))))]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_20)));
}

