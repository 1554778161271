import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { singleton, cons, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { Workout_Workout } from "../../Shared/Shared.fs.js";
import { Render as Render_1 } from "./WorkoutPartsEdit.fs.js";

export function Render(renderInputProps) {
    let props_6, props_4, props_2, value_12, props_13, elms, props_10, value_32;
    const children = renderInputProps.children;
    const onChange = renderInputProps.onChange;
    const workout = renderInputProps.workout;
    return react.createElement(react.Fragment, {}, (props_6 = singleton(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-7"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", "Namn"]])))), (props_4 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-small"], (value_12 = workout.Title, ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_12)) : false) {
            e.value = value_12;
        }
    }]), ["onChange", (ev) => {
        onChange(new Workout_Workout(ev.target.value, workout.Comment, workout.WorkoutParts));
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_2)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_4))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_6)))), (props_13 = singleton(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-7"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", "Kommentar före träning"]])))), (elms = singleton((props_10 = ofArray([["className", "is-small"], ["rows", 3], (value_32 = workout.Comment, ["ref", (e_1) => {
        if ((!(e_1 == null)) ? (!equals(e_1.value, value_32)) : false) {
            e_1.value = value_32;
        }
    }]), ["onChange", (ev_1) => {
        onChange(new Workout_Workout(workout.Title, ev_1.target.value, workout.WorkoutParts));
    }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_10))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_13)))), children, createElement(Render_1, {
        workoutParts: workout.WorkoutParts,
        onChange: (newWorkoutParts) => {
            onChange(new Workout_Workout(workout.Title, workout.Comment, newWorkoutParts));
        },
    }));
}

