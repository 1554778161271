import { Union, Record } from "./.fable/fable-library.3.2.1/Types.js";
import { union_type, tuple_type, record_type, list_type, option_type, int32_type, string_type, class_type } from "./.fable/fable-library.3.2.1/Reflection.js";
import { Calendar_weekDaysRange, PlanFact_list, Shared_CalendarDay_CalendarDay__CalendarDay_WeekInfo, PlanFact_strPlanFact, StudentDays_StudentDayListRequest, Calendar_WeekInfo_get_Current, Calendar_periodWeeksRange, Calendar_getYearWeekIndex, Calendar_WeekInfo, Calendar_PeriodInfo__Next, Calendar_PeriodInfo__PreviousN_Z524259A4, Common_str, Calendar_PeriodInfo_get_Current, Calendar_getWeekInfo, Calendar_PeriodInfo__Previous, DayPlanFact_DayPlanFact, DayHelpers_withDaySegment, DayHelpers_deletePlanFact, StudentDays_StudentDayInfo, DayHelpers_addUpdatePlanFact, StudentDays_StudentDayInfo_Empty, PlanFact_PlanFact$reflection, DaySegment_DaySegment$reflection, CalendarDay_CalendarDay$reflection, DayPlanFact_DayPlanFact$reflection, StudentDays_StudentDayKey$reflection, StudentDays_StudentDayInfo$reflection, Calendar_PeriodInfo$reflection } from "../Shared/Shared.fs.js";
import { Cmd_none, Cmd_batch } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { filter, cons, ofArray, singleton, append, map, tryFind, empty } from "./.fable/fable-library.3.2.1/List.js";
import { int32ToString, compare, createObj, partialApply, equals } from "./.fable/fable-library.3.2.1/Util.js";
import { bind, map as map_1, defaultArg } from "./.fable/fable-library.3.2.1/Option.js";
import { equalsWith, tryFind as tryFind_1 } from "./.fable/fable-library.3.2.1/Array.js";
import { toPath, Route, navigateTo } from "./Router.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.51.0/Interop.fs.js";
import { join, interpolate, toText } from "./.fable/fable-library.3.2.1/String.js";
import { createElement } from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { empty as empty_1, collect, map as map_2, singleton as singleton_1, append as append_1, delay, toList } from "./.fable/fable-library.3.2.1/Seq.js";
import { rangeDouble } from "./.fable/fable-library.3.2.1/Range.js";
import { View_renderSelectOptionDisabled } from "./Common.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { View_Render } from "./Components/PassPanel.fs.js";
import { StatisticWithTabs } from "./Teacher/Statistic.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { singleton as singleton_2 } from "./.fable/fable-library.3.2.1/AsyncBuilder.js";
import { Remoting_handleNonAuth } from "./Extensions.fs.js";
import { studentsApi, studentDaysApi } from "./Communication.fs.js";
import { loader } from "./Loader.fs.js";
import { WeekParams, Render } from "./Components/CalendarWeek.fs.js";
import { StudentDayActions } from "./Components/DayPlanFactBox.fs.js";

export class Types_State extends Record {
    constructor(StudentId, StudentFullname, PeriodInfo, PeriodWeek, StudentDays) {
        super();
        this.StudentId = StudentId;
        this.StudentFullname = StudentFullname;
        this.PeriodInfo = PeriodInfo;
        this.PeriodWeek = PeriodWeek;
        this.StudentDays = StudentDays;
    }
}

export function Types_State$reflection() {
    return record_type("Overview.Types.State", [], Types_State, () => [["StudentId", class_type("System.Guid")], ["StudentFullname", string_type], ["PeriodInfo", Calendar_PeriodInfo$reflection()], ["PeriodWeek", option_type(int32_type)], ["StudentDays", list_type(StudentDays_StudentDayInfo$reflection())]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdatedPlanFact", "MovedPlanFact", "DeletedPlanFact", "NavigateToPeriod"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Overview.Types.Msg", [], Types_Msg, () => [[["Item1", StudentDays_StudentDayKey$reflection()], ["Item2", DayPlanFact_DayPlanFact$reflection()]], [["Item1", class_type("System.Guid")], ["Item2", tuple_type(CalendarDay_CalendarDay$reflection(), DaySegment_DaySegment$reflection())], ["Item3", tuple_type(CalendarDay_CalendarDay$reflection(), DaySegment_DaySegment$reflection())], ["Item4", PlanFact_PlanFact$reflection()]], [["Item1", StudentDays_StudentDayKey$reflection()], ["Item2", DaySegment_DaySegment$reflection()], ["Item3", PlanFact_PlanFact$reflection()]], [["Item", Calendar_PeriodInfo$reflection()]]]);
}

export function State_init(periodInfo, periodWeek, student, studentDays) {
    return [new Types_State(student.Id, student.FullName, periodInfo, periodWeek, studentDays), Cmd_batch(empty())];
}

export function State_update(msg, state) {
    const tryFindStudentDay = (f, days) => tryFind(f, days);
    const equalsCalendarDay = (calendarDay, x) => equals(x.CalendarDay, calendarDay);
    const updateCalendarDay = (calendarDay_1, f_1, days_1) => map((x_1) => {
        if (equalsCalendarDay(calendarDay_1, x_1)) {
            return f_1(x_1);
        }
        else {
            return x_1;
        }
    }, days_1);
    const addOrUpdateCalendarDay = (calendarDay_2, f_2, days_2) => {
        const matchValue = tryFindStudentDay(partialApply(1, equalsCalendarDay, [calendarDay_2]), state.StudentDays);
        if (matchValue == null) {
            return append(days_2, singleton(f_2(StudentDays_StudentDayInfo_Empty(state.StudentId, calendarDay_2))));
        }
        else {
            return map((x_2) => {
                if (equalsCalendarDay(calendarDay_2, x_2)) {
                    return f_2(x_2);
                }
                else {
                    return x_2;
                }
            }, days_2);
        }
    };
    const addOrUpdateDayPlanFact = (dayPlanFact, x_3) => {
        const patternInput = DayHelpers_addUpdatePlanFact(dayPlanFact, x_3.DayPlans, x_3.DayFacts);
        return new StudentDays_StudentDayInfo(x_3.StudentId, x_3.CalendarDay, patternInput[0], patternInput[1], x_3.DayData);
    };
    const deleteDayPlanFact = (daySegment, planFact, x_4) => {
        const patternInput_1 = DayHelpers_deletePlanFact(daySegment, planFact, x_4.DayPlans, x_4.DayFacts);
        return new StudentDays_StudentDayInfo(x_4.StudentId, x_4.CalendarDay, patternInput_1[0], patternInput_1[1], x_4.DayData);
    };
    switch (msg.tag) {
        case 1: {
            const planFact_1 = msg.fields[3];
            const dayTo = msg.fields[1];
            const dayFrom = msg.fields[2];
            const daySegmentFrom = dayFrom[1];
            const calendarDayFrom = dayFrom[0];
            return [new Types_State(state.StudentId, state.StudentFullname, state.PeriodInfo, state.PeriodWeek, defaultArg(map_1((days_5) => updateCalendarDay(calendarDayFrom, (x_6) => deleteDayPlanFact(daySegmentFrom, planFact_1, x_6), days_5), map_1((planFact_2) => addOrUpdateCalendarDay(dayTo[0], partialApply(1, addOrUpdateDayPlanFact, [planFact_2]), state.StudentDays), map_1((dayPlanFact_2) => DayHelpers_withDaySegment(dayTo[1], dayPlanFact_2), bind((x_5) => {
                if (planFact_1.tag === 1) {
                    return map_1((arg0_1) => (new DayPlanFact_DayPlanFact(1, arg0_1)), tryFind_1((d_1) => equals(d_1.DaySegment, daySegmentFrom), x_5.DayFacts));
                }
                else {
                    return map_1((arg0) => (new DayPlanFact_DayPlanFact(0, arg0)), tryFind_1((d) => equals(d.DaySegment, daySegmentFrom), x_5.DayPlans));
                }
            }, tryFindStudentDay(partialApply(1, equalsCalendarDay, [calendarDayFrom]), state.StudentDays))))), state.StudentDays)), Cmd_none()];
        }
        case 2: {
            return [new Types_State(state.StudentId, state.StudentFullname, state.PeriodInfo, state.PeriodWeek, updateCalendarDay(msg.fields[0].CalendarDay, partialApply(1, deleteDayPlanFact, [msg.fields[1], msg.fields[2]]), state.StudentDays)), Cmd_none()];
        }
        case 3: {
            return [state, navigateTo(new Route(29, state.StudentId, msg.fields[0]))];
        }
        default: {
            return [new Types_State(state.StudentId, state.StudentFullname, state.PeriodInfo, state.PeriodWeek, addOrUpdateCalendarDay(msg.fields[0].CalendarDay, partialApply(1, addOrUpdateDayPlanFact, [msg.fields[1]]), state.StudentDays)), Cmd_none()];
        }
    }
}

export function View_renderNavigation(periodInfo, periodWeek, studentId, studentFullname, dispatch) {
    let props, props_23, props_21;
    const props_25 = singleton(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-2"], ["className", "has-text-weight-bold"], ["className", "pl-3"], ["children", toText(interpolate("for %P(), %P()/%P()", [studentFullname, periodInfo.PeriodYear, (periodInfo.PeriodYear + 1) % 100]))]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_23 = ofArray([["className", "pl-0"], ["children", Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "are-small"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_4;
        return append_1(singleton_1((props_4 = toList(delay(() => append_1(singleton_1(["href", toPath((periodWeek == null) ? (new Route(29, studentId, Calendar_PeriodInfo__Previous(periodInfo))) : (new Route(30, studentId, Calendar_getWeekInfo(Calendar_PeriodInfo__Previous(periodInfo), 0))))]), delay(() => {
            let elms;
            return singleton_1(["children", Interop_reactApi.Children.toArray([(elms = singleton(createElement("i", {
                className: join(" ", ["fa", "fa-chevron-left"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]);
        })))), createElement("a", createObj(Helpers_combineClasses("button", props_4))))), delay(() => {
            let patternInput;
            const periodToOption = (strValue, periodInfo_1) => [strValue, toText(interpolate("Period %P() - %P() / %P()", [periodInfo_1.Period + 1, periodInfo_1.PeriodYear, periodInfo_1.PeriodYear + 1])), false, periodInfo_1];
            const periods = toList(delay(() => append_1(singleton_1(periodToOption("current", Calendar_PeriodInfo_get_Current())), delay(() => append_1(singleton_1(["default", "----- Välj period -----", true, void 0]), delay(() => map_2((i) => periodToOption(Common_str(i), Calendar_PeriodInfo__PreviousN_Z524259A4(periodInfo, i)), toList(rangeDouble(1, 1, 11)))))))));
            const options = map((tupledArg) => View_renderSelectOptionDisabled(tupledArg[0], tupledArg[1], tupledArg[2]), periods);
            return append_1(singleton_1(createElement("div", {
                style: {
                    marginRight: 0.5 + "rem",
                    marginBottom: 0.5 + "rem",
                },
                children: Interop_reactApi.Children.toArray([(patternInput = Helpers_extractClasses(ofArray([["className", "is-small"], ["value", "default"], ["onChange", (ev) => {
                    const x = ev.target.value;
                    void map_1((period_1) => dispatch(new Types_Msg(3, period_1)), bind((tupledArg_2) => tupledArg_2[3], tryFind((tupledArg_1) => (tupledArg_1[0] === x), periods)));
                }], ["children", Interop_reactApi.Children.toArray(Array.from(options))]])), createElement("div", {
                    className: join(" ", cons("select", patternInput[0])),
                    children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
                }))]),
            })), delay(() => {
                let props_13;
                return append_1(singleton_1((props_13 = toList(delay(() => append_1(singleton_1(["href", toPath((periodWeek == null) ? (new Route(29, studentId, Calendar_PeriodInfo__Next(periodInfo))) : (new Route(30, studentId, Calendar_getWeekInfo(Calendar_PeriodInfo__Next(periodInfo), 0))))]), delay(() => {
                    let elms_1;
                    return singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton(createElement("i", {
                        className: join(" ", ["fa", "fa-chevron-right"]),
                    })), createElement("span", {
                        className: "icon",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                    }))])]);
                })))), createElement("a", createObj(Helpers_combineClasses("button", props_13))))), delay(() => append_1(collect((weekIndex) => {
                    let props_15;
                    const itemWeekInfo = new Calendar_WeekInfo(periodInfo, weekIndex);
                    return singleton_1((props_15 = toList(delay(() => append_1(equals(periodWeek, weekIndex) ? singleton_1(["className", "is-primary"]) : empty_1(), delay(() => append_1(singleton_1(["href", toPath(new Route(30, studentId, itemWeekInfo))]), delay(() => singleton_1(["children", toText(interpolate("V. %P()", [Calendar_getYearWeekIndex(itemWeekInfo)]))]))))))), createElement("a", createObj(Helpers_combineClasses("button", props_15)))));
                }, Calendar_periodWeeksRange), delay(() => {
                    let props_17;
                    return append_1(singleton_1((props_17 = toList(delay(() => append_1(equals(periodWeek, void 0) ? singleton_1(["className", "is-primary"]) : empty_1(), delay(() => append_1(singleton_1(["children", "Översikt"]), delay(() => singleton_1(["href", toPath(new Route(29, studentId, periodInfo))]))))))), createElement("a", createObj(Helpers_combineClasses("button", props_17))))), delay(() => {
                        let props_19;
                        return singleton_1((props_19 = ofArray([["href", toPath(new Route(30, studentId, Calendar_WeekInfo_get_Current()))], ["children", "Idag"]]), createElement("a", createObj(Helpers_combineClasses("button", props_19)))));
                    }));
                }))));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_23))))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_25)));
}

export function View_renderMainLayout(view_renderMainLayoutInputProps) {
    let props_4, elms, props_2;
    const renderContent = view_renderMainLayoutInputProps.renderContent;
    const student = view_renderMainLayoutInputProps.student;
    const periodWeek = view_renderMainLayoutInputProps.periodWeek;
    const periodInfo = view_renderMainLayoutInputProps.periodInfo;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(periodInfo, periodWeek, student, view_renderMainLayoutInputProps.studentDays), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_6 = singleton(["children", Interop_reactApi.Children.toArray([View_renderNavigation(periodInfo, periodWeek, student.Id, student.FullName, dispatch), (props_4 = singleton(["children", Interop_reactApi.Children.toArray([(elms = singleton(renderContent(state_1)(dispatch)), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_2 = ofArray([["style", {
        maxWidth: 25 + "%",
    }], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            position: "sticky",
            top: 10 + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement(View_Render, null), createElement(StatisticWithTabs, {
            overviewList: state_1.StudentDays,
            studentId: student.Id,
            periodInfo: periodInfo,
            periodWeek: periodWeek,
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_4))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_6)));
}

export function View_renderMainLayoutWithLoad(view_renderMainLayoutWithLoadInputProps) {
    const renderContent = view_renderMainLayoutWithLoadInputProps.renderContent;
    const periodWeek = view_renderMainLayoutWithLoadInputProps.periodWeek;
    const periodInfo = view_renderMainLayoutWithLoadInputProps.periodInfo;
    const studentId = view_renderMainLayoutWithLoadInputProps.studentId;
    const query = new StudentDays_StudentDayListRequest(studentId, periodInfo.PeriodYear, periodInfo.Period, periodWeek, void 0);
    const dependenciesArray = [studentId, periodInfo.PeriodYear, periodInfo.Period, periodWeek];
    const paramsDef = useFeliz_React__React_useDeferred_Static_2344FC52(singleton_2.Delay(() => singleton_2.Return(dependenciesArray)), dependenciesArray);
    const matchValue = [useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(studentDaysApi().list(query)), dependenciesArray), useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(studentsApi().getSettings(studentId)), [studentId]), paramsDef];
    let pattern_matching_result, error, error_1, paramsDef_2, student_2, studentDays_2;
    if (matchValue[0].tag === 3) {
        pattern_matching_result = 0;
        error = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 3) {
            pattern_matching_result = 0;
            error = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[0].tag === 0) {
                if (matchValue[2].tag === 2) {
                    if (equalsWith((x, y) => compare(x, y), matchValue[2].fields[0], dependenciesArray)) {
                        pattern_matching_result = 2;
                        paramsDef_2 = matchValue[2].fields[0];
                        student_2 = matchValue[1].fields[0].fields[0];
                        studentDays_2 = matchValue[0].fields[0];
                    }
                    else {
                        pattern_matching_result = 3;
                    }
                }
                else {
                    pattern_matching_result = 3;
                }
            }
            else {
                pattern_matching_result = 1;
                error_1 = matchValue[1].fields[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (matchValue[1].tag === 3) {
        pattern_matching_result = 0;
        error = matchValue[1].fields[0];
    }
    else if (matchValue[1].tag === 2) {
        if (matchValue[1].fields[0].tag === 1) {
            pattern_matching_result = 1;
            error_1 = matchValue[1].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            const value = error.message;
            return createElement("div", {
                children: [value],
            });
        }
        case 1: {
            return createElement("div", {
                children: [error_1],
            });
        }
        case 2: {
            return createElement(View_renderMainLayout, {
                periodInfo: periodInfo,
                periodWeek: periodWeek,
                student: student_2,
                studentDays: studentDays_2,
                renderContent: renderContent,
            });
        }
        case 3: {
            return loader("big");
        }
    }
}

export function View_renderPeriodLayout(state, dispatch) {
    const elms = toList(delay(() => map_2((planFact) => {
        const props_2 = singleton(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props;
            return append_1(singleton_1((props = ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", PlanFact_strPlanFact(planFact)]]), createElement("div", createObj(Helpers_combineClasses("box", props))))), delay(() => collect((periodWeek) => {
                const weekInfo = Calendar_getWeekInfo(state.PeriodInfo, periodWeek);
                return singleton_1(createElement(Render, new WeekParams(planFact, weekInfo, state.StudentId, filter((x) => equals(Shared_CalendarDay_CalendarDay__CalendarDay_WeekInfo(x.CalendarDay), weekInfo), state.StudentDays), new StudentDayActions((arg) => {
                    let tupledArg;
                    dispatch((tupledArg = arg, new Types_Msg(0, tupledArg[0], tupledArg[1])));
                }, (arg_1) => {
                    let tupledArg_1;
                    dispatch((tupledArg_1 = arg_1, new Types_Msg(1, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2], tupledArg_1[3])));
                }, (arg_2) => {
                    let tupledArg_2;
                    dispatch((tupledArg_2 = arg_2, new Types_Msg(2, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2])));
                }))));
            }, Calendar_periodWeeksRange)));
        }))))]);
        return createElement("div", createObj(Helpers_combineClasses("column", props_2)));
    }, PlanFact_list)));
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function View_renderWeekLayout(weekInfo, state, dispatch) {
    const studentDays = state.StudentDays;
    const children = toList(delay(() => append_1(collect((planFact) => {
        let props;
        return append_1(singleton_1((props = ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", PlanFact_strPlanFact(planFact)]]), createElement("div", createObj(Helpers_combineClasses("box", props))))), delay(() => singleton_1(createElement(Render, new WeekParams(planFact, weekInfo, state.StudentId, filter((x) => equals(Shared_CalendarDay_CalendarDay__CalendarDay_WeekInfo(x.CalendarDay), weekInfo), studentDays), new StudentDayActions((arg) => {
            let tupledArg;
            dispatch((tupledArg = arg, new Types_Msg(0, tupledArg[0], tupledArg[1])));
        }, (arg_1) => {
            let tupledArg_1;
            dispatch((tupledArg_1 = arg_1, new Types_Msg(1, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2], tupledArg_1[3])));
        }, (arg_2) => {
            let tupledArg_2;
            dispatch((tupledArg_2 = arg_2, new Types_Msg(2, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2])));
        }))))));
    }, PlanFact_list), delay(() => {
        let props_20, props_18;
        return singleton_1((props_20 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "Övrigt"]])))), (props_18 = ofArray([["className", "is-gapless"], ["className", "is-centered"], ["className", "pb-3"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_8;
            return append_1(singleton_1((props_8 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                className: "",
                children: "Sjuk",
            }), createElement("p", {
                className: "",
                children: "Skadad",
            }), createElement("p", {
                className: "",
                children: "Resedag",
            }), createElement("p", {
                className: "",
                children: "Vilopuls",
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))), delay(() => collect((weekDay) => {
                let props_16, s_4, s_5, s_6, s_7;
                const dayData = bind((x_2) => x_2.DayData, tryFind((x_1) => (x_1.CalendarDay.Day === weekDay), studentDays));
                return singleton_1((props_16 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", ""]])))), (s_4 = defaultArg(map_1((x_3) => {
                    if (x_3.IsSick) {
                        return "+";
                    }
                    else {
                        return "-";
                    }
                }, dayData), "-"), createElement("p", {
                    className: "",
                    children: s_4,
                })), (s_5 = defaultArg(map_1((x_4) => {
                    if (x_4.IsDamaged) {
                        return "+";
                    }
                    else {
                        return "-";
                    }
                }, dayData), "-"), createElement("p", {
                    className: "",
                    children: s_5,
                })), (s_6 = defaultArg(map_1((x_5) => {
                    if (x_5.IsTravelDay) {
                        return "+";
                    }
                    else {
                        return "-";
                    }
                }, dayData), "-"), createElement("p", {
                    className: "",
                    children: s_6,
                })), (s_7 = defaultArg(map_1((x_6) => int32ToString(x_6.Heartrate), dayData), "-"), createElement("p", {
                    className: "",
                    children: s_7,
                }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))));
            }, Calendar_weekDaysRange)));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_20)))));
    }))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function View_renderPeriod(studentId, periodInfo, _user) {
    return createElement(View_renderMainLayoutWithLoad, {
        studentId: studentId,
        periodInfo: periodInfo,
        periodWeek: void 0,
        renderContent: (state) => ((dispatch) => View_renderPeriodLayout(state, dispatch)),
    });
}

export function View_renderWeek(studentId, weekInfo, _user) {
    return createElement(View_renderMainLayoutWithLoad, {
        studentId: studentId,
        periodInfo: weekInfo.PeriodInfo,
        periodWeek: weekInfo.PeriodWeek,
        renderContent: (state) => ((dispatch) => View_renderWeekLayout(weekInfo, state, dispatch)),
    });
}

