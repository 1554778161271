import { Union } from "./.fable/fable-library.3.2.1/Types.js";
import { class_type, union_type } from "./.fable/fable-library.3.2.1/Reflection.js";
import { Cmd_ofSub } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { createCancellationToken, startImmediate } from "./.fable/fable-library.3.2.1/Async.js";
import { singleton } from "./.fable/fable-library.3.2.1/AsyncBuilder.js";
import { removeUser } from "./LocalStorage.fs.js";
import { ProxyRequestException__get_StatusCode, ProxyRequestException } from "./.fable/Fable.Remoting.Client.7.16.0/Types.fs.js";
import { Result_MapError, Result_Map } from "./.fable/fable-library.3.2.1/Choice.js";
import { Toastr_title, Toastr_extendedTimout, Toastr_showCloseButton, Toastr_error, Toastr_warning, Toastr_message, Toastr_position, Toastr_timeout, Toastr_success } from "./.fable/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";
import { warning, info, error as error_1, success } from "toastr";
import { defaultArg } from "./.fable/fable-library.3.2.1/Option.js";
import { empty as empty_1, tryFind } from "./.fable/fable-library.3.2.1/Map.js";
import { isEmpty, fold, empty } from "./.fable/fable-library.3.2.1/List.js";
import { interpolate, toText } from "./.fable/fable-library.3.2.1/String.js";
import { Validator$1__Test, Validator$1__NotBlank_2B595, Validator$1__End_Z5E18B1E2, validateSync } from "./.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { Auth_LogInRequest, EmailModule_ofString } from "../Shared/Shared.fs.js";
import { equals } from "./.fable/fable-library.3.2.1/Util.js";
import { toList } from "./.fable/fable-library.3.2.1/Seq.js";

export class DeferredResult$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStartedYet", "InProgress", "Resolved"];
    }
}

export function DeferredResult$1$reflection(gen0) {
    return union_type("Extensions.DeferredResult`1", [gen0], DeferredResult$1, () => [[], [], [["Item", gen0]]]);
}

export class AsyncOperationStatus$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Started", "Finished"];
    }
}

export function AsyncOperationStatus$1$reflection(gen0) {
    return union_type("Extensions.AsyncOperationStatus`1", [gen0], AsyncOperationStatus$1, () => [[], [["Item", gen0]]]);
}

export function Cmd_fromAsync(operation) {
    return Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(operation, (_arg1) => {
            dispatch(_arg1);
            return singleton.Zero();
        })));
    });
}

export function Cmd_fromAsyncCsp(operation) {
    const cs = createCancellationToken();
    return [Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(operation, (_arg1) => {
            dispatch(_arg1);
            return singleton.Zero();
        })), cs);
    }), cs];
}

export function Remoting_handleNonAuth(computation) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.ReturnFrom(computation)), (_arg1) => {
        let pattern_matching_result, ex_1;
        if (_arg1 instanceof ProxyRequestException) {
            if (ProxyRequestException__get_StatusCode(_arg1) === 401) {
                pattern_matching_result = 0;
                ex_1 = _arg1;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                console.debug("Catch 401, removing user from storage and raise un-authorize event");
                removeUser();
                document.dispatchEvent(new Event('Fable.Remoting.Unauthorized'));
                return singleton.Return((() => {
                    throw ex_1;
                })());
            }
            case 1: {
                throw _arg1;
                return null;
            }
        }
    }));
}

export function Remoting_subscribe(msg, model) {
    return Cmd_ofSub((dispatch) => {
        document.addEventListener('Fable.Remoting.Unauthorized', function (e) {(() => {
            dispatch(msg);
        })() });
    });
}

export function Remoting_userChanged(userUpdate) {
    document.dispatchEvent(new CustomEvent('User.Changed',{ detail: {userUpdate: userUpdate}}));
}

export function Remoting_subscribeUserChangedEvent(msg) {
    return Cmd_ofSub((dispatch) => {
        document.addEventListener('User.Changed', function (e) {((x) => {
            dispatch(msg(x));
        })(e.detail.userUpdate) });
    });
}

export function KeyValuePair_key(x) {
    return x[0];
}

export function KeyValuePair_value(x) {
    return x[1];
}

export function KeyValuePair_create(key, value) {
    return [key, value];
}

export function DeferredResult_map(f, x) {
    switch (x.tag) {
        case 1: {
            return new DeferredResult$1(1);
        }
        case 2: {
            return new DeferredResult$1(2, Result_Map(f, x.fields[0]));
        }
        default: {
            return new DeferredResult$1(0);
        }
    }
}

export function DeferredResult_mapError(f, x) {
    switch (x.tag) {
        case 1: {
            return new DeferredResult$1(1);
        }
        case 2: {
            return new DeferredResult$1(2, Result_MapError(f, x.fields[0]));
        }
        default: {
            return new DeferredResult$1(0);
        }
    }
}

export function DeferredResult_fold(fOk, fError, fDefault, x) {
    let pattern_matching_result, x_1, error;
    if (x.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (x.tag === 2) {
        if (x.fields[0].tag === 1) {
            pattern_matching_result = 2;
            error = x.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
            x_1 = x.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return fDefault();
        }
        case 1: {
            return fOk(x_1);
        }
        case 2: {
            return fError(error);
        }
    }
}

export function DeferredResult_tee(f, x) {
    let pattern_matching_result, item;
    if (x.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (x.tag === 2) {
        if (x.fields[0].tag === 1) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 1;
            item = x.fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return x;
        }
        case 1: {
            f(item);
            return x;
        }
        case 2: {
            return x;
        }
    }
}

export function DeferredResult_isOk(x) {
    return DeferredResult_fold((_arg1) => true, (_arg2) => false, () => false, x);
}

export function DeferredResult_isError(x) {
    return DeferredResult_fold((_arg1) => false, (_arg2) => true, () => false, x);
}

export function View_Cmd_toastSuccess(msg) {
    return Toastr_success(Toastr_timeout(1000, Toastr_position("toast-top-right", Toastr_message(msg))));
}

export function View_Cmd_toastWarning(msg) {
    return Toastr_warning(Toastr_position("toast-top-right", Toastr_message(msg)));
}

export function View_Cmd_toastError(msg) {
    return Toastr_error(Toastr_position("toast-top-right", Toastr_message(msg)));
}

export function View_Cmd_toastServerError(title, msg) {
    return Toastr_error(Toastr_showCloseButton(Toastr_timeout(0, Toastr_extendedTimout(0, Toastr_position("toast-top-right", Toastr_title(title, Toastr_message(msg)))))));
}

const Toastr_successToastWithTitle = success;

const Toastr_errorToastWithTitle = error_1;

const Toastr_infoToastWithTitle = info;

const Toastr_warningToastWithTitle = warning;

export function Toastr_toastSuccess(msg, title) {
    Toastr_successToastWithTitle(msg, title, {});
}

export function Toastr_toastWarning(msg, title) {
    Toastr_warningToastWithTitle(msg, title, {});
}

export function Toastr_toastError(msg, title) {
    Toastr_errorToastWithTitle(msg, title, {});
}

export function Form_filter(x, errors) {
    if (x) {
        return errors;
    }
    else {
        return void 0;
    }
}

export function Form_getFieldError(errors, field) {
    return defaultArg(tryFind(field, errors), empty());
}

export function Form_Field_errors(fieldName, errors) {
    return Form_getFieldError(errors, fieldName);
}

export function Form_Field_errorsAsString(fieldName) {
    return (arg) => fold((s, x) => toText(interpolate("%s%P() %s%P()", [s, x])), "", Form_Field_errors(fieldName, arg));
}

export function Form_Field_hasErrors(fieldName) {
    return (arg_1) => (!isEmpty(Form_Field_errors(fieldName, arg_1)));
}

export function Form_validateLogin(user) {
    return validateSync(true, (t) => (new Auth_LogInRequest(EmailModule_ofString(Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Email is required")(Validator$1__Test(t, "Email", user.Email)))), Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Password is required")(Validator$1__Test(t, "Password", user.Password))))));
}

export function Form_validationLogin(user, validationState) {
    const form = Form_validateLogin(user);
    if (form.tag === 1) {
        return validationState ? form.fields[0] : empty_1();
    }
    else {
        return empty_1();
    }
}

export class FlatpickrExtensions_Locales {
    constructor() {
    }
}

export function FlatpickrExtensions_Locales$reflection() {
    return class_type("Extensions.FlatpickrExtensions.Locales", void 0, FlatpickrExtensions_Locales);
}

export function Feliz_Recharts_Interop_objectHas(keys, x) {
    return equals(keys, toList(Object.keys(x)));
}

