import { Record } from "../.fable/fable-library.3.2.1/Types.js";
import { DurationModule_intToHMSString, Workout_getWorkoutTotalDuration, DurationModule_toInt, DaySegment_list, StudentDays_StudentDayKey, DayPlanFact_DayPlanFact, StudentDays_StudentDayInfo_Empty, Calendar_getCalendarDayFromWeekInfo, DaySegment_strDaySegment, Calendar_weekDaysRange, Calendar_getMonth3LetterTitle, Calendar_getWeekDay3LetterTitle, Calendar_getWeekDayDate, Calendar_getWeekStartDate, StudentDays_StudentDayInfo$reflection, Calendar_WeekInfo$reflection, PlanFact_PlanFact$reflection } from "../../Shared/Shared.fs.js";
import { record_type, list_type, class_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { Render as Render_1, StudentDayActions$reflection } from "./DayPlanFactBox.fs.js";
import { borderStyleBottomGrey, borderStyleBottomPrimary, borderStylePrimary } from "../Common.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { map, collect, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import { equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { filter, sumBy as sumBy_1, map as map_2, tryFind, ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { map as map_1, defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { day as day_3, month as month_1 } from "../.fable/fable-library.3.2.1/Date.js";
import { interpolate, toText } from "../.fable/fable-library.3.2.1/String.js";
import { map as map_3, sum, sumBy, tryFind as tryFind_1 } from "../.fable/fable-library.3.2.1/Array.js";

export class WeekParams extends Record {
    constructor(PlanFact, WeekInfo, StudentId, StudentDays, Actions) {
        super();
        this.PlanFact = PlanFact;
        this.WeekInfo = WeekInfo;
        this.StudentId = StudentId;
        this.StudentDays = StudentDays;
        this.Actions = Actions;
    }
}

export function WeekParams$reflection() {
    return record_type("CalendarWeek.WeekParams", [], WeekParams, () => [["PlanFact", PlanFact_PlanFact$reflection()], ["WeekInfo", Calendar_WeekInfo$reflection()], ["StudentId", class_type("System.Guid")], ["StudentDays", list_type(StudentDays_StudentDayInfo$reflection())], ["Actions", StudentDayActions$reflection()]]);
}

export function Render(weekParams) {
    const props_20 = ofArray([["className", "px-2"], ["className", "pb-0"], borderStylePrimary, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_8;
        return append(singleton((props_8 = ofArray([["className", "has-text-centered"], ["className", "is-gapless"], ["className", "mb-2"], ["className", "pb-3"], borderStyleBottomPrimary, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"]))))), delay(() => {
            const weekStartDate = Calendar_getWeekStartDate(weekParams.WeekInfo);
            return collect((weekDay) => {
                let props_6, props_2, props_4;
                const weekDayDate = Calendar_getWeekDayDate(weekStartDate, weekDay);
                return singleton((props_6 = ofArray([["className", "py-1"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "has-text-black"], ["className", "is-size-7"], ["children", defaultArg(Calendar_getWeekDay3LetterTitle(weekDay), "")]]), createElement("p", createObj(Helpers_combineClasses("", props_2)))), (props_4 = toList(delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["className", "is-size-6"]), delay(() => {
                    const month = defaultArg(Calendar_getMonth3LetterTitle(month_1(weekDayDate)), "");
                    return singleton(["children", toText(interpolate("%P() %P()", [day_3(weekDayDate), month]))]);
                })))))), createElement("span", createObj(Helpers_combineClasses("", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))));
            }, Calendar_weekDaysRange);
        }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_8))))), delay(() => append(map((daySegment) => {
            const props_12 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "py-1"], ["className", "my-2"], borderStyleBottomGrey, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_10;
                return append(singleton((props_10 = ofArray([["className", "is-1"], ["children", DaySegment_strDaySegment(daySegment)]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))), delay(() => collect((weekDay_1) => {
                    const calendarDay = Calendar_getCalendarDayFromWeekInfo(weekParams.WeekInfo, weekDay_1);
                    const studentDay = defaultArg(tryFind((x) => equals(x.CalendarDay, calendarDay), weekParams.StudentDays), StudentDays_StudentDayInfo_Empty(weekParams.StudentId, calendarDay));
                    const dayPlanFact = (weekParams.PlanFact.tag === 1) ? map_1((arg0_1) => (new DayPlanFact_DayPlanFact(1, arg0_1)), tryFind_1((fact) => equals(fact.DaySegment, daySegment), studentDay.DayFacts)) : map_1((arg0) => (new DayPlanFact_DayPlanFact(0, arg0)), tryFind_1((plan) => equals(plan.DaySegment, daySegment), studentDay.DayPlans));
                    const studentDayKey = new StudentDays_StudentDayKey(studentDay.StudentId, studentDay.CalendarDay);
                    return singleton(createElement(Render_1, {
                        dayPlanFact: dayPlanFact,
                        studentDay: studentDayKey,
                        daySegment: daySegment,
                        planFact: weekParams.PlanFact,
                        actions: weekParams.Actions,
                    }));
                }, Calendar_weekDaysRange)));
            }))))]]);
            return createElement("div", createObj(Helpers_combineClasses("columns", props_12)));
        }, DaySegment_list), delay(() => {
            let props_18;
            return singleton((props_18 = ofArray([["className", "is-gapless"], ["className", "py-2"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"]))))), delay(() => {
                const durations = (weekParams.PlanFact.tag === 1) ? map_2((day_1) => [day_1.CalendarDay.Day, sumBy((x_3) => DurationModule_toInt(Workout_getWorkoutTotalDuration(x_3.Workout)), day_1.DayFacts, {
                    GetZero: () => 0,
                    Add: (x_4, y_1) => (x_4 + y_1),
                })], weekParams.StudentDays) : map_2((day) => [day.CalendarDay.Day, sum(map_3((x_1) => DurationModule_toInt(Workout_getWorkoutTotalDuration(x_1.Workout)), day.DayPlans, Int32Array), {
                    GetZero: () => 0,
                    Add: (x_2, y) => (x_2 + y),
                })], weekParams.StudentDays);
                return collect((weekDay_2) => {
                    let props_16;
                    return singleton((props_16 = ofArray([["className", "py-1"], ["className", "has-text-centered"], ["children", DurationModule_intToHMSString(sumBy_1((tuple) => tuple[1], filter((tupledArg) => (tupledArg[0] === weekDay_2), durations), {
                        GetZero: () => 0,
                        Add: (x_5, y_2) => (x_5 + y_2),
                    }))]]), createElement("div", createObj(Helpers_combineClasses("column", props_16)))));
                }, Calendar_weekDaysRange);
            }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18)))));
        }))));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_20)));
}

