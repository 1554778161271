import { Record } from "../fable-library.3.2.1/Types.js";
import { class_type, record_type, bool_type, string_type, obj_type } from "../fable-library.3.2.1/Reflection.js";
import { map as map_1, toArray, ofArray } from "../fable-library.3.2.1/List.js";
import { keyValueList } from "../fable-library.3.2.1/MapUtil.js";
import { getEnumerator } from "../fable-library.3.2.1/Util.js";
import * as react from "react";
import react$002Dflatpickr from "react-flatpickr";

class OptionType extends Record {
    constructor(Value, Key, IsConfig) {
        super();
        this.Value = Value;
        this.Key = Key;
        this.IsConfig = IsConfig;
    }
}

function OptionType$reflection() {
    return record_type("Flatpickr.OptionType", [], OptionType, () => [["Value", obj_type], ["Key", string_type], ["IsConfig", bool_type]]);
}

export function Value(date) {
    return new OptionType(date, "value", false);
}

export function EnableTimePicker(cond) {
    return new OptionType(cond, "enableTime", true);
}

export function EnableSecondsPicker(cond) {
    return new OptionType(cond, "enableSeconds", true);
}

export function MinimumDate(date) {
    return new OptionType(date, "minDate", true);
}

export function MaximumDate(date) {
    return new OptionType(date, "maxDate", true);
}

export function Placeholder(placeholder) {
    return new OptionType(placeholder, "placeholder", false);
}

export function Id(id) {
    return new OptionType(id, "id", false);
}

export function custom(key, value, config) {
    return new OptionType(value, key, config);
}

export function DefaultHour(hour) {
    return new OptionType(hour, "defaultHour", true);
}

export function DefaultMinute(min) {
    return new OptionType(min, "defaultMinute", true);
}

export function EnableWeekNumbers(cond) {
    return new OptionType(cond, "weekNumbers", true);
}

export function OnChange(callback) {
    return new OptionType((dates) => {
        if ((dates == null) ? true : (dates.length === 0)) {
        }
        else {
            callback(dates[0]);
        }
    }, "onChange", false);
}

export function OnManyChanged(callback) {
    return new OptionType((dates) => {
        callback(ofArray(dates));
    }, "onChange", false);
}

export function ClassName(name) {
    return new OptionType(name, "className", false);
}

export function Style(props) {
    return new OptionType(keyValueList(props, 1), "style", false);
}

export function Disabled(value) {
    return new OptionType(value, "disabled", false);
}

export function DateFormat(value) {
    return new OptionType(value, "dateFormat", true);
}

export function HideCalendar(value) {
    return new OptionType(value, "noCalendar", true);
}

export function SelectionMode(mode) {
    return new OptionType(mode, "mode", true);
}

export function AlwaysOpen(value) {
    return new OptionType(value, "inline", true);
}

export function DisableDates(dates) {
    return new OptionType(toArray(dates), "disable", true);
}

export function DisableBy(pred) {
    return new OptionType([pred], "disable", true);
}

export function MinuteIncrement(min) {
    return new OptionType(min, "minuteIncrement", true);
}

export function HourIncrement(hours) {
    return new OptionType(hours, "hourIncrement", true);
}

export function DisableRanges(ranges) {
    return new OptionType(toArray(map_1((tupledArg) => {
        const range = {};
        range["to"] = tupledArg[1];
        range["from"] = tupledArg[0];
        return range;
    }, ranges)), "disable", true);
}

export function EnableDates(dates) {
    return new OptionType(toArray(dates), "enable", true);
}

export function EnableBy(pred) {
    return new OptionType([pred], "enable", true);
}

export function FormatDateBy(map) {
    return new OptionType(map, "formatDate", true);
}

export function TimeTwentyFour(cond) {
    return new OptionType(cond, "time_24hr", true);
}

export function UseShorthandMonthNames(cond) {
    return new OptionType(cond, "shorthandCurrentMonth", true);
}

export function EnableRanges(ranges) {
    return new OptionType(toArray(map_1((tupledArg) => {
        const range = {};
        range["to"] = tupledArg[1];
        range["from"] = tupledArg[0];
        return range;
    }, ranges)), "enable", true);
}

export function Locale(loc) {
    return new OptionType(loc, "locale", true);
}

export function flatpickr(options) {
    const props = {};
    const propOptions = {};
    const enumerator = getEnumerator(options);
    try {
        while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
            const option = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
            if (option.IsConfig) {
                propOptions[option.Key] = option.Value;
            }
            else {
                props[option.Key] = option.Value;
            }
        }
    }
    finally {
        enumerator.Dispose();
    }
    props["options"] = propOptions;
    return react.createElement(react$002Dflatpickr, props);
}

export class Locales {
    constructor() {
    }
}

export function Locales$reflection() {
    return class_type("Flatpickr.Locales", void 0, Locales);
}

