import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { bool_type, union_type, unit_type, list_type, record_type, string_type, class_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { GroupIdModule_fromString, GroupIdModule_toString, SchoolIdModule_fromString, SchoolIdModule_toString, EmailModule_toString, Admin_StudentSchoolUpdateRequest, Admin_GroupAdminItem$reflection, Schools_School$reflection } from "../../Shared/Shared.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, View_Cmd_toastSuccess, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { schoolsApi, adminApi } from "../Communication.fs.js";
import { validateSync } from "../.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton as singleton_1, cons, ofArray, filter, map } from "../.fable/fable-library.3.2.1/List.js";
import { borderStylePrimary, View_renderSelectOption } from "../Common.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_Form extends Record {
    constructor(Id, Email, FullName, SchoolId, GroupId) {
        super();
        this.Id = Id;
        this.Email = Email;
        this.FullName = FullName;
        this.SchoolId = SchoolId;
        this.GroupId = GroupId;
    }
}

export function Types_Form$reflection() {
    return record_type("StudentSchoolEdit.Types.Form", [], Types_Form, () => [["Id", class_type("System.Guid")], ["Email", string_type], ["FullName", string_type], ["SchoolId", class_type("System.Guid")], ["GroupId", class_type("System.Guid")]]);
}

export class Types_State extends Record {
    constructor(Form, OriginalValue, Schools, Groups, Saving, FormErrors, IsNeedValidation) {
        super();
        this.Form = Form;
        this.OriginalValue = OriginalValue;
        this.Schools = Schools;
        this.Groups = Groups;
        this.Saving = Saving;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
    }
}

export function Types_State$reflection() {
    return record_type("StudentSchoolEdit.Types.State", [], Types_State, () => [["Form", Types_Form$reflection()], ["OriginalValue", Types_Form$reflection()], ["Schools", list_type(Schools_School$reflection())], ["Groups", list_type(Admin_GroupAdminItem$reflection())], ["Saving", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SchoolIdChanged", "GroupIdChanged", "Save"];
    }
}

export function Types_Msg$reflection() {
    return union_type("StudentSchoolEdit.Types.Msg", [], Types_Msg, () => [[["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_save(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Admin_StudentSchoolUpdateRequest(state.Form.Id, state.Form.SchoolId, state.Form.GroupId);
        return singleton.Bind(Remoting_handleNonAuth(adminApi().updateStudentSchool(request)), (_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, response.fields[0])))) : singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))));
        });
    }), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Validation_validate(formInfo) {
    return validateSync(true, (_arg1) => (new Types_Form(formInfo.Id, formInfo.Email, formInfo.FullName, formInfo.SchoolId, formInfo.GroupId)));
}

export function Validation_validateIf(needValidate, x) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

export function State_init(student, schools, groups) {
    const form = new Types_Form(student.Id, student.Email, student.FullName, student.SchoolId, student.GroupId);
    return [new Types_State(form, form, schools, groups, new DeferredResult$1(0), empty(), false), Cmd_none()];
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord;
    if (msg.tag === 1) {
        const patternInput_1 = Validation_validateIf(state.IsNeedValidation, (inputRecord_1 = state.Form, new Types_Form(inputRecord_1.Id, inputRecord_1.Email, inputRecord_1.FullName, inputRecord_1.SchoolId, msg.fields[0])));
        return [new Types_State(patternInput_1[0], state.OriginalValue, state.Schools, state.Groups, state.Saving, patternInput_1[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Form, state.OriginalValue, state.Schools, state.Groups, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Form, state.Form, state.Schools, state.Groups, new DeferredResult$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.IsNeedValidation), View_Cmd_toastSuccess("Student saved")];
            }
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new Types_State(state.Form, state.OriginalValue, state.Schools, state.Groups, state.Saving, matchValue.fields[0], true), Cmd_none()];
            }
            else {
                return [new Types_State(matchValue.fields[0], state.OriginalValue, state.Schools, state.Groups, new DeferredResult$1(1), empty(), true), Cmd_fromAsync(Cmd_save(state))];
            }
        }
    }
    else {
        const patternInput = Validation_validateIf(state.IsNeedValidation, (inputRecord = state.Form, new Types_Form(inputRecord.Id, inputRecord.Email, inputRecord.FullName, msg.fields[0], inputRecord.GroupId)));
        return [new Types_State(patternInput[0], state.OriginalValue, state.Schools, state.Groups, state.Saving, patternInput[1], state.IsNeedValidation), Cmd_none()];
    }
}

export function View_view(view_viewInputProps) {
    let props_66, props_64, props_62, props_60, props_12, props_10, props_8, elms_1, elms, props_24, props_22, props_20, elms_3, elms_2, props_16, props_39, props_37, props_35, props_33, elms_4, patternInput, props_54, props_52, props_50, props_48, elms_5, patternInput_1, elms_7, elms_6, props_56;
    const dispatch = view_viewInputProps.dispatch;
    const state = view_viewInputProps.state;
    const availableSchools = map((x) => View_renderSelectOption(x.Id, x.Title), state.Schools);
    const availableGroups = map((x_2) => View_renderSelectOption(x_2.Id, x_2.Title), filter((x_1) => (x_1.SchoolId === state.Form.SchoolId), state.Groups));
    const elms_8 = singleton_1((props_66 = ofArray([["className", "is-6"], ["className", "pt-6"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Ändra egenskaper"]])))), (props_64 = ofArray([["className", "px-5"], borderStylePrimary, ["children", Interop_reactApi.Children.toArray([(props_62 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_60 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Fullständigt namn: "]])))), (props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = singleton_1(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["defaultValue", state.Form.FullName], ["disabled", true]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_12)))), (props_24 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Epost:"]])))), (props_20 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1((elms_2 = singleton_1((props_16 = ofArray([["defaultValue", EmailModule_toString(state.Form.Email)], ["disabled", true]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_16)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_24)))), (props_39 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_37 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "School:"]])))), (props_35 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_33 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_4 = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray(Array.from(availableSchools))], ["value", SchoolIdModule_toString(state.Form.SchoolId)], ["onChange", (ev) => {
        dispatch(new Types_Msg(0, SchoolIdModule_fromString(ev.target.value)));
    }]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_33))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_35))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_37))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_39)))), (props_54 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_52 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Grupp:"]])))), (props_50 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_48 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_1((patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray(Array.from(availableGroups))], ["value", GroupIdModule_toString(state.Form.GroupId)], ["onChange", (ev_1) => {
        dispatch(new Types_Msg(1, GroupIdModule_fromString(ev_1.target.value)));
    }]])), createElement("div", {
        className: join(" ", cons("select", patternInput_1[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_48))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_50))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_52))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_54)))), (elms_7 = singleton_1((elms_6 = singleton_1((props_56 = ofArray([["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["children", "Spara"], ["disabled", equals(state.Saving, new DeferredResult$1(1)) ? true : equals(state.Form, state.OriginalValue)], ["onClick", (x_7) => {
        x_7.preventDefault();
        dispatch(new Types_Msg(2, new AsyncOperationStatus$1(0)));
    }]]), createElement("a", createObj(Helpers_combineClasses("button", props_56))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_60))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_62))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_64))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_66)))));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    });
}

export function Component_RenderForm(component_RenderFormInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(component_RenderFormInputProps.student, component_RenderFormInputProps.isNew, component_RenderFormInputProps.groups), (msg, state) => State_update(msg, state), []);
    return createElement(View_view, {
        state: patternInput[0],
        dispatch: patternInput[1],
    });
}

export function Component_ResolveStudent(component_ResolveStudentInputProps) {
    const groups = component_ResolveStudentInputProps.groups;
    const schools = component_ResolveStudentInputProps.schools;
    const id = component_ResolveStudentInputProps.id;
    const student = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(adminApi().getStudentSchool(id)), [id]);
    switch (student.tag) {
        case 2: {
            return createElement(Component_RenderForm, {
                student: student.fields[0],
                isNew: schools,
                groups: groups,
            });
        }
        case 3: {
            return createElement("div", {
                children: ["Error"],
            });
        }
        default: {
            return loader("big");
        }
    }
}

export function Component_Render(component_RenderInputProps) {
    const _arg1 = component_RenderInputProps._arg1;
    const id = component_RenderInputProps.id;
    const matchValue = [useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(schoolsApi().list()), [id]), useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(adminApi().getAllGroups()), [id])];
    let pattern_matching_result, groups_1, schools_1;
    if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 2) {
            pattern_matching_result = 0;
            groups_1 = matchValue[1].fields[0];
            schools_1 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return createElement(Component_ResolveStudent, {
                id: id,
                schools: schools_1,
                groups: groups_1,
            });
        }
        case 1: {
            return loader("big");
        }
    }
}

