import { Union, Record } from "./.fable/fable-library.3.2.1/Types.js";
import { union_type, unit_type, option_type, class_type, record_type, string_type } from "./.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, View_Cmd_toastSuccess, Remoting_userChanged, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "./Extensions.fs.js";
import { singleton } from "./.fable/fable-library.3.2.1/AsyncBuilder.js";
import { Auth_SignedInUser, Students_StudentUpdateChatTextRequest } from "../Shared/Shared.fs.js";
import { studentsApi } from "./Communication.fs.js";
import { Cmd_none } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { toLocalTime, now } from "./.fable/fable-library.3.2.1/DateOffset.js";
import { Interop_reactApi } from "./.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj, equals } from "./.fable/fable-library.3.2.1/Util.js";
import { singleton as singleton_1, ofArray } from "./.fable/fable-library.3.2.1/List.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { borderStylePrimary } from "./Common.fs.js";
import { interpolate, toText, join } from "./.fable/fable-library.3.2.1/String.js";
import { map, defaultArg } from "./.fable/fable-library.3.2.1/Option.js";
import { toString } from "./.fable/fable-library.3.2.1/Date.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "./Loader.fs.js";

export class Types_Form extends Record {
    constructor(ChatText) {
        super();
        this.ChatText = ChatText;
    }
}

export function Types_Form$reflection() {
    return record_type("Chat.Types.Form", [], Types_Form, () => [["ChatText", string_type]]);
}

export class Types_State extends Record {
    constructor(StudentId, CurrentUserId, ChatText, ChatChangeDate, Changing) {
        super();
        this.StudentId = StudentId;
        this.CurrentUserId = CurrentUserId;
        this.ChatText = ChatText;
        this.ChatChangeDate = ChatChangeDate;
        this.Changing = Changing;
    }
}

export function Types_State$reflection() {
    return record_type("Chat.Types.State", [], Types_State, () => [["StudentId", class_type("System.Guid")], ["CurrentUserId", class_type("System.Guid")], ["ChatText", string_type], ["ChatChangeDate", option_type(class_type("System.DateTimeOffset"))], ["Changing", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChatTextChanged", "ChangeChatText"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Chat.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_changeChatText(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Students_StudentUpdateChatTextRequest(state.StudentId, state.ChatText);
        return singleton.Bind(Remoting_handleNonAuth(studentsApi().updateChatText(request)), (_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, response.fields[0])))) : singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))));
        });
    }), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(studentSettings, currentUserId) {
    return [new Types_State(studentSettings.Id, currentUserId, studentSettings.ChatText, studentSettings.ChatChangeDate, new DeferredResult$1(0)), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.StudentId, state.CurrentUserId, state.ChatText, state.ChatChangeDate, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                if (state.StudentId === state.CurrentUserId) {
                    Remoting_userChanged((x_2) => (new Auth_SignedInUser(x_2.Email, x_2.FullName, x_2.SchoolId, x_2.GroupId, x_2.GdprAcceptDate, x_2.AccessToken, x_2.Role, x_2.UserId, x_2.ChatText, now())));
                }
                return [new Types_State(state.StudentId, state.CurrentUserId, state.ChatText, now(), new DeferredResult$1(2, new FSharpResult$2(0, void 0))), View_Cmd_toastSuccess("Chatten har sparats")];
            }
        }
        else {
            return [state, Cmd_fromAsync(Cmd_changeChatText(state))];
        }
    }
    else {
        return [new Types_State(state.StudentId, state.CurrentUserId, msg.fields[0], state.ChatChangeDate, state.Changing), Cmd_none()];
    }
}

export function View_renderForm(state, dispatch) {
    let elms_3, elms, props_2, props, value_3, elms_2, elms_1;
    const props_11 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1(createElement("form", {
        onSubmit: (x) => {
            x.preventDefault();
            dispatch(new Types_Msg(1, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray([(elms = singleton_1((props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = ofArray([["rows", 20], (value_3 = state.ChatText, ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_3)) : false) {
                e.value = value_3;
            }
        }]), ["onChange", (ev) => {
            dispatch(new Types_Msg(0, ev.target.value));
        }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_2))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_2 = singleton_1((elms_1 = singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["className", "has-text-weight-bold"], ["children", "Spara"]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_11)));
}

export function View_view(view_viewInputProps) {
    let props_11, elms, props_1, props_3, elms_1, props_6, props_9;
    const studentSettings = view_viewInputProps.studentSettings;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(studentSettings, view_viewInputProps.currentUserId), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const props_13 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "px-5"], borderStylePrimary, ["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_1 = ofArray([["className", "is-medium"], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: join(" ", ["fa", "fa-user", "fa-lg"]),
    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_1)))), (props_3 = ofArray([["className", "is-size-4"], ["className", "has-text-weight-bold"], ["className", "has-text-primary"], ["children", toText(interpolate("Chat för %P()", [studentSettings.FullName]))]]), createElement("span", createObj(Helpers_combineClasses("", props_3))))]), createElement("div", {
        className: "block",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton_1((props_6 = ofArray([["className", "is-size-5"], ["className", "has-text-primary"], ["className", "mx-2"], ["children", defaultArg(map((x) => {
        let copyOfStruct;
        return "Senast uppdaterad: " + (copyOfStruct = toLocalTime(x), toString(copyOfStruct, "yyyy-MM-dd HH:mm:ss"));
    }, state_1.ChatChangeDate), "Senast uppdaterad: aldrig")]]), createElement("span", createObj(Helpers_combineClasses("", props_6))))), createElement("div", {
        className: "block",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_9 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([View_renderForm(state_1, patternInput[1])])]]), createElement("div", createObj(Helpers_combineClasses("box", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_11))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_13)));
}

export function Component_ResolveStudentChat(component_ResolveStudentChatInputProps) {
    const user = component_ResolveStudentChatInputProps.user;
    const studentId = component_ResolveStudentChatInputProps.studentId;
    const studentSettings = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(studentsApi().getSettings(studentId)), [studentId]);
    if (studentSettings.tag === 2) {
        if (studentSettings.fields[0].tag === 1) {
            return createElement("div", {
                children: [studentSettings.fields[0].fields[0]],
            });
        }
        else {
            return createElement(View_view, {
                studentSettings: studentSettings.fields[0].fields[0],
                currentUserId: user,
            });
        }
    }
    else {
        return loader("big");
    }
}

export function Component_Render(component_RenderInputProps) {
    const user = component_RenderInputProps.user;
    const studentId = component_RenderInputProps.studentId;
    return createElement(Component_ResolveStudentChat, {
        studentId: studentId,
        user: user.UserId,
    });
}

