import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { union_type, unit_type, list_type, bool_type, record_type, string_type, class_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { Students_StudentSettings_Default_Z3CDDBBFA, GroupIdModule_fromString, GroupIdModule_toString, EmailModule_toString, EmailModule_ofString, Students_StudentUpdateSettingsRequest, Students_StudentCreateRequest, Groups_GroupListItem$reflection } from "../../Shared/Shared.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, View_Cmd_toastSuccess, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { groupsApi, studentsApi } from "../Communication.fs.js";
import { Validator$1__Test, Validator$1__NotBlank_2B595, Validator$1__End_Z5E18B1E2, validateSync } from "../.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { head, singleton as singleton_1, cons, ofArray, map } from "../.fable/fable-library.3.2.1/List.js";
import { borderStylePrimary, View_renderSelectOption } from "../Common.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_Form extends Record {
    constructor(Id, Email, Password, FullName, GroupId) {
        super();
        this.Id = Id;
        this.Email = Email;
        this.Password = Password;
        this.FullName = FullName;
        this.GroupId = GroupId;
    }
}

export function Types_Form$reflection() {
    return record_type("StudentEdit.Types.Form", [], Types_Form, () => [["Id", class_type("System.Guid")], ["Email", string_type], ["Password", string_type], ["FullName", string_type], ["GroupId", class_type("System.Guid")]]);
}

export class Types_State extends Record {
    constructor(Form, OriginalValue, IsNew, Groups, Saving, FormErrors, IsNeedValidation) {
        super();
        this.Form = Form;
        this.OriginalValue = OriginalValue;
        this.IsNew = IsNew;
        this.Groups = Groups;
        this.Saving = Saving;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
    }
}

export function Types_State$reflection() {
    return record_type("StudentEdit.Types.State", [], Types_State, () => [["Form", Types_Form$reflection()], ["OriginalValue", Types_Form$reflection()], ["IsNew", bool_type], ["Groups", list_type(Groups_GroupListItem$reflection())], ["Saving", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailChanged", "PasswordChanged", "FullNameChanged", "GroupIdChanged", "Save"];
    }
}

export function Types_Msg$reflection() {
    return union_type("StudentEdit.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_save(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let request, request_1;
        return singleton.Bind(Remoting_handleNonAuth(state.IsNew ? (request = (new Students_StudentCreateRequest(state.Form.Id, state.Form.Email, state.Form.Password, state.Form.FullName, state.Form.GroupId)), studentsApi().create(request)) : (request_1 = (new Students_StudentUpdateSettingsRequest(state.Form.Id, state.Form.Email, state.Form.Password, state.Form.FullName, state.Form.GroupId)), studentsApi().updateSettings(request_1))), (_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? singleton.Return(new Types_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, response.fields[0])))) : singleton.Return(new Types_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))));
        });
    }), (_arg2) => singleton.Return(new Types_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => (new Types_Form(formInfo.Id, EmailModule_ofString(Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Email is required")(Validator$1__Test(t, "Email", EmailModule_toString(formInfo.Email))))), Validator$1__End_Z5E18B1E2(t, Validator$1__Test(t, "Password", formInfo.Password)), Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Full name is required")(Validator$1__Test(t, "FullName", formInfo.FullName))), formInfo.GroupId)));
}

export function Validation_validateIf(needValidate, x) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

export function State_init(student, isNew, groups) {
    const form = new Types_Form(student.Id, student.Email, "", student.FullName, student.GroupId);
    return [new Types_State(form, form, isNew, groups, new DeferredResult$1(0), empty(), false), Cmd_none()];
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord;
    if (msg.tag === 0) {
        const patternInput_1 = Validation_validateIf(state.IsNeedValidation, (inputRecord_1 = state.Form, new Types_Form(inputRecord_1.Id, msg.fields[0], inputRecord_1.Password, inputRecord_1.FullName, inputRecord_1.GroupId)));
        return [new Types_State(patternInput_1[0], state.OriginalValue, state.IsNew, state.Groups, state.Saving, patternInput_1[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 1) {
        const patternInput_2 = Validation_validateIf(state.IsNeedValidation, (inputRecord_2 = state.Form, new Types_Form(inputRecord_2.Id, inputRecord_2.Email, msg.fields[0], inputRecord_2.FullName, inputRecord_2.GroupId)));
        return [new Types_State(patternInput_2[0], state.OriginalValue, state.IsNew, state.Groups, state.Saving, patternInput_2[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        const patternInput_3 = Validation_validateIf(state.IsNeedValidation, (inputRecord_3 = state.Form, new Types_Form(inputRecord_3.Id, inputRecord_3.Email, inputRecord_3.Password, inputRecord_3.FullName, msg.fields[0])));
        return [new Types_State(patternInput_3[0], state.OriginalValue, state.IsNew, state.Groups, state.Saving, patternInput_3[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Form, state.OriginalValue, state.IsNew, state.Groups, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Form, state.Form, false, state.Groups, new DeferredResult$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.IsNeedValidation), View_Cmd_toastSuccess("Utövare skapad")];
            }
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new Types_State(state.Form, state.OriginalValue, state.IsNew, state.Groups, state.Saving, matchValue.fields[0], true), Cmd_none()];
            }
            else {
                return [new Types_State(matchValue.fields[0], state.OriginalValue, state.IsNew, state.Groups, new DeferredResult$1(1), empty(), true), Cmd_fromAsync(Cmd_save(state))];
            }
        }
    }
    else {
        const patternInput = Validation_validateIf(state.IsNeedValidation, (inputRecord = state.Form, new Types_Form(inputRecord.Id, inputRecord.Email, inputRecord.Password, msg.fields[0], inputRecord.GroupId)));
        return [new Types_State(patternInput[0], state.OriginalValue, state.IsNew, state.Groups, state.Saving, patternInput[1], state.IsNeedValidation), Cmd_none()];
    }
}

export function View_view(view_viewInputProps) {
    let props_63, props_61, props_59, props_57, props_12, props_10, props_8, elms_1, elms, props_24, props_22, props_20, elms_3, elms_2, props_16, props_36, props_34, props_32, elms_5, elms_4, props_51, props_49, props_47, props_45, elms_6, patternInput, elms_8, elms_7, props_53;
    const dispatch = view_viewInputProps.dispatch;
    const state = view_viewInputProps.state;
    const availableGroups = map((x) => View_renderSelectOption(x.Id, x.Title), state.Groups);
    const elms_9 = singleton_1((props_63 = ofArray([["className", "is-6"], ["className", "pt-6"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Ändra egenskaper"]])))), (props_61 = ofArray([["className", "px-5"], borderStylePrimary, ["children", Interop_reactApi.Children.toArray([(props_59 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_57 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Fullständigt namn: "]])))), (props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = singleton_1(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Nytt fullständigt namn"], ["defaultValue", state.Form.FullName], ["onChange", (ev) => {
        dispatch(new Types_Msg(2, ev.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_12)))), (props_24 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Epost:"]])))), (props_20 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1((elms_2 = singleton_1((props_16 = ofArray([["placeholder", "Ny epost"], ["defaultValue", EmailModule_toString(state.Form.Email)], ["onChange", (ev_1) => {
        dispatch(new Types_Msg(0, EmailModule_ofString(ev_1.target.value)));
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_16)))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_24)))), (props_36 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Nytt lösenord:"]])))), (props_32 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_1((elms_4 = singleton_1(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["autoComplete", "new-password"], ["placeholder", "Nytt lösenord"], ["onChange", (ev_2) => {
        dispatch(new Types_Msg(1, ev_2.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_32))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_36)))), (props_51 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([(props_49 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Grupp:"]])))), (props_47 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_45 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_6 = singleton_1((patternInput = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray(Array.from(availableGroups))], ["value", GroupIdModule_toString(state.Form.GroupId)], ["onChange", (ev_3) => {
        dispatch(new Types_Msg(3, GroupIdModule_fromString(ev_3.target.value)));
    }]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_45))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_47))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_51)))), (elms_8 = singleton_1((elms_7 = singleton_1((props_53 = ofArray([["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["children", "Spara"], ["disabled", equals(state.Saving, new DeferredResult$1(1)) ? true : equals(state.Form, state.OriginalValue)], ["onClick", (x_3) => {
        x_3.preventDefault();
        dispatch(new Types_Msg(4, new AsyncOperationStatus$1(0)));
    }]]), createElement("a", createObj(Helpers_combineClasses("button", props_53))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("column", props_57))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_59))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_61))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_63)))));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    });
}

export function Component_RenderForm(component_RenderFormInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(component_RenderFormInputProps.student, component_RenderFormInputProps.isNew, component_RenderFormInputProps.groups), (msg, state) => State_update(msg, state), []);
    return createElement(View_view, {
        state: patternInput[0],
        dispatch: patternInput[1],
    });
}

export function Component_ResolveStudent(component_ResolveStudentInputProps) {
    const groups = component_ResolveStudentInputProps.groups;
    const id = component_ResolveStudentInputProps.id;
    let patternInput;
    if (id == null) {
        patternInput = [singleton.Delay(() => singleton.Return(new FSharpResult$2(0, Students_StudentSettings_Default_Z3CDDBBFA(head(groups).Id)))), true];
    }
    else {
        const id_1 = id;
        patternInput = [studentsApi().getSettings(id_1), false];
    }
    const student = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(patternInput[0]), [id]);
    if (student.tag === 2) {
        if (student.fields[0].tag === 1) {
            return createElement("div", {
                children: [student.fields[0].fields[0]],
            });
        }
        else {
            return createElement(Component_RenderForm, {
                student: student.fields[0].fields[0],
                isNew: patternInput[1],
                groups: groups,
            });
        }
    }
    else {
        return loader("big");
    }
}

export function Component_Render(component_RenderInputProps) {
    const _arg1 = component_RenderInputProps._arg1;
    const id = component_RenderInputProps.id;
    const groups = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(groupsApi().list()), [id]);
    if (groups.tag === 2) {
        return createElement(Component_ResolveStudent, {
            id: id,
            groups: groups.fields[0],
        });
    }
    else {
        return loader("big");
    }
}

