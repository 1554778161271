import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { Toastr_toastError, Remoting_handleNonAuth } from "../Extensions.fs.js";
import { workoutTemplatesApi } from "../Communication.fs.js";
import { WorkoutTemplates_WorkoutTemplateInfo, WorkoutTemplates_WorkoutTemplateInfo_get_Default } from "../../Shared/Shared.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { empty, singleton as singleton_2, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { View_checkBoxField } from "../Common.fs.js";
import { Render as Render_1 } from "./WorkoutEdit.fs.js";
import { startImmediate } from "../.fable/fable-library.3.2.1/Async.js";

export function saveWorkoutTemplate(workoutTemplate, isNew, onSuccess) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth((isNew ? workoutTemplatesApi().create : workoutTemplatesApi().update)(workoutTemplate)), (_arg1) => {
        if (_arg1.tag === 0) {
            onSuccess(workoutTemplate);
            return singleton.Zero();
        }
        else {
            Toastr_toastError("Save pass error", "");
            return singleton.Zero();
        }
    })), (_arg2) => {
        Toastr_toastError("Save pass error", "");
        return singleton.Zero();
    }));
}

export function Render(renderInputProps) {
    let elms_2, elms, props_9, children_1, props_15;
    const onCancel = renderInputProps.onCancel;
    const onSaved = renderInputProps.onSaved;
    const withIsGlobal = renderInputProps.withIsGlobal;
    const workoutTemplate = renderInputProps.workoutTemplate;
    let patternInput;
    if (workoutTemplate != null) {
        const workoutTemplate_1 = workoutTemplate;
        patternInput = [workoutTemplate_1.Workout.Title, workoutTemplate_1];
    }
    else {
        patternInput = ["Nytt pass", WorkoutTemplates_WorkoutTemplateInfo_get_Default()];
    }
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(patternInput[1]);
    const setEditModel = patternInput_1[1];
    const editModel = patternInput_1[0];
    const props_20 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg1) => {
        onCancel();
    }])))), (elms_2 = ofArray([(elms = ofArray([createElement("p", createObj(Helpers_combineClasses("modal-card-title", ofArray([["className", "is-size-6"], ["className", "has-text-weight-bold"], ["children", patternInput[0]]])))), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg2) => {
        onCancel();
    }]))))]), createElement("header", {
        className: "modal-card-head",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_9 = ofArray([["style", {
        overflowY: "scroll",
    }], ["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray([(children_1 = react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let elms_1;
        return withIsGlobal ? singleton_2((elms_1 = singleton_1(View_checkBoxField("Globalt pass", editModel.IsGlobal, (newIsGlobal) => {
            setEditModel(new WorkoutTemplates_WorkoutTemplateInfo(editModel.Id, newIsGlobal, editModel.Workout));
        })), createElement("div", {
            className: "block",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))) : empty();
    }))), createElement(Render_1, {
        workout: editModel.Workout,
        onChange: (newWorkout) => {
            setEditModel(new WorkoutTemplates_WorkoutTemplateInfo(editModel.Id, editModel.IsGlobal, newWorkout));
        },
        children: children_1,
    }))])]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_9)))), (props_15 = ofArray([["style", {
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg3) => {
        onCancel();
    }], ["className", "is-text"], ["className", "has-text-danger"], ["children", "Avbryt"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg4) => {
        startImmediate(saveWorkoutTemplate(editModel, workoutTemplate == null, onSaved));
    }], ["className", "is-success"], ["children", "Spara"]]))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_15))))]), createElement("div", {
        className: "modal-card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), createElement("button", createObj(Helpers_combineClasses("modal-close", singleton_1(["onClick", (_arg5) => {
        onCancel();
    }]))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_20)));
}

