import { value as value_30, defaultArg } from "./.fable/fable-library.3.2.1/Option.js";
import { empty as empty_1, tryFind } from "./.fable/fable-library.3.2.1/Map.js";
import { map, cons, singleton, ofArray, isEmpty, fold, empty } from "./.fable/fable-library.3.2.1/List.js";
import { join, interpolate, toText } from "./.fable/fable-library.3.2.1/String.js";
import { Validator$1__NotBlank_2B595, Validator$1__Test, Validator$1__IsMail, Validator$1__End_Z5E18B1E2, validateSync } from "./.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { Auth_LogInRequest, EmailModule_toString, EmailModule_ofString } from "../Shared/Shared.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "./.fable/fable-library.3.2.1/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { useReact_useRef_1505 } from "./.fable/Feliz.1.51.0/React.fs.js";

export const borderStylePrimary = ["style", {
    border: (((1 + "px ") + "solid") + " ") + "#226699",
}];

export const borderStyleBottomPrimary = ["style", {
    borderBottom: (((1 + "px ") + "solid") + " ") + "#226699",
}];

export const borderStyleBottomGrey = ["style", {
    borderBottom: (((1 + "px ") + "solid") + " ") + "#808080",
}];

export function Form_filter(x, errors) {
    if (x) {
        return errors;
    }
    else {
        return void 0;
    }
}

export function Form_getFieldError(errors, field) {
    return defaultArg(tryFind(field, errors), empty());
}

export function Form_Field_errors(fieldName, errors) {
    return Form_getFieldError(errors, fieldName);
}

export function Form_Field_errorsAsString(fieldName) {
    return (arg) => fold((s, x) => toText(interpolate("%s%P() %s%P()", [s, x])), "", Form_Field_errors(fieldName, arg));
}

export function Form_Field_hasErrors(fieldName) {
    return (arg_1) => (!isEmpty(Form_Field_errors(fieldName, arg_1)));
}

export const Form_Field_fieldStyleOnError = ofArray([["borderColor", "#FF0000"], ["boxShadow", "none"]]);

export function Form_validateLogin(user) {
    return validateSync(true, (t) => (new Auth_LogInRequest(EmailModule_ofString(Validator$1__End_Z5E18B1E2(t, Validator$1__IsMail(t, "Email is incorrect", Validator$1__Test(t, "Login", EmailModule_toString(user.Email))))), Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Password is required")(Validator$1__Test(t, "Password", user.Password))))));
}

export function Form_validationLogin(user, validationState) {
    const form = Form_validateLogin(user);
    if (form.tag === 1) {
        return validationState ? form.fields[0] : empty_1();
    }
    else {
        return empty_1();
    }
}

export function Form_validationForgotPassword(email, validationState) {
    const form = validateSync(true, (t) => EmailModule_ofString(Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Email is required")(Validator$1__Test(t, "Email", EmailModule_toString(email))))));
    if (form.tag === 1) {
        return validationState ? form.fields[0] : empty_1();
    }
    else {
        return empty_1();
    }
}

export function View_renderNotification(color, text) {
    const props_3 = ofArray([color, ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            textAlign: "left",
        },
        children: Interop_reactApi.Children.toArray([createElement("i", {
            style: {
                fontSize: 25 + "px",
                verticalAlign: "middle",
            },
            className: join(" ", ["fa", "fa-2x", "fa-info-circle"]),
        }), createElement("p", {
            style: {
                fontSize: 14 + "px",
                fontWeight: 400,
            },
            className: "mt-2",
            children: text,
        })]),
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("notification", props_3)));
}

export function View_RenderDeleteWindow(props) {
    let props_11, elms, props_8;
    const props_13 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg1) => {
        props.notConfirmed();
    }])))), (props_11 = singleton(["children", Interop_reactApi.Children.toArray([(elms = ofArray([createElement("p", {
        className: "",
        children: props.text,
    }), (props_8 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg2) => {
        props.notConfirmed();
    }], ["className", "is-text"], ["children", "Avbryt"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg3) => {
        props.confirmed();
    }], ["className", "is-danger"], ["children", "Ta bort"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_8))))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_11))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_13)));
}

export function View_checkBoxField(label, value, onChange) {
    let props;
    const checkboxRef = useReact_useRef_1505(void 0);
    const elms = ofArray([(props = ofArray([["ref", checkboxRef], ["className", "is-small"], ["onChange", (ev) => {
        onChange(ev.target.checked);
    }], ["checked", value], ["className", join(" ", ["is-checkradio"])]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", props))))), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-primary"], ["className", "has-text-weight-normal"], ["children", label], ["onClick", (_arg1) => {
        let copyOfStruct = value_30(checkboxRef.current);
        copyOfStruct.click();
    }]]))))]);
    return createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function View_checkBoxFieldBig(label, value, onChange) {
    let props;
    const checkboxRef = useReact_useRef_1505(void 0);
    const elms = ofArray([(props = ofArray([["ref", checkboxRef], ["onChange", (ev) => {
        onChange(ev.target.checked);
    }], ["checked", value], ["className", join(" ", ["is-checkradio"])]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", props))))), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-primary"], ["className", "has-text-weight-normal"], ["children", label], ["onClick", (_arg1) => {
        let copyOfStruct = value_30(checkboxRef.current);
        copyOfStruct.click();
    }]]))))]);
    return createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function View_renderSelectOption(id, text) {
    return createElement("option", {
        value: id,
        children: text,
    });
}

export function View_renderEmptySelectOption(text, disabled) {
    return createElement("option", {
        disabled: disabled,
        children: text,
    });
}

export function View_renderSelectOptionDisabled(id, text, disabled) {
    return createElement("option", {
        value: id,
        children: text,
        disabled: disabled,
    });
}

export function View_DUSelect(title, values, toStrValue, toStrLabel, parse, value, onChange) {
    let props_2, elms, patternInput;
    const options = map((x) => View_renderSelectOption(toStrValue(x), toStrLabel(x)), values);
    const props_9 = singleton(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["className", "has-text-grey"], ["children", title]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton((patternInput = Helpers_extractClasses(ofArray([["className", "is-small"], ["className", "is-size-7"], ["className", "is-fullwidth"], ["className", "has-text-left"], ["className", "has-text-grey"], ["defaultValue", toStrValue(value)], ["onChange", (ev) => {
        onChange(parse(ev.target.value));
    }], ["children", Interop_reactApi.Children.toArray(Array.from(options))]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_9)));
}

