import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { SchoolIdModule_empty, Calendar_PeriodInfo_get_Current, Groups_GroupListItem$reflection, Students_StudentListItem$reflection } from "../../Shared/Shared.fs.js";
import { record_type, array_type, class_type, union_type, string_type, list_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { groupsApi, studentsApi } from "../Communication.fs.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { empty as empty_1, singleton as singleton_2, length, filter, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { collect, map, empty, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import { equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Route, toPath } from "../Router.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { toString } from "../.fable/fable-library.3.2.1/Date.js";
import { View_renderNotification, View_RenderDeleteWindow } from "../Common.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { State_getOrderedGroups } from "./GroupList.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_State extends Record {
    constructor(ListStudents, ListGroups, GroupsOrder, ListExternalStudents) {
        super();
        this.ListStudents = ListStudents;
        this.ListGroups = ListGroups;
        this.GroupsOrder = GroupsOrder;
        this.ListExternalStudents = ListExternalStudents;
    }
}

export function Types_State$reflection() {
    return record_type("StudentList.Types.State", [], Types_State, () => [["ListStudents", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_StudentListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_StudentListItem$reflection())]], [["ErrorValue", string_type]]]))], ["ListGroups", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Groups_GroupListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Groups_GroupListItem$reflection())]], [["ErrorValue", string_type]]]))], ["GroupsOrder", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(class_type("System.Guid")), string_type], FSharpResult$2, () => [[["ResultValue", array_type(class_type("System.Guid"))]], [["ErrorValue", string_type]]]))], ["ListExternalStudents", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_StudentListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_StudentListItem$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadStudents", "LoadGroups", "LoadGroupsOrder", "LoadExternalStudents", "DeleteClick", "Delete"];
    }
}

export function Types_Msg$reflection() {
    return union_type("StudentList.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_StudentListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_StudentListItem$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Groups_GroupListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Groups_GroupListItem$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(class_type("System.Guid")), string_type], FSharpResult$2, () => [[["ResultValue", array_type(class_type("System.Guid"))]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_StudentListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_StudentListItem$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]]]);
}

export const Cmd_loadStudents = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(studentsApi().list()), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export const Cmd_loadGroups = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(groupsApi().list()), (_arg1) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export const Cmd_loadGroupsOrder = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(groupsApi().getGroupsOrder()), (_arg1) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export const Cmd_loadExternalStudents = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(studentsApi().listExternalStudents()), (_arg1) => singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_delete(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(studentsApi().delete(id)), (_arg1) => {
        const result = _arg1;
        return singleton.Return((result.tag === 0) ? (new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, id)))) : (new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))));
    })), (_arg2) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export const State_init = [new Types_State(new DeferredResult$1(1), new DeferredResult$1(1), new DeferredResult$1(1), new DeferredResult$1(1)), Cmd_batch(ofArray([Cmd_OfFunc_result(new Types_Msg(0, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Types_Msg(1, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Types_Msg(2, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Types_Msg(3, new AsyncOperationStatus$1(0)))]))];

export function State_update(msg, state) {
    let matchValue;
    if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.ListStudents, state.ListGroups, state.GroupsOrder, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new Types_State(state.ListStudents, state.ListGroups, state.GroupsOrder, new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.ListStudents, state.ListGroups, state.GroupsOrder, new DeferredResult$1(1)), Cmd_fromAsync(Cmd_loadExternalStudents)];
        }
    }
    else if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.ListStudents, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.GroupsOrder, state.ListExternalStudents), Cmd_none()];
            }
            else {
                return [new Types_State(state.ListStudents, new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.GroupsOrder, state.ListExternalStudents), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.ListStudents, new DeferredResult$1(1), state.GroupsOrder, state.ListExternalStudents), Cmd_fromAsync(Cmd_loadGroups)];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.ListStudents, state.ListGroups, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.ListExternalStudents), Cmd_none()];
            }
            else {
                return [new Types_State(state.ListStudents, state.ListGroups, new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.ListExternalStudents), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.ListStudents, state.ListGroups, new DeferredResult$1(1), state.ListExternalStudents), Cmd_fromAsync(Cmd_loadGroupsOrder)];
        }
    }
    else if (msg.tag === 4) {
        return [state, Cmd_fromAsync(Cmd_delete(msg.fields[0]))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.ListGroups, state.GroupsOrder, state.ListExternalStudents), Cmd_none()];
            }
            else {
                return [new Types_State((matchValue = state.ListStudents, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, filter((x_4) => (x_4.Id !== msg.fields[0].fields[0].fields[0]), matchValue.fields[0].fields[0])))) : state.ListStudents) : state.ListStudents), state.ListGroups, state.GroupsOrder, state.ListExternalStudents), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.ListGroups, state.GroupsOrder, state.ListExternalStudents), Cmd_none()];
        }
        else {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.ListGroups, state.GroupsOrder, state.ListExternalStudents), Cmd_none()];
        }
    }
    else {
        return [new Types_State(new DeferredResult$1(1), state.ListGroups, state.GroupsOrder, state.ListExternalStudents), Cmd_fromAsync(Cmd_loadStudents)];
    }
}

export function View_RenderStudent(view_RenderStudentInputProps) {
    const dispatch = view_RenderStudentInputProps.dispatch;
    const student = view_RenderStudentInputProps.student;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setChatHover = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setStatisticHover = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setOverViewHover = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setPlanHover = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setEditHover = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteHover = patternInput_5[1];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_6[1];
    const isDeleteWindow = patternInput_6[0];
    const props_33 = ofArray([["className", "is-vcentered"], ["style", {
        borderTop: (((1 + "px ") + "solid") + " ") + "#A9A9A9",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props;
        return append(singleton_1((props = ofArray([["style", {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        }], ["children", student.FullName]]), createElement("div", createObj(Helpers_combineClasses("column", props))))), delay(() => {
            let props_4, props_2;
            return append(singleton_1((props_4 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append(singleton_1(["className", "is-size-7"]), delay(() => append(patternInput[0] ? singleton_1(["children", "Visa chat"]) : empty(), delay(() => append(patternInput_1[0] ? singleton_1(["children", "Visa statistik"]) : empty(), delay(() => append(patternInput_2[0] ? singleton_1(["children", "Visa kalender"]) : empty(), delay(() => append(patternInput_3[0] ? singleton_1(["children", "Visa årsplanering"]) : empty(), delay(() => append(patternInput_4[0] ? singleton_1(["children", "Ändra egenskaper"]) : empty(), delay(() => (patternInput_5[0] ? singleton_1(["children", "Ta bort utövaren"]) : empty()))))))))))))))), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))), delay(() => {
                let props_31, props_12, props_16, props_20, props_24, props_28;
                return append(singleton_1((props_31 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "px-2",
                    onMouseEnter: (_arg1) => {
                        setChatHover(true);
                    },
                    onMouseLeave: (_arg2) => {
                        setChatHover(false);
                    },
                    href: toPath(new Route(6, student.Id)),
                    style: {
                        width: 5 + "rem",
                        display: "inline-block",
                        textAlign: "left",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let props_7;
                        return append(singleton_1((props_7 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["far", "fa-comments"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7))))), delay(() => {
                            const matchValue = student.ChatChangeDate;
                            if (matchValue == null) {
                                return singleton_1(null);
                            }
                            else {
                                const x = matchValue;
                                return singleton_1(createElement("span", {
                                    style: {
                                        color: "#666",
                                        fontSize: 0.7 + "rem",
                                    },
                                    className: "ml-1",
                                    children: toString(x, "d/M"),
                                }));
                            }
                        }));
                    })))),
                }), createElement("a", {
                    className: "px-2",
                    onMouseEnter: (_arg3) => {
                        setStatisticHover(true);
                    },
                    onMouseLeave: (_arg4) => {
                        setStatisticHover(false);
                    },
                    href: toPath(new Route(15, student.Id)),
                    children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["far", "fa-chart-bar"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_12))))]),
                }), createElement("a", {
                    className: "px-2",
                    onMouseEnter: (_arg5) => {
                        setOverViewHover(true);
                    },
                    onMouseLeave: (_arg6) => {
                        setOverViewHover(false);
                    },
                    href: toPath(new Route(29, student.Id, Calendar_PeriodInfo_get_Current())),
                    children: Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-calendar-alt"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_16))))]),
                }), createElement("a", {
                    className: "px-2",
                    onMouseEnter: (_arg7) => {
                        setPlanHover(true);
                    },
                    onMouseLeave: (_arg8) => {
                        setPlanHover(false);
                    },
                    href: toPath(new Route(13, student.Id)),
                    children: Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["far", "fa-clock"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_20))))]),
                }), createElement("a", {
                    onMouseEnter: (_arg9) => {
                        setEditHover(true);
                    },
                    onMouseLeave: (_arg10) => {
                        setEditHover(false);
                    },
                    className: "px-2",
                    href: toPath(new Route(12, student.Id)),
                    children: Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-pencil-alt"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_24))))]),
                }), createElement("a", {
                    onMouseEnter: (_arg11) => {
                        setDeleteHover(true);
                    },
                    onMouseLeave: (_arg12) => {
                        setDeleteHover(false);
                    },
                    onClick: (_arg13) => {
                        setIsDeleteWindow(!isDeleteWindow);
                    },
                    children: Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fas", "fa-times"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_28))))]),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_31))))), delay(() => (isDeleteWindow ? singleton_1(createElement(View_RenderDeleteWindow, {
                    confirmed: () => {
                        setIsDeleteWindow(!isDeleteWindow);
                        dispatch(new Types_Msg(4, student.Id));
                    },
                    notConfirmed: () => {
                        setIsDeleteWindow(!isDeleteWindow);
                    },
                    text: "Är du säker på att du vill ta bort här utövaren?",
                })) : empty())));
            }));
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_33)));
}

export function View_RenderExternalStudent(student) {
    let props, props_4, props_2, props_23, props_12, props_16, props_20;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setChatHover = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setStatisticHover = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setOverViewHover = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setPlanHover = patternInput_3[1];
    const props_25 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    }], ["children", student.FullName]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_4 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append(singleton_1(["className", "is-size-7"]), delay(() => append(patternInput[0] ? singleton_1(["children", "Visa chat"]) : empty(), delay(() => append(patternInput_1[0] ? singleton_1(["children", "Visa statistik"]) : empty(), delay(() => append(patternInput_2[0] ? singleton_1(["children", "Visa kalender"]) : empty(), delay(() => (patternInput_3[0] ? singleton_1(["children", "Visa årsplanering"]) : empty()))))))))))), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (props_23 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
        className: "px-2",
        onMouseEnter: (_arg1) => {
            setChatHover(true);
        },
        onMouseLeave: (_arg2) => {
            setChatHover(false);
        },
        href: toPath(new Route(6, student.Id)),
        style: {
            width: 5 + "rem",
            display: "inline-block",
            textAlign: "left",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_7;
            return append(singleton_1((props_7 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["far", "fa-comments"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7))))), delay(() => {
                const matchValue = student.ChatChangeDate;
                if (matchValue == null) {
                    return singleton_1(null);
                }
                else {
                    const x = matchValue;
                    return singleton_1(createElement("span", {
                        style: {
                            color: "#666",
                            fontSize: 0.7 + "rem",
                        },
                        className: "ml-1",
                        children: toString(x, "d/M"),
                    }));
                }
            }));
        })))),
    }), createElement("a", {
        className: "px-2",
        onMouseEnter: (_arg3) => {
            setStatisticHover(true);
        },
        onMouseLeave: (_arg4) => {
            setStatisticHover(false);
        },
        href: toPath(new Route(15, student.Id)),
        children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["far", "fa-chart-bar"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_12))))]),
    }), createElement("a", {
        className: "px-2",
        onMouseEnter: (_arg5) => {
            setOverViewHover(true);
        },
        onMouseLeave: (_arg6) => {
            setOverViewHover(false);
        },
        href: toPath(new Route(29, student.Id, Calendar_PeriodInfo_get_Current())),
        children: Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-calendar-alt"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_16))))]),
    }), createElement("a", {
        className: "px-2",
        onMouseEnter: (_arg7) => {
            setPlanHover(true);
        },
        onMouseLeave: (_arg8) => {
            setPlanHover(false);
        },
        href: toPath(new Route(13, student.Id)),
        children: Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["far", "fa-clock"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_20))))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_23))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_25)));
}

export function View_RenderGroup(view_RenderGroupInputProps) {
    const dispatch = view_RenderGroupInputProps.dispatch;
    const students = view_RenderGroupInputProps.students;
    const group = view_RenderGroupInputProps.group;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const props_16 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
        border: (((1 + "px ") + "solid") + " ") + "#808080",
        alignContent: "space-between",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_14, props_2, props_12;
        return append(singleton_1((props_14 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["onClick", (_arg1) => {
            setVisible(!isVisible);
        }], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-primary"], ["children", group.Title]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_12 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_5;
            return append((length(students) > 0) ? singleton_1(createElement("a", {
                className: join(" ", ["hoverable"]),
                className: "px-2",
                onClick: (_arg2) => {
                    setVisible(!isVisible);
                },
                children: Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (isVisible ? singleton_1(["className", join(" ", ["fa", "fa-chevron-up"])]) : singleton_1(["className", join(" ", ["fa", "fa-chevron-down"])]))))))])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_5))))]),
            })) : empty(), delay(() => {
                let props_9;
                return singleton_1(createElement("a", {
                    className: "px-2",
                    href: toPath(new Route(16, group.Id)),
                    children: Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", join(" ", ["hoverable"])], ["className", "is-medium"], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["far", "fa-chart-bar", "fa-lg"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_9))))]),
                }));
            }));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_14))))), delay(() => (isVisible ? map((student) => createElement(View_RenderStudent, {
            student: student,
            dispatch: dispatch,
        }), students) : empty())));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_16)));
}

export function Component_Render(user) {
    let props_18, elms, elms_1, props_15;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init, (msg, state) => State_update(msg, state), [user]);
    const state_1 = patternInput[0];
    const props_20 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_18 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => {
        let props_6, props_3;
        return append(singleton_1((props_6 = ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["style", {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", singleton_2(["children", "Utövare"])))), createElement("a", {
            className: "px-2",
            href: toPath(new Route(17, defaultArg(user.SchoolId, SchoolIdModule_empty))),
            children: Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-medium"], ["className", "has-text-white"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["far", "fa-chart-bar", "fa-lg"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_3))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_6))))), delay(() => {
            let matchValue;
            return append((matchValue = [state_1.ListStudents, state_1.ListGroups, state_1.GroupsOrder], (matchValue[0].tag === 2) ? ((matchValue[0].fields[0].tag === 1) ? singleton_1(View_renderNotification(["className", "is-danger"], matchValue[0].fields[0].fields[0])) : ((matchValue[1].tag === 2) ? ((matchValue[1].fields[0].tag === 1) ? singleton_1(View_renderNotification(["className", "is-danger"], matchValue[1].fields[0].fields[0])) : ((matchValue[2].tag === 2) ? ((matchValue[2].fields[0].tag === 1) ? singleton_1(View_renderNotification(["className", "is-danger"], matchValue[2].fields[0].fields[0])) : collect((group) => {
                const groupStudents = filter((x) => (x.GroupId === group.Id), matchValue[0].fields[0].fields[0]);
                return singleton_1(createElement(View_RenderGroup, {
                    group: group,
                    students: groupStudents,
                    dispatch: patternInput[1],
                }));
            }, State_getOrderedGroups(matchValue[1].fields[0].fields[0], matchValue[2].fields[0].fields[0]))) : singleton_1(loader("big")))) : ((matchValue[2].tag === 2) ? ((matchValue[2].fields[0].tag === 1) ? singleton_1(View_renderNotification(["className", "is-danger"], matchValue[2].fields[0].fields[0])) : singleton_1(loader("big"))) : singleton_1(loader("big"))))) : ((matchValue[1].tag === 2) ? ((matchValue[1].fields[0].tag === 1) ? singleton_1(View_renderNotification(["className", "is-danger"], matchValue[1].fields[0].fields[0])) : ((matchValue[2].tag === 2) ? ((matchValue[2].fields[0].tag === 1) ? singleton_1(View_renderNotification(["className", "is-danger"], matchValue[2].fields[0].fields[0])) : singleton_1(loader("big"))) : singleton_1(loader("big")))) : ((matchValue[2].tag === 2) ? ((matchValue[2].fields[0].tag === 1) ? singleton_1(View_renderNotification(["className", "is-danger"], matchValue[2].fields[0].fields[0])) : singleton_1(loader("big"))) : singleton_1(loader("big"))))), delay(() => {
                let props_10;
                const matchValue_1 = state_1.ListExternalStudents;
                return (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 1) ? singleton_1(View_renderNotification(["className", "is-danger"], matchValue_1.fields[0].fields[0])) : append((!equals(matchValue_1.fields[0].fields[0], empty_1())) ? singleton_1((props_10 = ofArray([["className", "py-2"], ["className", "mt-6"], ["className", "has-background-primary"], ["className", "has-text-white"], ["style", {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", singleton_2(["children", "Externa utövare"]))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_10))))) : empty(), delay(() => map((student) => {
                    const props_12 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
                        border: (((1 + "px ") + "solid") + " ") + "#808080",
                        alignContent: "space-between",
                    }], ["children", Interop_reactApi.Children.toArray([createElement(View_RenderExternalStudent, student)])]]);
                    return createElement("div", createObj(Helpers_combineClasses("box", props_12)));
                }, matchValue_1.fields[0].fields[0])))) : singleton_1(null);
            }));
        }));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton_2((props_15 = ofArray([["href", toPath(new Route(11))], ["className", "py-2"], ["className", "mb-5"], ["className", "is-success"], ["children", "Lägg till utövare"]]), createElement("a", createObj(Helpers_combineClasses("button", props_15))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_20)));
}

