import { Interop_reactApi } from "./.fable/Feliz.1.51.0/Interop.fs.js";
import { singleton, append, delay, toList } from "./.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import { createObj } from "./.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { singleton as singleton_1, ofArray } from "./.fable/fable-library.3.2.1/List.js";

export function loader(sizeLoader) {
    let props_2, props;
    const elms = singleton_1((props_2 = ofArray([["className", "is-vcentered"], ["className", "is-centered"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props = toList(delay(() => append(singleton(["style", {
        borderStyle: "none",
    }]), delay(() => append((sizeLoader === "big") ? singleton(["className", "is-size-1"]) : ((sizeLoader === "normal") ? singleton(["className", "is-size-3"]) : ((sizeLoader === "small") ? singleton(["className", "is-size-5"]) : singleton(["className", "is-size-3"]))), delay(() => append(singleton(["className", "is-primary"]), delay(() => append(singleton(["className", "is-loading"]), delay(() => append(singleton(["className", "is-inverted"]), delay(() => append(singleton(["className", "is-outlined"]), delay(() => singleton(["className", "is-large"]))))))))))))))), createElement("span", createObj(Helpers_combineClasses("button", props))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_2)))));
    return createElement("section", {
        className: "section",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export const inlineSmall = (() => {
    const props = ofArray([["style", {
        borderStyle: "none",
    }], ["className", "is-size-5"], ["className", "is-primary"], ["className", "is-loading"], ["className", "is-inverted"], ["className", "is-outlined"], ["className", "is-medium"]]);
    return createElement("span", createObj(Helpers_combineClasses("button", props)));
})();

export function loaderBig() {
    return loader("big");
}

export function loaderSmall() {
    return loader("small");
}

