import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { PlanFact_PlanFact, DayPlan_DayPlan, DayPlanFact_DayPlanFact, DayFact_DayFact, Shape_Shape, PlanFact_PlanFact$reflection, DaySegment_DaySegment$reflection, CalendarDay_CalendarDay$reflection, DayPlanFact_DayPlanFact$reflection, StudentDays_StudentDayKey$reflection, StudentDays_StudentDayImplementPlanRequest, StudentDays_StudentDayDeletePlanFactRequest, StudentDays_StudentDayMovePlanFactRequest, StudentDays_StudentDayUpdatePlanFactRequest } from "../../Shared/Shared.fs.js";
import { Toastr_toastError, Remoting_handleNonAuth } from "../Extensions.fs.js";
import { studentDaysApi } from "../Communication.fs.js";
import { Record } from "../.fable/fable-library.3.2.1/Types.js";
import { record_type, class_type, lambda_type, unit_type, tuple_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { CollectedDropProps, DropSpec$2, useDrop } from "./ReactDnd.fs.js";
import { startImmediate } from "../.fable/fable-library.3.2.1/Async.js";
import { ofArray, item } from "../.fable/fable-library.3.2.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { empty, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { inlineSmall } from "../Loader.fs.js";
import { Render as Render_1 } from "./WorkoutBlock.fs.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Render as Render_2 } from "./DayFactEdit.fs.js";
import { Render as Render_3 } from "./DayPlanEdit.fs.js";

export function updatePlanFact(studentDay, dayPlanFact, onSuccess, onError) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new StudentDays_StudentDayUpdatePlanFactRequest(studentDay.StudentId, studentDay.CalendarDay, dayPlanFact);
        return singleton.Bind(Remoting_handleNonAuth(studentDaysApi().updatePlanFact(request)), (_arg1) => {
            if (_arg1.tag === 0) {
                onSuccess();
                return singleton.Zero();
            }
            else {
                Toastr_toastError("Save error", "");
                onError();
                return singleton.Zero();
            }
        });
    }), (_arg2) => {
        Toastr_toastError("Save error", "");
        onError();
        return singleton.Zero();
    }));
}

export function movePlanFact(studentId, toDay_0, toDay_1, fromDay_0, fromDay_1, planFact, onSuccess, onError) {
    const toDay = [toDay_0, toDay_1];
    const fromDay = [fromDay_0, fromDay_1];
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new StudentDays_StudentDayMovePlanFactRequest(studentId, toDay[0], toDay[1], fromDay[0], fromDay[1], planFact);
        return singleton.Bind(Remoting_handleNonAuth(studentDaysApi().movePlanFact(request)), (_arg1) => {
            const result = _arg1;
            if (result.tag === 0) {
                onSuccess();
                return singleton.Zero();
            }
            else if (result.fields[0][1] != null) {
                Toastr_toastError(result.fields[0][1], "");
                onError();
                return singleton.Zero();
            }
            else {
                Toastr_toastError("Move error", "");
                onError();
                return singleton.Zero();
            }
        });
    }), (_arg2) => {
        Toastr_toastError("Move error", "");
        onError();
        return singleton.Zero();
    }));
}

export function deletePlanFact(studentDay, daySegment, planFact, onSuccess, onError) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new StudentDays_StudentDayDeletePlanFactRequest(studentDay.StudentId, studentDay.CalendarDay, daySegment, planFact);
        return singleton.Bind(Remoting_handleNonAuth(studentDaysApi().deletePlanFact(request)), (_arg1) => {
            if (_arg1.tag === 0) {
                onSuccess();
                return singleton.Zero();
            }
            else {
                Toastr_toastError("Delete error", "");
                onError();
                return singleton.Zero();
            }
        });
    }), (_arg2) => {
        Toastr_toastError("Delete error", "");
        onError();
        return singleton.Zero();
    }));
}

export function implementPlan(studentDay, dayFact, onSuccess, onError) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new StudentDays_StudentDayImplementPlanRequest(studentDay.StudentId, studentDay.CalendarDay, dayFact);
        return singleton.Bind(Remoting_handleNonAuth(studentDaysApi().implementPlan(request)), (_arg1) => {
            const result = _arg1;
            if (result.tag === 0) {
                onSuccess();
                return singleton.Zero();
            }
            else if (result.fields[0][1] != null) {
                Toastr_toastError(result.fields[0][1], "");
                onError();
                return singleton.Zero();
            }
            else {
                Toastr_toastError("Implement plan error", "");
                onError();
                return singleton.Zero();
            }
        });
    }), (_arg2) => {
        Toastr_toastError("Implement plan error", "");
        onError();
        return singleton.Zero();
    }));
}

export class StudentDayActions extends Record {
    constructor(UpdatedPlanFact, MovedPlanFact, DeletedPlanFact) {
        super();
        this.UpdatedPlanFact = UpdatedPlanFact;
        this.MovedPlanFact = MovedPlanFact;
        this.DeletedPlanFact = DeletedPlanFact;
    }
}

export function StudentDayActions$reflection() {
    return record_type("DayPlanFactBox.StudentDayActions", [], StudentDayActions, () => [["UpdatedPlanFact", lambda_type(tuple_type(StudentDays_StudentDayKey$reflection(), DayPlanFact_DayPlanFact$reflection()), unit_type)], ["MovedPlanFact", lambda_type(tuple_type(class_type("System.Guid"), tuple_type(CalendarDay_CalendarDay$reflection(), DaySegment_DaySegment$reflection()), tuple_type(CalendarDay_CalendarDay$reflection(), DaySegment_DaySegment$reflection()), PlanFact_PlanFact$reflection()), unit_type)], ["DeletedPlanFact", lambda_type(tuple_type(StudentDays_StudentDayKey$reflection(), DaySegment_DaySegment$reflection(), PlanFact_PlanFact$reflection()), unit_type)]]);
}

export function Render(renderInputProps) {
    let matchValue;
    const actions = renderInputProps.actions;
    const planFact = renderInputProps.planFact;
    const daySegment = renderInputProps.daySegment;
    const studentDay = renderInputProps.studentDay;
    const dayPlanFact = renderInputProps.dayPlanFact;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsSaving = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        setIsSaving(false);
    }, [dayPlanFact]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setEditModel = patternInput_1[1];
    const editModel = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const setIsImplementPlan = patternInput_3[1];
    const isImplementPlan = patternInput_3[0];
    const patternInput_6 = useDrop(ofArray([new DropSpec$2(0, (matchValue = [dayPlanFact, planFact], (matchValue[0] != null) ? [] : ((matchValue[1].tag === 1) ? ["WorkoutTemplate", "DayFact"] : ["WorkoutTemplate", "DayPlan"]))), new DropSpec$2(1, (mon) => {
        patternInput_2[1](mon.isOver());
        const canDrop = mon.canDrop();
        return new CollectedDropProps(mon.isOver(), canDrop);
    }), new DropSpec$2(2, (dragItem) => {
        const matchValue_1 = dragItem.dragType;
        switch (matchValue_1) {
            case "WorkoutTemplate": {
                const workoutTemplate = dragItem.dragSrc;
                setIsSaving(true);
                const dayPlanFact_1 = (planFact.tag === 1) ? (new DayPlanFact_DayPlanFact(1, new DayFact_DayFact(daySegment, new Shape_Shape(4), "", workoutTemplate.Workout))) : (new DayPlanFact_DayPlanFact(0, new DayPlan_DayPlan(daySegment, workoutTemplate.Workout)));
                startImmediate(updatePlanFact(studentDay, dayPlanFact_1, () => {
                    actions.UpdatedPlanFact([studentDay, dayPlanFact_1]);
                    setIsSaving(false);
                }, () => {
                    setIsSaving(false);
                }));
                break;
            }
            case "DayPlan": {
                const dragSrc = dragItem.dragSrc;
                const patternInput_4 = [item(0, dragSrc), item(1, dragSrc)];
                setIsSaving(true);
                const dayTo = [studentDay.CalendarDay, daySegment];
                const dayFrom = [patternInput_4[1], patternInput_4[0].DaySegment];
                startImmediate(movePlanFact(studentDay.StudentId, dayTo[0], dayTo[1], dayFrom[0], dayFrom[1], new PlanFact_PlanFact(0), () => {
                    actions.MovedPlanFact([studentDay.StudentId, dayTo, dayFrom, new PlanFact_PlanFact(0)]);
                    setIsSaving(false);
                }, () => {
                    setIsSaving(false);
                }));
                break;
            }
            case "DayFact": {
                const dragSrc_1 = dragItem.dragSrc;
                const patternInput_5 = [item(0, dragSrc_1), item(1, dragSrc_1)];
                setIsSaving(true);
                const dayTo_1 = [studentDay.CalendarDay, daySegment];
                const dayFrom_1 = [patternInput_5[1], patternInput_5[0].DaySegment];
                startImmediate(movePlanFact(studentDay.StudentId, dayTo_1[0], dayTo_1[1], dayFrom_1[0], dayFrom_1[1], new PlanFact_PlanFact(1), () => {
                    actions.MovedPlanFact([studentDay.StudentId, dayTo_1, dayFrom_1, new PlanFact_PlanFact(1)]);
                    setIsSaving(false);
                }, () => {
                    setIsSaving(false);
                }));
                break;
            }
            default: {
            }
        }
    })]));
    const onSave = (dayPlanFact_2) => {
        startImmediate(updatePlanFact(studentDay, dayPlanFact_2, () => {
            actions.UpdatedPlanFact([studentDay, dayPlanFact_2]);
            setEditModel(void 0);
        }, () => {
        }));
    };
    const onDelete = () => {
        startImmediate(deletePlanFact(studentDay, daySegment, planFact, () => {
            actions.DeletedPlanFact([studentDay, daySegment, planFact]);
            setEditModel(void 0);
        }, () => {
        }));
    };
    const onCancel = () => {
        setEditModel(void 0);
    };
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props_1;
        return append(singleton_1((props_1 = ofArray([["ref", patternInput_6[1]], ["className", "py-1"], ["className", "px-0"], ["className", "mx-1"], ["className", "day-plan-fact-box"], ["style", createObj(toList(delay(() => append(singleton_1(["borderRadius", 6 + "px"]), delay(() => (patternInput_2[0] ? singleton_1(["backgroundColor", "#dbdbdb70"]) : empty()))))))], ["onClick", (_arg1) => {
            setEditModel(dayPlanFact);
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let x_1, x;
            return (dayPlanFact == null) ? (patternInput[0] ? singleton_1(inlineSmall) : singleton_1(createElement("div", {
                className: "day-plan-fact-box-empty",
            }))) : ((dayPlanFact.tag === 1) ? (x_1 = dayPlanFact.fields[0], singleton_1(createElement(Render_1, {
                workout: x_1.Workout,
                comment: x_1.CommentAfterTraining,
                shapeOpt: x_1.Shape,
                dragType: "DayFact",
                dragData: ofArray([x_1, studentDay.CalendarDay]),
            }))) : (x = dayPlanFact.fields[0], singleton_1(createElement(Render_1, {
                workout: x.Workout,
                comment: x.Workout.Comment,
                shapeOpt: void 0,
                dragType: "DayPlan",
                dragData: ofArray([x, studentDay.CalendarDay]),
            }))));
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_1))))), delay(() => {
            let dayFact_1, dayPlan_2;
            return append((editModel == null) ? singleton_1(null) : ((editModel.tag === 1) ? (dayFact_1 = editModel.fields[0], singleton_1(createElement(Render_2, {
                dayFact: dayFact_1,
                sourceDayPlan: void 0,
                daySegment: dayFact_1.DaySegment,
                onSave: (arg_1) => {
                    onSave(new DayPlanFact_DayPlanFact(1, arg_1));
                },
                onDelete: onDelete,
                onCancel: onCancel,
            }))) : (dayPlan_2 = editModel.fields[0], singleton_1(createElement(Render_3, {
                dayPlan: dayPlan_2,
                onSave: (arg) => {
                    onSave(new DayPlanFact_DayPlanFact(0, arg));
                },
                onImplement: () => {
                    setEditModel(void 0);
                    setIsImplementPlan(dayPlan_2);
                },
                onDelete: onDelete,
                onCancel: onCancel,
            })))), delay(() => {
                if (isImplementPlan == null) {
                    return singleton_1(null);
                }
                else {
                    const dayPlan_3 = isImplementPlan;
                    return singleton_1(createElement(Render_2, {
                        dayFact: void 0,
                        sourceDayPlan: dayPlan_3,
                        daySegment: daySegment,
                        onSave: (dayFact_2) => {
                            startImmediate(implementPlan(studentDay, dayFact_2, () => {
                                setIsImplementPlan(void 0);
                                onSave(new DayPlanFact_DayPlanFact(1, dayFact_2));
                            }, () => {
                                setIsImplementPlan(void 0);
                            }));
                        },
                        onDelete: () => {
                        },
                        onCancel: () => {
                            setIsImplementPlan(void 0);
                        },
                    }));
                }
            }));
        }));
    })));
}

