import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { union_type, list_type, bool_type, class_type, record_type, int32_type, string_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { Calendar_getWeekEndDate, Calendar_getWeekStartDate, Calendar_getYearEndDate, Calendar_getYearStartDate, Statistics_StatisticRequest, Calendar_getPeriodEndDate, Calendar_getPeriodStartDate, Statistics_StatisticType, StatisticHelpers_calcForm, StatisticHelpers_calcDayDataSummary, StatisticHelpers_calcRest, SpeedActivity_list, StatisticHelpers_calcSpeedActivity, SpeedActivity_strSpeedActivity, StatisticHelpers_calcMobility, StrengthActivity_list, StatisticHelpers_calcStrengthActivity, StrengthActivity_strStrengthActivity, StatisticHelpers_calcEnduranceIntensity, StatisticHelpers_calcFactSummary, StatisticHelpers_calcWorkoutPart, StatisticHelpers_calcPlanSummary, StatisticHelpers_strSummary, StatisticHelpers_calcEnduranceActivityCount, StatisticHelpers_calcEnduranceIntensityCount, EnduranceActivity_getEnduranceActivityColor, EnduranceActivity_strEnduranceActivity, StatisticHelpers_calcEnduranceActivityDuration, DurationModule_intToHMSString, EnduranceIntensity_getEnduranceIntensityColor, EnduranceIntensity_strEnduranceIntensity, StatisticHelpers_facts, StatisticHelpers_calcEnduranceIntensityDuration, StatisticHelpers_mapWorkoutParts, StatisticHelpers_foldDurationOpt, EnduranceActivity_list, EnduranceIntensity_list, Calendar_getWeekInfo, Calendar_getDayDate, Calendar_PeriodInfo_get_Current, EnduranceActivity_EnduranceActivity$reflection, EnduranceIntensity_EnduranceIntensity$reflection } from "../../Shared/Shared.fs.js";
import { append as append_1, ofSeq, length, singleton as singleton_1, ofArray, contains, toArray, empty, sumBy, filter, map } from "../.fable/fable-library.3.2.1/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { Legend, Pie, Tooltip, PieChart as PieChart_1, Cell } from "recharts";
import { createElement } from "react";
import * as react from "react";
import { map as map_1, empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { partialApply, compare, createObj, safeHash, round, equals } from "../.fable/fable-library.3.2.1/Util.js";
import { join, interpolate, toText } from "../.fable/fable-library.3.2.1/String.js";
import { loader } from "../Loader.fs.js";
import { map as map_2, defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { useReact_useMemo_CF4EA67, useReact_useState_FCFD9EF, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { FSharpSet__Remove, FSharpSet__Add, FSharpSet__Contains, empty as empty_2, ofList } from "../.fable/fable-library.3.2.1/Set.js";
import { View_checkBoxFieldBig, View_checkBoxField } from "../Common.fs.js";
import { Locale, ClassName, OnChange, Value, DateFormat, custom, flatpickr } from "../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { Deferred_map, Deferred$1, useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.fs.js";
import { statisticsApi } from "../Communication.fs.js";
import { singleton as singleton_2 } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { Route, toPath } from "../Router.fs.js";
import { addYears } from "../.fable/fable-library.3.2.1/Date.js";

export class PieSlice extends Record {
    constructor(name, value) {
        super();
        this.name = name;
        this.value = (value | 0);
    }
}

export function PieSlice$reflection() {
    return record_type("Statistic.PieSlice", [], PieSlice, () => [["name", string_type], ["value", int32_type]]);
}

export class StatisticFilters extends Record {
    constructor(DateFrom, DateTo, CompareWithPrevious, EnduranceIntensities, EnduranceActivities) {
        super();
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.CompareWithPrevious = CompareWithPrevious;
        this.EnduranceIntensities = EnduranceIntensities;
        this.EnduranceActivities = EnduranceActivities;
    }
}

export function StatisticFilters$reflection() {
    return record_type("Statistic.StatisticFilters", [], StatisticFilters, () => [["DateFrom", class_type("System.DateTime")], ["DateTo", class_type("System.DateTime")], ["CompareWithPrevious", bool_type], ["EnduranceIntensities", list_type(EnduranceIntensity_EnduranceIntensity$reflection())], ["EnduranceActivities", list_type(EnduranceActivity_EnduranceActivity$reflection())]]);
}

export function StatisticFilters_get_Current() {
    const periodInfo = Calendar_PeriodInfo_get_Current();
    return new StatisticFilters(Calendar_getDayDate(Calendar_getWeekInfo(periodInfo, 0), 0), Calendar_getDayDate(Calendar_getWeekInfo(periodInfo, 3), 6), false, EnduranceIntensity_list, EnduranceActivity_list);
}

export class ChartItem$1 extends Record {
    constructor(Key, Value) {
        super();
        this.Key = Key;
        this.Value = (Value | 0);
    }
}

export function ChartItem$1$reflection(gen0) {
    return record_type("Statistic.ChartItem`1", [gen0], ChartItem$1, () => [["Key", gen0], ["Value", int32_type]]);
}

export function PieChart(pieChartInputProps) {
    const withLegend = pieChartInputProps.withLegend;
    const valueFormatter = pieChartInputProps.valueFormatter;
    const colorGetter = pieChartInputProps.colorGetter;
    const nameGetter = pieChartInputProps.nameGetter;
    const items = pieChartInputProps.items;
    const chartData = map((x_1) => (new PieSlice(nameGetter(x_1.Key), x_1.Value)), filter((x) => (x.Value > 0), items));
    const colors = map((x_2) => Interop_reactApi.createElement(Cell, {
        fill: colorGetter(x_2.Key),
    }), items);
    const sum = sumBy((x_3) => x_3.Value, items, {
        GetZero: () => 0,
        Add: (x_4, y) => (x_4 + y),
    }) | 0;
    const patternInput = withLegend ? [600, 300, 150, 150, 80, 120] : [200, 200, 100, 100, 40, 80];
    return createElement("div", {
        style: {
            position: "relative",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(equals(chartData, empty()) ? singleton(createElement("div", {
            style: {
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("label", {
                className: "label",
                children: "No data",
            })]),
        })) : empty_1(), delay(() => singleton(Interop_reactApi.createElement(PieChart_1, {
            width: patternInput[0],
            height: patternInput[1],
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(Interop_reactApi.createElement(Tooltip, {
                separator: " ",
                formatter: (value_1) => toText(interpolate("%P() (%P()%%)", [valueFormatter(value_1), (sum === 0) ? 0 : round((value_1 * 100) / sum)])),
            })), delay(() => append(singleton(Interop_reactApi.createElement(Pie, {
                data: toArray(chartData),
                cx: patternInput[2],
                cy: patternInput[3],
                innerRadius: patternInput[4],
                outerRadius: patternInput[5],
                labelLine: false,
                fill: "#8884d8",
                children: ["children", Interop_reactApi.Children.toArray(Array.from(colors))],
                legendType: "circle",
                dataKey: (x_6) => x_6.value,
            })), delay(() => (withLegend ? singleton(Interop_reactApi.createElement(Legend, {
                align: "right",
                layout: "vertical",
                verticalAlign: "middle",
            })) : empty_1()))))))))),
        })))))))),
    });
}

export function renderOrLoader(deferred, el) {
    switch (deferred.tag) {
        case 3: {
            const value = deferred.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            return el(deferred.fields[0]);
        }
        default: {
            return loader("big");
        }
    }
}

export function EnduranceIntensityDurationPieChart(enduranceIntensityDurationPieChartInputProps) {
    const withLegend = enduranceIntensityDurationPieChartInputProps.withLegend;
    const intensities = enduranceIntensityDurationPieChartInputProps.intensities;
    const daysList = enduranceIntensityDurationPieChartInputProps.daysList;
    const chartData = map((x_2) => (new ChartItem$1(x_2, defaultArg(StatisticHelpers_foldDurationOpt(StatisticHelpers_mapWorkoutParts((workoutPart) => StatisticHelpers_calcEnduranceIntensityDuration(x_2, workoutPart), StatisticHelpers_facts(daysList))), 0))), filter((x) => contains(x, intensities, {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => safeHash(x_1),
    }), EnduranceIntensity_list));
    return createElement(PieChart, {
        items: chartData,
        nameGetter: (_arg1) => EnduranceIntensity_strEnduranceIntensity(_arg1),
        colorGetter: (_arg1_1) => EnduranceIntensity_getEnduranceIntensityColor(_arg1_1),
        valueFormatter: (x_3) => DurationModule_intToHMSString(x_3),
        withLegend: withLegend,
    });
}

export function EnduranceActivityDurationPieChart(enduranceActivityDurationPieChartInputProps) {
    const withLegend = enduranceActivityDurationPieChartInputProps.withLegend;
    const activities = enduranceActivityDurationPieChartInputProps.activities;
    const daysList = enduranceActivityDurationPieChartInputProps.daysList;
    const chartData = map((x_2) => (new ChartItem$1(x_2, defaultArg(StatisticHelpers_foldDurationOpt(StatisticHelpers_mapWorkoutParts((workoutPart) => StatisticHelpers_calcEnduranceActivityDuration(x_2, workoutPart), StatisticHelpers_facts(daysList))), 0))), filter((x) => contains(x, activities, {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => safeHash(x_1),
    }), EnduranceActivity_list));
    return createElement(PieChart, {
        items: chartData,
        nameGetter: (_arg1) => EnduranceActivity_strEnduranceActivity(_arg1),
        colorGetter: (_arg1_1) => EnduranceActivity_getEnduranceActivityColor(_arg1_1),
        valueFormatter: (x_3) => DurationModule_intToHMSString(x_3),
        withLegend: withLegend,
    });
}

export function EnduranceIntensityCountPieChart(enduranceIntensityCountPieChartInputProps) {
    const withLegend = enduranceIntensityCountPieChartInputProps.withLegend;
    const intensities = enduranceIntensityCountPieChartInputProps.intensities;
    const daysList = enduranceIntensityCountPieChartInputProps.daysList;
    const chartData = map((x_2) => (new ChartItem$1(x_2, defaultArg(StatisticHelpers_foldDurationOpt(StatisticHelpers_mapWorkoutParts((workoutPart) => StatisticHelpers_calcEnduranceIntensityCount(x_2, workoutPart), StatisticHelpers_facts(daysList))), 0))), filter((x) => contains(x, intensities, {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => safeHash(x_1),
    }), EnduranceIntensity_list));
    return createElement(PieChart, {
        items: chartData,
        nameGetter: (_arg1) => EnduranceIntensity_strEnduranceIntensity(_arg1),
        colorGetter: (_arg1_1) => EnduranceIntensity_getEnduranceIntensityColor(_arg1_1),
        valueFormatter: (x_3) => toText(interpolate("%P() st", [x_3])),
        withLegend: withLegend,
    });
}

export function EnduranceActivityCountPieChart(enduranceActivityCountPieChartInputProps) {
    const withLegend = enduranceActivityCountPieChartInputProps.withLegend;
    const activities = enduranceActivityCountPieChartInputProps.activities;
    const daysList = enduranceActivityCountPieChartInputProps.daysList;
    const chartData = map((x_2) => (new ChartItem$1(x_2, defaultArg(StatisticHelpers_foldDurationOpt(StatisticHelpers_mapWorkoutParts((workoutPart) => StatisticHelpers_calcEnduranceActivityCount(x_2, workoutPart), StatisticHelpers_facts(daysList))), 0))), filter((x) => contains(x, activities, {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => safeHash(x_1),
    }), EnduranceActivity_list));
    return createElement(PieChart, {
        items: chartData,
        nameGetter: (_arg1) => EnduranceActivity_strEnduranceActivity(_arg1),
        colorGetter: (_arg1_1) => EnduranceActivity_getEnduranceActivityColor(_arg1_1),
        valueFormatter: (x_3) => toText(interpolate("%P() st", [x_3])),
        withLegend: withLegend,
    });
}

export class Chart extends Record {
    constructor(id, name) {
        super();
        this.id = (id | 0);
        this.name = name;
    }
}

export function Chart$reflection() {
    return record_type("Statistic.Chart", [], Chart, () => [["id", int32_type], ["name", string_type]]);
}

export const chartsList = ofArray([new Chart(1, "Intensitet, Tid"), new Chart(2, "Aktivitet, Tid"), new Chart(3, "Intensitet, Antal"), new Chart(4, "Aktivitet, Antal")]);

export function RenderPeriodCharts(renderPeriodChartsInputProps) {
    const daysList = renderPeriodChartsInputProps.daysList;
    const intensities = renderPeriodChartsInputProps.intensities;
    const activities = renderPeriodChartsInputProps.activities;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const selectedChart = patternInput[0];
    const renderChart = (index, withLegend) => react.createElement(react.Fragment, {}, ...toList(delay(() => append((index === 1) ? singleton(createElement(EnduranceIntensityDurationPieChart, {
        daysList: daysList,
        intensities: intensities,
        withLegend: withLegend,
    })) : empty_1(), delay(() => append((index === 2) ? singleton(createElement(EnduranceActivityDurationPieChart, {
        daysList: daysList,
        activities: activities,
        withLegend: withLegend,
    })) : empty_1(), delay(() => append((index === 3) ? singleton(createElement(EnduranceIntensityCountPieChart, {
        daysList: daysList,
        intensities: intensities,
        withLegend: withLegend,
    })) : empty_1(), delay(() => ((index === 4) ? singleton(createElement(EnduranceActivityCountPieChart, {
        daysList: daysList,
        activities: activities,
        withLegend: withLegend,
    })) : empty_1()))))))))));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let elms_1;
        return append(singleton((elms_1 = toList(delay(() => map_1((chart) => {
            let elms;
            const props_2 = ofArray([["onClick", (_arg1) => {
                patternInput[1](equals(selectedChart, chart) ? (void 0) : chart);
            }], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(renderChart(chart.id, false)), createElement("div", {
                className: "box",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]]);
            return createElement("div", createObj(Helpers_combineClasses("column", props_2)));
        }, chartsList))), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), delay(() => {
            let props_7;
            if (selectedChart == null) {
                return singleton(null);
            }
            else {
                const selectedChart_1 = selectedChart;
                return singleton((props_7 = ofArray([["style", {
                    display: "flex",
                    justifyContent: "center",
                }], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", selectedChart_1.name]])))), renderChart(selectedChart_1.id, true)])]]), createElement("div", createObj(Helpers_combineClasses("box", props_7)))));
            }
        }));
    })));
}

export function RenderPreviousYearPeriodCharts(renderPreviousYearPeriodChartsInputProps) {
    const daysList = renderPreviousYearPeriodChartsInputProps.daysList;
    const intensities = renderPreviousYearPeriodChartsInputProps.intensities;
    const activities = renderPreviousYearPeriodChartsInputProps.activities;
    return react.createElement(react.Fragment, {}, createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "Föregående år"]])))), createElement(RenderPeriodCharts, {
        activities: activities,
        intensities: intensities,
        daysList: daysList,
    }));
}

export function RenderEnduranceIntensities(renderEnduranceIntensitiesInputProps) {
    const onChange = renderEnduranceIntensitiesInputProps.onChange;
    const value = renderEnduranceIntensitiesInputProps.value;
    const patternInput = useFeliz_React__React_useState_Static_1505(ofList(value, {
        Compare: (x, y) => compare(x, y),
    }));
    const setSelected = patternInput[1];
    const selected = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        setSelected(ofList(value, {
            Compare: (x_1, y_1) => compare(x_1, y_1),
        }));
    }, [value]);
    const isAllSelected = length(value) === length(EnduranceIntensity_list);
    const changeValue = (x_2) => {
        setSelected(x_2);
        onChange(ofSeq(x_2));
    };
    const elms = toList(delay(() => append(singleton(View_checkBoxField("Markera alla", isAllSelected, (isChecked) => {
        changeValue(isChecked ? ofList(EnduranceIntensity_list, {
            Compare: (x_3, y_2) => compare(x_3, y_2),
        }) : empty_2({
            Compare: (x_4, y_3) => compare(x_4, y_3),
        }));
    })), delay(() => map_1((item) => {
        let intensity;
        return View_checkBoxField(EnduranceIntensity_strEnduranceIntensity(item), FSharpSet__Contains(selected, item), (intensity = item, (isChecked_1) => {
            changeValue(isChecked_1 ? FSharpSet__Add(selected, intensity) : FSharpSet__Remove(selected, intensity));
        }));
    }, EnduranceIntensity_list)))));
    return createElement("div", {
        className: "block",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function RenderEnduranceActivities(renderEnduranceActivitiesInputProps) {
    const onChange = renderEnduranceActivitiesInputProps.onChange;
    const value = renderEnduranceActivitiesInputProps.value;
    const patternInput = useFeliz_React__React_useState_Static_1505(ofList(value, {
        Compare: (x, y) => compare(x, y),
    }));
    const setSelected = patternInput[1];
    const selected = patternInput[0];
    useReact_useEffect_Z101E1A95(() => {
        setSelected(ofList(value, {
            Compare: (x_1, y_1) => compare(x_1, y_1),
        }));
    }, [value]);
    const isAllSelected = length(value) === length(EnduranceActivity_list);
    const changeValue = (x_2) => {
        setSelected(x_2);
        onChange(ofSeq(x_2));
    };
    const elms = toList(delay(() => append(singleton(View_checkBoxField("Markera alla", isAllSelected, (isChecked) => {
        changeValue(isChecked ? ofList(EnduranceActivity_list, {
            Compare: (x_3, y_2) => compare(x_3, y_2),
        }) : empty_2({
            Compare: (x_4, y_3) => compare(x_4, y_3),
        }));
    })), delay(() => map_1((item) => {
        let intensity;
        return View_checkBoxField(EnduranceActivity_strEnduranceActivity(item), FSharpSet__Contains(selected, item), (intensity = item, (isChecked_1) => {
            changeValue(isChecked_1 ? FSharpSet__Add(selected, intensity) : FSharpSet__Remove(selected, intensity));
        }));
    }, EnduranceActivity_list)))));
    return createElement("div", {
        className: "block",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function RenderFilters(renderFiltersInputProps) {
    let elms_8, elms_9, elms_10;
    const excelRender = renderFiltersInputProps.excelRender;
    const onChange = renderFiltersInputProps.onChange;
    const filters = renderFiltersInputProps.filters;
    const elms_11 = ofArray([(elms_8 = toList(delay(() => {
        let elms_3, elms_2, elms, elms_1;
        return append(singleton((elms_3 = singleton_1((elms_2 = ofArray([(elms = singleton_1(createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["children", "Från"]]))))), createElement("div", {
            className: "field-label",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_1(flatpickr(ofArray([custom("locale", {
            firstDayOfWeek: 1,
        }, true), DateFormat("d M Y"), Value(filters.DateFrom), OnChange((x) => {
            onChange(new StatisticFilters(x, filters.DateTo, filters.CompareWithPrevious, filters.EnduranceIntensities, filters.EnduranceActivities));
        }), ClassName("input"), Locale(Swedish)]))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))), delay(() => {
            let elms_7, elms_6, elms_4, elms_5;
            return append(singleton((elms_7 = singleton_1((elms_6 = ofArray([(elms_4 = singleton_1(createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["children", "Till"]]))))), createElement("div", {
                className: "field-label",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            })), (elms_5 = singleton_1(flatpickr(ofArray([custom("locale", {
                firstDayOfWeek: 1,
            }, true), DateFormat("d M Y"), Value(filters.DateTo), OnChange((x_1) => {
                onChange(new StatisticFilters(filters.DateFrom, x_1, filters.CompareWithPrevious, filters.EnduranceIntensities, filters.EnduranceActivities));
            }), ClassName("input"), Locale(Swedish)]))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            }))]), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            }))), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
            }))), delay(() => append(singleton(View_checkBoxFieldBig("Jämför med tidigare år", filters.CompareWithPrevious, (x_2) => {
                onChange(new StatisticFilters(filters.DateFrom, filters.DateTo, x_2, filters.EnduranceIntensities, filters.EnduranceActivities));
            })), delay(() => ((excelRender == null) ? singleton(null) : singleton(excelRender))))));
        }));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    })), (elms_9 = singleton_1(createElement(RenderEnduranceIntensities, {
        value: filters.EnduranceIntensities,
        onChange: (x_3) => {
            onChange(new StatisticFilters(filters.DateFrom, filters.DateTo, filters.CompareWithPrevious, x_3, filters.EnduranceActivities));
        },
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    })), (elms_10 = singleton_1(createElement(RenderEnduranceActivities, {
        value: filters.EnduranceActivities,
        onChange: (x_4) => {
            onChange(new StatisticFilters(filters.DateFrom, filters.DateTo, filters.CompareWithPrevious, filters.EnduranceIntensities, x_4));
        },
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    }))]);
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
    });
}

export function StatisticBlock(statisticBlockInputProps) {
    const moreStatisticLink = statisticBlockInputProps.moreStatisticLink;
    const yearPlanStr = statisticBlockInputProps.yearPlanStr;
    const daysList = statisticBlockInputProps.daysList;
    const row = (title, sumStr, countStr) => {
        const children = ofArray([createElement("td", {
            children: [title],
        }), createElement("td", {
            children: [sumStr],
        }), createElement("td", {
            children: [countStr],
        })]);
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    };
    const timeRow = (title_1, tupledArg) => row(title_1, tupledArg[0], tupledArg[2]);
    const amountRow = (title_2, tupledArg_1) => row(title_2, tupledArg_1[1], tupledArg_1[2]);
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props_11, elms, children_4, children_2, children_6;
        return append(singleton((props_11 = ofArray([["className", "is-size-7"], ["children", Interop_reactApi.Children.toArray([(elms = ofArray([(children_4 = singleton_1((children_2 = ofArray([createElement("th", {
            children: [""],
        }), createElement("th", {
            children: ["Tid"],
        }), createElement("th", {
            children: ["Pass"],
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        })), (children_6 = ofArray([row("Årsplanering", yearPlanStr, "-"), timeRow("Passplanering", StatisticHelpers_strSummary(StatisticHelpers_calcPlanSummary((workoutPart) => StatisticHelpers_calcWorkoutPart(workoutPart), daysList))), timeRow("Genomfört", StatisticHelpers_strSummary(StatisticHelpers_calcFactSummary((workoutPart_1) => StatisticHelpers_calcWorkoutPart(workoutPart_1), daysList)))]), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))]), createElement("table", {
            className: "table",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("panel-block", props_11))))), delay(() => {
            let props_25, props_23, children_11, children_9, children_15;
            return append(singleton((props_25 = ofArray([["className", "is-size-7"], ["children", Interop_reactApi.Children.toArray([(props_23 = singleton_1(["children", Interop_reactApi.Children.toArray([(children_11 = singleton_1((children_9 = ofArray([createElement("th", {
                children: [""],
            }), createElement("th", {
                children: ["Tid"],
            }), createElement("th", {
                children: ["Pass"],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_9)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_11)),
            })), (children_15 = toList(delay(() => {
                const headRow = (title_3) => {
                    const children_13 = ofArray([createElement("th", {
                        style: {
                            color: "#D3D3D3",
                        },
                        className: "pl-1",
                        children: title_3,
                    }), createElement("th", {}), createElement("th", {})]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_13)),
                    });
                };
                return append(singleton(headRow("Uthållighet")), delay(() => append(map_1((item) => timeRow(EnduranceIntensity_strEnduranceIntensity(item), StatisticHelpers_strSummary(StatisticHelpers_calcFactSummary((workoutPart_2) => StatisticHelpers_calcEnduranceIntensity(item, workoutPart_2), daysList))), EnduranceIntensity_list), delay(() => append(singleton(headRow("Styrka")), delay(() => append(map_1((item_1) => timeRow(StrengthActivity_strStrengthActivity(item_1), StatisticHelpers_strSummary(StatisticHelpers_calcFactSummary((workoutPart_3) => StatisticHelpers_calcStrengthActivity(item_1, workoutPart_3), daysList))), StrengthActivity_list), delay(() => append(singleton(headRow("Rörlighet")), delay(() => append(singleton(timeRow("Rörlighet", StatisticHelpers_strSummary(StatisticHelpers_calcFactSummary((workoutPart_4) => StatisticHelpers_calcMobility(workoutPart_4), daysList)))), delay(() => append(singleton(headRow("Snabbhet")), delay(() => append(map_1((item_2) => amountRow(SpeedActivity_strSpeedActivity(item_2), StatisticHelpers_strSummary(StatisticHelpers_calcFactSummary((workoutPart_5) => StatisticHelpers_calcSpeedActivity(item_2, workoutPart_5), daysList))), SpeedActivity_list), delay(() => append(singleton(headRow("Vila")), delay(() => singleton(amountRow("Vila", StatisticHelpers_strSummary(StatisticHelpers_calcFactSummary((workoutPart_6) => StatisticHelpers_calcRest(workoutPart_6), daysList))))))))))))))))))))));
            })), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_15)),
            }))])]), createElement("table", createObj(Helpers_combineClasses("table", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("panel-block", props_25))))), delay(() => append(singleton(createElement(EnduranceActivityDurationPieChart, {
                daysList: daysList,
                activities: EnduranceActivity_list,
                withLegend: false,
            })), delay(() => append(singleton(moreStatisticLink), delay(() => {
                let props_46, props_44, children_19, children_17, children_29, children_21, children_23, children_25, children_27;
                const patternInput = StatisticHelpers_calcDayDataSummary(daysList);
                const form = StatisticHelpers_calcForm(daysList);
                return singleton((props_46 = ofArray([["className", "is-size-7"], ["children", Interop_reactApi.Children.toArray([(props_44 = ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray([(children_19 = singleton_1((children_17 = ofArray([createElement("th", {
                    children: [""],
                }), createElement("th", {
                    children: ["Antal"],
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_17)),
                }))), createElement("thead", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_19)),
                })), (children_29 = ofArray([(children_21 = ofArray([createElement("td", {
                    children: ["Sjuk"],
                }), createElement("td", {
                    children: [patternInput[0]],
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_21)),
                })), (children_23 = ofArray([createElement("td", {
                    children: ["Skadad"],
                }), createElement("td", {
                    children: [patternInput[1]],
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_23)),
                })), (children_25 = ofArray([createElement("td", {
                    children: ["Resedag"],
                }), createElement("td", {
                    children: [patternInput[2]],
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_25)),
                })), (children_27 = ofArray([createElement("td", {
                    children: ["Form"],
                }), createElement("td", {
                    children: [form],
                })]), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_27)),
                }))]), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_29)),
                }))])]]), createElement("table", createObj(Helpers_combineClasses("table", props_44))))])]]), createElement("div", createObj(Helpers_combineClasses("panel-block", props_46)))));
            }))))));
        }));
    })));
}

export function StatisticWithoutTabs(statisticWithoutTabsInputProps) {
    const daysList = statisticWithoutTabsInputProps.daysList;
    const planStr = statisticWithoutTabsInputProps.planStr;
    const title = statisticWithoutTabsInputProps.isPreviousYear ? "Föregående år" : "Statistik";
    const elms = ofArray([createElement("p", createObj(Helpers_combineClasses("panel-heading", ofArray([["className", "is-size-6"], ["className", "has-text-weight-light"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", title]])))), createElement(StatisticBlock, {
        daysList: daysList,
        yearPlanStr: planStr,
        moreStatisticLink: null,
    })]);
    return createElement("nav", {
        className: "panel",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export class StatisticPeriodType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Year", "Period", "Week"];
    }
}

export function StatisticPeriodType$reflection() {
    return union_type("Statistic.StatisticPeriodType", [], StatisticPeriodType, () => [[], [], []]);
}

export function StatisticWithTabs(statisticWithTabsInputProps) {
    const periodWeek = statisticWithTabsInputProps.periodWeek;
    const periodInfo = statisticWithTabsInputProps.periodInfo;
    const studentId = statisticWithTabsInputProps.studentId;
    const overviewList = statisticWithTabsInputProps.overviewList;
    const patternInput = useFeliz_React__React_useState_Static_1505((periodWeek != null) ? (new StatisticPeriodType(2)) : (new StatisticPeriodType(1)));
    const statisticPeriodType = patternInput[0];
    const setStatisticPeriodType = patternInput[1];
    const statisticPeriodList = useFeliz_React__React_useDeferred_Static_2344FC52(defaultArg(map_2((arg) => Remoting_handleNonAuth(statisticsApi().daysList(arg)), (statisticPeriodType.tag === 1) ? ((periodWeek != null) ? (new Statistics_StatisticRequest(new Statistics_StatisticType(2, studentId), Calendar_getPeriodStartDate(periodInfo), Calendar_getPeriodEndDate(periodInfo))) : (void 0)) : ((statisticPeriodType.tag === 2) ? (void 0) : (new Statistics_StatisticRequest(new Statistics_StatisticType(2, studentId), Calendar_getYearStartDate(periodInfo.PeriodYear), Calendar_getYearEndDate(periodInfo.PeriodYear))))), singleton_2.Delay(() => singleton_2.Return(overviewList))), [studentId, statisticPeriodType, periodInfo, periodWeek]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(1));
    useReact_useEffect_Z101E1A95(() => {
        const dayInPeriod = (periodInfo_1, periodWeek_1, x) => {
            if ((x.CalendarDay.Year === periodInfo_1.PeriodYear) ? (x.CalendarDay.Period === periodInfo_1.Period) : false) {
                if (equals(x.CalendarDay.Week, periodWeek_1)) {
                    return true;
                }
                else {
                    return periodWeek_1 == null;
                }
            }
            else {
                return false;
            }
        };
        patternInput_1[1](Deferred_map((sList) => {
            const fullLust = append_1(overviewList, filter((arg_1) => (!dayInPeriod(periodInfo, periodWeek, arg_1)), sList));
            const matchValue = [statisticPeriodType, periodWeek];
            let pattern_matching_result;
            if (matchValue[0].tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue[0].tag === 2) {
                if (matchValue[1] == null) {
                    pattern_matching_result = 1;
                }
                else {
                    pattern_matching_result = 0;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return fullLust;
                }
                case 1: {
                    return filter(partialApply(1, dayInPeriod, [periodInfo, 0]), fullLust);
                }
            }
        }, statisticPeriodList));
    }, [overviewList, statisticPeriodList, statisticPeriodType]);
    let patternInput_2;
    switch (statisticPeriodType.tag) {
        case 1: {
            patternInput_2 = [Calendar_getPeriodStartDate(periodInfo), Calendar_getPeriodEndDate(periodInfo)];
            break;
        }
        case 2: {
            const weekInfo = Calendar_getWeekInfo(periodInfo, defaultArg(periodWeek, 0));
            patternInput_2 = [Calendar_getWeekStartDate(weekInfo), Calendar_getWeekEndDate(weekInfo)];
            break;
        }
        default: {
            patternInput_2 = [Calendar_getYearStartDate(periodInfo.PeriodYear), Calendar_getYearEndDate(periodInfo.PeriodYear)];
        }
    }
    const query_2 = new Statistics_StatisticRequest(new Statistics_StatisticType(2, studentId), patternInput_2[0], patternInput_2[1]);
    const yearPlan = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(statisticsApi().yearPlanSeconds(query_2)), [studentId, statisticPeriodType, periodInfo, periodWeek]);
    const yearPlanStr = (yearPlan.tag === 2) ? DurationModule_intToHMSString(yearPlan.fields[0]) : "";
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput_3[0];
    const props_12 = ofArray([["style", {
        overflow: "hidden",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_3;
        return append(singleton((props_3 = ofArray([["style", {
            minWidth: "200px",
            display: "flex",
        }], ["className", "is-size-6"], ["className", "has-text-weight-light"], ["className", "has-background-primary"], ["className", "has-text-white"], ["onClick", (_arg1) => {
            patternInput_3[1](!isVisible);
        }], ["children", Interop_reactApi.Children.toArray([createElement("span", {
            style: {
                flexGrow: 1,
            },
            children: "Statistik",
        }), createElement("span", {
            className: "has-text-white",
            children: Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (isVisible ? singleton(["className", join(" ", ["fa", "fa-chevron-up"])]) : singleton(["className", join(" ", ["fa", "fa-chevron-down"])]))))))]),
        })])]]), createElement("p", createObj(Helpers_combineClasses("panel-heading", props_3))))), delay(() => {
            let props_8;
            return isVisible ? append(singleton((props_8 = ofArray([["className", "is-size-5"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
                children: "Å",
                className: join(" ", toList(delay(() => (equals(statisticPeriodType, new StatisticPeriodType(0)) ? singleton("is-active") : empty_1())))),
                onClick: (_arg2) => {
                    setStatisticPeriodType(new StatisticPeriodType(0));
                },
            }), createElement("a", {
                children: "P",
                className: join(" ", toList(delay(() => (equals(statisticPeriodType, new StatisticPeriodType(1)) ? singleton("is-active") : empty_1())))),
                onClick: (_arg3) => {
                    setStatisticPeriodType(new StatisticPeriodType(1));
                },
            }), createElement("a", {
                children: "V",
                className: join(" ", toList(delay(() => (equals(statisticPeriodType, new StatisticPeriodType(2)) ? singleton("is-active") : empty_1())))),
                onClick: (_arg4) => {
                    setStatisticPeriodType(new StatisticPeriodType(2));
                },
            })])]]), createElement("p", createObj(Helpers_combineClasses("panel-tabs", props_8))))), delay(() => singleton(renderOrLoader(patternInput_1[0], (list_2) => {
                let moreStatisticLink;
                const props_10 = ofArray([["href", toPath(new Route(15, studentId))], ["style", {
                    display: "block",
                }], ["className", "is-text"], ["children", "Visa mer statistik"], ["className", "has-text-link"]]);
                moreStatisticLink = createElement("a", createObj(Helpers_combineClasses("button", props_10)));
                return createElement(StatisticBlock, {
                    daysList: list_2,
                    yearPlanStr: yearPlanStr,
                    moreStatisticLink: moreStatisticLink,
                });
            })))) : empty_1();
        }));
    }))))]]);
    return createElement("nav", createObj(Helpers_combineClasses("panel", props_12)));
}

export class TablesPeriod extends Record {
    constructor(DateFrom, DateTo) {
        super();
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
    }
}

export function TablesPeriod$reflection() {
    return record_type("Statistic.TablesPeriod", [], TablesPeriod, () => [["DateFrom", class_type("System.DateTime")], ["DateTo", class_type("System.DateTime")]]);
}

export function RenderFiltersAndCharts(renderFiltersAndChartsInputProps) {
    let prevYearQuery, prevYearQuery_1;
    const tablesRender = renderFiltersAndChartsInputProps.tablesRender;
    const excelRender = renderFiltersAndChartsInputProps.excelRender;
    const statisticType = renderFiltersAndChartsInputProps.statisticType;
    const title = renderFiltersAndChartsInputProps.title;
    const patternInput = useReact_useState_FCFD9EF(StatisticFilters_get_Current);
    const filters = patternInput[0];
    const query = new Statistics_StatisticRequest(statisticType, filters.DateFrom, filters.DateTo);
    const daysList = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(statisticsApi().daysList(query)), [statisticType, filters.DateFrom, filters.DateTo]);
    const query_1 = new Statistics_StatisticRequest(statisticType, filters.DateFrom, filters.DateTo);
    const yearPlan = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(statisticsApi().yearPlanSeconds(query_1)), [statisticType, filters.DateFrom, filters.DateTo]);
    const yearPlanStr = (yearPlan.tag === 2) ? DurationModule_intToHMSString(yearPlan.fields[0]) : "";
    const prevYearList = useFeliz_React__React_useDeferred_Static_2344FC52(filters.CompareWithPrevious ? (prevYearQuery = (new Statistics_StatisticRequest(statisticType, addYears(filters.DateFrom, -1), addYears(filters.DateTo, -1))), Remoting_handleNonAuth(statisticsApi().daysList(prevYearQuery))) : singleton_2.Delay(() => singleton_2.Return(empty())), [statisticType, filters.DateFrom, filters.DateTo, filters.CompareWithPrevious]);
    const thisYearDates = useReact_useMemo_CF4EA67(() => (new TablesPeriod(filters.DateFrom, filters.DateTo)), [filters.DateFrom, filters.DateTo]);
    const prevYearDates = useReact_useMemo_CF4EA67(() => (new TablesPeriod(addYears(filters.DateFrom, -1), addYears(filters.DateTo, -1))), [filters.DateFrom, filters.DateTo]);
    const prevYearPlan = useFeliz_React__React_useDeferred_Static_2344FC52(filters.CompareWithPrevious ? (prevYearQuery_1 = (new Statistics_StatisticRequest(statisticType, prevYearDates.DateFrom, prevYearDates.DateTo)), Remoting_handleNonAuth(statisticsApi().yearPlanSeconds(prevYearQuery_1))) : singleton_2.Delay(() => singleton_2.Return(0)), [statisticType, filters.DateFrom, filters.DateTo, filters.CompareWithPrevious]);
    const prevYearPlanStr = (prevYearPlan.tag === 2) ? DurationModule_intToHMSString(prevYearPlan.fields[0]) : "";
    const renderTables = (dates, daysList_1) => {
        if (tablesRender == null) {
            return null;
        }
        else {
            return tablesRender(dates)(daysList_1);
        }
    };
    const props_14 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_12, elms_1, props_10;
        return append(singleton((props_12 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_1 = toList(delay(() => {
            let props_3;
            return append(singleton((props_3 = ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                className: "",
                children: "Statistik för ",
            }), createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", title]]))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_3))))), delay(() => {
                let elms;
                return append(singleton((elms = toList(delay(() => append(singleton(createElement(RenderFilters, {
                    filters: filters,
                    onChange: patternInput[1],
                    excelRender: excelRender,
                })), delay(() => append(singleton(renderOrLoader(daysList, (daysList_2) => createElement(RenderPeriodCharts, {
                    activities: filters.EnduranceActivities,
                    intensities: filters.EnduranceIntensities,
                    daysList: daysList_2,
                }))), delay(() => (filters.CompareWithPrevious ? singleton(renderOrLoader(prevYearList, (daysList_3) => createElement(RenderPreviousYearPeriodCharts, {
                    activities: filters.EnduranceActivities,
                    intensities: filters.EnduranceIntensities,
                    daysList: daysList_3,
                }))) : empty_1()))))))), createElement("div", {
                    className: "box",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))), delay(() => (filters.CompareWithPrevious ? append(singleton(renderTables(thisYearDates, daysList)), delay(() => {
                    let props_7;
                    return append(singleton((props_7 = ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
                        className: "",
                        children: "Föregående år",
                    })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_7))))), delay(() => singleton(renderTables(prevYearDates, prevYearList))));
                })) : empty_1())));
            }));
        })), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (props_10 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(renderOrLoader(daysList, (daysList_4) => createElement(StatisticWithoutTabs, {
            planStr: yearPlanStr,
            isPreviousYear: false,
            daysList: daysList_4,
        }))), delay(() => (filters.CompareWithPrevious ? singleton(renderOrLoader(prevYearList, (daysList_5) => createElement(StatisticWithoutTabs, {
            planStr: prevYearPlanStr,
            isPreviousYear: true,
            daysList: daysList_5,
        }))) : empty_1())))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_10))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_12))))), delay(() => ((!filters.CompareWithPrevious) ? singleton(renderTables(thisYearDates, daysList)) : empty_1())));
    }))))]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_14)));
}

