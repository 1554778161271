import { DayFact_DayFact, Shape_getShapeIntValue, DayFact_createFromDayPlan, DayFact_DayFact_Default_Z38742B54 } from "../../Shared/Shared.fs.js";
import { interpolate, toText } from "../.fable/fable-library.3.2.1/String.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { ShapeEditor } from "./WorkoutPartEdit.fs.js";
import { Render as Render_1 } from "./WorkoutEdit.fs.js";
import { View_RenderDeleteWindow } from "../Common.fs.js";

export function Render(renderInputProps) {
    const onCancel = renderInputProps.onCancel;
    const onDelete = renderInputProps.onDelete;
    const onSave = renderInputProps.onSave;
    const daySegment = renderInputProps.daySegment;
    const sourceDayPlan = renderInputProps.sourceDayPlan;
    const dayFact = renderInputProps.dayFact;
    let patternInput;
    if (dayFact == null) {
        if (sourceDayPlan == null) {
            patternInput = ["Registrera ny träning", DayFact_DayFact_Default_Z38742B54(daySegment)];
        }
        else {
            const dayPlan = sourceDayPlan;
            const dayFact_2 = DayFact_createFromDayPlan(dayPlan);
            patternInput = [toText(interpolate("Registrera %P()", [dayPlan.Workout.Title])), dayFact_2];
        }
    }
    else {
        const dayFact_1 = dayFact;
        patternInput = [toText(interpolate("%P() (%P())", [dayFact_1.Workout.Title, Shape_getShapeIntValue(dayFact_1.Shape)])), dayFact_1];
    }
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(patternInput[1]);
    const setEditModel = patternInput_1[1];
    const editModel = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_2[1];
    const isDeleteWindow = patternInput_2[0];
    const props_28 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg1) => {
        onCancel();
    }]))))), delay(() => {
        let elms_2, elms, props_15, children_1, props_12, elms_1, props_9, value_29, props_23;
        return append(singleton((elms_2 = ofArray([(elms = ofArray([createElement("p", createObj(Helpers_combineClasses("modal-card-title", ofArray([["className", "is-size-6"], ["className", "has-text-weight-bold"], ["children", patternInput[0]]])))), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg2) => {
            onCancel();
        }]))))]), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_15 = ofArray([["style", {
            overflowY: "scroll",
        }], ["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray([(children_1 = react.createElement(react.Fragment, {}, ShapeEditor(editModel.Shape, (newShape) => {
            setEditModel(new DayFact_DayFact(editModel.DaySegment, newShape, editModel.CommentAfterTraining, editModel.Workout));
        }), (props_12 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-7"], ["className", "has-text-grey"], ["className", "mb-1"], ["children", "Kommentar efter träning"]])))), (elms_1 = singleton_1((props_9 = ofArray([["className", "is-small"], ["rows", 3], (value_29 = editModel.CommentAfterTraining, ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_29)) : false) {
                e.value = value_29;
            }
        }]), ["onChange", (ev) => {
            setEditModel(new DayFact_DayFact(editModel.DaySegment, editModel.Shape, ev.target.value, editModel.Workout));
        }]]), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_9))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_12))))), createElement(Render_1, {
            workout: editModel.Workout,
            onChange: (newWorkout) => {
                setEditModel(new DayFact_DayFact(editModel.DaySegment, editModel.Shape, editModel.CommentAfterTraining, newWorkout));
            },
            children: children_1,
        }))])]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_15)))), (props_23 = ofArray([["style", {
            justifyContent: "flex-end",
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((dayFact == null) ? singleton(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg4) => {
            onCancel();
        }], ["className", "is-text"], ["className", "has-text-danger"], ["children", "Avbryt"]]))))) : singleton(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg3) => {
            setIsDeleteWindow(!isDeleteWindow);
        }], ["className", "has-text-danger"], ["children", "Ta bort"]]))))), delay(() => singleton(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg5) => {
            onSave(editModel);
        }], ["className", "is-success"], ["children", "Spara"]])))))))))))]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_23))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), delay(() => append(singleton(createElement("button", createObj(Helpers_combineClasses("modal-close", singleton_1(["onClick", (_arg6) => {
            onCancel();
        }]))))), delay(() => (isDeleteWindow ? singleton(createElement(View_RenderDeleteWindow, {
            confirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
                onDelete();
            },
            notConfirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            text: "Är du säker på att du vill ta bort detta pass?",
        })) : empty())))));
    }))))))]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_28)));
}

