import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { borderStylePrimary } from "../Common.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import { singleton as singleton_1, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { EmailModule_toString } from "../../Shared/Shared.fs.js";
import { map, defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { toString } from "../.fable/fable-library.3.2.1/Date.js";
import { Route, toPath } from "../Router.fs.js";
import { Component_Render as Component_Render_1 } from "./ChangeFullName.fs.js";
import { Component_Render as Component_Render_2 } from "./ChangeEmail.fs.js";
import { Component_Render as Component_Render_3 } from "./ChangePassword.fs.js";

export function View_view(user) {
    let props_51, props_49, props_39, props_47, props_41, props_43, props_45;
    const props_53 = ofArray([["className", "pt-6"], ["children", Interop_reactApi.Children.toArray([(props_51 = ofArray([["className", "px-5"], borderStylePrimary, ["children", Interop_reactApi.Children.toArray([(props_49 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-8"])], ["children", Interop_reactApi.Children.toArray([(props_39 = ofArray([["className", "is-narrow"], ["className", "mr-6"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms, props_1;
        return append(singleton((elms = ofArray([(props_1 = ofArray([["className", "is-medium"], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
            className: join(" ", ["fa", "fa-user", "fa-lg"]),
        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_1)))), createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "is-size-4"], ["className", "has-text-weight-bold"], ["className", "has-text-primary"], ["className", "mx-2"], ["children", user.FullName]]))))]), createElement("div", {
            className: "block",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), delay(() => {
            let elms_1, props_7, props_9, props_13, props_15;
            return append(singleton((elms_1 = ofArray([(props_7 = ofArray([["className", "is-medium"], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-envelope", "fa-lg"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7)))), (props_9 = ofArray([["className", "is-size-5"], ["className", "has-text-primary"], ["className", "mx-2"], ["children", EmailModule_toString(user.Email)]]), createElement("span", createObj(Helpers_combineClasses("", props_9)))), createElement("p", {
                className: "mb-2",
            }), (props_13 = ofArray([["className", "is-medium"], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-check-double", "fa-lg"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_13)))), (props_15 = ofArray([["className", "is-size-5"], ["className", "has-text-primary"], ["className", "mx-2"], ["children", defaultArg(map((x) => ("Godkände GDPR " + toString(x, "yyyy-MM-dd")), user.GdprAcceptDate), "GDPR är inte godkänt")]]), createElement("span", createObj(Helpers_combineClasses("", props_15))))]), createElement("div", {
                className: "block",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))), delay(() => {
                let elms_2, props_22, props_19;
                return append(singleton((elms_2 = singleton_1((props_22 = ofArray([["className", "is-outlined"], ["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", "is-left"], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-tools"]),
                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_19)))), createElement("span", {
                    className: "",
                    children: "Lathund",
                })])]]), createElement("a", createObj(Helpers_combineClasses("button", props_22))))), createElement("div", {
                    className: "block",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))), delay(() => {
                    let elms_3, props_29, props_26;
                    return (user.Role === 3) ? append(singleton((elms_3 = singleton_1((props_29 = ofArray([["className", "is-outlined"], ["className", "is-primary"], ["href", toPath(new Route(13, user.UserId))], ["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["className", "is-left"], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-clipboard-check"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_26)))), createElement("span", {
                        className: "",
                        children: "Planering",
                    })])]]), createElement("a", createObj(Helpers_combineClasses("button", props_29))))), createElement("div", {
                        className: "block",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    }))), delay(() => {
                        let elms_4, props_36, props_33;
                        return singleton((elms_4 = singleton_1((props_36 = ofArray([["className", "is-outlined"], ["className", "is-primary"], ["href", toPath(new Route(14, user.UserId))], ["children", Interop_reactApi.Children.toArray([(props_33 = ofArray([["className", "is-left"], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["fa", "fa-user"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_33)))), createElement("span", {
                            className: "",
                            children: "Mina externa tränare",
                        })])]]), createElement("a", createObj(Helpers_combineClasses("button", props_36))))), createElement("div", {
                            className: "block",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                        })));
                    })) : empty();
                }));
            }));
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_39)))), (props_47 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_41 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement(Component_Render_1, null)])]]), createElement("div", createObj(Helpers_combineClasses("box", props_41)))), (props_43 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement(Component_Render_2, null)])]]), createElement("div", createObj(Helpers_combineClasses("box", props_43)))), (props_45 = ofArray([["className", "has-background-primary-light"], ["children", Interop_reactApi.Children.toArray([createElement(Component_Render_3, null)])]]), createElement("div", createObj(Helpers_combineClasses("box", props_45))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_47))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_51))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_53)));
}

export function Component_Render(user) {
    return View_view(user);
}

