import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { record_type, bool_type, class_type, list_type, union_type, string_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { usersApi } from "../Communication.fs.js";
import { EmailModule_ofString, EmailModule_empty } from "../../Shared/Shared.fs.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { View_renderNotification, Form_validationForgotPassword } from "../Common.fs.js";
import { value as value_50 } from "../.fable/fable-library.3.2.1/Option.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { cons, singleton as singleton_1, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { singleton as singleton_2, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { Route, toPath } from "../Router.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { View_layout } from "./Login.fs.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export class Types_State extends Record {
    constructor(Email, Recovery, FormErrors, FormValidation) {
        super();
        this.Email = Email;
        this.Recovery = Recovery;
        this.FormErrors = FormErrors;
        this.FormValidation = FormValidation;
    }
}

export function Types_State$reflection() {
    return record_type("ForgotPassword.Types.State", [], Types_State, () => [["Email", string_type], ["Recovery", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["FormValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailChanged", "RecoverAccount", "UserSaved"];
    }
}

export function Types_Msg$reflection() {
    return union_type("ForgotPassword.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]], []]);
}

export function Cmd_recover(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().requestResetPassword(state.Email), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Det finns ingen användare med den epost-adressen.")))) : singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.fields[0]))))))), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init() {
    return [new Types_State(EmailModule_empty, new DeferredResult$1(0), empty(), false), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Email, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Email, new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.FormValidation), Cmd_none()];
            }
        }
        else {
            const errors_1 = Form_validationForgotPassword(state.Email, (new Types_State(state.Email, state.Recovery, state.FormErrors, true)).FormValidation);
            if (value_50(errors_1).Equals(empty())) {
                return [new Types_State(state.Email, new DeferredResult$1(1), value_50(errors_1), true), Cmd_fromAsync(Cmd_recover(state))];
            }
            else {
                return [new Types_State(state.Email, new DeferredResult$1(0), value_50(errors_1), true), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 2) {
        return [state, Cmd_none()];
    }
    else {
        return [new Types_State(msg.fields[0], state.Recovery, value_50(Form_validationForgotPassword(msg.fields[0], state.FormValidation)), state.FormValidation), Cmd_none()];
    }
}

export function View_renderRequestingError(state) {
    const matchValue = state.Recovery;
    let pattern_matching_result, error;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return null;
        }
        case 1: {
            return View_renderNotification(["className", "is-success"], "Ett meddelande med instruktioner har skickats till din epost.");
        }
        case 2: {
            return View_renderNotification(["className", "is-danger"], error);
        }
    }
}

export function View_renderForm(state, dispatch) {
    let props_7, props_2, elms, elms_1, props_17, props_15, elms_2;
    return createElement("form", {
        onSubmit: (x) => {
            x.preventDefault();
            dispatch(new Types_Msg(1, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray([(props_7 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-6"], ["className", "has-text-left"], ["children", "Din epost-adress"]]))))])]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1(createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", ofArray([["placeholder", "Din epost-adress"], ["type", "email"], ["onChange", (ev) => {
            dispatch(new Types_Msg(0, EmailModule_ofString(ev.target.value)));
        }]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_7)))), (elms_1 = toList(delay(() => {
            const matchValue = state.Recovery;
            let pattern_matching_result;
            if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 0) {
                    pattern_matching_result = 2;
                }
                else {
                    pattern_matching_result = 0;
                }
            }
            else if (matchValue.tag === 1) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["children", "Skicka instruktioner"]])))));
                }
                case 1: {
                    return singleton_2(null);
                }
                case 2: {
                    return singleton_2(null);
                }
            }
        })), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (props_17 = ofArray([["className", "mt-3"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["href", toPath(new Route(0))], ["className", "is-outlined"], ["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_1(createElement("i", {
            className: join(" ", ["fa", "fa-chevron-left"]),
        })), createElement("span", {
            className: "icon",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), createElement("span", {
            className: "",
            children: "Back to login",
        })])]]), createElement("a", createObj(Helpers_combineClasses("button", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_17)))), View_renderRequestingError(state)]),
    });
}

export function View_view(state, dispatch) {
    return View_layout(View_renderForm(state, dispatch));
}

export function Component_Render() {
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(msg, state), []);
    return View_view(patternInput[0], patternInput[1]);
}

