import { interpolate, toText } from "./.fable/fable-library.3.2.1/String.js";
import { getToken } from "./LocalStorage.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./.fable/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { Admin_FavoritesApi$reflection, Schools_SchoolsApi$reflection, Statistics_StatisticsApi$reflection, Schedules_SchedulesApi$reflection, StudentDays_StudentDaysApi$reflection, WorkoutTemplates_WorkoutTemplatesApi$reflection, Teachers_TeachersApi$reflection, Students_StudentsApi$reflection, Groups_GroupsApi$reflection, Settings_SettingsApi$reflection, Users_UsersApi$reflection, Auth_SignInApi$reflection, Route_builder } from "../Shared/Shared.fs.js";

function token() {
    return toText(interpolate("Bearer %s%P()", [getToken()]));
}

export function authApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: Auth_SignInApi$reflection,
    });
}

export function usersApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: Users_UsersApi$reflection,
    });
}

export function settingsApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Settings_SettingsApi$reflection,
    });
}

export function groupsApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Groups_GroupsApi$reflection,
    });
}

export function studentsApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Students_StudentsApi$reflection,
    });
}

export function teachersApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Teachers_TeachersApi$reflection,
    });
}

export function workoutTemplatesApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: WorkoutTemplates_WorkoutTemplatesApi$reflection,
    });
}

export function studentDaysApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: StudentDays_StudentDaysApi$reflection,
    });
}

export function schedulesApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Schedules_SchedulesApi$reflection,
    });
}

export function statisticsApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Statistics_StatisticsApi$reflection,
    });
}

export function schoolsApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Schools_SchoolsApi$reflection,
    });
}

export function adminApi() {
    let options_1;
    return Remoting_buildProxy_Z15584635((options_1 = RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(token(), options_1)), {
        ResolveType: Admin_FavoritesApi$reflection,
    });
}

