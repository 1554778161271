import { Record, Union } from "./.fable/fable-library.3.2.1/Types.js";
import { lambda_type, record_type, union_type, int32_type, class_type, string_type } from "./.fable/fable-library.3.2.1/Reflection.js";
import { Calendar_WeekInfo_get_Current, Calendar_PeriodInfo_get_Current, Calendar_DayInfo_get_Current, Users_User, Auth_SignedInUser$reflection, Users_User$reflection, Calendar_WeekInfo$reflection, Calendar_PeriodInfo$reflection, Calendar_DayInfo$reflection } from "../Shared/Shared.fs.js";
import { Cmd_OfFunc_result, Cmd_batch, Cmd_none } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { navigateTo, Route, modifyUrl } from "./Router.fs.js";
import { map, defaultArg } from "./.fable/fable-library.3.2.1/Option.js";
import { saveUser, removeUser, getUser } from "./LocalStorage.fs.js";
import { singleton, ofArray } from "./.fable/fable-library.3.2.1/List.js";
import { Remoting_subscribeUserChangedEvent } from "./Extensions.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./.fable/Feliz.1.51.0/Interop.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.2.1/Seq.js";
import { Render } from "./Admin/HeaderAdmin.fs.js";
import { Render as Render_1 } from "./Teacher/HeaderTeacher.fs.js";
import { Render as Render_2 } from "./Student/HeaderStudent.fs.js";
import { DndContext } from "./Components/ReactDnd.fs.js";
import { equals } from "./.fable/fable-library.3.2.1/Util.js";
import { Render as Render_3 } from "./Login/GdprModal.fs.js";
import { Component_Render } from "./Login/ForgotPassword.fs.js";
import { Component_Render as Component_Render_1 } from "./Login/ResetPassword.fs.js";
import { render } from "./Login/Gdpr.fs.js";
import { render as render_1 } from "./Login/Application.fs.js";
import { Component_Render as Component_Render_2 } from "./Settings/Settings.fs.js";
import { Component_Render as Component_Render_3 } from "./Chat.fs.js";
import { View_Render } from "./Teacher/GroupList.fs.js";
import { Component_Render as Component_Render_4 } from "./Teacher/GroupEdit.fs.js";
import { Component_Render as Component_Render_5 } from "./Teacher/StudentList.fs.js";
import { Component_Render as Component_Render_6 } from "./Teacher/StudentEdit.fs.js";
import { Component_Render as Component_Render_7 } from "./Teacher/StudentPlan.fs.js";
import { View_Render as View_Render_1 } from "./Student/StudentExternalTeachers.fs.js";
import { Render as Render_4 } from "./Teacher/StudentStatistics.fs.js";
import { Render as Render_5 } from "./Teacher/GroupStatistics.fs.js";
import { Render as Render_6 } from "./Teacher/SchoolStatistics.fs.js";
import { Component_Render as Component_Render_8 } from "./Teacher/TeacherList.fs.js";
import { Component_Render as Component_Render_9 } from "./Teacher/TeacherEdit.fs.js";
import { View_Render as View_Render_2 } from "./PassList.fs.js";
import { View_renderPeriod, View_renderWeek } from "./Overview.fs.js";
import { Render as Render_7 } from "./Student/Activity.fs.js";
import { View_Render as View_Render_3 } from "./Teacher/ScheduleList.fs.js";
import { View_Render as View_Render_4 } from "./Teacher/ScheduleAssign.fs.js";
import { View_RenderWeek, View_RenderPeriod } from "./Teacher/ScheduleCalendar.fs.js";
import { Component_Render as Component_Render_10 } from "./Admin/AdminOverview.fs.js";
import { Component_Render as Component_Render_11 } from "./Admin/StudentSchoolEdit.fs.js";
import { Component_Render as Component_Render_12 } from "./Admin/UserRestore.fs.js";
import { Component_Render as Component_Render_13 } from "./Login/Login.fs.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Login", "ForgotPassword", "ResetPassword", "Gdpr", "Application", "Settings", "Chat", "GroupList", "GroupAdd", "GroupEdit", "StudentList", "StudentAdd", "StudentEdit", "UserRestore", "StudentPlan", "StudentExternalTeachers", "StudentStatistics", "GroupStatistics", "SchoolStatistics", "TeacherList", "TeacherAdd", "TeacherEdit", "ScheduleList", "ScheduleAssign", "ScheduleCalendarPeriod", "ScheduleCalendarWeek", "Activity", "PassList", "OverviewToday", "OverviewPeriod", "OverviewWeek", "AdminOverview", "StudentSchoolEdit"];
    }
}

export function Page$reflection() {
    return union_type("Index.Page", [], Page, () => [[], [], [["Item", string_type]], [], [], [], [["Item", class_type("System.Guid")]], [], [], [["Item", class_type("System.Guid")]], [], [], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [], [], [["Item", class_type("System.Guid")]], [], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", int32_type]], [["Item", Calendar_DayInfo$reflection()]], [], [["Item", class_type("System.Guid")]], [["Item1", class_type("System.Guid")], ["Item2", Calendar_PeriodInfo$reflection()]], [["Item1", class_type("System.Guid")], ["Item2", Calendar_WeekInfo$reflection()]], [], [["Item", class_type("System.Guid")]]]);
}

export class State extends Record {
    constructor(CurrentPage, User) {
        super();
        this.CurrentPage = CurrentPage;
        this.User = User;
    }
}

export function State$reflection() {
    return record_type("Index.State", [], State, () => [["CurrentPage", Page$reflection()], ["User", Users_User$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoggedInApp", "Logout", "Unauthorized", "StartSubscription", "UserChanged"];
    }
}

export function Msg$reflection() {
    return union_type("Index.Msg", [], Msg, () => [[["Item", Auth_SignedInUser$reflection()]], [], [], [], [["Item", lambda_type(Auth_SignedInUser$reflection(), Auth_SignedInUser$reflection())]]]);
}

export function isHeaderRouteActive(currentPage, headerRoute) {
    const matchValue = [headerRoute, currentPage];
    let pattern_matching_result;
    if (matchValue[0].tag === 27) {
        if (matchValue[1].tag === 27) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[1].tag === 5) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 31) {
        if (matchValue[1].tag === 31) {
            pattern_matching_result = 2;
        }
        else if (matchValue[1].tag === 10) {
            pattern_matching_result = 3;
        }
        else if (matchValue[1].tag === 11) {
            pattern_matching_result = 4;
        }
        else if (matchValue[1].tag === 12) {
            pattern_matching_result = 5;
        }
        else if (matchValue[1].tag === 32) {
            pattern_matching_result = 6;
        }
        else if (matchValue[1].tag === 13) {
            pattern_matching_result = 7;
        }
        else if (matchValue[1].tag === 14) {
            pattern_matching_result = 8;
        }
        else if (matchValue[1].tag === 16) {
            pattern_matching_result = 9;
        }
        else if (matchValue[1].tag === 17) {
            pattern_matching_result = 10;
        }
        else if (matchValue[1].tag === 18) {
            pattern_matching_result = 11;
        }
        else if (matchValue[1].tag === 6) {
            pattern_matching_result = 12;
        }
        else if (matchValue[1].tag === 29) {
            pattern_matching_result = 13;
        }
        else if (matchValue[1].tag === 30) {
            pattern_matching_result = 14;
        }
        else if (matchValue[1].tag === 28) {
            pattern_matching_result = 15;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 7) {
        if (matchValue[1].tag === 7) {
            pattern_matching_result = 16;
        }
        else if (matchValue[1].tag === 8) {
            pattern_matching_result = 17;
        }
        else if (matchValue[1].tag === 9) {
            pattern_matching_result = 18;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 10) {
        if (matchValue[1].tag === 10) {
            pattern_matching_result = 19;
        }
        else if (matchValue[1].tag === 11) {
            pattern_matching_result = 20;
        }
        else if (matchValue[1].tag === 12) {
            pattern_matching_result = 21;
        }
        else if (matchValue[1].tag === 14) {
            pattern_matching_result = 22;
        }
        else if (matchValue[1].tag === 16) {
            pattern_matching_result = 23;
        }
        else if (matchValue[1].tag === 17) {
            pattern_matching_result = 24;
        }
        else if (matchValue[1].tag === 18) {
            pattern_matching_result = 25;
        }
        else if (matchValue[1].tag === 6) {
            pattern_matching_result = 26;
        }
        else if (matchValue[1].tag === 29) {
            pattern_matching_result = 27;
        }
        else if (matchValue[1].tag === 30) {
            pattern_matching_result = 28;
        }
        else if (matchValue[1].tag === 28) {
            pattern_matching_result = 29;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 18) {
        if (matchValue[1].tag === 19) {
            pattern_matching_result = 30;
        }
        else if (matchValue[1].tag === 20) {
            pattern_matching_result = 31;
        }
        else if (matchValue[1].tag === 21) {
            pattern_matching_result = 32;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 21) {
        if (matchValue[1].tag === 22) {
            pattern_matching_result = 33;
        }
        else if (matchValue[1].tag === 23) {
            pattern_matching_result = 34;
        }
        else if (matchValue[1].tag === 24) {
            pattern_matching_result = 35;
        }
        else if (matchValue[1].tag === 25) {
            pattern_matching_result = 36;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 25) {
        if (matchValue[1].tag === 26) {
            pattern_matching_result = 37;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 29) {
        if (matchValue[1].tag === 29) {
            pattern_matching_result = 38;
        }
        else if (matchValue[1].tag === 30) {
            pattern_matching_result = 39;
        }
        else if (matchValue[1].tag === 28) {
            pattern_matching_result = 40;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].tag === 6) {
            pattern_matching_result = 41;
        }
        else {
            pattern_matching_result = 42;
        }
    }
    else {
        pattern_matching_result = 42;
    }
    switch (pattern_matching_result) {
        case 0: {
            return true;
        }
        case 1: {
            return true;
        }
        case 2: {
            return true;
        }
        case 3: {
            return true;
        }
        case 4: {
            return true;
        }
        case 5: {
            return true;
        }
        case 6: {
            return true;
        }
        case 7: {
            return true;
        }
        case 8: {
            return true;
        }
        case 9: {
            return true;
        }
        case 10: {
            return true;
        }
        case 11: {
            return true;
        }
        case 12: {
            return true;
        }
        case 13: {
            return true;
        }
        case 14: {
            return true;
        }
        case 15: {
            return true;
        }
        case 16: {
            return true;
        }
        case 17: {
            return true;
        }
        case 18: {
            return true;
        }
        case 19: {
            return true;
        }
        case 20: {
            return true;
        }
        case 21: {
            return true;
        }
        case 22: {
            return true;
        }
        case 23: {
            return true;
        }
        case 24: {
            return true;
        }
        case 25: {
            return true;
        }
        case 26: {
            return true;
        }
        case 27: {
            return true;
        }
        case 28: {
            return true;
        }
        case 29: {
            return true;
        }
        case 30: {
            return true;
        }
        case 31: {
            return true;
        }
        case 32: {
            return true;
        }
        case 33: {
            return true;
        }
        case 34: {
            return true;
        }
        case 35: {
            return true;
        }
        case 36: {
            return true;
        }
        case 37: {
            return true;
        }
        case 38: {
            return true;
        }
        case 39: {
            return true;
        }
        case 40: {
            return true;
        }
        case 41: {
            return true;
        }
        case 42: {
            return false;
        }
    }
}

export function urlUpdate(nextPage, state) {
    const matchValue = [nextPage, state.User];
    let pattern_matching_result, resetPasswordId, id, id_1, id_2, id_3, id_4, id_5, userId, info, userId_1, info_1, userId_2, dayInfo, scheduleId, scheduleId_1, periodWeek, scheduleId_2, userId_3, id_6, id_7, id_8, id_9, user;
    if (matchValue[0] != null) {
        if (matchValue[0].tag === 1) {
            pattern_matching_result = 1;
        }
        else if (matchValue[0].tag === 2) {
            pattern_matching_result = 2;
            resetPasswordId = matchValue[0].fields[0];
        }
        else if (matchValue[0].tag === 3) {
            pattern_matching_result = 3;
        }
        else if (matchValue[0].tag === 4) {
            pattern_matching_result = 4;
        }
        else if (matchValue[0].tag === 5) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 5;
            }
        }
        else if (matchValue[0].tag === 6) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 6;
                id = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 7) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 7;
            }
        }
        else if (matchValue[0].tag === 8) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 8;
            }
        }
        else if (matchValue[0].tag === 9) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 9;
                id_1 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 10) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 10;
            }
        }
        else if (matchValue[0].tag === 11) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 11;
            }
        }
        else if (matchValue[0].tag === 12) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 12;
                id_2 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 13) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 13;
                id_3 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 14) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 14;
                id_4 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 18) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 15;
            }
        }
        else if (matchValue[0].tag === 19) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 16;
            }
        }
        else if (matchValue[0].tag === 20) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 17;
                id_5 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 28) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 18;
                userId = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 29) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 19;
                info = matchValue[0].fields[1];
                userId_1 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 30) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 20;
                info_1 = matchValue[0].fields[1];
                userId_2 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 25) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 21;
            }
        }
        else if (matchValue[0].tag === 26) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 22;
                dayInfo = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 27) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 23;
            }
        }
        else if (matchValue[0].tag === 21) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 24;
            }
        }
        else if (matchValue[0].tag === 22) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 25;
                scheduleId = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 23) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 26;
                scheduleId_1 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 24) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 27;
                periodWeek = matchValue[0].fields[1];
                scheduleId_2 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 15) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 28;
                userId_3 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 16) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 29;
                id_6 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 17) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 30;
                id_7 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 31) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 31;
            }
        }
        else if (matchValue[0].tag === 32) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 32;
                id_8 = matchValue[0].fields[0];
            }
        }
        else if (matchValue[0].tag === 33) {
            if (matchValue[1].tag === 0) {
                pattern_matching_result = 37;
            }
            else {
                pattern_matching_result = 33;
                id_9 = matchValue[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else if (matchValue[1].tag === 0) {
        pattern_matching_result = 37;
    }
    else if (matchValue[1].fields[0].Role === 1) {
        pattern_matching_result = 34;
    }
    else if (matchValue[1].fields[0].Role === 2) {
        pattern_matching_result = 35;
    }
    else {
        pattern_matching_result = 36;
        user = matchValue[1].fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new State(new Page(0), new Users_User(0)), Cmd_none()];
        }
        case 1: {
            return [new State(new Page(1), state.User), Cmd_none()];
        }
        case 2: {
            return [new State(new Page(2, resetPasswordId), state.User), Cmd_none()];
        }
        case 3: {
            return [new State(new Page(3), state.User), Cmd_none()];
        }
        case 4: {
            return [new State(new Page(4), state.User), Cmd_none()];
        }
        case 5: {
            return [new State(new Page(5), state.User), Cmd_none()];
        }
        case 6: {
            return [new State(new Page(6, id), state.User), Cmd_none()];
        }
        case 7: {
            return [new State(new Page(7), state.User), Cmd_none()];
        }
        case 8: {
            return [new State(new Page(8), state.User), Cmd_none()];
        }
        case 9: {
            return [new State(new Page(9, id_1), state.User), Cmd_none()];
        }
        case 10: {
            return [new State(new Page(10), state.User), Cmd_none()];
        }
        case 11: {
            return [new State(new Page(11), state.User), Cmd_none()];
        }
        case 12: {
            return [new State(new Page(12, id_2), state.User), Cmd_none()];
        }
        case 13: {
            return [new State(new Page(14, id_3), state.User), Cmd_none()];
        }
        case 14: {
            return [new State(new Page(15, id_4), state.User), Cmd_none()];
        }
        case 15: {
            return [new State(new Page(19), state.User), Cmd_none()];
        }
        case 16: {
            return [new State(new Page(20), state.User), Cmd_none()];
        }
        case 17: {
            return [new State(new Page(21, id_5), state.User), Cmd_none()];
        }
        case 18: {
            return [new State(new Page(28, userId), state.User), Cmd_none()];
        }
        case 19: {
            return [new State(new Page(29, userId_1, info), state.User), Cmd_none()];
        }
        case 20: {
            return [new State(new Page(30, userId_2, info_1), state.User), Cmd_none()];
        }
        case 21: {
            return [new State(new Page(26, Calendar_DayInfo_get_Current()), state.User), Cmd_none()];
        }
        case 22: {
            return [new State(new Page(26, dayInfo), state.User), Cmd_none()];
        }
        case 23: {
            return [new State(new Page(27), state.User), Cmd_none()];
        }
        case 24: {
            return [new State(new Page(22), state.User), Cmd_none()];
        }
        case 25: {
            return [new State(new Page(23, scheduleId), state.User), Cmd_none()];
        }
        case 26: {
            return [new State(new Page(24, scheduleId_1), state.User), Cmd_none()];
        }
        case 27: {
            return [new State(new Page(25, scheduleId_2, periodWeek), state.User), Cmd_none()];
        }
        case 28: {
            return [new State(new Page(16, userId_3), state.User), Cmd_none()];
        }
        case 29: {
            return [new State(new Page(17, id_6), state.User), Cmd_none()];
        }
        case 30: {
            return [new State(new Page(18, id_7), state.User), Cmd_none()];
        }
        case 31: {
            return [new State(new Page(31), state.User), Cmd_none()];
        }
        case 32: {
            return [new State(new Page(32, id_8), state.User), Cmd_none()];
        }
        case 33: {
            return [new State(new Page(13, id_9), state.User), Cmd_none()];
        }
        case 34: {
            return [new State(new Page(31), state.User), modifyUrl(new Route(31))];
        }
        case 35: {
            return [new State(new Page(7), state.User), modifyUrl(new Route(7))];
        }
        case 36: {
            return [new State(new Page(28, user.UserId), state.User), modifyUrl(new Route(28, user.UserId))];
        }
        case 37: {
            return [new State(new Page(0), state.User), modifyUrl(new Route(0))];
        }
    }
}

export function init(page) {
    const user = defaultArg(map((arg0) => (new Users_User(1, arg0)), getUser()), new Users_User(0));
    const patternInput = urlUpdate(page, new State((user.tag === 0) ? (new Page(0)) : ((user.fields[0].Role === 1) ? (new Page(31)) : ((user.fields[0].Role === 2) ? (new Page(7)) : (new Page(28, user.fields[0].UserId)))), user));
    return [patternInput[0], Cmd_batch(ofArray([patternInput[1], Cmd_OfFunc_result(new Msg(3))]))];
}

export function update(msg, state) {
    let matchValue, user_5;
    let pattern_matching_result, user_1;
    if (msg.tag === 0) {
        if (msg.fields[0].Role === 1) {
            pattern_matching_result = 0;
            user_1 = msg.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new State(state.CurrentPage, new Users_User(1, user_1)), navigateTo(new Route(31))];
        }
        case 1: {
            let pattern_matching_result_1, user_3;
            if (msg.tag === 0) {
                if (msg.fields[0].Role === 2) {
                    pattern_matching_result_1 = 0;
                    user_3 = msg.fields[0];
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [new State(state.CurrentPage, new Users_User(1, user_3)), navigateTo(new Route(10))];
                }
                case 1: {
                    switch (msg.tag) {
                        case 1: {
                            removeUser();
                            return [new State(state.CurrentPage, new Users_User(0)), navigateTo(new Route(0))];
                        }
                        case 2: {
                            return init(new Route(0));
                        }
                        case 3: {
                            return [state, Cmd_batch(singleton(Remoting_subscribeUserChangedEvent((arg0) => (new Msg(4, arg0)))))];
                        }
                        case 4: {
                            return [new State(state.CurrentPage, (matchValue = state.User, (matchValue.tag === 0) ? (new Users_User(0)) : (user_5 = msg.fields[0](matchValue.fields[0]), (saveUser(user_5), new Users_User(1, user_5))))), Cmd_none()];
                        }
                        default: {
                            const user_4 = msg.fields[0];
                            return [new State(state.CurrentPage, new Users_User(1, user_4)), navigateTo(new Route(29, user_4.UserId, Calendar_PeriodInfo_get_Current()))];
                        }
                    }
                }
            }
        }
    }
}

export function pageUser(roleFilter, state, dispatch, content) {
    let user_4, user_2;
    const matchValue = [state.User, roleFilter];
    let pattern_matching_result;
    if (matchValue[0].tag === 1) {
        if (matchValue[1] != null) {
            if (matchValue[1] === 1) {
                if (matchValue[0].fields[0].Role !== 1) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return createElement("p", {
                children: ["Only administrators can access this page"],
            });
        }
        case 1: {
            let pattern_matching_result_1;
            if (matchValue[0].tag === 1) {
                if (matchValue[1] != null) {
                    if (matchValue[1] === 2) {
                        if (user_2 = matchValue[0].fields[0], (user_2.Role !== 2) ? (user_2.Role !== 1) : false) {
                            pattern_matching_result_1 = 0;
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return createElement("p", {
                        children: ["Only teachers can access this page"],
                    });
                }
                case 1: {
                    let pattern_matching_result_2;
                    if (matchValue[0].tag === 1) {
                        if (matchValue[1] != null) {
                            if (matchValue[1] === 3) {
                                if (user_4 = matchValue[0].fields[0], (user_4.Role !== 3) ? (user_4.Role !== 1) : false) {
                                    pattern_matching_result_2 = 0;
                                }
                                else {
                                    pattern_matching_result_2 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return createElement("p", {
                                children: ["Only students can access this page"],
                            });
                        }
                        case 1: {
                            if (matchValue[0].tag === 1) {
                                const user_6 = matchValue[0].fields[0];
                                return createElement("div", {
                                    className: "app-root",
                                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                        let matchValue_1;
                                        return append((matchValue_1 = (user_6.Role | 0), (matchValue_1 === 1) ? singleton_1(createElement(Render, {
                                            user: user_6,
                                            logOut: () => {
                                                dispatch(new Msg(1));
                                            },
                                            isActive: (headerRoute) => isHeaderRouteActive(state.CurrentPage, headerRoute),
                                        })) : ((matchValue_1 === 2) ? singleton_1(createElement(Render_1, {
                                            user: user_6,
                                            logOut: () => {
                                                dispatch(new Msg(1));
                                            },
                                            isActive: (headerRoute_1) => isHeaderRouteActive(state.CurrentPage, headerRoute_1),
                                        })) : ((matchValue_1 === 3) ? singleton_1(createElement(Render_2, {
                                            user: user_6,
                                            logOut: () => {
                                                dispatch(new Msg(1));
                                            },
                                            isActive: (headerRoute_2) => isHeaderRouteActive(state.CurrentPage, headerRoute_2),
                                        })) : singleton_1(null)))), delay(() => append(singleton_1(DndContext([content(user_6)])), delay(() => (equals(user_6.GdprAcceptDate, void 0) ? singleton_1(createElement(Render_3, null)) : empty())))));
                                    })))),
                                });
                            }
                            else {
                                return createElement("p", {
                                    children: ["Only authentificated user can access this page"],
                                });
                            }
                        }
                    }
                }
            }
        }
    }
}

export const pageAll = (state) => ((dispatch) => ((content) => pageUser(void 0, state, dispatch, content)));

export const pageAdmin = (state) => ((dispatch) => ((content) => pageUser(1, state, dispatch, content)));

export const pageTeacher = (state) => ((dispatch) => ((content) => pageUser(2, state, dispatch, content)));

export const pageStudent = (state) => ((dispatch) => ((content) => pageUser(3, state, dispatch, content)));

export function view(state, dispatch) {
    let weekInfo;
    if (equals(state.CurrentPage, new Page(0))) {
        document.getElementById("viewport_id").setAttribute("content", "width=device-width, initial-scale=1");
    }
    else {
        document.getElementById("viewport_id").setAttribute("content", "");
    }
    const matchValue = state.CurrentPage;
    switch (matchValue.tag) {
        case 1: {
            return createElement(Component_Render, null);
        }
        case 2: {
            return createElement(Component_Render_1, {
                code: matchValue.fields[0],
            });
        }
        case 3: {
            return render;
        }
        case 4: {
            return render_1;
        }
        case 5: {
            return pageAll(state)(dispatch)((user) => createElement(Component_Render_2, user));
        }
        case 6: {
            return pageAll(state)(dispatch)((user_1) => createElement(Component_Render_3, {
                studentId: matchValue.fields[0],
                user: user_1,
            }));
        }
        case 7: {
            return pageTeacher(state)(dispatch)((_user) => createElement(View_Render, _user));
        }
        case 8: {
            return pageTeacher(state)(dispatch)((arg10$0040) => createElement(Component_Render_4, {
                id: void 0,
            }));
        }
        case 9: {
            return pageTeacher(state)(dispatch)((arg10$0040_1) => createElement(Component_Render_4, {
                id: matchValue.fields[0],
            }));
        }
        case 10: {
            return pageTeacher(state)(dispatch)((user_2) => createElement(Component_Render_5, user_2));
        }
        case 11: {
            return pageTeacher(state)(dispatch)((arg10$0040_2) => createElement(Component_Render_6, {
                id: void 0,
            }));
        }
        case 12: {
            return pageTeacher(state)(dispatch)((arg10$0040_3) => createElement(Component_Render_6, {
                id: matchValue.fields[0],
            }));
        }
        case 14: {
            return pageAll(state)(dispatch)((user_3) => createElement(Component_Render_7, {
                id: matchValue.fields[0],
                user: user_3,
            }));
        }
        case 15: {
            return pageAll(state)(dispatch)((_user_1) => createElement(View_Render_1, {
                studentId: matchValue.fields[0],
                _user: _user_1,
            }));
        }
        case 16: {
            return pageAll(state)(dispatch)((arg10$0040_4) => createElement(Render_4, {
                studentId: matchValue.fields[0],
            }));
        }
        case 17: {
            return pageTeacher(state)(dispatch)((arg10$0040_5) => createElement(Render_5, {
                groupId: matchValue.fields[0],
            }));
        }
        case 18: {
            return pageTeacher(state)(dispatch)((arg10$0040_6) => createElement(Render_6, {
                schoolId: matchValue.fields[0],
            }));
        }
        case 19: {
            return pageTeacher(state)(dispatch)((user_4) => createElement(Component_Render_8, user_4));
        }
        case 20: {
            return pageTeacher(state)(dispatch)((arg10$0040_7) => createElement(Component_Render_9, {
                id: void 0,
            }));
        }
        case 21: {
            return pageTeacher(state)(dispatch)((arg10$0040_8) => createElement(Component_Render_9, {
                id: matchValue.fields[0],
            }));
        }
        case 27: {
            return pageAll(state)(dispatch)((user_5) => createElement(View_Render_2, user_5));
        }
        case 28: {
            return pageAll(state)(dispatch)((weekInfo = Calendar_WeekInfo_get_Current(), (_user_2) => View_renderWeek(matchValue.fields[0], weekInfo, _user_2)));
        }
        case 29: {
            return pageAll(state)(dispatch)((_user_3) => View_renderPeriod(matchValue.fields[0], matchValue.fields[1], _user_3));
        }
        case 30: {
            return pageAll(state)(dispatch)((_user_4) => View_renderWeek(matchValue.fields[0], matchValue.fields[1], _user_4));
        }
        case 26: {
            return pageStudent(state)(dispatch)((user_6) => createElement(Render_7, {
                dayInfo: matchValue.fields[0],
                user: user_6,
            }));
        }
        case 22: {
            return pageTeacher(state)(dispatch)((user_7) => createElement(View_Render_3, user_7));
        }
        case 23: {
            return pageTeacher(state)(dispatch)((arg10$0040_9) => createElement(View_Render_4, {
                scheduleId: matchValue.fields[0],
            }));
        }
        case 24: {
            return pageTeacher(state)(dispatch)((arg10$0040_10) => createElement(View_RenderPeriod, {
                scheduleId: matchValue.fields[0],
            }));
        }
        case 25: {
            return pageTeacher(state)(dispatch)((arg20$0040) => createElement(View_RenderWeek, {
                scheduleId: matchValue.fields[0],
                periodWeek: matchValue.fields[1],
            }));
        }
        case 31: {
            return pageAdmin(state)(dispatch)((user_8) => createElement(Component_Render_10, user_8));
        }
        case 32: {
            return pageAdmin(state)(dispatch)((arg10$0040_11) => createElement(Component_Render_11, {
                id: matchValue.fields[0],
            }));
        }
        case 13: {
            return pageAdmin(state)(dispatch)((arg10$0040_12) => createElement(Component_Render_12, {
                id: matchValue.fields[0],
            }));
        }
        default: {
            return createElement(Component_Render_13, {
                loggedIn: (arg) => {
                    dispatch(new Msg(0, arg));
                },
            });
        }
    }
}

