import { ProgramModule_mkProgram, ProgramModule_withSubscription, ProgramModule_run } from "./.fable/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactSynchronous } from "./.fable/Fable.Elmish.React.3.0.1/react.fs.js";
import { ProgramModule_toNavigable } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { parseHash } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { routeParser } from "./Router.fs.js";
import { view, update, init, Msg, urlUpdate as urlUpdate_1 } from "./Index.fs.js";
import { Remoting_subscribe } from "./Extensions.fs.js";
import "./styles/global.scss";


ProgramModule_run(Program_withReactSynchronous("elmish-app", ProgramModule_toNavigable((location) => parseHash(routeParser, location), (nextPage, state_2) => urlUpdate_1(nextPage, state_2), ProgramModule_withSubscription((model) => Remoting_subscribe(new Msg(2), model), ProgramModule_mkProgram((page) => init(page), (msg, state) => update(msg, state), (state_1, dispatch) => view(state_1, dispatch))))));

