import { createElement } from "react";
import { RenderFiltersAndCharts } from "./Statistic.fs.js";
import { Statistics_StatisticType } from "../../Shared/Shared.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.fs.js";
import { groupsApi } from "../Communication.fs.js";
import { loader } from "../Loader.fs.js";

export function RenderMainLayout(group) {
    return createElement(RenderFiltersAndCharts, {
        title: group.Title,
        statisticType: new Statistics_StatisticType(1, group.Id),
        excelRender: void 0,
        tablesRender: void 0,
    });
}

export function renderMainLayoutWithLoad(groupId) {
    const group = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(groupsApi().get(groupId)), [groupId]);
    switch (group.tag) {
        case 3: {
            const value = group.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            return createElement(RenderMainLayout, group.fields[0]);
        }
        default: {
            return loader("big");
        }
    }
}

export function Render(renderInputProps) {
    const _arg1 = renderInputProps._arg1;
    return renderMainLayoutWithLoad(renderInputProps.groupId);
}

