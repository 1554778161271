import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { DayPlanFact_DayPlanFact, Workout_getWorkoutTotalDuration, DurationModule_toHMSString } from "../../Shared/Shared.fs.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Render as Render_1 } from "../Components/DayFactEdit.fs.js";
import { startImmediate } from "../.fable/fable-library.3.2.1/Async.js";
import { updatePlanFact } from "../Components/DayPlanFactBox.fs.js";

export function Render(renderInputProps) {
    const onSave = renderInputProps.onSave;
    const daySegment = renderInputProps.daySegment;
    const studentDayKey = renderInputProps.studentDayKey;
    const isDayFactExists = renderInputProps.isDayFactExists;
    const dayPlan = renderInputProps.dayPlan;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsImplement = patternInput[1];
    if (dayPlan == null) {
        return null;
    }
    else {
        const dayPlan_1 = dayPlan;
        const children_1 = toList(delay(() => {
            let props_6;
            return append(singleton((props_6 = toList(delay(() => append(singleton(["className", "mb-0"]), delay(() => append((!isDayFactExists) ? singleton(["onClick", (_arg1) => {
                setIsImplement(true);
            }]) : empty(), delay(() => append(singleton(["className", "has-text-primary"]), delay(() => append(singleton(["className", "has-text-centered"]), delay(() => append(singleton(["style", {
                border: (((1 + "px ") + "dashed") + " ") + "#808080",
            }]), delay(() => {
                let elms, props_4;
                return singleton(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("i", createObj(toList(delay(() => (isDayFactExists ? singleton(["className", join(" ", ["fa", "fa-check"])]) : singleton(["className", join(" ", ["fa", "fa-arrow-right"])]))))))), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })), (props_4 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("span", {
                    children: [dayPlan_1.Workout.Title],
                }), createElement("span", {
                    style: {
                        opacity: 0.7,
                    },
                    className: "ml-2",
                    children: DurationModule_toHMSString(Workout_getWorkoutTotalDuration(dayPlan_1.Workout)),
                })])]), createElement("p", createObj(Helpers_combineClasses("", props_4))))])]);
            })))))))))))), createElement("div", createObj(Helpers_combineClasses("box", props_6))))), delay(() => (patternInput[0] ? singleton(createElement(Render_1, {
                dayFact: void 0,
                sourceDayPlan: dayPlan_1,
                daySegment: daySegment,
                onSave: (dayFact) => {
                    startImmediate(updatePlanFact(studentDayKey, new DayPlanFact_DayPlanFact(1, dayFact), () => {
                        setIsImplement(false);
                        onSave(dayFact);
                    }, () => {
                        setIsImplement(false);
                    }));
                },
                onDelete: () => {
                },
                onCancel: () => {
                    setIsImplement(false);
                },
            })) : empty())));
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_1)),
        });
    }
}

