import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { Schedules_ScheduleListItem, Schedules_ScheduleListItem_Default_Z1043BF58, Auth_SignedInUser$reflection, Schedules_ScheduleListItem$reflection } from "../../Shared/Shared.fs.js";
import { union_type, string_type, class_type, record_type, list_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { View_Cmd_toastError, Cmd_fromAsync, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { schedulesApi } from "../Communication.fs.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofArray, singleton as singleton_1, append, map, exists, filter } from "../.fable/fable-library.3.2.1/List.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { map as map_1, empty, singleton as singleton_2, append as append_1, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Route, toPath } from "../Router.fs.js";
import { interpolate, toText, join } from "../.fable/fable-library.3.2.1/String.js";
import { Render } from "./ScheduleListItemEdit.fs.js";
import { View_RenderDeleteWindow } from "../Common.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_State extends Record {
    constructor(Schedules, User) {
        super();
        this.Schedules = Schedules;
        this.User = User;
    }
}

export function Types_State$reflection() {
    return record_type("ScheduleList.Types.State", [], Types_State, () => [["Schedules", list_type(Schedules_ScheduleListItem$reflection())], ["User", Auth_SignedInUser$reflection()]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SavedSchedule", "DeleteScheduleClick", "DeleteSchedule"];
    }
}

export function Types_Msg$reflection() {
    return union_type("ScheduleList.Types.Msg", [], Types_Msg, () => [[["Item", Schedules_ScheduleListItem$reflection()]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_delete(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(schedulesApi().delete(id)), (_arg1) => {
        const result = _arg1;
        return singleton.Return((result.tag === 0) ? (new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, id)))) : (new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))));
    })), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(schedules, user) {
    return [new Types_State(schedules, user), Cmd_none()];
}

export function State_update(msg, state) {
    let lst, isUpdated;
    if (msg.tag === 1) {
        return [state, Cmd_fromAsync(Cmd_delete(msg.fields[0]))];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [state, View_Cmd_toastError(msg.fields[0].fields[0].fields[0])];
            }
            else {
                return [new Types_State(filter((x_2) => (x_2.Id !== msg.fields[0].fields[0].fields[0]), state.Schedules), state.User), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new Types_State((lst = state.Schedules, (isUpdated = ((x) => (x.Id === msg.fields[0].Id)), exists(isUpdated, lst) ? map((x_1) => (isUpdated(x_1) ? msg.fields[0] : x_1), lst) : append(singleton_1(msg.fields[0]), lst))), state.User), Cmd_none()];
    }
}

export function View_ScheduleRow(view_ScheduleRowInputProps) {
    const dispatch = view_ScheduleRowInputProps.dispatch;
    const user = view_ScheduleRowInputProps.user;
    const schedule = view_ScheduleRowInputProps.schedule;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setCopyHover = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setDuplicateHover = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setOverviewHover = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setEditHover = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteHover = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDuplicateWindow = patternInput_5[1];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
    const setIsEditWindow = patternInput_6[1];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_7[1];
    const isDeleteWindow = patternInput_7[0];
    const children_2 = toList(delay(() => {
        let props_30, elms_1, elms, props_5, props_3, props_27, props_8, props_12, props_16, props_20, props_24;
        return append_1(singleton_2((props_30 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
            border: (((1 + "px ") + "solid") + " ") + "#808080",
            alignContent: "space-between",
        }], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton_1(createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-primary"], ["children", schedule.Title]]))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_5 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append_1(singleton_2(["className", "is-size-7"]), delay(() => append_1(patternInput[0] ? singleton_2(["children", "Kopiera till utövare"]) : empty(), delay(() => append_1(patternInput_1[0] ? singleton_2(["children", "Duplicera program"]) : empty(), delay(() => append_1(patternInput_2[0] ? singleton_2(["children", "Visa kalender"]) : empty(), delay(() => append_1(patternInput_3[0] ? singleton_2(["children", "Ändra egenskaper"]) : empty(), delay(() => (patternInput_4[0] ? singleton_2(["children", "Ta bort programmet"]) : empty()))))))))))))), createElement("span", createObj(Helpers_combineClasses("", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_27 = ofArray([["className", "has-text-right"], ["style", {
            whiteSpace: "nowrap",
        }], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            onMouseEnter: (_arg1) => {
                setCopyHover(true);
            },
            onMouseLeave: (_arg2) => {
                setCopyHover(false);
            },
            className: "px-2",
            href: toPath(new Route(22, schedule.Id)),
            children: Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-user-plus"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8))))]),
        }), createElement("a", {
            onMouseEnter: (_arg3) => {
                setDuplicateHover(true);
            },
            onMouseLeave: (_arg4) => {
                setDuplicateHover(false);
            },
            onClick: (_arg5) => {
                setIsDuplicateWindow(true);
            },
            className: "px-2",
            children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["far", "fa-copy"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_12))))]),
        }), createElement("a", {
            onMouseEnter: (_arg6) => {
                setOverviewHover(true);
            },
            onMouseLeave: (_arg7) => {
                setOverviewHover(false);
            },
            className: "px-2",
            href: toPath(new Route(23, schedule.Id)),
            children: Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-calendar-alt"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_16))))]),
        }), createElement("a", {
            onMouseEnter: (_arg8) => {
                setEditHover(true);
            },
            onMouseLeave: (_arg9) => {
                setEditHover(false);
            },
            onClick: (_arg10) => {
                setIsEditWindow(true);
            },
            className: "px-2",
            children: Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-pencil-alt"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_20))))]),
        }), createElement("a", {
            onMouseEnter: (_arg11) => {
                setDeleteHover(true);
            },
            onMouseLeave: (_arg12) => {
                setDeleteHover(false);
            },
            onClick: (_arg13) => {
                setIsDeleteWindow(true);
            },
            className: "px-2",
            children: Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-times"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_24))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_27))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_30))))), delay(() => {
            let scheduleDuplicate, inputRecord;
            return append_1(patternInput_5[0] ? (scheduleDuplicate = (inputRecord = Schedules_ScheduleListItem_Default_Z1043BF58(schedule.TeacherId), new Schedules_ScheduleListItem(inputRecord.Id, inputRecord.TeacherId, schedule.Title, schedule.IsGlobal)), singleton_2(createElement(Render, {
                scheduleListItem: scheduleDuplicate,
                currentUserId: user.UserId,
                sourceScheduleId: schedule.Id,
                onSaved: (schedule_1) => {
                    dispatch(new Types_Msg(0, schedule_1));
                    setIsDuplicateWindow(false);
                },
                onCancel: () => {
                    setIsDuplicateWindow(false);
                },
            }))) : empty(), delay(() => append_1(patternInput_6[0] ? singleton_2(createElement(Render, {
                scheduleListItem: schedule,
                currentUserId: user.UserId,
                sourceScheduleId: void 0,
                onSaved: (schedule_2) => {
                    dispatch(new Types_Msg(0, schedule_2));
                    setIsEditWindow(false);
                },
                onCancel: () => {
                    setIsEditWindow(false);
                },
            })) : empty(), delay(() => (isDeleteWindow ? singleton_2(createElement(View_RenderDeleteWindow, {
                confirmed: () => {
                    setIsDeleteWindow(!isDeleteWindow);
                    dispatch(new Types_Msg(1, schedule.Id));
                },
                notConfirmed: () => {
                    setIsDeleteWindow(!isDeleteWindow);
                },
                text: toText(interpolate("Vill du verkligen ta bort %P() och all data kopplad till detta program?", [schedule.Title])),
            })) : empty())))));
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function View_RenderView(view_RenderViewInputProps) {
    let props_10, elms, elms_1;
    const user = view_RenderViewInputProps.user;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_RenderViewInputProps.schedules, user), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsCreateWindow = patternInput_1[1];
    const props_12 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Alla program"]]))))), delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("", ofArray([["className", "py-2"], ["className", "has-text-primary"], ["children", "Skapade av mig"]]))))), delay(() => map_1((schedule) => createElement(View_ScheduleRow, {
        schedule: schedule,
        user: user,
        dispatch: dispatch,
    }), filter((x) => (x.TeacherId === user.UserId), state_1.Schedules)))))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = toList(delay(() => append_1(singleton_2(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "py-2"], ["className", "mb-5"], ["className", "is-success"], ["children", "Lägg till program"], ["onClick", (_arg1) => {
        setIsCreateWindow(true);
    }]]))))), delay(() => append_1(patternInput_1[0] ? singleton_2(createElement(Render, {
        scheduleListItem: void 0,
        currentUserId: user.UserId,
        sourceScheduleId: void 0,
        onSaved: (schedule_1) => {
            dispatch(new Types_Msg(0, schedule_1));
            setIsCreateWindow(false);
        },
        onCancel: () => {
            setIsCreateWindow(false);
        },
    })) : empty(), delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("", ofArray([["className", "py-2"], ["className", "has-text-primary"], ["children", "Skapade av andra"]]))))), delay(() => map_1((schedule_2) => createElement(View_ScheduleRow, {
        schedule: schedule_2,
        user: user,
        dispatch: dispatch,
    }), filter((x_1) => (x_1.TeacherId !== user.UserId), state_1.Schedules)))))))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_12)));
}

export function View_RenderWithLoad(user) {
    const schedules = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(schedulesApi().list()), []);
    switch (schedules.tag) {
        case 3: {
            const value = schedules.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            return createElement(View_RenderView, {
                schedules: schedules.fields[0],
                user: user,
            });
        }
        default: {
            return loader("big");
        }
    }
}

export function View_Render(user) {
    return createElement(View_RenderWithLoad, user);
}

