import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { record_type, class_type, list_type, bool_type, union_type, string_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { EmailModule_ofString, EmailModule_empty, Errors_ServerErrorModule_explain, Auth_LogInRequest, Auth_SignedInUser$reflection } from "../../Shared/Shared.fs.js";
import { Result_MapError, FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, Form_validationLogin, DeferredResult$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { authApi } from "../Communication.fs.js";
import { saveUser } from "../LocalStorage.fs.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_OfFunc_perform, Cmd_batch, Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { value as value_212 } from "../.fable/fable-library.3.2.1/Option.js";
import { cons, ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { Img_logo } from "../Css.fs.js";
import { int32ToString, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Route, toPath } from "../Router.fs.js";
import { year, now } from "../.fable/fable-library.3.2.1/Date.js";
import * as illustration2 from "../public/illustration2.svg";
import * as App_Store_Badge_black from "../public/App_Store_Badge_black.png";
import * as google$002Dplay$002Dbadge_black from "../public/google-play-badge_black.png";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../.fable/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export class Types_State extends Record {
    constructor(Email, Password, LoginResult, isUserValid, FormErrors, FormValidation) {
        super();
        this.Email = Email;
        this.Password = Password;
        this.LoginResult = LoginResult;
        this.isUserValid = isUserValid;
        this.FormErrors = FormErrors;
        this.FormValidation = FormValidation;
    }
}

export function Types_State$reflection() {
    return record_type("Login.Types.State", [], Types_State, () => [["Email", string_type], ["Password", string_type], ["LoginResult", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_SignedInUser$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Auth_SignedInUser$reflection()]], [["ErrorValue", string_type]]]))], ["isUserValid", bool_type], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["FormValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoginChanged", "PasswordChanged", "Login", "UserSaved", "Empty"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Login.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_SignedInUser$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Auth_SignedInUser$reflection()]], [["ErrorValue", string_type]]]))]], [["Item", Auth_SignedInUser$reflection()]], []]);
}

export function Cmd_login(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const loginInfo = new Auth_LogInRequest(state.Email, state.Password);
        return singleton.Bind(authApi().logIn(loginInfo), (_arg1) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, Result_MapError((_arg1_1) => Errors_ServerErrorModule_explain(_arg1_1), _arg1)))));
    }), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_saveUser(user) {
    saveUser(user);
}

export function State_init() {
    return [new Types_State(EmailModule_empty, "", new DeferredResult$1(0), true, empty(), false), Cmd_none()];
}

export function State_update(signin, msg, state) {
    if (msg.tag === 1) {
        return [new Types_State(state.Email, msg.fields[0], state.LoginResult, state.isUserValid, value_212(Form_validationLogin(new Auth_LogInRequest(state.Email, msg.fields[0]), state.FormValidation)), state.FormValidation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Email, state.Password, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), false, state.FormErrors, state.FormValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Email, state.Password, new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), true, state.FormErrors, state.FormValidation), Cmd_batch(singleton_1(Cmd_OfFunc_perform((user_1) => {
                    Cmd_saveUser(user_1);
                }, msg.fields[0].fields[0].fields[0], () => (new Types_Msg(3, msg.fields[0].fields[0].fields[0])))))];
            }
        }
        else {
            const errors_2 = Form_validationLogin(new Auth_LogInRequest(state.Email, state.Password), (new Types_State(state.Email, state.Password, state.LoginResult, state.isUserValid, state.FormErrors, true)).FormValidation);
            if (value_212(errors_2).Equals(empty())) {
                return [new Types_State(state.Email, state.Password, new DeferredResult$1(1), state.isUserValid, value_212(errors_2), true), Cmd_fromAsync(Cmd_login(state))];
            }
            else {
                return [new Types_State(state.Email, state.Password, new DeferredResult$1(0), state.isUserValid, value_212(errors_2), true), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 3) {
        return [state, Cmd_OfFunc_perform(signin, msg.fields[0], (_arg2) => (new Types_Msg(4)))];
    }
    else if (msg.tag === 4) {
        return [state, Cmd_none()];
    }
    else {
        const errors = Form_validationLogin(new Auth_LogInRequest(EmailModule_ofString(msg.fields[0]), state.Password), state.FormValidation);
        return [new Types_State(EmailModule_ofString(msg.fields[0]), state.Password, state.LoginResult, state.isUserValid, value_212(errors), state.FormValidation), Cmd_none()];
    }
}

export function View_layout(form) {
    let props_55, props_23, props_3, props_1, props_13, props_11, children, props_21, props_17, props_15, props_19, copyOfStruct_1, copyOfStruct, props_53, props_51, value_123, props_49, props_47, props_41, value_180, props_45, value_197;
    const props_57 = ofArray([["className", "is-fullhd"], ["className", "py-5"], ["children", Interop_reactApi.Children.toArray([(props_55 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", "is-one-third"], ["style", {
        display: "grid",
        alignContent: "space-between",
    }], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-vcentered"], ["className", "px-4"], ["className", "py-4"], ["children", Interop_reactApi.Children.toArray([createElement("img", {
        src: Img_logo,
        style: {
            height: 60,
            width: 60,
        },
    }), (props_1 = ofArray([["className", "pl-3"], ["style", {
        fontSize: 24 + "px",
        fontWeight: 700,
    }], ["className", "has-text-weight-bold"], ["children", "Endurance"]]), createElement("span", createObj(Helpers_combineClasses("", props_1))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_3)))), (props_13 = ofArray([["style", {
        alignSelf: "center",
    }], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "has-text-centered"], ["className", "mb-6"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
        children: ["Har du frågor efter senaste uppdateringen?"],
    }), (children = ofArray([createElement("span", {
        children: ["Maila på "],
    }), createElement("a", {
        children: "noreply@endurance.se",
        href: "mailto:noreply@endurance.se",
    }), createElement("span", {
        children: [" eller ring på "],
    }), createElement("a", {
        style: {
            whiteSpace: "nowrap",
        },
        children: "0733-448 184",
        href: "tel:0733-448 184",
    })]), createElement("p", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_11)))), form])]]), createElement("div", createObj(Helpers_combineClasses("column", props_13)))), (props_21 = ofArray([["className", "has-text-centered"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-text"], ["className", "has-text-primary"], ["href", toPath(new Route(3))], ["target", "_blank"], ["children", "GDPR"]]), createElement("a", createObj(Helpers_combineClasses("button", props_15))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_17)))), (props_19 = ofArray([["className", "is-size-7"], ["className", "has-text-centered"], ["children", "© 2010 - " + (copyOfStruct_1 = (copyOfStruct = now(), year(copyOfStruct)), int32ToString(copyOfStruct_1))]]), createElement("span", createObj(Helpers_combineClasses("", props_19))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_23)))), (props_53 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_51 = ofArray([["style", {
        height: 92.1 + "vh",
        overflow: "hidden",
        position: "relative",
    }], ["className", "py-5"], ["className", "has-background-primary"], ["children", Interop_reactApi.Children.toArray([createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-1"], ["className", "pt-6"], ["className", "px-6"], ["className", "has-text-white"], ["children", "Välkommen till Endurance!"]])))), createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "pt-4"], ["className", "mb-0"], ["className", "px-6"], ["className", "has-text-white"], ["children", "Här kan du enkelt"]])))), createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "px-6"], ["className", "my-0"], ["className", "has-text-white"], ["children", "hålla reda på din träning"]])))), createElement("h2", createObj(Helpers_combineClasses("subtitle", ofArray([["className", "px-6"], ["className", "my-0"], ["className", "has-text-white"], ["children", "och mäta din framgång"]])))), createElement("img", createObj(ofArray([(value_123 = illustration2, ["src", value_123]), ["style", {
        height: "auto",
        width: 100 + "%",
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 0,
    }]]))), (props_49 = ofArray([["className", "has-background-white"], ["style", {
        height: 80,
        width: 600,
        position: "absolute",
        bottom: 20,
        right: 20,
        zIndex: 0,
        padding: 20,
    }], ["children", Interop_reactApi.Children.toArray([(props_47 = ofArray([["className", "my-0"], ["className", "mx-0"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            lineHeight: 20 + "px",
            fontSize: 18 + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Endurance "]])))), createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-grey-dark"], ["children", "finns nu till din favoritenhet!"]]))))]),
    }), (props_41 = ofArray([["className", "py-0"], ["className", "px-3"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
        href: "https://apps.apple.com/us/app/endurance-skidor/id1590125092?itsct=apps_box_link\u0026itscg=30200",
        children: Interop_reactApi.Children.toArray([createElement("img", createObj(ofArray([(value_180 = App_Store_Badge_black, ["src", value_180]), ["style", {
            height: 40,
            width: "auto",
        }]])))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_41)))), (props_45 = ofArray([["className", "py-0"], ["className", "px-0"], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
        href: "https://play.google.com/store/apps/details?id=se.endurance.app",
        children: Interop_reactApi.Children.toArray([createElement("img", createObj(ofArray([(value_197 = google$002Dplay$002Dbadge_black, ["src", value_197]), ["style", {
            height: 40,
            width: "auto",
        }]])))]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_45))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_47))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_51))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_53))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_55))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_57)));
}

export function View_mobile(form) {
    let props_11, props_3, props_1, props_9, props_7, props_5, props_37;
    const props_40 = ofArray([["style", {
        display: "grid",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            padding: 20,
        },
        children: Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "mx-0"], ["className", "my-0"], ["className", "is-vcentered"], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "px-0"], ["className", "pt-0"], ["style", {
            alignItems: "center",
            display: "flex",
        }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
            src: Img_logo,
            style: {
                height: 30,
                width: 30,
            },
        }), (props_1 = ofArray([["className", "pl-3"], ["style", {
            fontSize: 13 + "px",
            fontWeight: 700,
        }], ["className", "has-text-weight-bold"], ["children", "Endurance"]]), createElement("span", createObj(Helpers_combineClasses("", props_1))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_9 = ofArray([["className", "px-0"], ["className", "pt-0"], ["children", Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "is-text"], ["className", "has-text-primary"], ["href", toPath(new Route(3))], ["target", "_blank"], ["children", "GDPR"]]), createElement("a", createObj(Helpers_combineClasses("button", props_5))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_11)))), createElement("div", {
            style: {
                alignSelf: "flex-end",
            },
            children: Interop_reactApi.Children.toArray([form]),
        })]),
    }), createElement("div", {
        style: {
            padding: ((((((0 + "px ") + 20) + "px ") + 20) + "px ") + 20) + "px",
        },
        children: Interop_reactApi.Children.toArray([(props_37 = toList(delay(() => {
            const h = document.documentElement.clientHeight - 320;
            return append(singleton_2(["style", {
                height: h + "px",
                overflow: "hidden",
                position: "relative",
            }]), delay(() => append(singleton_2(["className", "has-background-primary"]), delay(() => {
                let props_15, props_17, value_89, props_35, props_20, props_22, props_33, props_27, value_157, props_31, value_170;
                return singleton_2(["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "pt-4"], ["style", {
                    fontSize: 18 + "px",
                }], ["className", "has-text-centered"], ["className", "has-text-white"], ["children", "Välkommen till Endurance!"]]), createElement("h1", createObj(Helpers_combineClasses("title", props_15)))), (props_17 = ofArray([["className", "pt-4"], ["style", {
                    fontSize: 10 + "px",
                }], ["className", "has-text-centered"], ["className", "has-text-white"], ["children", "Här kan du enkelt hålla reda på din träning och mäta din framgång"]]), createElement("h2", createObj(Helpers_combineClasses("subtitle", props_17)))), createElement("img", createObj(ofArray([(value_89 = illustration2, ["src", value_89]), ["style", {
                    height: "auto",
                    width: 150 + "%",
                    minWidth: 150 + "%",
                    position: "absolute",
                    top: 10 + "%",
                    left: -40 + "%",
                    zIndex: 0,
                }]]))), (props_35 = ofArray([["className", "has-background-white"], ["style", {
                    height: 135,
                    width: 90 + "%",
                    position: "absolute",
                    bottom: 20,
                    right: 5 + "%",
                    zIndex: 0,
                    padding: 20,
                }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                    className: "has-text-centered",
                    style: {
                        paddingBottom: 20,
                    },
                    children: Interop_reactApi.Children.toArray([(props_20 = ofArray([["style", {
                        fontSize: 18 + "px",
                        lineHeight: 20 + "px",
                    }], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Endurance "]]), createElement("span", createObj(Helpers_combineClasses("", props_20)))), (props_22 = ofArray([["style", {
                        fontSize: 18 + "px",
                        lineHeight: 20 + "px",
                    }], ["className", "has-text-grey-dark"], ["children", "finns nu till din favoritenhet!"]]), createElement("span", createObj(Helpers_combineClasses("", props_22))))]),
                }), (props_33 = ofArray([["className", "is-centered"], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([(props_27 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
                    href: "https://apps.apple.com/us/app/endurance-skidor/id1590125092?itsct=apps_box_link\u0026itscg=30200",
                    children: Interop_reactApi.Children.toArray([createElement("img", createObj(ofArray([(value_157 = App_Store_Badge_black, ["src", value_157]), ["style", {
                        height: 35,
                        width: "auto",
                    }]])))]),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_27)))), (props_31 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
                    href: "https://play.google.com/store/apps/details?id=se.endurance.app",
                    children: Interop_reactApi.Children.toArray([createElement("img", createObj(ofArray([(value_170 = google$002Dplay$002Dbadge_black, ["src", value_170]), ["style", {
                        height: 35,
                        width: "auto",
                    }]])))]),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_33))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_35))))])]);
            }))));
        })), createElement("div", createObj(Helpers_combineClasses("box", props_37))))]),
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_40)));
}

export function View_renderForm(state, dispatch) {
    return createElement("form", {
        onSubmit: (e) => {
            e.preventDefault();
            dispatch(new Types_Msg(2, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_3, elms;
            return append(singleton_2((props_3 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", ofArray([["placeholder", "Epost"], ["onChange", (ev) => {
                dispatch(new Types_Msg(0, ev.target.value));
            }]])))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_3))))), delay(() => {
                let elms_2, elms_1;
                return append(singleton_2((elms_2 = singleton_1((elms_1 = singleton_1(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["placeholder", "Lösenord"], ["onChange", (ev_1) => {
                    dispatch(new Types_Msg(1, ev_1.target.value));
                }]])))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))), delay(() => {
                    let props_11, props_9;
                    return append(singleton_2((props_11 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-text"], ["className", "has-text-primary"], ["href", toPath(new Route(1))], ["children", "Glömt lösenord?"]]), createElement("a", createObj(Helpers_combineClasses("button", props_9))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_11))))), delay(() => append(singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["children", "Logga in"]]))))), delay(() => ((!state.isUserValid) ? singleton_2(createElement("div", {
                        className: "has-text-centered",
                        style: {
                            fontSize: 14 + "px",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("p", {
                            children: ["You are not logged in."],
                        }), createElement("p", {
                            children: ["Have you entered wrong username or password?"],
                        })]),
                    })) : empty_1())))));
                }));
            }));
        })))),
    });
}

export function View_view(state, dispatch) {
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF();
    switch (width.tag) {
        case 0:
        case 1:
        case 2: {
            return View_mobile(View_renderForm(state, dispatch));
        }
        default: {
            return View_layout(View_renderForm(state, dispatch));
        }
    }
}

export function Component_Render(component_RenderInputProps) {
    const loggedIn = component_RenderInputProps.loggedIn;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(State_init, (msg, state) => State_update(loggedIn, msg, state), []);
    return View_view(patternInput[0], patternInput[1]);
}

