import { createElement } from "react";
import * as react from "react";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { toPath, Route } from "../Router.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { Render as Render_1, RenderLogoutButton, RenderAccountButton } from "../HeaderCommon.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";

export function Render(renderInputProps) {
    let props_5, props_7, elms, props;
    const isActive = renderInputProps.isActive;
    const logOut = renderInputProps.logOut;
    const user = renderInputProps.user;
    const itemsRightTouchView = react.createElement(react.Fragment, {}, (props_5 = toList(delay(() => append(isActive(new Route(5)) ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", join(" ", ["is-hidden-desktop"])]), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["children", "Konto"]), delay(() => singleton(["href", toPath(new Route(5))]))))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_5)))), (props_7 = ofArray([["className", join(" ", ["is-hidden-desktop"])], ["className", "has-text-weight-bold"], ["children", "Logga ut"], ["onClick", (x) => {
        x.preventDefault();
        logOut();
    }]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_7)))));
    const itemsRightDesktopView = react.createElement(react.Fragment, {}, (elms = ofArray([RenderAccountButton(user), RenderLogoutButton(logOut)]), createElement("div", {
        className: "buttons",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })));
    const itemsLeft = react.createElement(react.Fragment, {}, (props = toList(delay(() => append(isActive(new Route(31)) ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["children", "Översikt"]), delay(() => singleton(["href", toPath(new Route(31))]))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props)))));
    return createElement(Render_1, {
        itemsLeft: itemsLeft,
        itemsRightDesktopView: itemsRightDesktopView,
        itemsRightTouchView: itemsRightTouchView,
    });
}

