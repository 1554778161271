import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { Students_TeacherListItem$reflection } from "../../Shared/Shared.fs.js";
import { class_type, record_type, union_type, string_type, list_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { teachersApi } from "../Communication.fs.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofArray, filter, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { collect, empty, singleton as singleton_2, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Route, toPath } from "../Router.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { View_renderNotification, View_RenderDeleteWindow } from "../Common.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_State extends Record {
    constructor(ListTeachers) {
        super();
        this.ListTeachers = ListTeachers;
    }
}

export function Types_State$reflection() {
    return record_type("TeacherList.Types.State", [], Types_State, () => [["ListTeachers", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_TeacherListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_TeacherListItem$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadTeachers", "DeleteClick", "Delete"];
    }
}

export function Types_Msg$reflection() {
    return union_type("TeacherList.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_TeacherListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_TeacherListItem$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]]]);
}

export const Cmd_loadTeachers = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(teachersApi().list()), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_delete(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(teachersApi().delete(id)), (_arg1) => {
        const result = _arg1;
        return singleton.Return((result.tag === 0) ? (new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, id)))) : (new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))));
    })), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export const State_init = [new Types_State(new DeferredResult$1(1)), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Types_Msg(0, new AsyncOperationStatus$1(0)))))];

export function State_update(msg, state) {
    let matchValue;
    if (msg.tag === 1) {
        return [state, Cmd_fromAsync(Cmd_delete(msg.fields[0]))];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new Types_State((matchValue = state.ListTeachers, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, filter((x_1) => (x_1.Id !== msg.fields[0].fields[0].fields[0]), matchValue.fields[0].fields[0])))) : state.ListTeachers) : state.ListTeachers)), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
        else {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
    }
    else {
        return [new Types_State(new DeferredResult$1(1)), Cmd_fromAsync(Cmd_loadTeachers)];
    }
}

export function View_RenderTeacher(view_RenderTeacherInputProps) {
    let elms;
    const dispatch = view_RenderTeacherInputProps.dispatch;
    const teacher = view_RenderTeacherInputProps.teacher;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setEditHover = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteHover = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_2[1];
    const isDeleteWindow = patternInput_2[0];
    const props_17 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
        border: (((1 + "px ") + "solid") + " ") + "#808080",
        alignContent: "space-between",
    }], ["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => {
        let props;
        return append(singleton_2((props = ofArray([["style", {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        }], ["children", teacher.FullName]]), createElement("div", createObj(Helpers_combineClasses("column", props))))), delay(() => {
            let props_4, props_2;
            return append(singleton_2((props_4 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append(singleton_2(["className", "is-size-7"]), delay(() => append(patternInput[0] ? singleton_2(["children", "Ändra egenskaper"]) : empty(), delay(() => (patternInput_1[0] ? singleton_2(["children", "Ta bort tränare"]) : empty()))))))), createElement("span", createObj(Helpers_combineClasses("", props_2))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))), delay(() => {
                let props_14, props_7, props_11;
                return append(singleton_2((props_14 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
                    onMouseEnter: (_arg1) => {
                        setEditHover(true);
                    },
                    onMouseLeave: (_arg2) => {
                        setEditHover(false);
                    },
                    className: "px-2",
                    href: toPath(new Route(20, teacher.Id)),
                    children: Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-pencil-alt"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_7))))]),
                }), createElement("a", {
                    onMouseEnter: (_arg3) => {
                        setDeleteHover(true);
                    },
                    onMouseLeave: (_arg4) => {
                        setDeleteHover(false);
                    },
                    onClick: (_arg5) => {
                        setIsDeleteWindow(!isDeleteWindow);
                    },
                    children: Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fas", "fa-times"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_11))))]),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))), delay(() => (isDeleteWindow ? singleton_2(createElement(View_RenderDeleteWindow, {
                    confirmed: () => {
                        setIsDeleteWindow(!isDeleteWindow);
                        dispatch(new Types_Msg(1, teacher.Id));
                    },
                    notConfirmed: () => {
                        setIsDeleteWindow(!isDeleteWindow);
                    },
                    text: "Är du säker på att du vill ta bort här tränare?",
                })) : empty())));
            }));
        }));
    })), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_17)));
}

export function Component_Render(user) {
    let props_8, elms, elms_1, props_5;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init, (msg, state) => State_update(msg, state), [user]);
    const props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => {
        let props_2;
        return append(singleton_2((props_2 = ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["style", {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", singleton_1(["children", "Tränare"]))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_2))))), delay(() => {
            const matchValue = patternInput[0].ListTeachers;
            return (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 1) ? singleton_2(View_renderNotification(["className", "is-danger"], matchValue.fields[0].fields[0])) : collect((teacher) => ((teacher.Id !== user.UserId) ? singleton_2(createElement(View_RenderTeacher, {
                teacher: teacher,
                dispatch: patternInput[1],
            })) : empty()), matchValue.fields[0].fields[0])) : singleton_2(loader("big"));
        }));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton_1((props_5 = ofArray([["href", toPath(new Route(19))], ["className", "py-2"], ["className", "mb-5"], ["className", "is-success"], ["children", "Lägg till tränare"]]), createElement("a", createObj(Helpers_combineClasses("button", props_5))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_8))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_10)));
}

