import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { value as value_62 } from "../.fable/fable-library.3.2.1/Option.js";
import { Toastr_toastError, Remoting_handleNonAuth } from "../Extensions.fs.js";
import { schedulesApi } from "../Communication.fs.js";
import { Schedules_ScheduleListItem, Schedules_ScheduleListItem_Default_Z1043BF58, Schedules_ScheduleUpdateRequest, Schedules_ScheduleCreateRequest, Schedules_ScheduleCreateAsDuplicateRequest } from "../../Shared/Shared.fs.js";
import { interpolate, toText } from "../.fable/fable-library.3.2.1/String.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { cons, ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { empty, singleton as singleton_2, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { View_checkBoxField } from "../Common.fs.js";
import { startImmediate } from "../.fable/fable-library.3.2.1/Async.js";

export function saveScheduleListItem(scheduleListItem, isNew, sourceScheduleId, onSuccess) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(singleton.Delay(() => {
        if (isNew) {
            if (sourceScheduleId != null) {
                const sourceScheduleId_1 = value_62(sourceScheduleId);
                return singleton.Bind(Remoting_handleNonAuth(schedulesApi().get(sourceScheduleId_1)), (_arg1) => {
                    const request = new Schedules_ScheduleCreateAsDuplicateRequest(scheduleListItem.Id, scheduleListItem.Title, scheduleListItem.IsGlobal, _arg1.ScheduleWeeks, sourceScheduleId_1);
                    return singleton.Return(schedulesApi().createAsDuplicate(request));
                });
            }
            else {
                const request_1 = new Schedules_ScheduleCreateRequest(scheduleListItem.Id, scheduleListItem.Title, scheduleListItem.IsGlobal, []);
                return singleton.Return(schedulesApi().create(request_1));
            }
        }
        else {
            const request_2 = new Schedules_ScheduleUpdateRequest(scheduleListItem.Id, scheduleListItem.Title, scheduleListItem.IsGlobal, void 0);
            return singleton.Return(schedulesApi().update(request_2));
        }
    }), (_arg2) => singleton.Bind(Remoting_handleNonAuth(_arg2), (_arg3) => {
        if (_arg3.tag === 0) {
            onSuccess(scheduleListItem);
            return singleton.Zero();
        }
        else {
            Toastr_toastError("Save schedule error", "");
            return singleton.Zero();
        }
    }))), (_arg4) => {
        Toastr_toastError("Save schedule error", "");
        return singleton.Zero();
    }));
}

export function Render(renderInputProps) {
    let scheduleListItem_2, scheduleListItem_1, elms_2, elms, props_16, props_22;
    const onCancel = renderInputProps.onCancel;
    const onSaved = renderInputProps.onSaved;
    const sourceScheduleId = renderInputProps.sourceScheduleId;
    const currentUserId = renderInputProps.currentUserId;
    let patternInput;
    const matchValue = [renderInputProps.scheduleListItem, sourceScheduleId];
    patternInput = ((matchValue[0] != null) ? ((matchValue[1] == null) ? (scheduleListItem_2 = matchValue[0], [scheduleListItem_2.Title, scheduleListItem_2, false, scheduleListItem_2.TeacherId === currentUserId]) : (scheduleListItem_1 = matchValue[0], [toText(interpolate("Kopia av %P()", [scheduleListItem_1.Title])), scheduleListItem_1, true, false])) : ["Nytt program", Schedules_ScheduleListItem_Default_Z1043BF58(currentUserId), true, true]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(patternInput[1]);
    const setEditModel = patternInput_1[1];
    const editModel = patternInput_1[0];
    const props_27 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg1) => {
        onCancel();
    }])))), (elms_2 = ofArray([(elms = ofArray([createElement("p", createObj(Helpers_combineClasses("modal-card-title", ofArray([["className", "is-size-6"], ["className", "has-text-weight-bold"], ["children", patternInput[0]]])))), createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg2) => {
        onCancel();
    }]))))]), createElement("header", {
        className: "modal-card-head",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_16 = ofArray([["style", {
        overflowY: "scroll",
    }], ["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_13, props_11, props_9, value_30;
        return append(singleton_2((props_13 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-7"], ["className", "mb-1"], ["className", "has-text-grey"], ["children", "Titel"]])))), (props_11 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-small"], (value_30 = editModel.Title, ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_30)) : false) {
                e.value = value_30;
            }
        }]), ["onChange", (ev) => {
            setEditModel(new Schedules_ScheduleListItem(editModel.Id, editModel.TeacherId, ev.target.value, editModel.IsGlobal));
        }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_9)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_13))))), delay(() => {
            let elms_1;
            return patternInput[3] ? singleton_2((elms_1 = singleton_1(View_checkBoxField("Globalt program", editModel.IsGlobal, (newIsGlobal) => {
                setEditModel(new Schedules_ScheduleListItem(editModel.Id, editModel.TeacherId, editModel.Title, newIsGlobal));
            })), createElement("div", {
                className: "block",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))) : empty();
        }));
    }))))]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_16)))), (props_22 = ofArray([["style", {
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg3) => {
        onCancel();
    }], ["className", "is-text"], ["className", "has-text-danger"], ["children", "Avbryt"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg4) => {
        startImmediate(saveScheduleListItem(editModel, patternInput[2], sourceScheduleId, onSaved));
    }], ["className", "is-success"], ["children", "Spara"]]))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_22))))]), createElement("div", {
        className: "modal-card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), createElement("button", createObj(Helpers_combineClasses("modal-close", singleton_1(["onClick", (_arg5) => {
        onCancel();
    }]))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_27)));
}

