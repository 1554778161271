import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { indexed, skip, take, tryItem, append, mapIndexed } from "../.fable/fable-library.3.2.1/Array.js";
import { interpolate, toText } from "../.fable/fable-library.3.2.1/String.js";
import { WorkoutType_SpeedInfo_get_Default, WorkoutType_MobilityInfo_get_Default, WorkoutType_StrengthInfo_get_Default, WorkoutType_EnduranceInfo_get_Default, WorkoutType_WorkoutType, WorkoutType_strWorkoutType, Workout_WorkoutPart, WorkoutType_titleWorkoutType } from "../../Shared/Shared.fs.js";
import { createElement } from "react";
import * as react from "react";
import { map, singleton, collect, append as append_1, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { Endurance, Rest, Speed, Mobility, Strength } from "./WorkoutPartEdit.fs.js";
import { defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray, empty } from "../.fable/fable-library.3.2.1/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";

export function Render(renderInputProps) {
    const onChange = renderInputProps.onChange;
    const workoutParts = renderInputProps.workoutParts;
    const patternInput = useFeliz_React__React_useState_Static_1505(mapIndexed((index, xx) => toText(interpolate("%P() %P()", [index + 1, WorkoutType_titleWorkoutType(xx.WorkoutType)])), workoutParts));
    const titles = patternInput[0];
    const setTitles = patternInput[1];
    const addWorkoutPart = (workoutType) => {
        onChange(append(workoutParts, [new Workout_WorkoutPart(false, workoutType)]));
        setTitles(append(titles, ["Ny del: " + WorkoutType_strWorkoutType(workoutType)]));
    };
    return react.createElement(react.Fragment, {}, ...toList(delay(() => append_1(collect((matchValue) => {
        let matchValue_1;
        const workoutPart_1 = matchValue[1];
        const index_4 = matchValue[0] | 0;
        const change = (newInfo) => {
            onChange(mapIndexed((index_1, x) => {
                if (index_1 === index_4) {
                    return new Workout_WorkoutPart(x.IsPrimary, newInfo);
                }
                else {
                    return x;
                }
            }, workoutParts));
        };
        return singleton((matchValue_1 = workoutPart_1.WorkoutType, (matchValue_1.tag === 1) ? ((title_1) => ((isPrimary_2) => ((isPrimaryChanged_1) => ((onDeleted_1) => createElement(Strength, {
            info: matchValue_1.fields[0],
            infoChanged: (arg_1) => {
                change(new WorkoutType_WorkoutType(1, arg_1));
            },
            title: title_1,
            isPrimary: isPrimary_2,
            isPrimaryChanged: isPrimaryChanged_1,
            onDeleted: onDeleted_1,
        }))))) : ((matchValue_1.tag === 2) ? ((title_2) => ((isPrimary_3) => ((isPrimaryChanged_2) => ((onDeleted_2) => createElement(Mobility, {
            info: matchValue_1.fields[0],
            infoChanged: (arg_2) => {
                change(new WorkoutType_WorkoutType(2, arg_2));
            },
            title: title_2,
            isPrimary: isPrimary_3,
            isPrimaryChanged: isPrimaryChanged_2,
            onDeleted: onDeleted_2,
        }))))) : ((matchValue_1.tag === 3) ? ((title_3) => ((isPrimary_4) => ((isPrimaryChanged_3) => ((onDeleted_3) => createElement(Speed, {
            info: matchValue_1.fields[0],
            infoChanged: (arg_3) => {
                change(new WorkoutType_WorkoutType(3, arg_3));
            },
            title: title_3,
            isPrimary: isPrimary_4,
            isPrimaryChanged: isPrimaryChanged_3,
            onDeleted: onDeleted_3,
        }))))) : ((matchValue_1.tag === 4) ? ((title_4) => ((isPrimary_5) => ((isPrimaryChanged_4) => ((onDeleted_4) => createElement(Rest, {
            title: title_4,
            isPrimary: isPrimary_5,
            isPrimaryChanged: isPrimaryChanged_4,
            onDeleted: onDeleted_4,
        }))))) : ((title) => ((isPrimary_1) => ((isPrimaryChanged) => ((onDeleted) => createElement(Endurance, {
            info: matchValue_1.fields[0],
            infoChanged: (arg) => {
                change(new WorkoutType_WorkoutType(0, arg));
            },
            title: title,
            isPrimary: isPrimary_1,
            isPrimaryChanged: isPrimaryChanged,
            onDeleted: onDeleted,
        })))))))))(defaultArg(tryItem(index_4, titles), ""))(workoutPart_1.IsPrimary)((isPrimary) => {
            onChange(mapIndexed((index_2, x_1) => {
                if (index_2 === index_4) {
                    return new Workout_WorkoutPart(isPrimary, x_1.WorkoutType);
                }
                else {
                    return x_1;
                }
            }, workoutParts));
        })((_arg1) => {
            const partIndex_2 = index_4 | 0;
            const removeArrayItem = (index_3, array_3) => append(take(index_3, array_3), skip(index_3 + 1, array_3));
            onChange(removeArrayItem(partIndex_2, workoutParts));
            setTitles(removeArrayItem(partIndex_2, titles));
        }));
    }, indexed(workoutParts)), delay(() => append_1(singleton(createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", empty())))), delay(() => {
        let props_4;
        return singleton((props_4 = ofArray([["className", "is-centered"], ["className", "are-small"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((button) => createElement("a", createObj(Helpers_combineClasses("button", ofArray([["children", button[0]], ["onClick", (_arg2) => {
            button[1]();
        }]])))), [["Uthållighet", () => {
            addWorkoutPart(new WorkoutType_WorkoutType(0, WorkoutType_EnduranceInfo_get_Default()));
        }], ["Styrka", () => {
            addWorkoutPart(new WorkoutType_WorkoutType(1, WorkoutType_StrengthInfo_get_Default()));
        }], ["Rörlighet", () => {
            addWorkoutPart(new WorkoutType_WorkoutType(2, WorkoutType_MobilityInfo_get_Default()));
        }], ["Snabbhet", () => {
            addWorkoutPart(new WorkoutType_WorkoutType(3, WorkoutType_SpeedInfo_get_Default()));
        }], ["Vila", () => {
            addWorkoutPart(new WorkoutType_WorkoutType(4));
        }]])))))]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_4)))));
    })))))));
}

