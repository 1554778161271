import { isMatch, create } from "../fable-library.3.2.1/RegExp.js";
import { toString, Record, Union } from "../fable-library.3.2.1/Types.js";
import { class_type, record_type, string_type, union_type } from "../fable-library.3.2.1/Reflection.js";
import { FSharpMap__get_Item, add, empty } from "../fable-library.3.2.1/Map.js";
import { contains, fold, empty as empty_1, singleton } from "../fable-library.3.2.1/List.js";
import { singleton as singleton_1 } from "../fable-library.3.2.1/AsyncBuilder.js";
import { value as value_3, some } from "../fable-library.3.2.1/Option.js";
import { FSharpResult$2 } from "../fable-library.3.2.1/Choice.js";
import { replace, printf, toConsole, isNullOrWhiteSpace } from "../fable-library.3.2.1/String.js";
import { structuralHash, equals, int32ToString, compare } from "../fable-library.3.2.1/Util.js";
import { forAll, length } from "../fable-library.3.2.1/Seq.js";

export const ValidateRegexes_mail = create("^(([^\u003c\u003e()\\[\\]\\\\.,;:\\s@\"]+(\\.[^\u003c\u003e()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$", 0);

export const ValidateRegexes_url = create("^((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[\\-;:\u0026=\\+\\$,\\w]+@)?[A-Za-z0-9\\.\\-]+|(?:www\\.|[\\-;:\u0026=\\+\\$,\\w]+@)[A-Za-z0-9\\.\\-]+)((?:\\/[\\+~%\\/\\.\\w\\-_]*)?\\??(?:[\\-\\+=\u0026;%@\\.\\w_]*)#?(?:[\\.\\!\\/\\\\\\w]*))?)$", 0);

export class ValidateResult$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Valid", "Invalid"];
    }
}

export function ValidateResult$1$reflection(gen0) {
    return union_type("Fable.Validation.Core.ValidateResult`1", [gen0], ValidateResult$1, () => [[["Item", gen0]], []]);
}

export function ValidateResult$1__get_IsValid_(x) {
    if (x.tag === 0) {
        return true;
    }
    else {
        return false;
    }
}

export function ValidateResult$1__get_IsInvalid_(x) {
    return !ValidateResult$1__get_IsValid_(x);
}

export class FieldInfo$3 extends Record {
    constructor(key, original, result, validator) {
        super();
        this.key = key;
        this.original = original;
        this.result = result;
        this.validator = validator;
    }
}

export function FieldInfo$3$reflection(gen0, gen1, gen2) {
    return record_type("Fable.Validation.Core.FieldInfo`3", [gen0, gen1, gen2], FieldInfo$3, () => [["key", string_type], ["original", gen0], ["result", ValidateResult$1$reflection(gen1)], ["validator", Validator$1$reflection(gen2)]]);
}

export class Validator$1 {
    constructor(all) {
        this.all = all;
        this.errors = empty();
        this.hasError = false;
    }
}

export function Validator$1$reflection(gen0) {
    return class_type("Fable.Validation.Core.Validator`1", [gen0], Validator$1);
}

export function Validator$1_$ctor_Z1FBCCD16(all) {
    return new Validator$1(all);
}

export function FieldInfo$3__Replace_74DD2B89(x, result) {
    return new FieldInfo$3(x.key, x.original, result, x.validator);
}

export function Validator$1__get_HasError(__) {
    return __.hasError;
}

export function Validator$1__get_Errors(__) {
    return __.errors;
}

export function Validator$1__PushError(__, name, error) {
    if (!__.hasError) {
        __.hasError = true;
    }
    __.errors = add(name, singleton(error), __.errors);
}

export function Validator$1__Test(x, name, value) {
    x.errors = add(name, empty_1(), x.errors);
    if ((!x.all) ? x.hasError : false) {
        return new FieldInfo$3(name, value, new ValidateResult$1(1), x);
    }
    else {
        return new FieldInfo$3(name, value, new ValidateResult$1(0, value), x);
    }
}

export function Validator$1__End_Z5E18B1E2(__, input) {
    const matchValue = input.result;
    if (matchValue.tag === 0) {
        return matchValue.fields[0];
    }
    else {
        return null;
    }
}

export function Validator$1__EndAsync_7C134CD2(x, input) {
    return singleton_1.Delay(() => singleton_1.Bind(input, (_arg1) => singleton_1.Return(Validator$1__End_Z5E18B1E2(x, _arg1))));
}

export function Validator$1__TestOnlySome(t, name, value, rules) {
    if (value == null) {
        return void 0;
    }
    else {
        return ((arg) => some(Validator$1__End_Z5E18B1E2(t, arg)))(fold((acc, f) => f(acc), Validator$1__Test(t, name, value_3(value)), rules));
    }
}

export function Validator$1__TestOnlyOk(t, name, value, rules) {
    if (value.tag === 1) {
        return new FSharpResult$2(1, value.fields[0]);
    }
    else {
        return ((arg) => (new FSharpResult$2(0, Validator$1__End_Z5E18B1E2(t, arg))))(fold((acc, f) => f(acc), Validator$1__Test(t, name, value.fields[0]), rules));
    }
}

export function Validator$1__TestOnlySomeAsync(t, name, value, rules) {
    return singleton_1.Delay(() => {
        let arg10, arg10_1;
        return (value == null) ? singleton_1.Return(void 0) : singleton_1.ReturnFrom((arg10 = (arg10_1 = value_3(value), singleton_1.Delay(() => singleton_1.Return(Validator$1__Test(t, name, arg10_1)))), singleton_1.Delay(() => singleton_1.Bind(fold((acc, f) => f(acc), arg10, rules), (_arg2) => singleton_1.Return(((arg) => some(Validator$1__End_Z5E18B1E2(t, arg)))(_arg2))))));
    });
}

export function Validator$1__TestOnlyOkAsync(t, name, value, rules) {
    return singleton_1.Delay(() => {
        let arg10;
        return (value.tag === 1) ? singleton_1.Return(new FSharpResult$2(1, value.fields[0])) : singleton_1.ReturnFrom((arg10 = singleton_1.Delay(() => singleton_1.Return(Validator$1__Test(t, name, value.fields[0]))), singleton_1.Delay(() => singleton_1.Bind(fold((acc, f) => f(acc), arg10, rules), (_arg2) => singleton_1.Return(((arg) => (new FSharpResult$2(0, Validator$1__End_Z5E18B1E2(t, arg))))(_arg2))))));
    });
}

export function Validator$1__IsValidOpt(__, tester, error, input) {
    const matchValue = input.result;
    if (matchValue.tag === 0) {
        const result = tester(matchValue.fields[0]);
        if (ValidateResult$1__get_IsInvalid_(result)) {
            Validator$1__PushError(input.validator, input.key, error);
        }
        return FieldInfo$3__Replace_74DD2B89(input, result);
    }
    else {
        return FieldInfo$3__Replace_74DD2B89(input, new ValidateResult$1(1));
    }
}

export function Validator$1__IsValid_Z4CF01147(x, tester) {
    return (arg10) => ((arg20) => Validator$1__IsValidOpt(x, (v) => (tester(v) ? (new ValidateResult$1(0, v)) : (new ValidateResult$1(1))), arg10, arg20));
}

export function Validator$1__IsValidOptAsync(__, tester, error, input) {
    return singleton_1.Delay(() => singleton_1.Bind(input, (_arg3) => {
        const input_1 = _arg3;
        const matchValue = input_1.result;
        return (matchValue.tag === 1) ? singleton_1.Return(FieldInfo$3__Replace_74DD2B89(input_1, new ValidateResult$1(1))) : singleton_1.Bind(tester(matchValue.fields[0]), (_arg4) => {
            const result = _arg4;
            return singleton_1.Combine(ValidateResult$1__get_IsInvalid_(result) ? (Validator$1__PushError(input_1.validator, input_1.key, error), singleton_1.Zero()) : singleton_1.Zero(), singleton_1.Delay(() => singleton_1.Return(FieldInfo$3__Replace_74DD2B89(input_1, result))));
        });
    }));
}

export function Validator$1__IsValidAsync_339E6CF5(x, tester) {
    return (arg10) => ((arg20) => Validator$1__IsValidOptAsync(x, (v) => singleton_1.Delay(() => singleton_1.Bind(tester(v), (_arg5) => singleton_1.Return(_arg5 ? (new ValidateResult$1(0, v)) : (new ValidateResult$1(1))))), arg10, arg20));
}

export function Validator$1__Trim_Z300EABB2(__, input) {
    const matchValue = input.result;
    if (matchValue.tag === 1) {
        return input;
    }
    else {
        return FieldInfo$3__Replace_74DD2B89(input, new ValidateResult$1(0, matchValue.fields[0].trim()));
    }
}

export function Validator$1__NotBlank_2B595(x, err) {
    return Validator$1__IsValid_Z4CF01147(x, (arg) => (!isNullOrWhiteSpace(arg)))(err);
}

export function Validator$1__IsSome_2B595(x, error) {
    return (arg20) => Validator$1__IsValidOpt(x, (i) => {
        if (i != null) {
            return new ValidateResult$1(0, value_3(i));
        }
        else {
            return new ValidateResult$1(1);
        }
    }, error, arg20);
}

export function Validator$1__DefaultOfNone(__, defaults, input) {
    const matchValue = input.result;
    let pattern_matching_result, value;
    if (matchValue.tag === 0) {
        if (matchValue.fields[0] != null) {
            pattern_matching_result = 0;
            value = value_3(matchValue.fields[0]);
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return FieldInfo$3__Replace_74DD2B89(input, new ValidateResult$1(0, value));
        }
        case 1: {
            return FieldInfo$3__Replace_74DD2B89(input, new ValidateResult$1(0, defaults));
        }
    }
}

export function Validator$1__IsOk_2B595(x, error) {
    return (arg20) => Validator$1__IsValidOpt(x, (i) => {
        if (i.tag === 0) {
            return new ValidateResult$1(0, i.fields[0]);
        }
        else {
            return new ValidateResult$1(1);
        }
    }, error, arg20);
}

export function Validator$1__DefaultOfError(__, defaults, input) {
    const matchValue = input.result;
    let pattern_matching_result, value;
    if (matchValue.tag === 0) {
        if (matchValue.fields[0].tag === 0) {
            pattern_matching_result = 0;
            value = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return FieldInfo$3__Replace_74DD2B89(input, new ValidateResult$1(0, value));
        }
        case 1: {
            return FieldInfo$3__Replace_74DD2B89(input, new ValidateResult$1(0, defaults));
        }
    }
}

export function Validator$1__Map_7C4B0DD6(x, fn) {
    return (arg20) => Validator$1__IsValidOpt(x, (arg) => (new ValidateResult$1(0, fn(arg))), null, arg20);
}

export function Validator$1__To_7C4B0DD6(x, fn) {
    return (arg10) => ((arg20) => Validator$1__IsValidOpt(x, (t) => {
        try {
            return new ValidateResult$1(0, fn(t));
        }
        catch (exn) {
            const arg40 = exn.stack;
            const arg30 = exn.message;
            toConsole(printf("Validation Map error: \nfn: %A \nvalue: %A \nException: %s %s"))(fn)(t)(arg30)(arg40);
            return new ValidateResult$1(1);
        }
    }, arg10, arg20));
}

export function Validator$1__ToAsync_Z5E18B1E2(__, input) {
    return singleton_1.Delay(() => singleton_1.Return(input));
}

export function Validator$1__Gt(x, min, err) {
    let copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = (((typeof matchValue) === "string") ? replace(matchValue, "{min}", (copyOfStruct = min, toString(copyOfStruct))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input) => (compare(input, min) > 0))(err_1);
}

export function Validator$1__Gte(x, min, err) {
    let copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = (((typeof matchValue) === "string") ? replace(matchValue, "{min}", (copyOfStruct = min, toString(copyOfStruct))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input) => (compare(input, min) >= 0))(err_1);
}

export function Validator$1__Lt(x, max, err) {
    let copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = (((typeof matchValue) === "string") ? replace(matchValue, "{max}", (copyOfStruct = max, toString(copyOfStruct))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input) => (compare(input, max) < 0))(err_1);
}

export function Validator$1__Lte(x, max, err) {
    let copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = (((typeof matchValue) === "string") ? replace(matchValue, "{max}", (copyOfStruct = max, toString(copyOfStruct))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input) => (compare(input, max) <= 0))(err_1);
}

export function Validator$1__MaxLen(x, len, err, input) {
    let copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = (((typeof matchValue) === "string") ? replace(matchValue, "{len}", (copyOfStruct = len, int32ToString(copyOfStruct))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input_1) => (length(input_1) <= len))(err_1)(input);
}

export function Validator$1__MinLen(x, len, err, input) {
    let copyOfStruct;
    let err_1;
    const matchValue = err;
    err_1 = (((typeof matchValue) === "string") ? replace(matchValue, "{len}", (copyOfStruct = len, int32ToString(copyOfStruct))) : err);
    return Validator$1__IsValid_Z4CF01147(x, (input_1) => (length(input_1) >= len))(err_1)(input);
}

export function Validator$1__Enum_Z5DE735D9(x, enums) {
    return Validator$1__IsValid_Z4CF01147(x, (input) => contains(input, enums, {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => structuralHash(x_1),
    }));
}

export function Validator$1__IsMail(x, error, input) {
    return Validator$1__IsValid_Z4CF01147(x, (arg00) => isMatch(ValidateRegexes_mail, arg00))(error)(input);
}

export function Validator$1__IsUrl(x, error, input) {
    return Validator$1__IsValid_Z4CF01147(x, (arg00) => isMatch(ValidateRegexes_url, arg00))(error)(input);
}

export function Validator$1__Match(x, regex, error, input) {
    return Validator$1__IsValid_Z4CF01147(x, (arg00) => isMatch(regex, arg00))(error)(input);
}

export function Validator$1__IsDegist(x, error, input) {
    return Validator$1__IsValid_Z4CF01147(x, (str) => forAll((c) => {
        if (c >= "0") {
            return c <= "9";
        }
        else {
            return false;
        }
    }, str.split("")))(error)(input);
}

function instance() {
    return Validator$1_$ctor_Z1FBCCD16(true);
}

export function isValid() {
    const objectArg = instance();
    return (arg00) => Validator$1__IsValid_Z4CF01147(objectArg, arg00);
}

export function isValidOpt() {
    const objectArg = instance();
    return (arg00) => ((arg10) => ((arg20) => Validator$1__IsValidOpt(objectArg, arg00, arg10, arg20)));
}

export function isValidAsync() {
    const objectArg = instance();
    return (arg00) => Validator$1__IsValidAsync_339E6CF5(objectArg, arg00);
}

export function isValidOptAsync() {
    const objectArg = instance();
    return (arg00) => ((arg10) => ((arg20) => Validator$1__IsValidOptAsync(objectArg, arg00, arg10, arg20)));
}

export function validateSync(all, tester) {
    const validator = Validator$1_$ctor_Z1FBCCD16(all);
    const ret = tester(validator);
    if (Validator$1__get_HasError(validator)) {
        return new FSharpResult$2(1, Validator$1__get_Errors(validator));
    }
    else {
        return new FSharpResult$2(0, ret);
    }
}

export function validateAsync(all, tester) {
    return singleton_1.Delay(() => {
        const validator = Validator$1_$ctor_Z1FBCCD16(all);
        return singleton_1.Bind(tester(validator), (_arg1) => (Validator$1__get_HasError(validator) ? singleton_1.Return(new FSharpResult$2(1, Validator$1__get_Errors(validator))) : singleton_1.Return(new FSharpResult$2(0, _arg1))));
    });
}

export function single(tester) {
    const t = Validator$1_$ctor_Z1FBCCD16(true);
    const ret = tester(t);
    if (Validator$1__get_HasError(t)) {
        return new FSharpResult$2(1, FSharpMap__get_Item(Validator$1__get_Errors(t), "s"));
    }
    else {
        return new FSharpResult$2(0, ret);
    }
}

export function singleAsync(tester) {
    return singleton_1.Delay(() => {
        const t = Validator$1_$ctor_Z1FBCCD16(true);
        return singleton_1.Bind(tester(t), (_arg1) => (Validator$1__get_HasError(t) ? singleton_1.Return(new FSharpResult$2(1, FSharpMap__get_Item(Validator$1__get_Errors(t), "s"))) : singleton_1.Return(new FSharpResult$2(0, _arg1))));
    });
}

