import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { bool_type, class_type, list_type, union_type, unit_type, record_type, string_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, Remoting_userChanged, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { Auth_SignedInUser, Settings_ChangeFullName } from "../../Shared/Shared.fs.js";
import { settingsApi } from "../Communication.fs.js";
import { Validator$1__Test, Validator$1__NotBlank_2B595, Validator$1__End_Z5E18B1E2, validateSync } from "../.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Form_Field_errorsAsString, Form_Field_fieldStyleOnError, Form_Field_hasErrors, View_renderNotification } from "../Common.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export class Types_Form extends Record {
    constructor(FullName) {
        super();
        this.FullName = FullName;
    }
}

export function Types_Form$reflection() {
    return record_type("Settings.ChangeFullName.Types.Form", [], Types_Form, () => [["FullName", string_type]]);
}

export class Types_State extends Record {
    constructor(Form, Changing, FormErrors, IsNeedValidation) {
        super();
        this.Form = Form;
        this.Changing = Changing;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
    }
}

export function Types_State$reflection() {
    return record_type("Settings.ChangeFullName.Types.State", [], Types_State, () => [["Form", Types_Form$reflection()], ["Changing", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FullNameChanged", "ChangeFullName"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Settings.ChangeFullName.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_changeFullName(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Settings_ChangeFullName(state.Form.FullName);
        return singleton.Bind(Remoting_handleNonAuth(settingsApi().changeFullName(request)), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Can not find account")))) : singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))));
    }), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => (new Types_Form(Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Name is required")(Validator$1__Test(t, "FullName", formInfo.FullName))))));
}

export function Validation_validateIf(needValidate, x) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

export const State_init = [new Types_State(new Types_Form(""), new DeferredResult$1(0), empty(), false), Cmd_none()];

export function State_update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Form, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
            else {
                Remoting_userChanged((x_3) => (new Auth_SignedInUser(x_3.Email, state.Form.FullName, x_3.SchoolId, x_3.GroupId, x_3.GdprAcceptDate, x_3.AccessToken, x_3.Role, x_3.UserId, x_3.ChatText, x_3.ChatChangeDate)));
                return [new Types_State(state.Form, new DeferredResult$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new Types_State(state.Form, state.Changing, matchValue.fields[0], state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(matchValue.fields[0], new DeferredResult$1(1), empty(), state.IsNeedValidation), Cmd_fromAsync(Cmd_changeFullName(state))];
            }
        }
    }
    else {
        const patternInput = Validation_validateIf(state.IsNeedValidation, new Types_Form(msg.fields[0]));
        return [new Types_State(patternInput[0], state.Changing, patternInput[1], state.IsNeedValidation), Cmd_none()];
    }
}

export function View_renderError(state) {
    const matchValue = state.Changing;
    let pattern_matching_result, error;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return null;
        }
        case 1: {
            return View_renderNotification(["className", "is-success"], "Namnet har ändrats.");
        }
        case 2: {
            return View_renderNotification(["className", "is-danger"], error);
        }
    }
}

export function View_view(state, dispatch) {
    let elms_3, elms, props_6, props_2, props_4, elms_2, elms_1;
    const props_15 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Byt namn"]])))), (elms_3 = singleton_2(createElement("form", {
        onSubmit: (x) => {
            x.preventDefault();
            dispatch(new Types_Msg(1, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray([(elms = singleton_2((props_6 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append(Form_Field_hasErrors("FullName")(state.FormErrors) ? singleton_1(["style", createObj(Form_Field_fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["name", "new-name"]), delay(() => append(singleton_1(["placeholder", "Nytt fullständigt namn"]), delay(() => singleton_1(["onChange", (ev) => {
            dispatch(new Types_Msg(0, ev.target.value));
        }]))))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_2))))), (props_4 = ofArray([["style", {
            color: "#FF0000",
        }], ["children", Form_Field_errorsAsString("FullName")(state.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_4))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_6))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_2 = singleton_2((elms_1 = singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["children", "Byt"]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), View_renderError(state)]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_15)));
}

export function Component_Render() {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init, (msg, state) => State_update(msg, state), []);
    return View_view(patternInput[0], patternInput[1]);
}

