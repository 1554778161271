import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { DayData_DayData, DayData_DayData_get_Default, Menstruation_parseMenstruationStr, Menstruation_displayMenstruation, Menstruation_list, SleepQuality_parseSleepQualityStr, SleepQuality_displaySleepQuality, Common_str, SleepQuality_list, StudentDays_StudentDayUpdateDataRequest } from "../../Shared/Shared.fs.js";
import { Toastr_toastError, Toastr_toastSuccess, Remoting_handleNonAuth } from "../Extensions.fs.js";
import { studentDaysApi } from "../Communication.fs.js";
import { View_checkBoxField, View_DUSelect } from "../Common.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { round, equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { singleton as singleton_1, cons, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { startImmediate } from "../.fable/fable-library.3.2.1/Async.js";

export function saveDayData(dayData, studentDayKey, onSuccess) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new StudentDays_StudentDayUpdateDataRequest(studentDayKey.StudentId, studentDayKey.CalendarDay, dayData);
        return singleton.Bind(Remoting_handleNonAuth(studentDaysApi().updateData(request)), (_arg1) => singleton.Return((_arg1.tag === 0) ? (Toastr_toastSuccess("Saved", ""), onSuccess(dayData)) : Toastr_toastError("Save day data error", "")));
    }), (_arg2) => {
        Toastr_toastError("Save day data error", "");
        return singleton.Zero();
    }));
}

export function SleepQualityEditor(value, onChange) {
    return View_DUSelect("Sömnkvalité", SleepQuality_list, (x) => Common_str(x), (x_1) => SleepQuality_displaySleepQuality(x_1), (_arg1) => SleepQuality_parseSleepQualityStr(_arg1), value, onChange);
}

export function MenstruationEditor(value, onChange) {
    return View_DUSelect("Menstruation", Menstruation_list, (x) => Common_str(x), (x_1) => Menstruation_displayMenstruation(x_1), (_arg1) => Menstruation_parseMenstruationStr(_arg1), value, onChange);
}

export function Render(renderInputProps) {
    let elms_5, elms_1, props_7, props_2, elms, props_4, value_25, elms_4, props_24, props_12, elms_3, props_21, props_16, props_14, value_62, elms_2, elms_8, elms_6, elms_7, elms_9, props_33;
    const onSave = renderInputProps.onSave;
    const studentDayKey = renderInputProps.studentDayKey;
    const patternInput = useFeliz_React__React_useState_Static_1505(defaultArg(renderInputProps.dayData, DayData_DayData_get_Default()));
    const setEditModel = patternInput[1];
    const editModel = patternInput[0];
    const children_10 = ofArray([View_checkBoxField("Sjuk", editModel.IsSick, (value_1) => {
        setEditModel(new DayData_DayData(value_1, editModel.IsDamaged, editModel.IsTravelDay, editModel.Heartrate, editModel.Sleep, editModel.SleepQuality, editModel.Menstruation));
    }), View_checkBoxField("Skadad", editModel.IsDamaged, (value_2) => {
        setEditModel(new DayData_DayData(editModel.IsSick, value_2, editModel.IsTravelDay, editModel.Heartrate, editModel.Sleep, editModel.SleepQuality, editModel.Menstruation));
    }), View_checkBoxField("Resedag", editModel.IsTravelDay, (value_3) => {
        setEditModel(new DayData_DayData(editModel.IsSick, editModel.IsDamaged, value_3, editModel.Heartrate, editModel.Sleep, editModel.SleepQuality, editModel.Menstruation));
    }), (elms_5 = ofArray([(elms_1 = singleton_1((props_7 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["children", "Vilopuls"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = singleton_1((props_4 = ofArray([["className", "is-size-7"], ["step", 1], ["min", 0], ["className", "has-text-left"], ["className", "has-text-grey"], (value_25 = (editModel.Heartrate | 0), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_25)) : false) {
            e.value = (value_25 | 0);
        }
    }]), ["onChange", (ev) => {
        const value_29 = ev.target.valueAsNumber;
        if ((!(value_29 == null)) ? (value_29 !== NaN) : false) {
            setEditModel(new DayData_DayData(editModel.IsSick, editModel.IsDamaged, editModel.IsTravelDay, round(value_29), editModel.Sleep, editModel.SleepQuality, editModel.Menstruation));
        }
    }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_7))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_4 = singleton_1((props_24 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["children", "Sömn"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_12)))), (elms_3 = singleton_1((props_21 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-size-7"], ["step", 1], ["min", 1], ["max", 24], ["className", "has-text-left"], ["className", "has-text-grey"], (value_62 = (editModel.Sleep | 0), ["ref", (e_1) => {
        if ((!(e_1 == null)) ? (!equals(e_1.value, value_62)) : false) {
            e_1.value = (value_62 | 0);
        }
    }]), ["onChange", (ev_1) => {
        const value_66 = ev_1.target.valueAsNumber;
        if ((!(value_66 == null)) ? (value_66 !== NaN) : false) {
            setEditModel(new DayData_DayData(editModel.IsSick, editModel.IsDamaged, editModel.IsTravelDay, editModel.Heartrate, round(value_66), editModel.SleepQuality, editModel.Menstruation));
        }
    }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_14)))))])]]), createElement("p", createObj(Helpers_combineClasses("control", props_16)))), (elms_2 = singleton_1(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-static"], ["className", "is-small"], ["children", "timmar"]]))))), createElement("p", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_21))))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_24))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    })), (elms_8 = ofArray([(elms_6 = singleton_1(SleepQualityEditor(editModel.SleepQuality, (value_6) => {
        setEditModel(new DayData_DayData(editModel.IsSick, editModel.IsDamaged, editModel.IsTravelDay, editModel.Heartrate, editModel.Sleep, value_6, editModel.Menstruation));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    })), (elms_7 = singleton_1(MenstruationEditor(editModel.Menstruation, (value_7) => {
        setEditModel(new DayData_DayData(editModel.IsSick, editModel.IsDamaged, editModel.IsTravelDay, editModel.Heartrate, editModel.Sleep, editModel.SleepQuality, value_7));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
    })), (elms_9 = singleton_1((props_33 = ofArray([["className", "is-right"], ["className", "pb-4"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["children", "Spara"], ["onClick", (_arg1) => {
        startImmediate(saveDayData(editModel, studentDayKey, onSave));
    }]]))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_33))))), createElement("div", {
        className: "block",
        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
    }))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    });
}

