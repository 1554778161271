import { createElement } from "react";
import { RenderFiltersAndCharts } from "./Statistic.fs.js";
import { Statistics_StatisticType } from "../../Shared/Shared.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.fs.js";
import { schoolsApi } from "../Communication.fs.js";
import { loader } from "../Loader.fs.js";

export function RenderMainLayout(school) {
    return createElement(RenderFiltersAndCharts, {
        title: school.Title,
        statisticType: new Statistics_StatisticType(0, school.Id),
        excelRender: void 0,
        tablesRender: void 0,
    });
}

export function renderMainLayoutWithLoad(schoolId) {
    const school = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(schoolsApi().get(schoolId)), [schoolId]);
    switch (school.tag) {
        case 3: {
            const value = school.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            return createElement(RenderMainLayout, school.fields[0]);
        }
        default: {
            return loader("big");
        }
    }
}

export function Render(renderInputProps) {
    const _arg1 = renderInputProps._arg1;
    return renderMainLayoutWithLoad(renderInputProps.schoolId);
}

