import { Record } from "../.fable/fable-library.3.2.1/Types.js";
import { record_type, class_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { DurationModule_intToHMSString, Workout_getWorkoutTotalDuration, DurationModule_toInt, DaySegment_list, Schedules_ScheduleDayKey, ScheduleDay_ScheduleDay_Default_Z524259A4, DaySegment_strDaySegment, Calendar_weekDaysRange, Calendar_getWeekDay3LetterTitle, ScheduleWeek_ScheduleWeek$reflection } from "../../Shared/Shared.fs.js";
import { Render as Render_1, ScheduleDayActions$reflection } from "./ScheduleDayBox.fs.js";
import { borderStyleBottomGrey, borderStyleBottomPrimary, borderStylePrimary } from "../Common.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { collect, map, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import { equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { sumBy, sum, map as map_1, tryFind } from "../.fable/fable-library.3.2.1/Array.js";

export class WeekParams extends Record {
    constructor(ScheduleId, ScheduleWeek, Actions) {
        super();
        this.ScheduleId = ScheduleId;
        this.ScheduleWeek = ScheduleWeek;
        this.Actions = Actions;
    }
}

export function WeekParams$reflection() {
    return record_type("ScheduleWeek.WeekParams", [], WeekParams, () => [["ScheduleId", class_type("System.Guid")], ["ScheduleWeek", ScheduleWeek_ScheduleWeek$reflection()], ["Actions", ScheduleDayActions$reflection()]]);
}

export function Render(weekParams) {
    const props_18 = ofArray([["className", "px-2"], ["className", "pb-0"], borderStylePrimary, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_6;
        return append(singleton((props_6 = ofArray([["className", "has-text-centered"], ["className", "is-gapless"], ["className", "mb-2"], ["className", "pb-3"], borderStyleBottomPrimary, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"]))))), delay(() => map((weekDay) => {
            let props_2;
            const props_4 = ofArray([["className", "py-1"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "has-text-weight-bold"], ["className", "is-size-6"], ["children", defaultArg(Calendar_getWeekDay3LetterTitle(weekDay), "")]]), createElement("p", createObj(Helpers_combineClasses("", props_2))))])]]);
            return createElement("div", createObj(Helpers_combineClasses("column", props_4)));
        }, Calendar_weekDaysRange)))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))), delay(() => append(map((daySegment) => {
            const props_10 = ofArray([["className", "is-gapless"], ["className", "is-vcentered"], ["className", "py-1"], ["className", "my-2"], borderStyleBottomGrey, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let props_8;
                return append(singleton((props_8 = ofArray([["className", "is-1"], ["children", DaySegment_strDaySegment(daySegment)]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))), delay(() => collect((weekDay_1) => {
                    const dayPlan = tryFind((plan) => equals(plan.DaySegment, daySegment), defaultArg(tryFind((x) => (x.WeekDay === weekDay_1), weekParams.ScheduleWeek.ScheduleDays), ScheduleDay_ScheduleDay_Default_Z524259A4(weekDay_1)).DayPlans);
                    const scheduleDayKey = new Schedules_ScheduleDayKey(weekParams.ScheduleId, weekParams.ScheduleWeek.PeriodWeek, weekDay_1);
                    return singleton(createElement(Render_1, {
                        dayPlan: dayPlan,
                        scheduleDayKey: scheduleDayKey,
                        daySegment: daySegment,
                        actions: weekParams.Actions,
                    }));
                }, Calendar_weekDaysRange)));
            }))))]]);
            return createElement("div", createObj(Helpers_combineClasses("columns", props_10)));
        }, DaySegment_list), delay(() => {
            let props_16;
            return singleton((props_16 = ofArray([["className", "is-gapless"], ["className", "py-2"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-1"]))))), delay(() => {
                const durations = map_1((day) => [day.WeekDay, sum(map_1((x_1) => DurationModule_toInt(Workout_getWorkoutTotalDuration(x_1.Workout)), day.DayPlans, Int32Array), {
                    GetZero: () => 0,
                    Add: (x_2, y) => (x_2 + y),
                })], weekParams.ScheduleWeek.ScheduleDays);
                return collect((weekDay_2) => {
                    let props_14;
                    return singleton((props_14 = ofArray([["className", "py-1"], ["className", "has-text-centered"], ["children", DurationModule_intToHMSString(sumBy((tuple) => tuple[1], durations.filter((tupledArg) => (tupledArg[0] === weekDay_2)), {
                        GetZero: () => 0,
                        Add: (x_3, y_1) => (x_3 + y_1),
                    }))]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))));
                }, Calendar_weekDaysRange);
            }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_16)))));
        }))));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_18)));
}

