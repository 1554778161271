import { createElement } from "react";
import { createObj } from "./.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { singleton, ofArray } from "./.fable/fable-library.3.2.1/List.js";
import { Route, toPath } from "./Router.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.51.0/Interop.fs.js";
import { join } from "./.fable/fable-library.3.2.1/String.js";
import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.51.0/React.fs.js";
import * as Subtract from "./public/Subtract.svg";
import { empty, singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.2.1/Seq.js";

export function RenderLogoutButton(logOut) {
    return createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (x) => {
        x.preventDefault();
        logOut();
    }], ["className", "is-primary"], ["children", "Logga ut"]]))));
}

export function RenderAccountButton(user) {
    let s, elms;
    const props_3 = ofArray([["href", toPath(new Route(5))], ["className", "is-outlined"], ["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(s = ("Hi, " + user.FullName), createElement("span", {
        className: "",
        children: s,
    })), (elms = singleton(createElement("i", {
        className: join(" ", ["fa", "fa-sliders-h"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("a", createObj(Helpers_combineClasses("button", props_3)));
}

export function Render(renderInputProps) {
    let props_17, elms_1, elms, value_16, props_8, props_15;
    const itemsRightTouchView = renderInputProps.itemsRightTouchView;
    const itemsRightDesktopView = renderInputProps.itemsRightDesktopView;
    const itemsLeft = renderInputProps.itemsLeft;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setMenuActive = patternInput[1];
    const menuActive = patternInput[0];
    const elms_4 = singleton((props_17 = ofArray([["className", "mb-3"], ["role", "navigation"], ["aria-label", "main-navigation"], ["className", "is-size-5"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton(createElement("div", {
        style: {
            display: "flex",
            alignItems: "center",
        },
        className: "is-vcentered",
        className: "has-text-primary",
        className: "mr-3",
        children: Interop_reactApi.Children.toArray([createElement("img", createObj(ofArray([(value_16 = Subtract, ["src", value_16]), ["style", {
            height: 26,
            width: 26,
        }]]))), createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "pl-2"], ["className", "has-text-weight-bold"], ["children", "Endurance"]]))))]),
    })), createElement("a", {
        className: "navbar-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_8 = toList(delay(() => append(singleton_1(["onClick", (x) => {
        x.preventDefault();
        setMenuActive(!menuActive);
    }]), delay(() => append(menuActive ? singleton_1(["className", "is-active"]) : empty(), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray([createElement("span", {}), createElement("span", {}), createElement("span", {})])]))))))), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_8))))]), createElement("div", {
        className: "navbar-brand",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_15 = toList(delay(() => append(menuActive ? singleton_1(["className", "is-active"]) : empty(), delay(() => append(singleton_1(["onClick", (_arg1) => {
        setMenuActive(!menuActive);
    }]), delay(() => {
        let elms_3, props_12;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: "navbar-start",
            children: Interop_reactApi.Children.toArray([itemsLeft]),
        }), (elms_3 = ofArray([(props_12 = ofArray([["className", join(" ", ["is-hidden-touch"])], ["children", Interop_reactApi.Children.toArray([itemsRightDesktopView])]]), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_12)))), itemsRightTouchView]), createElement("div", {
            className: "navbar-end",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]);
    })))))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_15))))])]]), createElement("nav", createObj(Helpers_combineClasses("navbar", props_17)))));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    });
}

