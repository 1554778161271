import { Record, Union } from "../.fable/fable-library.3.2.1/Types.js";
import { record_type, array_type, class_type, string_type, list_type, union_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { Groups_GroupsOrderUpdateRequest, Groups_GroupListItem$reflection } from "../../Shared/Shared.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { groupsApi } from "../Communication.fs.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton as singleton_2, filter as filter_1, tryFind, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { map, contains, choose, concat } from "../.fable/fable-library.3.2.1/Array.js";
import { map as map_1, empty, singleton as singleton_1, append, delay, toList, filter } from "../.fable/fable-library.3.2.1/Seq.js";
import { createObj, stringHash } from "../.fable/fable-library.3.2.1/Util.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { Route, toPath } from "../Router.fs.js";
import { View_renderNotification, View_RenderDeleteWindow } from "../Common.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_MoveDirection extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Up", "Down"];
    }
}

export function Types_MoveDirection$reflection() {
    return union_type("GroupList.Types.MoveDirection", [], Types_MoveDirection, () => [[], []]);
}

export class Types_State extends Record {
    constructor(Groups, GroupsOrder) {
        super();
        this.Groups = Groups;
        this.GroupsOrder = GroupsOrder;
    }
}

export function Types_State$reflection() {
    return record_type("GroupList.Types.State", [], Types_State, () => [["Groups", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Groups_GroupListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Groups_GroupListItem$reflection())]], [["ErrorValue", string_type]]]))], ["GroupsOrder", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(class_type("System.Guid")), string_type], FSharpResult$2, () => [[["ResultValue", array_type(class_type("System.Guid"))]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadGroups", "LoadGroupsOrder", "UpdateGroupsOrderClick", "UpdateGroupsOrder", "DeleteGroupClick", "DeleteGroup"];
    }
}

export function Types_Msg$reflection() {
    return union_type("GroupList.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Groups_GroupListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Groups_GroupListItem$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(class_type("System.Guid")), string_type], FSharpResult$2, () => [[["ResultValue", array_type(class_type("System.Guid"))]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Guid")], ["Item2", Types_MoveDirection$reflection()]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(class_type("System.Guid")), string_type], FSharpResult$2, () => [[["ResultValue", array_type(class_type("System.Guid"))]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]]]);
}

export const Cmd_loadGroups = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(groupsApi().list()), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export const Cmd_loadGroupsOrder = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(groupsApi().getGroupsOrder()), (_arg1) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_updateGroupsOrder(groupIds) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Groups_GroupsOrderUpdateRequest(groupIds);
        return singleton.Bind(Remoting_handleNonAuth(groupsApi().updateGroupsOrder(request)), (_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, response.fields[0])))) : singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, groupIds))));
        });
    }), (_arg2) => singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_delete(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(groupsApi().delete(id)), (_arg1) => {
        const result = _arg1;
        return singleton.Return((result.tag === 0) ? (new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, id)))) : (new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))));
    })), (_arg2) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export const State_init = [new Types_State(new DeferredResult$1(1), new DeferredResult$1(1)), Cmd_batch(ofArray([Cmd_OfFunc_result(new Types_Msg(0, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Types_Msg(1, new AsyncOperationStatus$1(0)))]))];

export function State_getOrderedGroups(groups, groupsOrder) {
    return concat([choose((groupId) => tryFind((x) => (x.Id === groupId), groups), groupsOrder), Array.from(filter((x_1) => (!contains(x_1.Id, groupsOrder, {
        Equals: (x_2, y) => (x_2 === y),
        GetHashCode: (x_2) => stringHash(x_2),
    })), groups))]);
}

export function State_update(msg, state) {
    let matchValue_1;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Groups, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new Types_State(state.Groups, new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.Groups, new DeferredResult$1(1)), Cmd_fromAsync(Cmd_loadGroupsOrder)];
        }
    }
    else if (msg.tag === 2) {
        let newGroupsOrder;
        const matchValue = [state.Groups, state.GroupsOrder];
        let pattern_matching_result, groups, groupsOrder;
        if (matchValue[0].tag === 2) {
            if (matchValue[0].fields[0].tag === 0) {
                if (matchValue[1].tag === 2) {
                    if (matchValue[1].fields[0].tag === 0) {
                        pattern_matching_result = 0;
                        groups = matchValue[0].fields[0].fields[0];
                        groupsOrder = matchValue[1].fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                const groupIds = map((x_2) => x_2.Id, State_getOrderedGroups(groups, groupsOrder));
                const groupIdIndex = groupIds.findIndex((x_3) => (x_3 === msg.fields[0])) | 0;
                newGroupsOrder = ((msg.fields[1].tag === 1) ? ((groupIdIndex < (groupIds.length - 1)) ? concat([groupIds.slice(void 0, (groupIdIndex - 1) + 1), [groupIds[groupIdIndex + 1]], [groupIds[groupIdIndex]], groupIds.slice(groupIdIndex + 2, groupIds.length)]) : groupIds) : ((groupIdIndex > 0) ? concat([groupIds.slice(void 0, (groupIdIndex - 2) + 1), [groupIds[groupIdIndex]], [groupIds[groupIdIndex - 1]], groupIds.slice(groupIdIndex + 1, groupIds.length)]) : groupIds));
                break;
            }
            case 1: {
                newGroupsOrder = [];
                break;
            }
        }
        return [new Types_State(state.Groups, new DeferredResult$1(2, new FSharpResult$2(0, newGroupsOrder))), Cmd_fromAsync(Cmd_updateGroupsOrder(newGroupsOrder))];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.GroupsOrder), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 4) {
        return [state, Cmd_fromAsync(Cmd_delete(msg.fields[0]))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.GroupsOrder), Cmd_none()];
            }
            else {
                return [new Types_State((matchValue_1 = state.Groups, (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, filter_1((x_4) => (x_4.Id !== msg.fields[0].fields[0].fields[0]), matchValue_1.fields[0].fields[0])))) : state.Groups) : state.Groups), state.GroupsOrder), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.GroupsOrder), Cmd_none()];
        }
        else {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.GroupsOrder), Cmd_none()];
        }
    }
    else {
        return [new Types_State(new DeferredResult$1(1), state.GroupsOrder), Cmd_fromAsync(Cmd_loadGroups)];
    }
}

export function View_Group(view_GroupInputProps) {
    const dispatch = view_GroupInputProps.dispatch;
    const group = view_GroupInputProps.group;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setMoveUpHover = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setMoveDownHover = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setEditHover = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteHover = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_4[1];
    const isDeleteWindow = patternInput_4[0];
    const children_2 = toList(delay(() => {
        let props_26, elms_1, elms, props_5, props_3, props_23, props_8, props_12, props_16, props_20;
        return append(singleton_1((props_26 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
            border: (((1 + "px ") + "solid") + " ") + "#808080",
            alignContent: "space-between",
        }], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton_2(createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-primary"], ["children", group.Title]]))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_5 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append(singleton_1(["className", "is-size-7"]), delay(() => append(patternInput[0] ? singleton_1(["children", "Flytta upp"]) : empty(), delay(() => append(patternInput_1[0] ? singleton_1(["children", "Flytta ner"]) : empty(), delay(() => append(patternInput_2[0] ? singleton_1(["children", "Ändra egenskaper"]) : empty(), delay(() => (patternInput_3[0] ? singleton_1(["children", "Ta bort gruppen"]) : empty()))))))))))), createElement("span", createObj(Helpers_combineClasses("", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_23 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            onMouseEnter: (_arg1) => {
                setMoveUpHover(true);
            },
            onMouseLeave: (_arg2) => {
                setMoveUpHover(false);
            },
            onClick: (_arg3) => {
                dispatch(new Types_Msg(2, group.Id, new Types_MoveDirection(1)));
            },
            className: "px-2",
            children: Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-dark"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-chevron-down"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8))))]),
        }), createElement("a", {
            onMouseEnter: (_arg4) => {
                setMoveDownHover(true);
            },
            onMouseLeave: (_arg5) => {
                setMoveDownHover(false);
            },
            onClick: (_arg6) => {
                dispatch(new Types_Msg(2, group.Id, new Types_MoveDirection(0)));
            },
            className: "px-2",
            children: Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-dark"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-chevron-up"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_12))))]),
        }), createElement("a", {
            onMouseEnter: (_arg7) => {
                setEditHover(true);
            },
            onMouseLeave: (_arg8) => {
                setEditHover(false);
            },
            className: "px-2",
            href: toPath(new Route(9, group.Id)),
            children: Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fa", "fa-pencil-alt"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_16))))]),
        }), createElement("a", {
            onMouseEnter: (_arg9) => {
                setDeleteHover(true);
            },
            onMouseLeave: (_arg10) => {
                setDeleteHover(false);
            },
            onClick: (_arg11) => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            className: "px-2",
            children: Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-times"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_20))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_23))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_26))))), delay(() => (isDeleteWindow ? singleton_1(createElement(View_RenderDeleteWindow, {
            confirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
                dispatch(new Types_Msg(4, group.Id));
            },
            notConfirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            text: "Är du säker på att du vill ta bort här gruppen?",
        })) : empty())));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function View_Render(_user) {
    let props_6, elms, elms_1, props_3;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init, (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const props_8 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_6 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => append(singleton_1(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Alla Grupper"]]))))), delay(() => {
        const matchValue = [state_1.Groups, state_1.GroupsOrder];
        let pattern_matching_result, groups, groupsOrder, e;
        if (matchValue[0].tag === 2) {
            if (matchValue[0].fields[0].tag === 1) {
                pattern_matching_result = 1;
                e = matchValue[0].fields[0].fields[0];
            }
            else if (matchValue[1].tag === 2) {
                if (matchValue[1].fields[0].tag === 1) {
                    pattern_matching_result = 1;
                    e = matchValue[1].fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 0;
                    groups = matchValue[0].fields[0].fields[0];
                    groupsOrder = matchValue[1].fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else if (matchValue[1].tag === 2) {
            if (matchValue[1].fields[0].tag === 1) {
                pattern_matching_result = 1;
                e = matchValue[1].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return map_1((group) => createElement(View_Group, {
                    group: group,
                    dispatch: patternInput[1],
                }), State_getOrderedGroups(groups, groupsOrder));
            }
            case 1: {
                return singleton_1(View_renderNotification(["className", "is-danger"], e));
            }
            case 2: {
                return singleton_1(loader("big"));
            }
        }
    })))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton_2((props_3 = ofArray([["href", toPath(new Route(8))], ["className", "py-2"], ["className", "is-success"], ["children", "Lägg till grupp"]]), createElement("a", createObj(Helpers_combineClasses("button", props_3))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_8)));
}

