import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { Calendar_PeriodInfo_get_Current, EmailModule_toString, Admin_Favorite, Students_StudentListItem$reflection, Admin_Favorite$reflection } from "../../Shared/Shared.fs.js";
import { tuple_type, class_type, record_type, list_type, union_type, string_type, array_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { adminApi } from "../Communication.fs.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { filter, length, ofArray, empty, ofSeq, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { distinct } from "../.fable/fable-library.3.2.1/Seq2.js";
import { map as map_1, empty as empty_1, singleton as singleton_2, append, delay, toList, collect } from "../.fable/fable-library.3.2.1/Seq.js";
import { createObj, stringHash } from "../.fable/fable-library.3.2.1/Util.js";
import { contains, map } from "../.fable/fable-library.3.2.1/Array.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Route, toPath } from "../Router.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { toString } from "../.fable/fable-library.3.2.1/Date.js";
import { View_renderNotification, View_RenderDeleteWindow } from "../Common.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_State extends Record {
    constructor(Favorites, ListStudents) {
        super();
        this.Favorites = Favorites;
        this.ListStudents = ListStudents;
    }
}

export function Types_State$reflection() {
    return record_type("AdminOverview.Types.State", [], Types_State, () => [["Favorites", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Admin_Favorite$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(Admin_Favorite$reflection())]], [["ErrorValue", string_type]]]))], ["ListStudents", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_StudentListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_StudentListItem$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadFavorites", "LoadStudents", "DeleteFavoriteClick", "DeleteFavorite", "DeleteFavoriteUserClick", "DeleteFavoriteUser"];
    }
}

export function Types_Msg$reflection() {
    return union_type("AdminOverview.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Admin_Favorite$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", array_type(Admin_Favorite$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_StudentListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_StudentListItem$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]], [["Item1", class_type("System.Guid")], ["Item2", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(class_type("System.Guid"), class_type("System.Guid")), string_type], FSharpResult$2, () => [[["ResultValue", tuple_type(class_type("System.Guid"), class_type("System.Guid"))]], [["ErrorValue", string_type]]]))]]]);
}

export const Cmd_loadFavorites = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(adminApi().favoriteList()), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_loadStudents(ids) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(adminApi().studentsByIds(ids)), (_arg1) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_deleteFavorite(favoriteId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(adminApi().deleteFavorite(favoriteId)), (_arg1) => {
        const result = _arg1;
        return singleton.Return((result.tag === 0) ? (new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, favoriteId)))) : (new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))));
    })), (_arg2) => singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Cmd_deleteFavoriteUser(favoriteId, userId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(adminApi().deleteFavoriteUser([favoriteId, userId])), (_arg1) => {
        const result = _arg1;
        return singleton.Return((result.tag === 0) ? (new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, [favoriteId, userId])))) : (new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))));
    })), (_arg2) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export const State_init = [new Types_State(new DeferredResult$1(1), new DeferredResult$1(1)), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Types_Msg(0, new AsyncOperationStatus$1(0)))))];

export function State_update(msg, state) {
    let matchValue, matchValue_1, matchValue_2;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Favorites, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new Types_State(state.Favorites, new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
        }
        else {
            return [new Types_State(state.Favorites, new DeferredResult$1(1)), Cmd_fromAsync(Cmd_loadStudents((matchValue = state.Favorites, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? ofSeq(distinct(collect((x_1) => x_1.UserIds, matchValue.fields[0].fields[0]), {
                Equals: (x_2, y) => (x_2 === y),
                GetHashCode: (x_2) => stringHash(x_2),
            })) : empty()) : empty())))];
        }
    }
    else if (msg.tag === 2) {
        return [state, Cmd_fromAsync(Cmd_deleteFavorite(msg.fields[0]))];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.ListStudents), Cmd_none()];
            }
            else {
                return [new Types_State((matchValue_1 = state.Favorites, (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, matchValue_1.fields[0].fields[0].filter((x_4) => (x_4.Id !== msg.fields[0].fields[0].fields[0]))))) : state.Favorites) : state.Favorites), state.ListStudents), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 4) {
        return [state, Cmd_fromAsync(Cmd_deleteFavoriteUser(msg.fields[0], msg.fields[1]))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.ListStudents), Cmd_none()];
            }
            else {
                return [new Types_State((matchValue_2 = state.Favorites, (matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, map((x_5) => {
                    if (x_5.Id === msg.fields[0].fields[0].fields[0][0]) {
                        return new Admin_Favorite(x_5.Id, x_5.Title, x_5.UserIds.filter((xx) => (xx !== msg.fields[0].fields[0].fields[0][1])));
                    }
                    else {
                        return x_5;
                    }
                }, matchValue_2.fields[0].fields[0])))) : state.Favorites) : state.Favorites), state.ListStudents), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.ListStudents), Cmd_none()];
        }
        else {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.ListStudents), Cmd_OfFunc_result(new Types_Msg(1, new AsyncOperationStatus$1(0)))];
        }
    }
    else {
        return [new Types_State(new DeferredResult$1(1), state.ListStudents), Cmd_fromAsync(Cmd_loadFavorites)];
    }
}

export function View_RenderStudent(view_RenderStudentInputProps) {
    const dispatch = view_RenderStudentInputProps.dispatch;
    const student = view_RenderStudentInputProps.student;
    const favorite = view_RenderStudentInputProps.favorite;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setChatHover = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setStatisticHover = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setOverViewHover = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setPlanHover = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_5[1];
    const isDeleteWindow = patternInput_5[0];
    const props_28 = ofArray([["className", "is-vcentered"], ["style", {
        borderTop: (((1 + "px ") + "solid") + " ") + "#A9A9A9",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_3, value_10;
        return append(singleton_2((props_3 = ofArray([["style", {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        }], ["children", Interop_reactApi.Children.toArray([createElement("span", {
            children: [student.FullName],
        }), createElement("br", {}), (value_10 = EmailModule_toString(student.Email), createElement("span", {
            children: [value_10],
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3))))), delay(() => {
            let props_7, props_5;
            return append(singleton_2((props_7 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_5 = toList(delay(() => append(singleton_2(["className", "is-size-7"]), delay(() => append(patternInput[0] ? singleton_2(["children", "Visa chat"]) : empty_1(), delay(() => append(patternInput_1[0] ? singleton_2(["children", "Visa statistik"]) : empty_1(), delay(() => append(patternInput_2[0] ? singleton_2(["children", "Visa kalender"]) : empty_1(), delay(() => append(patternInput_3[0] ? singleton_2(["children", "Visa årsplanering"]) : empty_1(), delay(() => (patternInput_4[0] ? singleton_2(["children", "Ta bort utövaren från favoritlistan"]) : empty_1()))))))))))))), createElement("span", createObj(Helpers_combineClasses("", props_5))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7))))), delay(() => {
                let props_26, props_15, props_19, props_23;
                return append(singleton_2((props_26 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
                    className: "px-2",
                    onMouseEnter: (_arg1) => {
                        setChatHover(true);
                    },
                    onMouseLeave: (_arg2) => {
                        setChatHover(false);
                    },
                    href: toPath(new Route(6, student.Id)),
                    style: {
                        width: 5 + "rem",
                        display: "inline-block",
                        textAlign: "left",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let props_10;
                        return append(singleton_2((props_10 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                            className: join(" ", ["far", "fa-comments"]),
                        })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_10))))), delay(() => {
                            const matchValue = student.ChatChangeDate;
                            if (matchValue == null) {
                                return singleton_2(null);
                            }
                            else {
                                const x = matchValue;
                                return singleton_2(createElement("span", {
                                    style: {
                                        color: "#666",
                                        fontSize: 0.7 + "rem",
                                    },
                                    className: "ml-1",
                                    children: toString(x, "d/M"),
                                }));
                            }
                        }));
                    })))),
                }), createElement("a", {
                    className: "px-2",
                    onMouseEnter: (_arg3) => {
                        setStatisticHover(true);
                    },
                    onMouseLeave: (_arg4) => {
                        setStatisticHover(false);
                    },
                    href: toPath(new Route(15, student.Id)),
                    children: Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["far", "fa-chart-bar"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_15))))]),
                }), createElement("a", {
                    className: "px-2",
                    onMouseEnter: (_arg5) => {
                        setOverViewHover(true);
                    },
                    onMouseLeave: (_arg6) => {
                        setOverViewHover(false);
                    },
                    href: toPath(new Route(29, student.Id, Calendar_PeriodInfo_get_Current())),
                    children: Interop_reactApi.Children.toArray([(props_19 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-calendar-alt"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_19))))]),
                }), createElement("a", {
                    className: "px-2",
                    onMouseEnter: (_arg7) => {
                        setPlanHover(true);
                    },
                    onMouseLeave: (_arg8) => {
                        setPlanHover(false);
                    },
                    href: toPath(new Route(13, student.Id)),
                    children: Interop_reactApi.Children.toArray([(props_23 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-success"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["far", "fa-clock"]),
                    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_23))))]),
                })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_26))))), delay(() => (isDeleteWindow ? singleton_2(createElement(View_RenderDeleteWindow, {
                    confirmed: () => {
                        setIsDeleteWindow(!isDeleteWindow);
                        dispatch(new Types_Msg(4, favorite.Id, student.Id));
                    },
                    notConfirmed: () => {
                        setIsDeleteWindow(!isDeleteWindow);
                    },
                    text: "Är du säker på att du vill ta bort här utövaren från favoritlistan?",
                })) : empty_1())));
            }));
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_28)));
}

export function View_RenderFavorite(view_RenderFavoriteInputProps) {
    const dispatch = view_RenderFavoriteInputProps.dispatch;
    const students = view_RenderFavoriteInputProps.students;
    const favorite = view_RenderFavoriteInputProps.favorite;
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_2[1];
    const isDeleteWindow = patternInput_2[0];
    const props_16 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
        border: (((1 + "px ") + "solid") + " ") + "#808080",
        alignContent: "space-between",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_14, props_2, props_6, props_4, props_12;
        return append(singleton_2((props_14 = ofArray([["className", "is-vcentered"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["onClick", (_arg1) => {
            setVisible(!isVisible);
        }], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-primary"], ["children", favorite.Title]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_6 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_2(["className", "is-size-7"]), delay(() => (patternInput_1[0] ? singleton_2(["children", "Ta bort favoritlistan"]) : empty_1()))))), createElement("span", createObj(Helpers_combineClasses("", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_12 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_9;
            return (length(students) > 0) ? singleton_2(createElement("a", {
                className: join(" ", ["hoverable"]),
                className: "px-2",
                onClick: (_arg2) => {
                    setVisible(!isVisible);
                },
                children: Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-primary"], ["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (isVisible ? singleton_2(["className", join(" ", ["fa", "fa-chevron-up"])]) : singleton_2(["className", join(" ", ["fa", "fa-chevron-down"])]))))))])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_9))))]),
            })) : empty_1();
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_14))))), delay(() => append(isVisible ? map_1((student) => createElement(View_RenderStudent, {
            favorite: favorite,
            student: student,
            dispatch: dispatch,
        }), students) : empty_1(), delay(() => (isDeleteWindow ? singleton_2(createElement(View_RenderDeleteWindow, {
            confirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
                dispatch(new Types_Msg(2, favorite.Id));
            },
            notConfirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            text: "Är du säker på att du vill ta bort den här favoritlistan?",
        })) : empty_1())))));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_16)));
}

export function Component_Render(user) {
    let props_7, elms;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init, (msg, state) => State_update(msg, state), [user]);
    const state_1 = patternInput[0];
    const props_9 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_7 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => {
        let props_2;
        return append(singleton_2((props_2 = ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["style", {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", singleton_1(["children", "Utövare"]))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_2))))), delay(() => {
            const matchValue = [state_1.Favorites, state_1.ListStudents];
            let pattern_matching_result, favorites, students, e;
            if (matchValue[0].tag === 2) {
                if (matchValue[0].fields[0].tag === 1) {
                    pattern_matching_result = 1;
                    e = matchValue[0].fields[0].fields[0];
                }
                else if (matchValue[1].tag === 2) {
                    if (matchValue[1].fields[0].tag === 1) {
                        pattern_matching_result = 1;
                        e = matchValue[1].fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result = 0;
                        favorites = matchValue[0].fields[0].fields[0];
                        students = matchValue[1].fields[0].fields[0];
                    }
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else if (matchValue[1].tag === 2) {
                if (matchValue[1].fields[0].tag === 1) {
                    pattern_matching_result = 1;
                    e = matchValue[1].fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else {
                pattern_matching_result = 2;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return collect((favorite) => {
                        const favoriteStudents = filter((x) => contains(x.Id, favorite.UserIds, {
                            Equals: (x_1, y) => (x_1 === y),
                            GetHashCode: (x_1) => stringHash(x_1),
                        }), students);
                        return singleton_2(createElement(View_RenderFavorite, {
                            favorite: favorite,
                            students: favoriteStudents,
                            dispatch: patternInput[1],
                        }));
                    }, favorites);
                }
                case 1: {
                    return singleton_2(View_renderNotification(["className", "is-danger"], e));
                }
                case 2: {
                    return singleton_2(loader("big"));
                }
            }
        }));
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("div", createObj(Helpers_combineClasses("column", empty())))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_7))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_9)));
}

