import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { record_type, union_type, string_type, list_type, class_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { EmailModule_ofString, Students_TeacherListItem$reflection } from "../../Shared/Shared.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Toastr_toastSuccess, Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { teachersApi, studentsApi } from "../Communication.fs.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { map as map_1, contains, ofArray, filter, cons, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { map, empty, singleton as singleton_2, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { isNullOrWhiteSpace, join, interpolate, toText } from "../.fable/fable-library.3.2.1/String.js";
import { createElement } from "react";
import { stringHash, equals, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { View_renderNotification, View_RenderDeleteWindow } from "../Common.fs.js";
import { startImmediate } from "../.fable/fable-library.3.2.1/Async.js";
import { loader } from "../Loader.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export class Types_State extends Record {
    constructor(StudentId, ExternalTeachers) {
        super();
        this.StudentId = StudentId;
        this.ExternalTeachers = ExternalTeachers;
    }
}

export function Types_State$reflection() {
    return record_type("StudentExternalTeachers.Types.State", [], Types_State, () => [["StudentId", class_type("System.Guid")], ["ExternalTeachers", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_TeacherListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_TeacherListItem$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadExternalTeachers", "AddedExternalTeacher", "DeleteExternalTeacherClick", "DeleteExternalTeacher"];
    }
}

export function Types_Msg$reflection() {
    return union_type("StudentExternalTeachers.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Students_TeacherListItem$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(Students_TeacherListItem$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", Students_TeacherListItem$reflection()]], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), string_type], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", string_type]]]))]]]);
}

export const Cmd_load = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(studentsApi().getStudentExternalTeachers()), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function Cmd_delete(id) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(studentsApi().deleteExternalTeacher(id)), (_arg1) => {
        const result = _arg1;
        return singleton.Return((result.tag === 0) ? (new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, id)))) : (new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, result.fields[0])))));
    })), (_arg2) => singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(studentId) {
    return [new Types_State(studentId, new DeferredResult$1(1)), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Types_Msg(0, new AsyncOperationStatus$1(0)))))];
}

export function State_update(msg, state) {
    let matchValue, matchValue_1;
    if (msg.tag === 1) {
        return [new Types_State(state.StudentId, (matchValue = state.ExternalTeachers, (matchValue.tag === 2) ? ((matchValue.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, cons(msg.fields[0], matchValue.fields[0].fields[0])))) : state.ExternalTeachers) : state.ExternalTeachers)), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [state, Cmd_fromAsync(Cmd_delete(msg.fields[0]))];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.StudentId, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new Types_State(state.StudentId, (matchValue_1 = state.ExternalTeachers, (matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? (new DeferredResult$1(2, new FSharpResult$2(0, filter((x_1) => (x_1.Id !== msg.fields[0].fields[0].fields[0]), matchValue_1.fields[0].fields[0])))) : state.ExternalTeachers) : state.ExternalTeachers)), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(state.StudentId, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
        else {
            return [new Types_State(state.StudentId, new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
    }
    else {
        return [new Types_State(state.StudentId, new DeferredResult$1(1)), Cmd_fromAsync(Cmd_load)];
    }
}

export function View_RenderTeacher(view_RenderTeacherInputProps) {
    const dispatch = view_RenderTeacherInputProps.dispatch;
    const teacher = view_RenderTeacherInputProps.teacher;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setDeleteHover = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsDeleteWindow = patternInput_1[1];
    const isDeleteWindow = patternInput_1[0];
    const children_2 = toList(delay(() => {
        let props_14, elms_1, elms, props, props_5, props_3, props_11, props_8;
        return append(singleton_2((props_14 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
            border: (((1 + "px ") + "solid") + " ") + "#808080",
            alignContent: "space-between",
        }], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton_1((props = ofArray([["className", "has-text-primary"], ["children", toText(interpolate("%P() - %P()", [teacher.FullName, teacher.Email]))]]), createElement("span", createObj(Helpers_combineClasses("", props))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_5 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append(singleton_2(["className", "is-size-7"]), delay(() => (patternInput[0] ? singleton_2(["children", "Ta bort"]) : empty()))))), createElement("span", createObj(Helpers_combineClasses("", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_11 = ofArray([["className", "is-narrow"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            onMouseEnter: (_arg1) => {
                setDeleteHover(true);
            },
            onMouseLeave: (_arg2) => {
                setDeleteHover(false);
            },
            onClick: (_arg3) => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            className: "px-2",
            children: Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", join(" ", ["hoverable"])], ["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["fas", "fa-times"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_11))))]), createElement("div", {
            className: "columns",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_14))))), delay(() => (isDeleteWindow ? singleton_2(createElement(View_RenderDeleteWindow, {
            confirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
                dispatch(new Types_Msg(2, teacher.Id));
            },
            notConfirmed: () => {
                setIsDeleteWindow(!isDeleteWindow);
            },
            text: "Är du säker på att du vill ta bort här extern tränare?",
        })) : empty())));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

export class View_AddModalSteps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TypeEmail", "SearchTeacher", "TeacherFound", "TeacherNotFound", "TeacherAlreadyAdded"];
    }
}

export function View_AddModalSteps$reflection() {
    return union_type("StudentExternalTeachers.View.AddModalSteps", [], View_AddModalSteps, () => [[], [], [["Item", Students_TeacherListItem$reflection()]], [], [["Item", Students_TeacherListItem$reflection()]]]);
}

export function View_RenderAddModal(view_RenderAddModalInputProps) {
    let props_43;
    const onClose = view_RenderAddModalInputProps.onClose;
    const onAdded = view_RenderAddModalInputProps.onAdded;
    const existingExternalTeacherIds = view_RenderAddModalInputProps.existingExternalTeacherIds;
    const patternInput = useFeliz_React__React_useState_Static_1505(new View_AddModalSteps(0));
    const step = patternInput[0];
    const setStep = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const email = patternInput_1[0];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Combine(equals(step, new View_AddModalSteps(1)) ? singleton.Bind(teachersApi().getTeacherByEmail(EmailModule_ofString(email)), (_arg1) => {
            const teacherOpt = _arg1;
            if (teacherOpt == null) {
                setStep(new View_AddModalSteps(3));
                return singleton.Zero();
            }
            else {
                const teacher = teacherOpt;
                if (contains(teacher.Id, existingExternalTeacherIds, {
                    Equals: (x, y) => (x === y),
                    GetHashCode: (x) => stringHash(x),
                })) {
                    setStep(new View_AddModalSteps(4, teacher));
                    return singleton.Zero();
                }
                else {
                    setStep(new View_AddModalSteps(2, teacher));
                    return singleton.Zero();
                }
            }
        }) : singleton.Zero(), singleton.Delay(() => singleton.Return()))));
    }, [step]);
    const props_45 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg1_1) => {
        onClose();
    }])))), (props_43 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms_1, props_19, elms_2, s_1, props_28, elms_3, props_34, elms_4, props_40, elms, props_8, props_6, props_4, value_11, props_14, props_12;
        switch (step.tag) {
            case 1: {
                return singleton_2((elms_1 = ofArray([loader("big"), (props_19 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg5) => {
                    onClose();
                }], ["className", "is-text"], ["children", "Avbryt"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_19))))]), createElement("div", {
                    className: "box",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })));
            }
            case 2: {
                const teacher_2 = step.fields[0];
                return singleton_2((elms_2 = ofArray([(s_1 = toText(interpolate("Det finns en användare med denna e-post: %P() %P()", [teacher_2.FullName, teacher_2.Email])), createElement("label", {
                    className: "label",
                    children: s_1,
                })), createElement("label", {
                    className: "label",
                    children: "Vill du koppla denna användare som extern tränare?",
                }), (props_28 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg6) => {
                    onClose();
                }], ["className", "is-text"], ["children", "Avbryt"]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg7) => {
                    onClose();
                }], ["onClick", (_arg8) => {
                    let teacher_1;
                    startImmediate((teacher_1 = teacher_2, singleton.Delay(() => singleton.Bind(studentsApi().addExternalTeacher(teacher_1.Id), (_arg2) => {
                        const result = _arg2;
                        return singleton.Combine((result.tag === 1) ? (Toastr_toastSuccess(result.fields[0], "Add coach error"), singleton.Zero()) : (onAdded(teacher_1), singleton.Zero()), singleton.Delay(() => singleton.Return()));
                    }))));
                }], ["className", "is-primary"], ["children", "Ok"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_28))))]), createElement("div", {
                    className: "box",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                })));
            }
            case 3: {
                return singleton_2((elms_3 = ofArray([createElement("label", {
                    className: "label",
                    children: "Det finns ingen användare med denna e-post.",
                }), (props_34 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg9) => {
                    onClose();
                }], ["className", "is-text"], ["children", "Avbryt"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_34))))]), createElement("div", {
                    className: "box",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                })));
            }
            case 4: {
                return singleton_2((elms_4 = ofArray([createElement("label", {
                    className: "label",
                    children: "Denna användare är redan kopplad som extern tränare.",
                }), (props_40 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg10) => {
                    onClose();
                }], ["className", "is-text"], ["children", "Avbryt"]]))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_40))))]), createElement("div", {
                    className: "box",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                })));
            }
            default: {
                return singleton_2((elms = ofArray([(props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", "Epost"]])))), (props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-small"], (value_11 = email, ["ref", (e_1) => {
                    if ((!(e_1 == null)) ? (!equals(e_1.value, value_11)) : false) {
                        e_1.value = value_11;
                    }
                }]), ["onChange", (ev) => {
                    patternInput_1[1](ev.target.value.trim());
                }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_6))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_8)))), (props_14 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg2_1) => {
                    onClose();
                }], ["className", "is-text"], ["children", "Avbryt"]])))), (props_12 = ofArray([["onClick", (_arg3) => {
                    onClose();
                }], ["disabled", isNullOrWhiteSpace(email)], ["onClick", (_arg4) => {
                    setStep(new View_AddModalSteps(1));
                }], ["className", "is-primary"], ["children", "Spara"]]), createElement("button", createObj(Helpers_combineClasses("button", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_14))))]), createElement("div", {
                    className: "box",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                })));
            }
        }
    }))))]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_43))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_45)));
}

export function View_Render(view_RenderInputProps) {
    const _user = view_RenderInputProps._user;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_RenderInputProps.studentId), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setIsAddWindow = patternInput_1[1];
    const props_8 = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_6, elms, elms_1;
        return append(singleton_2((props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = toList(delay(() => append(singleton_2(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Mina externa tränare"]]))))), delay(() => {
            const matchValue = state_1.ExternalTeachers;
            let pattern_matching_result, teachers, e;
            if (matchValue.tag === 1) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 2) {
                if (matchValue.fields[0].tag === 1) {
                    pattern_matching_result = 2;
                    e = matchValue.fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 1;
                    teachers = matchValue.fields[0].fields[0];
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton_2(loader("big"));
                }
                case 1: {
                    return map((teacher) => createElement(View_RenderTeacher, {
                        teacher: teacher,
                        dispatch: dispatch,
                    }), teachers);
                }
                case 2: {
                    return singleton_2(View_renderNotification(["className", "is-danger"], e));
                }
            }
        })))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_1(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["onClick", (_arg1) => {
            setIsAddWindow(true);
        }], ["className", "py-2"], ["className", "is-success"], ["children", "Lägg till extern tränare"]]))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))), delay(() => {
            const matchValue_1 = state_1.ExternalTeachers;
            let pattern_matching_result_1, teachers_1;
            if (matchValue_1.tag === 2) {
                if (matchValue_1.fields[0].tag === 0) {
                    pattern_matching_result_1 = 0;
                    teachers_1 = matchValue_1.fields[0].fields[0];
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const existingExternalTeacherIds = map_1((x) => x.Id, teachers_1);
                    return patternInput_1[0] ? singleton_2(createElement(View_RenderAddModal, {
                        existingExternalTeacherIds: existingExternalTeacherIds,
                        onAdded: (teacher_1) => {
                            dispatch(new Types_Msg(1, teacher_1));
                            setIsAddWindow(false);
                        },
                        onClose: () => {
                            setIsAddWindow(false);
                        },
                    })) : empty();
                }
                case 1: {
                    return singleton_2(null);
                }
            }
        }));
    }))))]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_8)));
}

