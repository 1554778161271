import { Convert_fromJson, Convert_serialize } from "./.fable/Fable.SimpleJson.3.21.0/Json.Converter.fs.js";
import { createTypeInfo } from "./.fable/Fable.SimpleJson.3.21.0/TypeInfo.Converter.fs.js";
import { Auth_SignedInUser$reflection } from "../Shared/Shared.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.1/Choice.js";
import { SimpleJson_tryParse } from "./.fable/Fable.SimpleJson.3.21.0/SimpleJson.fs.js";
import { interpolate, toText } from "./.fable/fable-library.3.2.1/String.js";

export function saveUser(user) {
    localStorage.setItem("loggedUser", Convert_serialize(user, createTypeInfo(Auth_SignedInUser$reflection())));
}

export function removeUser() {
    localStorage.removeItem("loggedUser");
}

export function getUser() {
    let matchValue;
    let matchValue_1;
    const arg00 = localStorage.getItem("loggedUser");
    try {
        matchValue_1 = (new FSharpResult$2(0, (matchValue = SimpleJson_tryParse(arg00), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(Auth_SignedInUser$reflection())) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })())));
    }
    catch (ex) {
        matchValue_1 = (new FSharpResult$2(1, ex.message));
    }
    if (matchValue_1.tag === 1) {
        return void 0;
    }
    else {
        return matchValue_1.fields[0];
    }
}

export function setItem(key, value) {
    localStorage.setItem(key, value);
}

export function tryGetItem(key) {
    const value = localStorage.getItem(key);
    if (value == null) {
        return void 0;
    }
    else {
        return value;
    }
}

export function getToken() {
    const user = getUser();
    if (user != null) {
        return user.AccessToken;
    }
    else {
        return "";
    }
}

export function getBearerToken() {
    return toText(interpolate("Bearer %s%P()", [getToken()]));
}

