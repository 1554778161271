import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { bool_type, class_type, list_type, union_type, unit_type, record_type, string_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { Settings_ChangePassword } from "../../Shared/Shared.fs.js";
import { settingsApi } from "../Communication.fs.js";
import { Validator$1__IsValid_Z4CF01147, Validator$1__Test, Validator$1__NotBlank_2B595, Validator$1__End_Z5E18B1E2, validateSync } from "../.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { isNullOrWhiteSpace } from "../.fable/fable-library.3.2.1/String.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList, exists } from "../.fable/fable-library.3.2.1/Seq.js";
import { isLetter, isDigit } from "../.fable/fable-library.3.2.1/Char.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Form_Field_errorsAsString, Form_Field_fieldStyleOnError, Form_Field_hasErrors, View_renderNotification } from "../Common.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { singleton as singleton_2, cons, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export class Types_Form extends Record {
    constructor(CurrentPassword, NewPassword, NewPasswordConfirmation) {
        super();
        this.CurrentPassword = CurrentPassword;
        this.NewPassword = NewPassword;
        this.NewPasswordConfirmation = NewPasswordConfirmation;
    }
}

export function Types_Form$reflection() {
    return record_type("Settings.ChangePassword.Types.Form", [], Types_Form, () => [["CurrentPassword", string_type], ["NewPassword", string_type], ["NewPasswordConfirmation", string_type]]);
}

export class Types_State extends Record {
    constructor(Form, Changing, FormErrors, IsNeedValidation) {
        super();
        this.Form = Form;
        this.Changing = Changing;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
    }
}

export function Types_State$reflection() {
    return record_type("Settings.ChangePassword.Types.State", [], Types_State, () => [["Form", Types_Form$reflection()], ["Changing", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CurrentPasswordChanged", "NewPasswordChanged", "NewPasswordConfirmationChanged", "ChangePassword"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Settings.ChangePassword.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_changePassword(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Settings_ChangePassword(state.Form.CurrentPassword, state.Form.NewPassword);
        return singleton.Bind(Remoting_handleNonAuth(settingsApi().changePassword(request)), (_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? ((response.fields[0].tag === 1) ? singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Current password is invalid")))) : singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Can not find account"))))) : singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))));
        });
    }), (_arg2) => singleton.Return(new Types_Msg(3, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => (new Types_Form(Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Gammalt lösenord krävs")(Validator$1__Test(t, "CurrentPassword", formInfo.CurrentPassword))), Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (value) => {
        if (value === "") {
            return true;
        }
        else if (!isNullOrWhiteSpace(value)) {
            if ((value.length >= 8) ? exists((arg00) => isDigit(arg00), value.split("")) : false) {
                return exists((arg00_1) => isLetter(arg00_1), value.split(""));
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    })("Lösenordet måste innehålla minst 1 bokstav och 1 siffra. Minsta längd är 8")(Validator$1__NotBlank_2B595(t, "Nytt lösenord krävs")(Validator$1__Test(t, "NewPassword", formInfo.NewPassword)))), Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (confirmPassword) => {
        if ((confirmPassword === "") ? (formInfo.NewPassword === "") : false) {
            return true;
        }
        else {
            return formInfo.NewPassword === confirmPassword;
        }
    })("Lösenordet matchar inte")(Validator$1__NotBlank_2B595(t, "Upprepa lösenord krävs")(Validator$1__Test(t, "NewPasswordConfirmation", formInfo.NewPasswordConfirmation)))))));
}

export function Validation_validateIf(needValidate, x) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

export const State_init = [new Types_State(new Types_Form("", "", ""), new DeferredResult$1(0), empty(), false), Cmd_none()];

export function State_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord;
    if (msg.tag === 1) {
        const patternInput_1 = Validation_validateIf(state.IsNeedValidation, (inputRecord_1 = state.Form, new Types_Form(inputRecord_1.CurrentPassword, msg.fields[0], inputRecord_1.NewPasswordConfirmation)));
        return [new Types_State(patternInput_1[0], state.Changing, patternInput_1[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        const patternInput_2 = Validation_validateIf(state.IsNeedValidation, (inputRecord_2 = state.Form, new Types_Form(inputRecord_2.CurrentPassword, inputRecord_2.NewPassword, msg.fields[0])));
        return [new Types_State(patternInput_2[0], state.Changing, patternInput_2[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Form, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Form, new DeferredResult$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new Types_State(state.Form, state.Changing, matchValue.fields[0], true), Cmd_none()];
            }
            else {
                return [new Types_State(matchValue.fields[0], new DeferredResult$1(1), empty(), true), Cmd_fromAsync(Cmd_changePassword(state))];
            }
        }
    }
    else {
        const patternInput = Validation_validateIf(state.IsNeedValidation, (inputRecord = state.Form, new Types_Form(msg.fields[0], inputRecord.NewPassword, inputRecord.NewPasswordConfirmation)));
        return [new Types_State(patternInput[0], state.Changing, patternInput[1], state.IsNeedValidation), Cmd_none()];
    }
}

export function View_renderError(state) {
    const matchValue = state.Changing;
    let pattern_matching_result, error;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return null;
        }
        case 1: {
            return View_renderNotification(["className", "is-success"], "Lösenordet har ändrats.");
        }
        case 2: {
            return View_renderNotification(["className", "is-danger"], error);
        }
    }
}

export function View_view(state, dispatch) {
    let elms_5, elms, props_6, props_2, props_4, elms_1, props_13, props_9, props_11, elms_2, props_20, props_16, props_18, elms_4, elms_3;
    const props_29 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-quarter"], ["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["children", "Byt lösenord"]])))), (elms_5 = singleton_2(createElement("form", {
        onSubmit: (x) => {
            x.preventDefault();
            dispatch(new Types_Msg(3, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray([(elms = singleton_2((props_6 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append(Form_Field_hasErrors("CurrentPassword")(state.FormErrors) ? singleton_1(["style", createObj(Form_Field_fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["name", "current-password"]), delay(() => append(singleton_1(["placeholder", "Gammalt lösenord"]), delay(() => singleton_1(["onChange", (ev) => {
            dispatch(new Types_Msg(0, ev.target.value));
        }]))))))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_2))))), (props_4 = ofArray([["style", {
            color: "#FF0000",
        }], ["children", Form_Field_errorsAsString("CurrentPassword")(state.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_4))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_6))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_2((props_13 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_9 = toList(delay(() => append(Form_Field_hasErrors("NewPassword")(state.FormErrors) ? singleton_1(["style", createObj(Form_Field_fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["name", "new-password"]), delay(() => append(singleton_1(["placeholder", "Nytt lösenord"]), delay(() => singleton_1(["onChange", (ev_1) => {
            dispatch(new Types_Msg(1, ev_1.target.value));
        }]))))))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_9))))), (props_11 = ofArray([["style", {
            color: "#FF0000",
        }], ["children", Form_Field_errorsAsString("NewPassword")(state.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_13))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_2 = singleton_2((props_20 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_16 = toList(delay(() => append(Form_Field_hasErrors("NewPasswordConfirmation")(state.FormErrors) ? singleton_1(["style", createObj(Form_Field_fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_1(["name", "new-password-confirmation"]), delay(() => append(singleton_1(["placeholder", "Upprepa lösenord"]), delay(() => singleton_1(["onChange", (ev_2) => {
            dispatch(new Types_Msg(2, ev_2.target.value));
        }]))))))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_16))))), (props_18 = ofArray([["style", {
            color: "#FF0000",
        }], ["children", Form_Field_errorsAsString("NewPasswordConfirmation")(state.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_18))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_20))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (elms_4 = singleton_2((elms_3 = singleton_2(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["children", "Byt lösenord"]]))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        })), View_renderError(state)]),
    })), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_29)));
}

export function Component_Render() {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init, (msg, state) => State_update(msg, state), []);
    return View_view(patternInput[0], patternInput[1]);
}

