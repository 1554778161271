import { createElement } from "react";
import * as react from "react";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { toPath, Route } from "../Router.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { Render as Render_1, RenderLogoutButton, RenderAccountButton } from "../HeaderCommon.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";

export function Render(renderInputProps) {
    let props_13, props_15, elms, props, props_2, props_4, props_6, props_8;
    const isActive = renderInputProps.isActive;
    const logOut = renderInputProps.logOut;
    const user = renderInputProps.user;
    const itemsRightTouchView = react.createElement(react.Fragment, {}, (props_13 = toList(delay(() => append(isActive(new Route(5)) ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", join(" ", ["is-hidden-desktop"])]), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["children", "Konto"]), delay(() => singleton(["href", toPath(new Route(5))]))))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_13)))), (props_15 = ofArray([["className", join(" ", ["is-hidden-desktop"])], ["className", "has-text-weight-bold"], ["children", "Logga ut"], ["onClick", (x) => {
        x.preventDefault();
        logOut();
    }]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_15)))));
    const itemsRightDesktopView = react.createElement(react.Fragment, {}, (elms = ofArray([RenderAccountButton(user), RenderLogoutButton(logOut)]), createElement("div", {
        className: "buttons",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })));
    const itemsLeft = react.createElement(react.Fragment, {}, (props = toList(delay(() => append(isActive(new Route(7)) ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["children", "Grupper"]), delay(() => singleton(["href", toPath(new Route(7))]))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props)))), (props_2 = toList(delay(() => append(isActive(new Route(10)) ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["children", "Utövare"]), delay(() => singleton(["href", toPath(new Route(10))]))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_2)))), (props_4 = toList(delay(() => append(isActive(new Route(18)) ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["children", "Tränare"]), delay(() => singleton(["href", toPath(new Route(18))]))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_4)))), (props_6 = toList(delay(() => append(isActive(new Route(27)) ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["children", "Pass"]), delay(() => singleton(["href", toPath(new Route(27))]))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_6)))), (props_8 = toList(delay(() => append(isActive(new Route(21)) ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["children", "Program"]), delay(() => singleton(["href", toPath(new Route(21))]))))))))), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_8)))));
    return createElement(Render_1, {
        itemsLeft: itemsLeft,
        itemsRightDesktopView: itemsRightDesktopView,
        itemsRightTouchView: itemsRightTouchView,
    });
}

