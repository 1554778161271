import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { Workout_getWorkoutTotalDuration, DurationModule_toHMSString, Workout_getWorkoutColor, WorkoutTemplates_WorkoutTemplateInfo$reflection } from "../../Shared/Shared.fs.js";
import { record_type, union_type, string_type, list_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { workoutTemplatesApi } from "../Communication.fs.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { CollectedDragProps, DragItem$1, DragSpec$1, useDrag } from "./ReactDnd.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { empty, map, singleton as singleton_2, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { loader } from "../Loader.fs.js";

export class Types_State extends Record {
    constructor(WorkoutTemplates) {
        super();
        this.WorkoutTemplates = WorkoutTemplates;
    }
}

export function Types_State$reflection() {
    return record_type("PassPanel.Types.State", [], Types_State, () => [["WorkoutTemplates", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WorkoutTemplates_WorkoutTemplateInfo$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(WorkoutTemplates_WorkoutTemplateInfo$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadWorkoutTemplates"];
    }
}

export function Types_Msg$reflection() {
    return union_type("PassPanel.Types.Msg", [], Types_Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WorkoutTemplates_WorkoutTemplateInfo$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(WorkoutTemplates_WorkoutTemplateInfo$reflection())]], [["ErrorValue", string_type]]]))]]]);
}

export const Cmd_load = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(workoutTemplatesApi().listSchool()), (_arg1) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export const State_init = [new Types_State(new DeferredResult$1(1)), Cmd_batch(singleton_1(Cmd_OfFunc_result(new Types_Msg(0, new AsyncOperationStatus$1(0)))))];

export function State_update(msg, state) {
    if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
        else {
            return [new Types_State(new DeferredResult$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
        }
    }
    else {
        return [new Types_State(new DeferredResult$1(1)), Cmd_fromAsync(Cmd_load)];
    }
}

export function View_PassItem(workoutTemplate) {
    let props_8, props_2, props_6, props_4;
    const patternInput = useDrag(ofArray([new DragSpec$1(0, "WorkoutTemplate"), new DragSpec$1(1, new DragItem$1("WorkoutTemplate", workoutTemplate)), new DragSpec$1(2, (mon) => (new CollectedDragProps(mon.isDragging())))]));
    const workoutTemplateColor = Workout_getWorkoutColor(workoutTemplate.Workout);
    const props_10 = ofArray([["style", {
        borderRight: (((2 + "px ") + "solid") + " ") + workoutTemplateColor,
    }], ["className", "py-1"], ["className", "px-1"], ["className", "my-2"], ["ref", patternInput[1]], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "py-0"], ["className", "px-0"], ["className", "is-gapless"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["style", {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }], ["className", "py-0"], ["className", "px-0"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", singleton_1(["children", workoutTemplate.Workout.Title]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_6 = ofArray([["className", "is-narrow"], ["className", "py-0"], ["className", "px-0"], ["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_4 = singleton_1(["children", DurationModule_toHMSString(Workout_getWorkoutTotalDuration(workoutTemplate.Workout))]), createElement("span", createObj(Helpers_combineClasses("", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_8))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_10)));
}

export function View_Render() {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init, (msg, state) => State_update(msg, state), []);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const isVisible = patternInput_1[0];
    const props_10 = ofArray([["className", "mb-4"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_5, props_3;
        return append(singleton_2((props_5 = ofArray([["className", "is-size-6"], ["className", "has-background-primary"], ["onClick", (_arg1) => {
            patternInput_1[1](!isVisible);
        }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("card-header-title", ofArray([["className", "px-2"], ["className", "py-2"], ["className", "has-text-weight-light"], ["className", "has-text-white"], ["children", "Pass"]])))), (props_3 = ofArray([["className", "has-text-white"], ["children", Interop_reactApi.Children.toArray([createElement("i", createObj(toList(delay(() => (isVisible ? singleton_2(["className", join(" ", ["fa", "fa-chevron-up"])]) : singleton_2(["className", join(" ", ["fa", "fa-chevron-down"])]))))))])]]), createElement("span", createObj(Helpers_combineClasses("card-header-icon", props_3))))])]]), createElement("header", createObj(Helpers_combineClasses("card-header", props_5))))), delay(() => {
            let props_8;
            return isVisible ? singleton_2((props_8 = ofArray([["className", "is-size-7"], ["className", "px-2"], ["className", "py-1"], ["style", {
                maxHeight: 602 + "px",
                overflowY: "auto",
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue = patternInput[0].WorkoutTemplates;
                let pattern_matching_result, passes, e;
                if (matchValue.tag === 1) {
                    pattern_matching_result = 0;
                }
                else if (matchValue.tag === 2) {
                    if (matchValue.fields[0].tag === 1) {
                        pattern_matching_result = 2;
                        e = matchValue.fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result = 1;
                        passes = matchValue.fields[0].fields[0];
                    }
                }
                else {
                    pattern_matching_result = 0;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return singleton_2(loader("big"));
                    }
                    case 1: {
                        return map((pass) => createElement(View_PassItem, pass), passes);
                    }
                    case 2: {
                        return singleton_2(createElement("span", {
                            children: [e],
                        }));
                    }
                }
            }))))]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_8))))) : empty();
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("card", props_10)));
}

