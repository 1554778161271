import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { union_type, tuple_type, record_type, option_type, int32_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { ScheduleWeek_ScheduleWeek_Default_Z524259A4, Calendar_periodWeeksRange, GroupIdModule_fromString, DurationModule_toHMSString, GroupIdModule_toString, Workout_getWorkoutTotalDuration, DurationModule_toInt, DurationModule_fromInt, ScheduleHelpers_deleteScheduleWeeksPlan, ScheduleHelpers_addUpdateScheduleWeeksPlan, DayPlan_DayPlan, ScheduleHelpers_tryFindScheduleWeeksDayPlan, Schedules_ScheduleInfo, DaySegment_DaySegment$reflection, DayPlan_DayPlan$reflection, Schedules_ScheduleInfo$reflection } from "../../Shared/Shared.fs.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { tryFind, tryItem, sum, sumBy } from "../.fable/fable-library.3.2.1/Array.js";
import { createObj, equals } from "../.fable/fable-library.3.2.1/Util.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.fs.js";
import { schedulesApi, groupsApi } from "../Communication.fs.js";
import { cons, singleton as singleton_1, ofArray, map as map_1, tryHead, empty } from "../.fable/fable-library.3.2.1/List.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { defaultArg, bind, map } from "../.fable/fable-library.3.2.1/Option.js";
import { View_renderSelectOption } from "../Common.fs.js";
import { Render, defaultPeriod } from "./SchedulePeriodSelect.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { collect, empty as empty_1, map as map_2, append, singleton as singleton_2, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { interpolate, toText, join } from "../.fable/fable-library.3.2.1/String.js";
import { loader } from "../Loader.fs.js";
import { Route, toPath } from "../Router.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { View_Render } from "../Components/PassPanel.fs.js";
import { WeekParams, Render as Render_1 } from "./ScheduleWeek.fs.js";
import { ScheduleDayActions } from "./ScheduleDayBox.fs.js";

export class Types_State extends Record {
    constructor(PeriodWeek, Schedule) {
        super();
        this.PeriodWeek = PeriodWeek;
        this.Schedule = Schedule;
    }
}

export function Types_State$reflection() {
    return record_type("ScheduleCalendar.Types.State", [], Types_State, () => [["PeriodWeek", option_type(int32_type)], ["Schedule", Schedules_ScheduleInfo$reflection()]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdatedPlan", "MovedPlan", "DeletedPlan"];
    }
}

export function Types_Msg$reflection() {
    return union_type("ScheduleCalendar.Types.Msg", [], Types_Msg, () => [[["Item1", int32_type], ["Item2", int32_type], ["Item3", DayPlan_DayPlan$reflection()]], [["Item1", tuple_type(int32_type, int32_type, DaySegment_DaySegment$reflection())], ["Item2", tuple_type(int32_type, int32_type, DaySegment_DaySegment$reflection())]], [["Item1", int32_type], ["Item2", int32_type], ["Item3", DaySegment_DaySegment$reflection()]]]);
}

export function State_init(periodWeek, schedule) {
    return [new Types_State(periodWeek, schedule), Cmd_none()];
}

export function State_update(msg, state) {
    let newDayPlan;
    const withWeeks = (weeks, state_1) => {
        let inputRecord;
        return new Types_State(state_1.PeriodWeek, (inputRecord = state_1.Schedule, new Schedules_ScheduleInfo(inputRecord.Id, inputRecord.TeacherId, inputRecord.Title, inputRecord.IsGlobal, weeks)));
    };
    switch (msg.tag) {
        case 1: {
            const weekDayFrom = msg.fields[1][1] | 0;
            const periodWeekFrom = msg.fields[1][0] | 0;
            const daySegmentFrom = msg.fields[1][2];
            const planFromOpt = ScheduleHelpers_tryFindScheduleWeeksDayPlan(periodWeekFrom, weekDayFrom, daySegmentFrom, state.Schedule.ScheduleWeeks);
            return [withWeeks(((planFromOpt == null) ? ((x) => x) : (newDayPlan = (new DayPlan_DayPlan(msg.fields[0][2], planFromOpt.Workout)), (arg) => ScheduleHelpers_addUpdateScheduleWeeksPlan(msg.fields[0][0], msg.fields[0][1], newDayPlan, ScheduleHelpers_deleteScheduleWeeksPlan(periodWeekFrom, weekDayFrom, daySegmentFrom, arg))))(state.Schedule.ScheduleWeeks), state), Cmd_none()];
        }
        case 2: {
            return [withWeeks(ScheduleHelpers_deleteScheduleWeeksPlan(msg.fields[0], msg.fields[1], msg.fields[2], state.Schedule.ScheduleWeeks), state), Cmd_none()];
        }
        default: {
            return [withWeeks(ScheduleHelpers_addUpdateScheduleWeeksPlan(msg.fields[0], msg.fields[1], msg.fields[2], state.Schedule.ScheduleWeeks), state), Cmd_none()];
        }
    }
}

export function View_RenderSummary(view_RenderSummaryInputProps) {
    let periodWeekOpt, group_2, props_8, props_6, props_4, props_16, props_14, props_12, elms, elms_1, props_29;
    const periodWeek = view_RenderSummaryInputProps.periodWeek;
    const current = DurationModule_fromInt(sumBy((scheduleWeek) => sumBy((scheduleDay) => sumBy((dayPlan) => DurationModule_toInt(Workout_getWorkoutTotalDuration(dayPlan.Workout)), scheduleDay.DayPlans, {
        GetZero: () => 0,
        Add: (x_2, y) => (x_2 + y),
    }), scheduleWeek.ScheduleDays, {
        GetZero: () => 0,
        Add: (x_3, y_1) => (x_3 + y_1),
    }), view_RenderSummaryInputProps.schedule.ScheduleWeeks.filter((x) => {
        if (equals(x.PeriodWeek, periodWeek)) {
            return true;
        }
        else {
            return equals(periodWeek, void 0);
        }
    }), {
        GetZero: () => 0,
        Add: (x_4, y_2) => (x_4 + y_2),
    })) | 0;
    const groupsRequest = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(groupsApi().list()), []);
    const groupsList = (groupsRequest.tag === 2) ? groupsRequest.fields[0] : empty();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setSelectedGroupId = patternInput[1];
    const selectedGroupId = patternInput[0];
    const firstGroupId = map((x_6) => x_6.Id, tryHead(groupsList));
    useReact_useEffect_Z101E1A95(() => {
        setSelectedGroupId(firstGroupId);
    }, [groupsList]);
    const groupOptions = map_1((group) => View_renderSelectOption(GroupIdModule_toString(group.Id), group.Title), groupsList);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(defaultPeriod);
    const selectedPeriod = patternInput_1[0];
    const groupRequest = useFeliz_React__React_useDeferred_Static_2344FC52(singleton.Delay(() => {
        if (selectedGroupId == null) {
            return singleton.Return(void 0);
        }
        else {
            const selectedGroupId_1 = selectedGroupId;
            return singleton.Bind(Remoting_handleNonAuth(groupsApi().get(selectedGroupId_1)), (_arg1) => singleton.Return(_arg1));
        }
    }), [selectedGroupId]);
    const groupPlanDuration = map((hours) => DurationModule_fromInt(hours * 3600), bind((periodWeekOpt = periodWeek, (periodPlan) => ((periodWeekOpt == null) ? sum(periodPlan.WeekHours, {
        GetZero: () => 0,
        Add: (x_8, y_3) => (x_8 + y_3),
    }) : tryItem(periodWeekOpt, periodPlan.WeekHours))), bind((yearPlan) => tryItem(selectedPeriod.Period, yearPlan.PeriodPlans), bind((array_4) => tryFind((yearPlan_1) => (yearPlan_1.Year === selectedPeriod.PeriodYear), array_4), (groupRequest.tag === 2) ? ((groupRequest.fields[0] != null) ? (group_2 = groupRequest.fields[0], group_2.YearPlans) : (void 0)) : (void 0)))));
    const props_32 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("panel-heading", ofArray([["className", "is-size-6"], ["className", "has-text-weight-light"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Summering"]])))), (props_8 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["style", {
        width: 100 + "vh",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-narrow"], ["children", "Nuvarande:"]])))), (props_4 = ofArray([["className", "has-text-right"], ["children", DurationModule_toHMSString(current)]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))])]), createElement("div", createObj(Helpers_combineClasses("panel-block", props_8)))), (props_16 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["style", {
        width: 100 + "vh",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-narrow"], ["children", "Planerad:"]])))), (props_12 = ofArray([["className", "has-text-right"], ["children", defaultArg(map((x_11) => DurationModule_toHMSString(x_11), groupPlanDuration), "N/A")]]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_14))))])]), createElement("div", createObj(Helpers_combineClasses("panel-block", props_16)))), createElement("div", {
        className: "panel-block",
        children: "Grupp och period för att visa planerad tid:",
    }), (elms = toList(delay(() => {
        let props_26, props_24, patternInput_2;
        return (groupsRequest.tag === 3) ? singleton_2(createElement("span", {
            children: ["Error on loading"],
        })) : ((groupsRequest.tag === 2) ? singleton_2((props_26 = ofArray([["style", {
            width: 100 + "vh",
        }], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(patternInput_2 = Helpers_extractClasses(ofArray([["className", "is-fullwidth"], ["className", "is-small"], ["className", "has-text-right"], ["value", defaultArg(map((x_12) => GroupIdModule_toString(x_12), selectedGroupId), "")], ["onChange", (ev) => {
            setSelectedGroupId(GroupIdModule_fromString(ev.target.value));
        }], ["children", Interop_reactApi.Children.toArray(Array.from(groupOptions))]])), createElement("div", {
            className: join(" ", cons("select", patternInput_2[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_2[1]))]),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_26))))) : singleton_2(loader("big")));
    })), createElement("div", {
        className: "panel-block",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton_1((props_29 = ofArray([["style", {
        width: 100 + "vh",
    }], ["children", Interop_reactApi.Children.toArray([createElement(Render, {
        value: selectedPeriod,
        onChange: patternInput_1[1],
        selectOptions: singleton_1(["className", "is-small"]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("field", props_29))))), createElement("div", {
        className: "panel-block",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]);
    return createElement("nav", createObj(Helpers_combineClasses("panel", props_32)));
}

export function View_renderNavigation(state) {
    let props_3, props_14, props_12, children;
    const props_16 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-narrow"], ["className", "has-text-weight-bold"], ["className", "pl-3"], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        className: "",
        children: "Programmets utformning: ",
    }), createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", state.Schedule.Title]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_3)))), (props_14 = ofArray([["className", "pl-0"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-toggle"], ["className", "is-small"], ["children", Interop_reactApi.Children.toArray([(children = toList(delay(() => append(map_2((periodWeek) => {
        const props_6 = toList(delay(() => append(equals(state.PeriodWeek, periodWeek) ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
            children: toText(interpolate("Vecka %P()", [periodWeek + 1])),
            href: toPath(new Route(24, state.Schedule.Id, periodWeek)),
        })])])))));
        return createElement("li", createObj(Helpers_combineClasses("", props_6)));
    }, Calendar_periodWeeksRange), delay(() => {
        let props_9;
        return singleton_2((props_9 = toList(delay(() => append(equals(state.PeriodWeek, void 0) ? singleton_2(["className", "is-active"]) : empty_1(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("a", {
            children: "Översikt",
            href: toPath(new Route(23, state.Schedule.Id)),
        })])]))))), createElement("li", createObj(Helpers_combineClasses("", props_9)))));
    })))), createElement("ul", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_16)));
}

export function View_RenderMainLayout(view_RenderMainLayoutInputProps) {
    let props_4, elms, props_2;
    const renderContent = view_RenderMainLayoutInputProps.renderContent;
    const schedule = view_RenderMainLayoutInputProps.schedule;
    const periodWeek = view_RenderMainLayoutInputProps.periodWeek;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(periodWeek, schedule), (msg, state) => State_update(msg, state), [periodWeek, schedule]);
    const state_1 = patternInput[0];
    const elms_1 = ofArray([View_renderNavigation(state_1), (props_4 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = singleton_1(renderContent(state_1)(patternInput[1])), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_2 = ofArray([["className", "is-2"], ["className", "ml-2"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            position: "sticky",
            top: 10 + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement(View_Render, null), createElement(View_RenderSummary, {
            periodWeek: state_1.PeriodWeek,
            schedule: state_1.Schedule,
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_4))))]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    });
}

export function View_renderPeriodLayout(state, dispatch) {
    let props;
    const elms = singleton_1((props = singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((periodWeek) => singleton_2(createElement(Render_1, new WeekParams(state.Schedule.Id, defaultArg(tryFind((x) => (x.PeriodWeek === periodWeek), state.Schedule.ScheduleWeeks), ScheduleWeek_ScheduleWeek_Default_Z524259A4(periodWeek)), new ScheduleDayActions((arg) => {
        let tupledArg;
        dispatch((tupledArg = arg, new Types_Msg(0, tupledArg[0], tupledArg[1], tupledArg[2])));
    }, (arg_1) => {
        let tupledArg_1;
        dispatch((tupledArg_1 = arg_1, new Types_Msg(1, tupledArg_1[0], tupledArg_1[1])));
    }, (arg_2) => {
        let tupledArg_2;
        dispatch((tupledArg_2 = arg_2, new Types_Msg(2, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2])));
    })))), Calendar_periodWeeksRange)))))]), createElement("div", createObj(Helpers_combineClasses("column", props)))));
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function View_renderWeekLayout(periodWeek, state, dispatch) {
    const scheduleWeek = defaultArg(tryFind((x) => (x.PeriodWeek === periodWeek), state.Schedule.ScheduleWeeks), ScheduleWeek_ScheduleWeek_Default_Z524259A4(periodWeek));
    const children = toList(delay(() => singleton_2(createElement(Render_1, new WeekParams(state.Schedule.Id, scheduleWeek, new ScheduleDayActions((arg) => {
        let tupledArg;
        dispatch((tupledArg = arg, new Types_Msg(0, tupledArg[0], tupledArg[1], tupledArg[2])));
    }, (arg_1) => {
        let tupledArg_1;
        dispatch((tupledArg_1 = arg_1, new Types_Msg(1, tupledArg_1[0], tupledArg_1[1])));
    }, (arg_2) => {
        let tupledArg_2;
        dispatch((tupledArg_2 = arg_2, new Types_Msg(2, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2])));
    }))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function View_RenderMainLayoutWithLoad(view_RenderMainLayoutWithLoadInputProps) {
    const renderContent = view_RenderMainLayoutWithLoadInputProps.renderContent;
    const periodWeek = view_RenderMainLayoutWithLoadInputProps.periodWeek;
    const scheduleId = view_RenderMainLayoutWithLoadInputProps.scheduleId;
    const schedule = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(schedulesApi().get(scheduleId)), [scheduleId]);
    switch (schedule.tag) {
        case 3: {
            const value = schedule.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            return createElement(View_RenderMainLayout, {
                periodWeek: periodWeek,
                schedule: schedule.fields[0],
                renderContent: renderContent,
            });
        }
        default: {
            return loader("big");
        }
    }
}

export function View_RenderPeriod(view_RenderPeriodInputProps) {
    const _arg1 = view_RenderPeriodInputProps._arg1;
    const scheduleId = view_RenderPeriodInputProps.scheduleId;
    return createElement(View_RenderMainLayoutWithLoad, {
        scheduleId: scheduleId,
        periodWeek: void 0,
        renderContent: (state) => ((dispatch) => View_renderPeriodLayout(state, dispatch)),
    });
}

export function View_RenderWeek(view_RenderWeekInputProps) {
    const _arg1 = view_RenderWeekInputProps._arg1;
    const periodWeek = view_RenderWeekInputProps.periodWeek;
    const scheduleId = view_RenderWeekInputProps.scheduleId;
    return createElement(View_RenderMainLayoutWithLoad, {
        scheduleId: scheduleId,
        periodWeek: periodWeek,
        renderContent: (state) => ((dispatch) => View_renderWeekLayout(periodWeek, state, dispatch)),
    });
}

