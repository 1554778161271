import { CollectedDragProps, DragItem$1, DragSpec$1, useDrag } from "./ReactDnd.fs.js";
import { ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { interpolate, toText } from "../.fable/fable-library.3.2.1/String.js";
import { Workout_getWorkoutTotalDuration, DurationModule_toHMSString, Workout_getWorkoutColor, Shape_getShapeIntValue } from "../../Shared/Shared.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";

export function Render(renderInputProps) {
    const dragType = renderInputProps.dragType;
    const shapeOpt = renderInputProps.shapeOpt;
    const comment = renderInputProps.comment;
    const workout = renderInputProps.workout;
    const patternInput = useDrag(ofArray([new DragSpec$1(0, dragType), new DragSpec$1(1, new DragItem$1(dragType, renderInputProps.dragData)), new DragSpec$1(2, (mon) => (new CollectedDragProps(mon.isDragging())))]));
    const title = (shapeOpt == null) ? workout.Title : toText(interpolate("%P() (%P())", [workout.Title, Shape_getShapeIntValue(shapeOpt)]));
    const props_3 = ofArray([["ref", patternInput[1]], ["className", "px-1"], ["className", "py-2"], ["className", "my-0"], ["className", "has-background-grey-lighter"], ["style", {
        borderRight: (((2 + "px ") + "solid") + " ") + Workout_getWorkoutColor(workout),
        width: 100 + "%",
        height: 5.4 + "rem",
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "hidden",
        whiteSpace: "pre-line",
        fontSize: 0.7 + "em",
        lineHeight: 0.95 + "rem",
    }], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        children: [title],
    }), createElement("div", {
        style: {
            opacity: 0.8,
            textAlign: "right",
        },
        children: DurationModule_toHMSString(Workout_getWorkoutTotalDuration(workout)),
    }), createElement("div", {
        style: {
            opacity: 0.8,
            fontSize: 0.6 + "em",
        },
        children: comment,
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_3)));
}

