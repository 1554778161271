import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { DayPlan_DayPlan, DaySegment_DaySegment$reflection, DayPlan_DayPlan$reflection, Schedules_ScheduleDeletePlanRequest, Schedules_ScheduleMovePlanRequest, Schedules_ScheduleUpdatePlanRequest } from "../../Shared/Shared.fs.js";
import { Toastr_toastError, Remoting_handleNonAuth } from "../Extensions.fs.js";
import { schedulesApi } from "../Communication.fs.js";
import { Record } from "../.fable/fable-library.3.2.1/Types.js";
import { record_type, lambda_type, unit_type, tuple_type, int32_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { CollectedDropProps, DropSpec$2, useDrop } from "../Components/ReactDnd.fs.js";
import { startImmediate } from "../.fable/fable-library.3.2.1/Async.js";
import { ofArray, item } from "../.fable/fable-library.3.2.1/List.js";
import { createElement } from "react";
import * as react from "react";
import { empty, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { inlineSmall } from "../Loader.fs.js";
import { Render as Render_1 } from "../Components/WorkoutBlock.fs.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Render as Render_2 } from "../Components/DayPlanEdit.fs.js";

export function updatePlan(scheduleDayKey, dayPlan, onSuccess, onError) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Schedules_ScheduleUpdatePlanRequest(scheduleDayKey.ScheduleId, scheduleDayKey.PeriodWeek, scheduleDayKey.WeekDay, dayPlan);
        return singleton.Bind(Remoting_handleNonAuth(schedulesApi().updatePlan(request)), (_arg1) => {
            if (_arg1.tag === 0) {
                onSuccess();
                return singleton.Zero();
            }
            else {
                Toastr_toastError("Save error", "");
                onError();
                return singleton.Zero();
            }
        });
    }), (_arg2) => {
        Toastr_toastError("Save error", "");
        onError();
        return singleton.Zero();
    }));
}

export function movePlan(scheduleId, toDay_0, toDay_1, toDay_2, fromDay_0, fromDay_1, fromDay_2, onSuccess, onError) {
    const toDay = [toDay_0, toDay_1, toDay_2];
    const fromDay = [fromDay_0, fromDay_1, fromDay_2];
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Schedules_ScheduleMovePlanRequest(scheduleId, toDay[0], toDay[1], toDay[2], fromDay[0], fromDay[1], fromDay[2]);
        return singleton.Bind(Remoting_handleNonAuth(schedulesApi().movePlan(request)), (_arg1) => {
            if (_arg1.tag === 0) {
                onSuccess();
                return singleton.Zero();
            }
            else {
                Toastr_toastError("Move error", "");
                onError();
                return singleton.Zero();
            }
        });
    }), (_arg2) => {
        Toastr_toastError("Move error", "");
        onError();
        return singleton.Zero();
    }));
}

export function deletePlan(scheduleDayKey, daySegment, onSuccess, onError) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Schedules_ScheduleDeletePlanRequest(scheduleDayKey.ScheduleId, scheduleDayKey.PeriodWeek, scheduleDayKey.WeekDay, daySegment);
        return singleton.Bind(Remoting_handleNonAuth(schedulesApi().deletePlan(request)), (_arg1) => {
            if (_arg1.tag === 0) {
                onSuccess();
                return singleton.Zero();
            }
            else {
                Toastr_toastError("Delete error", "");
                onError();
                return singleton.Zero();
            }
        });
    }), (_arg2) => {
        Toastr_toastError("Delete error", "");
        onError();
        return singleton.Zero();
    }));
}

export class ScheduleDayActions extends Record {
    constructor(UpdatedPlan, MovedPlan, DeletedPlan) {
        super();
        this.UpdatedPlan = UpdatedPlan;
        this.MovedPlan = MovedPlan;
        this.DeletedPlan = DeletedPlan;
    }
}

export function ScheduleDayActions$reflection() {
    return record_type("ScheduleDayBox.ScheduleDayActions", [], ScheduleDayActions, () => [["UpdatedPlan", lambda_type(tuple_type(int32_type, int32_type, DayPlan_DayPlan$reflection()), unit_type)], ["MovedPlan", lambda_type(tuple_type(tuple_type(int32_type, int32_type, DaySegment_DaySegment$reflection()), tuple_type(int32_type, int32_type, DaySegment_DaySegment$reflection())), unit_type)], ["DeletedPlan", lambda_type(tuple_type(int32_type, int32_type, DaySegment_DaySegment$reflection()), unit_type)]]);
}

export function Render(renderInputProps) {
    const actions = renderInputProps.actions;
    const daySegment = renderInputProps.daySegment;
    const scheduleDayKey = renderInputProps.scheduleDayKey;
    const dayPlan = renderInputProps.dayPlan;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsSaving = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        setIsSaving(false);
    }, [dayPlan, scheduleDayKey]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setEditModel = patternInput_1[1];
    const editModel = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_4 = useDrop(ofArray([new DropSpec$2(0, (dayPlan != null) ? [] : ["WorkoutTemplate", "DayPlan"]), new DropSpec$2(1, (mon) => {
        patternInput_2[1](mon.isOver());
        const canDrop = mon.canDrop();
        return new CollectedDropProps(mon.isOver(), canDrop);
    }), new DropSpec$2(2, (dragItem) => {
        const matchValue = dragItem.dragType;
        switch (matchValue) {
            case "WorkoutTemplate": {
                setIsSaving(true);
                const dayPlan_1 = new DayPlan_DayPlan(daySegment, dragItem.dragSrc.Workout);
                startImmediate(updatePlan(scheduleDayKey, dayPlan_1, () => {
                    actions.UpdatedPlan([scheduleDayKey.PeriodWeek, scheduleDayKey.WeekDay, dayPlan_1]);
                    setIsSaving(false);
                }, () => {
                    setIsSaving(false);
                }));
                break;
            }
            case "DayPlan": {
                const dragSrc = dragItem.dragSrc;
                const patternInput_3 = [item(0, dragSrc), item(1, dragSrc)];
                const scheduleDayKeyFrom = patternInput_3[1];
                setIsSaving(true);
                const dayTo = [scheduleDayKey.PeriodWeek, scheduleDayKey.WeekDay, daySegment];
                const dayFrom = [scheduleDayKeyFrom.PeriodWeek, scheduleDayKeyFrom.WeekDay, patternInput_3[0].DaySegment];
                startImmediate(movePlan(scheduleDayKey.ScheduleId, dayTo[0], dayTo[1], dayTo[2], dayFrom[0], dayFrom[1], dayFrom[2], () => {
                    actions.MovedPlan([dayTo, dayFrom]);
                    setIsSaving(false);
                }, () => {
                    setIsSaving(false);
                }));
                break;
            }
            default: {
            }
        }
    })]));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => {
        let props_1;
        return append(singleton_1((props_1 = ofArray([["ref", patternInput_4[1]], ["className", "py-1"], ["className", "px-0"], ["className", "mx-1"], ["style", createObj(toList(delay(() => append(singleton_1(["borderRadius", 6 + "px"]), delay(() => (patternInput_2[0] ? singleton_1(["backgroundColor", "#dbdbdb70"]) : empty()))))))], ["onClick", (_arg1) => {
            setEditModel(dayPlan);
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            if (dayPlan == null) {
                return patternInput[0] ? singleton_1(inlineSmall) : singleton_1(createElement("div", {
                    style: {
                        padding: (((3 + "rem") + " ") + 0) + "px",
                    },
                }));
            }
            else {
                const x = dayPlan;
                return singleton_1(createElement(Render_1, {
                    workout: x.Workout,
                    comment: x.Workout.Comment,
                    shapeOpt: void 0,
                    dragType: "DayPlan",
                    dragData: ofArray([x, scheduleDayKey]),
                }));
            }
        }))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_1))))), delay(() => {
            if (editModel == null) {
                return singleton_1(null);
            }
            else {
                const dayPlan_3 = editModel;
                return singleton_1(createElement(Render_2, {
                    dayPlan: dayPlan_3,
                    onSave: (dayPlan_2) => {
                        startImmediate(updatePlan(scheduleDayKey, dayPlan_2, () => {
                            actions.UpdatedPlan([scheduleDayKey.PeriodWeek, scheduleDayKey.WeekDay, dayPlan_2]);
                            setEditModel(void 0);
                        }, () => {
                        }));
                    },
                    onImplement: void 0,
                    onDelete: () => {
                        startImmediate(deletePlan(scheduleDayKey, daySegment, () => {
                            actions.DeletedPlan([scheduleDayKey.PeriodWeek, scheduleDayKey.WeekDay, daySegment]);
                            setEditModel(void 0);
                        }, () => {
                        }));
                    },
                    onCancel: () => {
                        setEditModel(void 0);
                    },
                }));
            }
        }));
    })));
}

