import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { GroupIdModule_toString, UserIdModule_toString, StudentDays_ScheduleAssignMode, StudentDays_ScheduleAssignRequest, StudentDays_ScheduleAssignMode$reflection, Calendar_PeriodInfo$reflection, Students_StudentListItem$reflection, Groups_GroupListItem$reflection, Schedules_ScheduleInfo$reflection } from "../../Shared/Shared.fs.js";
import { bool_type, record_type, union_type, string_type, unit_type, class_type, list_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, View_Cmd_toastSuccess, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { FSharpSet__get_IsEmpty, FSharpSet__Contains, FSharpSet__Remove, FSharpSet__Add, empty, toList } from "../.fable/fable-library.3.2.1/Set.js";
import { studentsApi, groupsApi, schedulesApi, studentDaysApi } from "../Communication.fs.js";
import { equals, createObj, comparePrimitives } from "../.fable/fable-library.3.2.1/Util.js";
import { Render, defaultPeriod } from "./SchedulePeriodSelect.fs.js";
import { Cmd_OfFunc_result, Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton as singleton_2, empty as empty_1, cons, ofArray, fold, filter, map } from "../.fable/fable-library.3.2.1/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { createElement } from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { collect, map as map_1, singleton as singleton_1, append, delay, toList as toList_1 } from "../.fable/fable-library.3.2.1/Seq.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_State extends Record {
    constructor(Schedule, Groups, Students, SelectedGroupIds, SelectedStudentIds, SelectedPeriod, SelectedScheduleAssignMode, AssignProgress) {
        super();
        this.Schedule = Schedule;
        this.Groups = Groups;
        this.Students = Students;
        this.SelectedGroupIds = SelectedGroupIds;
        this.SelectedStudentIds = SelectedStudentIds;
        this.SelectedPeriod = SelectedPeriod;
        this.SelectedScheduleAssignMode = SelectedScheduleAssignMode;
        this.AssignProgress = AssignProgress;
    }
}

export function Types_State$reflection() {
    return record_type("ScheduleAssign.Types.State", [], Types_State, () => [["Schedule", Schedules_ScheduleInfo$reflection()], ["Groups", list_type(Groups_GroupListItem$reflection())], ["Students", list_type(Students_StudentListItem$reflection())], ["SelectedGroupIds", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")])], ["SelectedStudentIds", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [class_type("System.Guid")])], ["SelectedPeriod", Calendar_PeriodInfo$reflection()], ["SelectedScheduleAssignMode", StudentDays_ScheduleAssignMode$reflection()], ["AssignProgress", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GroupClick", "StudentClick", "SelectPeriod", "SelectScheduleAssignMode", "AssignClick", "Assign"];
    }
}

export function Types_Msg$reflection() {
    return union_type("ScheduleAssign.Types.Msg", [], Types_Msg, () => [[["Item1", class_type("System.Guid")], ["Item2", bool_type]], [["Item1", class_type("System.Guid")], ["Item2", bool_type]], [["Item", Calendar_PeriodInfo$reflection()]], [["Item", StudentDays_ScheduleAssignMode$reflection()]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_assign(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new StudentDays_ScheduleAssignRequest(state.Schedule.Id, toList(state.SelectedStudentIds), state.SelectedPeriod, state.Schedule.ScheduleWeeks, state.SelectedScheduleAssignMode);
        return singleton.Bind(Remoting_handleNonAuth(studentDaysApi().assign(request)), (_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, response.fields[0])))) : singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))));
        });
    }), (_arg2) => singleton.Return(new Types_Msg(5, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function State_init(schedule, groups, students) {
    return [new Types_State(schedule, groups, students, empty({
        Compare: (x, y) => comparePrimitives(x, y),
    }), empty({
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    }), defaultPeriod, new StudentDays_ScheduleAssignMode(1), new DeferredResult$1(0)), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        return [new Types_State(state.Schedule, state.Groups, state.Students, state.SelectedGroupIds, msg.fields[1] ? FSharpSet__Add(state.SelectedStudentIds, msg.fields[0]) : FSharpSet__Remove(state.SelectedStudentIds, msg.fields[0]), state.SelectedPeriod, state.SelectedScheduleAssignMode, state.AssignProgress), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new Types_State(state.Schedule, state.Groups, state.Students, state.SelectedGroupIds, state.SelectedStudentIds, msg.fields[0], state.SelectedScheduleAssignMode, state.AssignProgress), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Types_State(state.Schedule, state.Groups, state.Students, state.SelectedGroupIds, state.SelectedStudentIds, state.SelectedPeriod, msg.fields[0], state.AssignProgress), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [state, Cmd_OfFunc_result(new Types_Msg(5, new AsyncOperationStatus$1(0)))];
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Schedule, state.Groups, state.Students, state.SelectedGroupIds, state.SelectedStudentIds, state.SelectedPeriod, state.SelectedScheduleAssignMode, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0]))), Cmd_none()];
            }
            else {
                return [new Types_State(state.Schedule, state.Groups, state.Students, state.SelectedGroupIds, state.SelectedStudentIds, state.SelectedPeriod, state.SelectedScheduleAssignMode, new DeferredResult$1(2, new FSharpResult$2(0, void 0))), View_Cmd_toastSuccess("Pass kopierade till utövare")];
            }
        }
        else {
            return [new Types_State(state.Schedule, state.Groups, state.Students, state.SelectedGroupIds, state.SelectedStudentIds, state.SelectedPeriod, state.SelectedScheduleAssignMode, new DeferredResult$1(1)), Cmd_fromAsync(Cmd_assign(state))];
        }
    }
    else {
        let patternInput;
        const studentIds = map((x_1) => x_1.Id, filter((x) => (x.GroupId === msg.fields[0]), state.Students));
        patternInput = (msg.fields[1] ? [FSharpSet__Add(state.SelectedGroupIds, msg.fields[0]), fold((set$_1, item) => FSharpSet__Add(set$_1, item), state.SelectedStudentIds, studentIds)] : [FSharpSet__Remove(state.SelectedGroupIds, msg.fields[0]), fold((set$_3, item_1) => FSharpSet__Remove(set$_3, item_1), state.SelectedStudentIds, studentIds)]);
        return [new Types_State(state.Schedule, state.Groups, state.Students, patternInput[0], patternInput[1], state.SelectedPeriod, state.SelectedScheduleAssignMode, state.AssignProgress), Cmd_none()];
    }
}

export function View_User(view_UserInputProps) {
    let elms, props;
    const dispatch = view_UserInputProps.dispatch;
    const state = view_UserInputProps.state;
    const student = view_UserInputProps.student;
    const checkboxId = "checkbox_" + UserIdModule_toString(student.Id);
    const props_5 = ofArray([["className", "mb-2"], ["className", "py-2"], ["style", {
        border: (((1 + "px ") + "solid") + " ") + "#808080",
        alignContent: "space-between",
    }], ["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props = ofArray([["name", checkboxId], ["className", join(" ", ["is-checkradio"])], ["id", checkboxId], ["checked", FSharpSet__Contains(state.SelectedStudentIds, student.Id)], ["onChange", (ev) => {
        dispatch(new Types_Msg(1, student.Id, ev.target.checked));
    }]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", props))))), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-primary"], ["className", "has-text-weight-normal"], ["htmlFor", checkboxId], ["children", student.FullName]]))))]), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_5)));
}

export function View_UserGroup(view_UserGroupInputProps) {
    const dispatch = view_UserGroupInputProps.dispatch;
    const state = view_UserGroupInputProps.state;
    const groupStudents = view_UserGroupInputProps.groupStudents;
    const group = view_UserGroupInputProps.group;
    const checkboxId = "checkbox_" + GroupIdModule_toString(group.Id);
    return react.createElement(react.Fragment, {}, ...toList_1(delay(() => {
        let props_5, elms, props;
        return append(singleton_1((props_5 = ofArray([["className", "mb-2"], ["className", "py-2"], ["className", "has-background-primary-light"], ["style", {
            border: (((1 + "px ") + "solid") + " ") + "#808080",
            alignContent: "space-between",
        }], ["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props = ofArray([["name", checkboxId], ["className", join(" ", ["is-checkradio"])], ["id", checkboxId], ["checked", FSharpSet__Contains(state.SelectedGroupIds, group.Id)], ["onChange", (ev) => {
            dispatch(new Types_Msg(0, group.Id, ev.target.checked));
        }]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("checkbox", props))))), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-primary"], ["htmlFor", checkboxId], ["children", group.Title]]))))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_5))))), delay(() => map_1((student) => createElement(View_User, {
            student: student,
            state: state,
            dispatch: dispatch,
        }), groupStudents)));
    })));
}

export function View_RenderMain(view_RenderMainInputProps) {
    let props_33, elms_2;
    const students = view_RenderMainInputProps.students;
    const groups = view_RenderMainInputProps.groups;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(view_RenderMainInputProps.schedule, groups, students), (msg, state) => State_update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_35 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_33 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_2 = toList_1(delay(() => append(singleton_1(createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Alla Grupper"]]))))), delay(() => append(collect((group) => {
        const groupStudents = filter((x) => (x.GroupId === group.Id), students);
        return singleton_1(createElement(View_UserGroup, {
            group: group,
            groupStudents: groupStudents,
            state: state_1,
            dispatch: dispatch,
        }));
    }, groups), delay(() => {
        let props_18, props_9, elms, props_4, props_16, elms_1, props_11;
        return append(singleton_1((props_18 = ofArray([["className", "mt-5"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", empty_1()))), (props_9 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(elms = ofArray([(props_4 = ofArray([["name", "copy-mode"], ["className", join(" ", ["is-checkradio"])], ["id", "copy-mode-append"], ["checked", equals(state_1.SelectedScheduleAssignMode, new StudentDays_ScheduleAssignMode(1))], ["onChange", (ev) => {
            const _arg1 = ev.target.checked;
            dispatch(new Types_Msg(3, new StudentDays_ScheduleAssignMode(1)));
        }]]), createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", props_4))))), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-primary"], ["className", "has-text-weight-normal"], ["htmlFor", "copy-mode-append"], ["children", "Lägg till"]]))))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9)))), (props_16 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_11 = ofArray([["name", "copy-mode"], ["className", join(" ", ["is-checkradio"])], ["id", "copy-mode-overwrite"], ["checked", equals(state_1.SelectedScheduleAssignMode, new StudentDays_ScheduleAssignMode(2))], ["onChange", (ev_1) => {
            const _arg2 = ev_1.target.checked;
            dispatch(new Types_Msg(3, new StudentDays_ScheduleAssignMode(2)));
        }]]), createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("radio", props_11))))), createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-primary"], ["className", "has-text-weight-normal"], ["htmlFor", "copy-mode-overwrite"], ["children", "Skriv över"]]))))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_18))))), delay(() => {
            let props_28, props_22, props_20, props_26, props_24;
            return singleton_1((props_28 = ofArray([["className", "pt-2"], ["style", {
                justifyContent: "flex-end",
            }], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_20 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement(Render, {
                value: state_1.SelectedPeriod,
                onChange: (arg) => {
                    dispatch(new Types_Msg(2, arg));
                },
                selectOptions: empty_1(),
            })])]), createElement("div", createObj(Helpers_combineClasses("field", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22)))), (props_26 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "py-2"], ["className", "is-success"], ["disabled", FSharpSet__get_IsEmpty(state_1.SelectedStudentIds)], ["onClick", (_arg3) => {
                dispatch(new Types_Msg(4));
            }], ["children", "Kopiera till utövare"]]), createElement("a", createObj(Helpers_combineClasses("button", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_28)))));
        }));
    })))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), createElement("div", createObj(Helpers_combineClasses("column", empty_1())))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_33))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_35)));
}

export function View_RenderWithLoad(view_RenderWithLoadInputProps) {
    const scheduleId = view_RenderWithLoadInputProps.scheduleId;
    const matchValue = [useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(schedulesApi().get(scheduleId)), [scheduleId]), useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(groupsApi().list()), [scheduleId]), useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(studentsApi().list()), [scheduleId])];
    let pattern_matching_result, error, groups_1, schedule_1, students_1;
    if (matchValue[0].tag === 3) {
        pattern_matching_result = 0;
        error = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 3) {
            pattern_matching_result = 0;
            error = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 2) {
            if (matchValue[2].tag === 3) {
                pattern_matching_result = 0;
                error = matchValue[2].fields[0];
            }
            else if (matchValue[2].tag === 2) {
                pattern_matching_result = 1;
                groups_1 = matchValue[1].fields[0];
                schedule_1 = matchValue[0].fields[0];
                students_1 = matchValue[2].fields[0];
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else if (matchValue[2].tag === 3) {
            pattern_matching_result = 0;
            error = matchValue[2].fields[0];
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue[1].tag === 3) {
        pattern_matching_result = 0;
        error = matchValue[1].fields[0];
    }
    else if (matchValue[2].tag === 3) {
        pattern_matching_result = 0;
        error = matchValue[2].fields[0];
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            const value = error.message;
            return createElement("div", {
                children: [value],
            });
        }
        case 1: {
            return createElement(View_RenderMain, {
                schedule: schedule_1,
                groups: groups_1,
                students: students_1,
            });
        }
        case 2: {
            return loader("big");
        }
    }
}

export function View_Render(view_RenderInputProps) {
    const _arg1 = view_RenderInputProps._arg1;
    const scheduleId = view_RenderInputProps.scheduleId;
    return createElement(View_RenderWithLoad, {
        scheduleId: scheduleId,
    });
}

