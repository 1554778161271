import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { bool_type, class_type, list_type, union_type, unit_type, record_type, string_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, DeferredResult$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { usersApi } from "../Communication.fs.js";
import { Users_ResetPasswordRequest } from "../../Shared/Shared.fs.js";
import { Validator$1__Test, Validator$1__NotBlank_2B595, Validator$1__IsValid_Z4CF01147, Validator$1__End_Z5E18B1E2, validateSync } from "../.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { isNullOrWhiteSpace } from "../.fable/fable-library.3.2.1/String.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList, exists } from "../.fable/fable-library.3.2.1/Seq.js";
import { isLetter, isDigit } from "../.fable/fable-library.3.2.1/Char.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Route, navigateTo } from "../Router.fs.js";
import { Form_Field_errorsAsString, Form_Field_fieldStyleOnError, Form_Field_hasErrors, View_renderNotification } from "../Common.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { cons, singleton as singleton_1, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { View_layout } from "./Login.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export class Types_Form extends Record {
    constructor(Password, PasswordConfirmation) {
        super();
        this.Password = Password;
        this.PasswordConfirmation = PasswordConfirmation;
    }
}

export function Types_Form$reflection() {
    return record_type("ResetPassword.Types.Form", [], Types_Form, () => [["Password", string_type], ["PasswordConfirmation", string_type]]);
}

export class Types_State extends Record {
    constructor(Code, Form, Changing, FormErrors, IsNeedValidation) {
        super();
        this.Code = Code;
        this.Form = Form;
        this.Changing = Changing;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
    }
}

export function Types_State$reflection() {
    return record_type("ResetPassword.Types.State", [], Types_State, () => [["Code", string_type], ["Form", Types_Form$reflection()], ["Changing", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PasswordChanged", "PasswordConfirmationChanged", "ResetPassword"];
    }
}

export function Types_Msg$reflection() {
    return union_type("ResetPassword.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_change(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(usersApi().resetPassword(new Users_ResetPasswordRequest(state.Code, state.Form.Password)), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Invalid code")))) : singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Types_Msg(2, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => (new Types_Form(Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (value) => {
        if (value === "") {
            return true;
        }
        else if (!isNullOrWhiteSpace(value)) {
            if ((value.length >= 8) ? exists((arg00) => isDigit(arg00), value.split("")) : false) {
                return exists((arg00_1) => isLetter(arg00_1), value.split(""));
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    })("Lösenordet måste innehålla minst 1 bokstav och 1 siffra. Minsta längd är 8")(Validator$1__NotBlank_2B595(t, "Nytt lösenord krävs")(Validator$1__Test(t, "NewPassword", formInfo.Password)))), Validator$1__End_Z5E18B1E2(t, Validator$1__IsValid_Z4CF01147(t, (confirmPassword) => {
        if ((confirmPassword === "") ? (formInfo.Password === "") : false) {
            return true;
        }
        else {
            return formInfo.Password === confirmPassword;
        }
    })("Lösenordet matchar inte")(Validator$1__Test(t, "NewPasswordConfirmation", formInfo.PasswordConfirmation))))));
}

export function Validation_validateIf(needValidate, x) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

export function State_init(id) {
    return [new Types_State(id, new Types_Form("", ""), new DeferredResult$1(0), empty(), false), Cmd_none()];
}

export function State_update(msg, state) {
    if (msg.tag === 1) {
        const patternInput_1 = Validation_validateIf(state.IsNeedValidation, new Types_Form(state.Form.Password, msg.fields[0]));
        return [new Types_State(state.Code, patternInput_1[0], state.Changing, patternInput_1[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Code, state.Form, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Code, state.Form, new DeferredResult$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.IsNeedValidation), navigateTo(new Route(0))];
            }
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new Types_State(state.Code, state.Form, state.Changing, matchValue.fields[0], state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Code, matchValue.fields[0], new DeferredResult$1(1), empty(), state.IsNeedValidation), Cmd_fromAsync(Cmd_change(state))];
            }
        }
    }
    else {
        const patternInput = Validation_validateIf(state.IsNeedValidation, new Types_Form(msg.fields[0], state.Form.PasswordConfirmation));
        return [new Types_State(state.Code, patternInput[0], state.Changing, patternInput[1], state.IsNeedValidation), Cmd_none()];
    }
}

export function View_renderChangePasswordError(state) {
    const matchValue = state.Changing;
    let pattern_matching_result, error;
    if (matchValue.tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 2;
            error = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return null;
        }
        case 1: {
            return View_renderNotification(["className", "is-success"], "Lösenordet har ändrats.");
        }
        case 2: {
            return View_renderNotification(["className", "is-danger"], error);
        }
    }
}

export function View_renderForm(state, dispatch) {
    let elms_1, props_2, elms, props_4, props_6, elms_3, props_12, elms_2, props_14, props_16;
    return createElement("form", {
        onSubmit: (e) => {
            e.preventDefault();
            dispatch(new Types_Msg(2, new AsyncOperationStatus$1(0)));
        },
        children: Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_2 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-6"], ["className", "has-text-left"], ["children", "Nytt lösenord"]]))))])]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms = ofArray([(props_4 = toList(delay(() => append(Form_Field_hasErrors("NewPassword")(state.FormErrors) ? singleton_2(["style", createObj(Form_Field_fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["placeholder", "Nytt lösenord"]), delay(() => singleton_2(["onChange", (ev) => {
            dispatch(new Types_Msg(0, ev.target.value));
        }]))))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_4))))), (props_6 = ofArray([["style", {
            color: "#FF0000",
        }], ["children", Form_Field_errorsAsString("NewPassword")(state.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_6))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = ofArray([(props_12 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "is-size-6"], ["className", "has-text-left"], ["children", "Lösenordsbekräftelse"]]))))])]), createElement("div", createObj(Helpers_combineClasses("field-label", props_12)))), (elms_2 = ofArray([(props_14 = toList(delay(() => append(Form_Field_hasErrors("NewPasswordConfirmation")(state.FormErrors) ? singleton_2(["style", createObj(Form_Field_fieldStyleOnError)]) : empty_1(), delay(() => append(singleton_2(["placeholder", "Lösenordsbekräftelse"]), delay(() => singleton_2(["onChange", (ev_1) => {
            dispatch(new Types_Msg(1, ev_1.target.value));
        }]))))))), createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", props_14))))), (props_16 = ofArray([["style", {
            color: "#FF0000",
        }], ["children", Form_Field_errorsAsString("NewPasswordConfirmation")(state.FormErrors)]]), createElement("p", createObj(Helpers_combineClasses("help", props_16))))]), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))]), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["children", "Återställ lösenord"]])))), createElement("div", {
            className: "mt-2",
            children: Interop_reactApi.Children.toArray([View_renderChangePasswordError(state)]),
        })]),
    });
}

export function View_view(state, dispatch) {
    return View_layout(View_renderForm(state, dispatch));
}

export function Component_Render(component_RenderInputProps) {
    const code = component_RenderInputProps.code;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(code), (msg, state) => State_update(msg, state), [code]);
    return View_view(patternInput[0], patternInput[1]);
}

