import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { list_type, union_type, unit_type, array_type, bool_type, int32_type, record_type, string_type, class_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { Groups_Group_Default_4019FA9, Common_str, Common_parseIntOrZero, Plans_YearPlan_AddOrUpdateYearPlan, Plans_PeriodPlan_Create, Plans_YearPlan, Groups_getYearPlanOrDefault, Calendar_WeekInfo_get_Current, Groups_GroupUpdateRequest, Groups_GroupCreateRequest, Plans_YearPlan$reflection } from "../../Shared/Shared.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, View_Cmd_toastSuccess, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { groupsApi } from "../Communication.fs.js";
import { Validator$1__Test, Validator$1__NotBlank_2B595, Validator$1__End_Z5E18B1E2, validateSync } from "../.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { sum, sumBy, indexed, map, mapIndexed } from "../.fable/fable-library.3.2.1/Array.js";
import { int32ToString, equals, createObj, round } from "../.fable/fable-library.3.2.1/Util.js";
import { collect, reverse, map as map_1, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { View_renderSelectOption, borderStylePrimary, Form_Field_fieldStyleOnError } from "../Common.fs.js";
import { createElement } from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { empty as empty_2, singleton as singleton_2, ofArray, cons } from "../.fable/fable-library.3.2.1/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { rangeDouble } from "../.fable/fable-library.3.2.1/Range.js";
import { padLeft, join } from "../.fable/fable-library.3.2.1/String.js";
import { Route, toPath } from "../Router.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_Form extends Record {
    constructor(Id, Title, YearPlan) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.YearPlan = YearPlan;
    }
}

export function Types_Form$reflection() {
    return record_type("GroupEdit.Types.Form", [], Types_Form, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["YearPlan", Plans_YearPlan$reflection()]]);
}

export class Types_State extends Record {
    constructor(Form, OriginalValue, CurrentYear, IsNew, YearPlans, Saving, FormErrors, IsNeedValidation) {
        super();
        this.Form = Form;
        this.OriginalValue = OriginalValue;
        this.CurrentYear = (CurrentYear | 0);
        this.IsNew = IsNew;
        this.YearPlans = YearPlans;
        this.Saving = Saving;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
    }
}

export function Types_State$reflection() {
    return record_type("GroupEdit.Types.State", [], Types_State, () => [["Form", Types_Form$reflection()], ["OriginalValue", Types_Form$reflection()], ["CurrentYear", int32_type], ["IsNew", bool_type], ["YearPlans", array_type(Plans_YearPlan$reflection())], ["Saving", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TitleChanged", "YearChanged", "YearTotalHoursChanged", "PeriodTotalHoursChanged", "PeriodWeekHoursChanged", "DistributeYearHours", "DistributePeriodsHours", "Save"];
    }
}

export function Types_Msg$reflection() {
    return union_type("GroupEdit.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item1", int32_type], ["Item2", int32_type]], [["Item1", int32_type], ["Item2", int32_type], ["Item3", int32_type]], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_save(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        let request, request_1;
        return singleton.Bind(Remoting_handleNonAuth(state.IsNew ? (request = (new Groups_GroupCreateRequest(state.Form.Id, state.Form.Title, state.Form.YearPlan)), groupsApi().create(request)) : (request_1 = (new Groups_GroupUpdateRequest(state.Form.Id, state.Form.Title, state.Form.YearPlan)), groupsApi().update(request_1))), (_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? singleton.Return(new Types_Msg(7, new AsyncOperationStatus$1(1, new FSharpResult$2(1, response.fields[0])))) : singleton.Return(new Types_Msg(7, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))));
        });
    }), (_arg2) => singleton.Return(new Types_Msg(7, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => (new Types_Form(formInfo.Id, Validator$1__End_Z5E18B1E2(t, Validator$1__NotBlank_2B595(t, "Title is required")(Validator$1__Test(t, "Title", formInfo.Title))), Validator$1__End_Z5E18B1E2(t, Validator$1__Test(t, "TotalHours", formInfo.YearPlan)))));
}

export function Validation_validateIf(needValidate, x) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

export function State_init(group, isNew) {
    const year = Calendar_WeekInfo_get_Current().PeriodInfo.PeriodYear | 0;
    const form = new Types_Form(group.Id, group.Title, Groups_getYearPlanOrDefault(group.YearPlans, year));
    return [new Types_State(form, form, year, isNew, group.YearPlans, new DeferredResult$1(0), empty(), false), Cmd_none()];
}

export function State_withYearPlan(state, newYearPlan) {
    let inputRecord;
    const patternInput = Validation_validateIf(state.IsNeedValidation, (inputRecord = state.Form, new Types_Form(inputRecord.Id, inputRecord.Title, newYearPlan)));
    return new Types_State(patternInput[0], state.OriginalValue, state.CurrentYear, state.IsNew, state.YearPlans, state.Saving, patternInput[1], state.IsNeedValidation);
}

export function State_update(msg, state) {
    let inputRecord_2, inputRecord_1, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord;
    if (msg.tag === 1) {
        return [State_withYearPlan(state, Groups_getYearPlanOrDefault(state.YearPlans, msg.fields[0])), Cmd_none()];
    }
    else if (msg.tag === 2) {
        const patternInput_1 = Validation_validateIf(state.IsNeedValidation, (inputRecord_2 = state.Form, new Types_Form(inputRecord_2.Id, inputRecord_2.Title, (inputRecord_1 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_1.Year, msg.fields[0], inputRecord_1.PeriodPlans)))));
        return [new Types_State(patternInput_1[0], state.OriginalValue, state.CurrentYear, state.IsNew, state.YearPlans, state.Saving, patternInput_1[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [State_withYearPlan(state, (inputRecord_3 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_3.Year, inputRecord_3.TotalHours, mapIndexed((periodIndex, period) => {
            if (periodIndex === msg.fields[0]) {
                return Plans_PeriodPlan_Create(msg.fields[1], period.WeekHours);
            }
            else {
                return period;
            }
        }, state.Form.YearPlan.PeriodPlans)))), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [State_withYearPlan(state, (inputRecord_4 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_4.Year, inputRecord_4.TotalHours, mapIndexed((pIndex, p) => {
            if (pIndex === msg.fields[0]) {
                return Plans_PeriodPlan_Create(p.TotalHours, mapIndexed((i, v) => {
                    if (i === msg.fields[1]) {
                        return msg.fields[2] | 0;
                    }
                    else {
                        return v;
                    }
                }, p.WeekHours));
            }
            else {
                return p;
            }
        }, state.Form.YearPlan.PeriodPlans)))), Cmd_none()];
    }
    else if (msg.tag === 5) {
        const hoursPerPeriod = (~(~round(state.Form.YearPlan.TotalHours / 13))) | 0;
        return [State_withYearPlan(state, (inputRecord_5 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_5.Year, inputRecord_5.TotalHours, map((x_7) => Plans_PeriodPlan_Create(hoursPerPeriod, x_7.WeekHours), state.Form.YearPlan.PeriodPlans)))), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [State_withYearPlan(state, (inputRecord_6 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_6.Year, inputRecord_6.TotalHours, map((periodPlan) => {
            const hoursPerWeek = (~(~round(periodPlan.TotalHours / 4))) | 0;
            return Plans_PeriodPlan_Create(periodPlan.TotalHours, new Int32Array([hoursPerWeek, hoursPerWeek, hoursPerWeek, hoursPerWeek]));
        }, state.Form.YearPlan.PeriodPlans)))), Cmd_none()];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.Form, state.OriginalValue, state.CurrentYear, state.IsNew, state.YearPlans, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.Form, state.Form, state.CurrentYear, false, Plans_YearPlan_AddOrUpdateYearPlan(state.Form.YearPlan, state.YearPlans), new DeferredResult$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.IsNeedValidation), View_Cmd_toastSuccess("Gruppen har sparats")];
            }
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new Types_State(state.Form, state.OriginalValue, state.CurrentYear, state.IsNew, state.YearPlans, state.Saving, matchValue.fields[0], true), Cmd_none()];
            }
            else {
                return [new Types_State(matchValue.fields[0], state.OriginalValue, state.CurrentYear, state.IsNew, state.YearPlans, new DeferredResult$1(1), empty(), true), Cmd_fromAsync(Cmd_save(state))];
            }
        }
    }
    else {
        const patternInput = Validation_validateIf(state.IsNeedValidation, (inputRecord = state.Form, new Types_Form(inputRecord.Id, msg.fields[0], inputRecord.YearPlan)));
        return [new Types_State(patternInput[0], state.OriginalValue, state.CurrentYear, state.IsNew, state.YearPlans, state.Saving, patternInput[1], state.IsNeedValidation), Cmd_none()];
    }
}

export function View_totalSumIndicator(totalValue, originalValue) {
    const props = toList(delay(() => append(singleton_1(["className", "is-small"]), delay(() => append(singleton_1(["disabled", true]), delay(() => append((Math.abs(totalValue - originalValue) > 10) ? singleton_1(["style", createObj(Form_Field_fieldStyleOnError)]) : empty_1(), delay(() => singleton_1(["value", totalValue])))))))));
    return createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props))));
}

export function View_view(state, dispatch) {
    let props_135, elms_1, props_9, props_79, props_77, props_22, props_20, props_18, props_16, props_14, value_49, props_38, props_36, props_34, props_28, props_26, value_74, props_32, props_67, props_75, props_73, props_71, props_133, elms_10;
    const yearPlan = state.Form.YearPlan;
    const props_137 = ofArray([["className", "pt-4"], ["children", Interop_reactApi.Children.toArray([(props_135 = ofArray([borderStylePrimary, ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_2((props_9 = ofArray([["className", "is-vcentered"], ["style", {
        display: "flex",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "is-size-5"], ["className", "has-text-primary"], ["children", "Ändra egenskaper"]]))))), delay(() => {
        let props_7, elms, patternInput, value_18;
        return (!state.IsNew) ? singleton_1((props_7 = ofArray([["className", "pl-4"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2((patternInput = Helpers_extractClasses(ofArray([["className", "is-small"], ["className", "has-text-right"], (value_18 = (yearPlan.Year | 0), ["ref", (e) => {
            if ((!(e == null)) ? (!equals(e.value, value_18)) : false) {
                e.value = (value_18 | 0);
            }
        }]), ["onChange", (ev) => {
            dispatch(new Types_Msg(1, Common_parseIntOrZero(ev.target.value)));
        }], ["children", Interop_reactApi.Children.toArray(Array.from(map_1((x_2) => View_renderSelectOption(x_2, x_2), map_1((x_1) => Common_str(x_1), reverse(map_1((x) => (x + state.CurrentYear), toList(rangeDouble(-1, 1, 5))))))))]])), createElement("div", {
            className: join(" ", cons("select", patternInput[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
        }))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_7))))) : empty_1();
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_79 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_77 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "Titel"], ["className", "mb-0"]])))), (props_22 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray([(props_20 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_18 = ofArray([["className", "is-grouped"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-small"], (value_49 = state.Form.Title, ["ref", (e_1) => {
        if ((!(e_1 == null)) ? (!equals(e_1.value, value_49)) : false) {
            e_1.value = value_49;
        }
    }]), ["onChange", (ev_1) => {
        dispatch(new Types_Msg(0, ev_1.target.value));
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_14)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_16))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_18))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_22)))), createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "Träningstimmar per år:"]])))), (props_38 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray([(props_36 = ofArray([["className", "pt-5"], ["children", Interop_reactApi.Children.toArray([(props_34 = ofArray([["className", "is-grouped"], ["children", Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["className", "is-small"], (value_74 = (yearPlan.TotalHours | 0), ["ref", (e_2) => {
        if ((!(e_2 == null)) ? (!equals(e_2.value, value_74)) : false) {
            e_2.value = (value_74 | 0);
        }
    }]), ["onChange", (ev_2) => {
        const value_78 = ev_2.target.valueAsNumber;
        if ((!(value_78 == null)) ? (value_78 !== NaN) : false) {
            dispatch(new Types_Msg(2, round(value_78)));
        }
    }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_26)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_28)))), (props_32 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-success"], ["children", "Fördela"], ["onClick", (_arg1) => {
        dispatch(new Types_Msg(5));
    }]]))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_32))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_36))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_38)))), createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "py-1"], ["className", "has-text-weight-bold"], ["children", "Träningstimmar per period:"]])))), (props_67 = ofArray([["className", "is-vcentered"], ["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(collect((matchValue) => {
        let elms_4, props_50, props_48, elms_2, props_42, elms_3, props_45, value_117;
        const index = matchValue[0] | 0;
        return singleton_1((elms_4 = singleton_2((props_50 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_48 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_42 = ofArray([["className", "has-text-left"], ["className", "has-text-weight-normal"], ["children", index + 1]]), createElement("label", createObj(Helpers_combineClasses("label", props_42))))), createElement("div", {
            className: "field-label",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (elms_3 = singleton_2((props_45 = ofArray([["className", "is-small"], (value_117 = (matchValue[1].TotalHours | 0), ["ref", (e_3) => {
            if ((!(e_3 == null)) ? (!equals(e_3.value, value_117)) : false) {
                e_3.value = (value_117 | 0);
            }
        }]), ["onChange", (ev_3) => {
            const value_121 = ev_3.target.valueAsNumber;
            if ((!(value_121 == null)) ? (value_121 !== NaN) : false) {
                dispatch(new Types_Msg(3, index, round(value_121)));
            }
        }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_45)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_48))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_50))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        })));
    }, indexed(yearPlan.PeriodPlans)), delay(() => {
        let props_55;
        return append(singleton_1((props_55 = ofArray([["className", "pt-5"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "="]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_55))))), delay(() => {
            let props_65, props_63, props_61, elms_5, elms_6;
            return singleton_1((props_65 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_63 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_61 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_2(createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["className", "has-text-weight-normal"], ["children", "Total"]]))))), createElement("div", {
                className: "field-label",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            })), (elms_6 = singleton_2(View_totalSumIndicator(sumBy((x_4) => x_4.TotalHours, yearPlan.PeriodPlans, {
                GetZero: () => 0,
                Add: (x_5, y) => (x_5 + y),
            }), yearPlan.TotalHours)), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("control", props_61))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_63))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_65)))));
        }));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_67)))), (props_75 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray([(props_73 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_71 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-success"], ["children", "Fördela"], ["onClick", (_arg2) => {
        dispatch(new Types_Msg(6));
    }]]))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_71))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_73))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_75))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_77))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_79)))), (props_133 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_10 = toList(delay(() => append(singleton_1(createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "Träningstimmar per vecka:"]]))))), delay(() => {
        let props_89;
        return append(singleton_1((props_89 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-1"])], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(collect((matchValue_1) => {
            let props_83;
            return singleton_1((props_83 = singleton_2(["children", "v." + padLeft(int32ToString(((matchValue_1[0] * 4) + 17) % 52), 2, "0")]), createElement("div", createObj(Helpers_combineClasses("column", props_83)))));
        }, indexed(yearPlan.PeriodPlans)), delay(() => append(singleton_1(createElement("div", createObj(Helpers_combineClasses("column", empty_2())))), delay(() => singleton_1(createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-1"])))))))))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_89))))), delay(() => append(map_1((i) => {
            const props_102 = ofArray([["className", "is-vcentered"], ["className", "is-variable"], ["className", join(" ", ["is-1"])], ["className", "is-size-7"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(collect((matchValue_2) => {
                let elms_7, props_95, props_93, props_91, value_197;
                return singleton_1((elms_7 = singleton_2((props_95 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_93 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([(props_91 = ofArray([["className", "is-small"], (value_197 = (matchValue_2[1].WeekHours[i - 1] | 0), ["ref", (e_4) => {
                    if ((!(e_4 == null)) ? (!equals(e_4.value, value_197)) : false) {
                        e_4.value = (value_197 | 0);
                    }
                }]), ["onChange", (ev_4) => {
                    const value_201 = ev_4.target.valueAsNumber;
                    if ((!(value_201 == null)) ? (value_201 !== NaN) : false) {
                        dispatch(new Types_Msg(4, matchValue_2[0], i - 1, round(value_201)));
                    }
                }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_91)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_93))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_95))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                })));
            }, indexed(yearPlan.PeriodPlans)), delay(() => append(singleton_1(createElement("div", createObj(Helpers_combineClasses("column", empty_2())))), delay(() => singleton_1(createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-1"])))))))))))))]]);
            return createElement("div", createObj(Helpers_combineClasses("columns", props_102)));
        }, toList(rangeDouble(1, 1, 4))), delay(() => {
            let props_120;
            return append(singleton_1((props_120 = ofArray([["className", "is-vcentered"], ["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(map_1((periodPlan_2) => {
                let props_106, props_104;
                const elms_8 = singleton_2((props_106 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_104 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([View_totalSumIndicator(sum(periodPlan_2.WeekHours, {
                    GetZero: () => 0,
                    Add: (x_7, y_1) => (x_7 + y_1),
                }), periodPlan_2.TotalHours)])]]), createElement("div", createObj(Helpers_combineClasses("control", props_104))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_106)))));
                return createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                });
            }, yearPlan.PeriodPlans), delay(() => {
                let props_111;
                return append(singleton_1((props_111 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "="]]))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_111))))), delay(() => {
                    let props_118, props_116, props_114, elms_9;
                    return singleton_1((props_118 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([(props_116 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_114 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_9 = singleton_2(View_totalSumIndicator(sumBy((x_8) => sum(x_8.WeekHours, {
                        GetZero: () => 0,
                        Add: (x_9, y_2) => (x_9 + y_2),
                    }), yearPlan.PeriodPlans, {
                        GetZero: () => 0,
                        Add: (x_10, y_3) => (x_10 + y_3),
                    }), yearPlan.TotalHours)), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("control", props_114))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_116))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_118)))));
                }));
            }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_120))))), delay(() => {
                let props_130, props_124, props_122, props_128, props_126;
                return singleton_1((props_130 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_124 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_122 = ofArray([["className", "has-text-danger"], ["className", "is-text"], ["children", "Tillbaka till alla grupper"], ["href", toPath(new Route(7))]]), createElement("a", createObj(Helpers_combineClasses("button", props_122))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_124)))), (props_128 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_126 = ofArray([["className", "is-success"], ["className", "mr-0"], ["children", "Spara"], ["onClick", (_arg3) => {
                    dispatch(new Types_Msg(7, new AsyncOperationStatus$1(0)));
                }], ["disabled", (!state.IsNew) ? equals(state.Form, state.OriginalValue) : false]]), createElement("a", createObj(Helpers_combineClasses("button", props_126))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_128))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_130)))));
            }));
        }))));
    })))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_133))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_135))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_137)));
}

export function Component_RenderForm(component_RenderFormInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(component_RenderFormInputProps.group, component_RenderFormInputProps.isNew), (msg, state) => State_update(msg, state), []);
    return View_view(patternInput[0], patternInput[1]);
}

export function Component_ResolveGroup(component_ResolveGroupInputProps) {
    const groupId = component_ResolveGroupInputProps.groupId;
    let patternInput;
    if (groupId == null) {
        patternInput = [singleton.Delay(() => singleton.Return(Groups_Group_Default_4019FA9(void 0))), true];
    }
    else {
        const id = groupId;
        patternInput = [groupsApi().get(id), false];
    }
    const group = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(patternInput[0]), [groupId]);
    if (group.tag === 2) {
        return createElement(Component_RenderForm, {
            group: group.fields[0],
            isNew: patternInput[1],
        });
    }
    else {
        return loader("big");
    }
}

export function Component_Render(component_RenderInputProps) {
    const _arg1 = component_RenderInputProps._arg1;
    const id = component_RenderInputProps.id;
    return createElement(Component_ResolveGroup, {
        groupId: id,
    });
}

