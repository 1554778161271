import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { record_type, bool_type, union_type, string_type, unit_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, Remoting_userChanged, View_Cmd_toastError, AsyncOperationStatus$1, Remoting_handleNonAuth, DeferredResult$1, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { settingsApi } from "../Communication.fs.js";
import { now } from "../.fable/fable-library.3.2.1/DateOffset.js";
import { Auth_SignedInUser } from "../../Shared/Shared.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { ofArray, singleton as singleton_2, empty as empty_1 } from "../.fable/fable-library.3.2.1/List.js";
import { Route, toPath } from "../Router.fs.js";

export class State extends Record {
    constructor(Changing, IsVisible) {
        super();
        this.Changing = Changing;
        this.IsVisible = IsVisible;
    }
}

export function State$reflection() {
    return record_type("GdprModal.State", [], State, () => [["Changing", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["IsVisible", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Accept"];
    }
}

export function Msg$reflection() {
    return union_type("GdprModal.Msg", [], Msg, () => [[["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function init() {
    return [new State(new DeferredResult$1(0), true), Cmd_none()];
}

export const change = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(settingsApi().acceptGdpr()), (_arg1) => ((_arg1.tag === 1) ? singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, "Can not find account")))) : singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))))))), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));

export function update(msg, state) {
    if (msg.fields[0].tag === 1) {
        if (msg.fields[0].fields[0].tag === 1) {
            return [new State(new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.IsVisible), View_Cmd_toastError(msg.fields[0].fields[0].fields[0])];
        }
        else {
            Remoting_userChanged((x) => (new Auth_SignedInUser(x.Email, x.FullName, x.SchoolId, x.GroupId, now(), x.AccessToken, x.Role, x.UserId, x.ChatText, x.ChatChangeDate)));
            return [new State(new DeferredResult$1(2, new FSharpResult$2(0, void 0)), false), Cmd_none()];
        }
    }
    else {
        return [new State(new DeferredResult$1(1), state.IsVisible), Cmd_fromAsync(change)];
    }
}

export function Render() {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(), (msg, state) => update(msg, state), []);
    const props_16 = toList(delay(() => append(patternInput[0].IsVisible ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let elms_1, elms, props_9, props_13;
        return singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), (elms_1 = ofArray([(elms = singleton_2(createElement("p", createObj(Helpers_combineClasses("modal-card-title", singleton_2(["children", "GDPR"]))))), createElement("header", {
            className: "modal-card-head",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_9 = ofArray([["className", "has-background-light"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
            children: ["Klicka på knappen negan för att godkänn vår hantering av dina uppgifter."],
        }), createElement("p", {
            children: Interop_reactApi.Children.toArray([createElement("span", {
                children: ["Du kan läsa om dessa här: "],
            }), createElement("a", {
                children: "GDPR",
                href: toPath(new Route(3)),
                target: "_blank",
            })]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("card-content", props_9)))), (props_13 = ofArray([["style", {
            justifyContent: "flex-end",
        }], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-success"], ["children", "JAG GODKÄNNER GDPR"], ["onClick", (_arg1) => {
            patternInput[1](new Msg(0, new AsyncOperationStatus$1(0)));
        }]]))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_13))))]), createElement("div", {
            className: "modal-card",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]);
    }))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_16)));
}

