import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { Img_logo } from "../Css.fs.js";
import { singleton, cons, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { int32ToString, createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Route, toPath } from "../Router.fs.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { year, now } from "../.fable/fable-library.3.2.1/Date.js";
import { renderGdprArticle } from "./Gdpr.fs.js";

export const render = (() => {
    let props_44, props_38, props_3, props_1, props_22, props_8, elms, elms_2, elms_1, props_16, props_14, props_18, props_20, props_36, elms_6, elms_3, elms_5, props_30, elms_4, props_34, copyOfStruct_1, copyOfStruct, props_42, props_40;
    const props_46 = ofArray([["className", "is-fullhd"], ["className", "py-5"], ["children", Interop_reactApi.Children.toArray([(props_44 = singleton(["children", Interop_reactApi.Children.toArray([(props_38 = ofArray([["className", "is-one-third"], ["style", {
        display: "grid",
        alignContent: "space-between",
    }], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-vcentered"], ["className", "px-4"], ["className", "py-4"], ["children", Interop_reactApi.Children.toArray([createElement("img", {
        src: Img_logo,
        style: {
            height: 60,
            width: 60,
        },
    }), (props_1 = ofArray([["className", "pl-3"], ["style", {
        fontSize: 24 + "px",
        fontWeight: 700,
    }], ["className", "has-text-weight-bold"], ["children", "Endurance"]]), createElement("span", createObj(Helpers_combineClasses("", props_1))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_3)))), (props_22 = ofArray([["className", "has-text-centered"], ["style", {
        alignSelf: "center",
    }], ["children", Interop_reactApi.Children.toArray([(props_8 = singleton(["children", Interop_reactApi.Children.toArray([(elms = singleton(createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", ofArray([["disabled", true], ["placeholder", "Epost"]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("field", props_8)))), (elms_2 = singleton((elms_1 = singleton(createElement("input", createObj(cons(["type", "password"], Helpers_combineClasses("input", ofArray([["disabled", true], ["placeholder", "Lösenord"]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (props_16 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["disabled", true], ["className", "is-text"], ["className", "has-text-primary"], ["href", toPath(new Route(1))], ["children", "Glömt lösenord?"]]), createElement("a", createObj(Helpers_combineClasses("button", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_16)))), (props_18 = ofArray([["disabled", true], ["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["href", toPath(new Route(0))], ["children", "Logga in"]]), createElement("a", createObj(Helpers_combineClasses("button", props_18)))), (props_20 = ofArray([["disabled", true], ["className", "mt-3"], ["className", "is-success"], ["className", "is-fullwidth"], ["className", "has-text-weight-bold"], ["href", toPath(new Route(7))], ["children", "Logga in som tränare(only for test)"]]), createElement("a", createObj(Helpers_combineClasses("button", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22)))), (props_36 = ofArray([["className", "has-text-centered"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(elms_6 = ofArray([(elms_3 = singleton(createElement("a", createObj(Helpers_combineClasses("button", ofArray([["className", "is-text"], ["className", "has-text-primary"], ["children", "Lathund"]]))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    })), (elms_5 = singleton((props_30 = ofArray([["href", toPath(new Route(0))], ["className", "is-outlined"], ["className", "is-primary"], ["children", Interop_reactApi.Children.toArray([(elms_4 = singleton(createElement("i", {
        className: join(" ", ["fa", "fa-chevron-left"]),
    })), createElement("span", {
        className: "icon",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })), createElement("span", {
        className: "",
        children: "Back to login",
    })])]]), createElement("a", createObj(Helpers_combineClasses("button", props_30))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    })), (props_34 = ofArray([["className", "is-size-7"], ["className", "has-text-centered"], ["children", "© 2010 - " + (copyOfStruct_1 = (copyOfStruct = now(), year(copyOfStruct)), int32ToString(copyOfStruct_1))]]), createElement("span", createObj(Helpers_combineClasses("", props_34))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_36))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_38)))), (props_42 = singleton(["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["style", {
        height: 92.1 + "vh",
        overflow: "scroll",
    }], ["className", "py-5"], ["className", "px-5"], ["className", "has-text-grey"], ["children", Interop_reactApi.Children.toArray([renderGdprArticle])]]), createElement("div", createObj(Helpers_combineClasses("box", props_40))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_42))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_44))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_46)));
})();

