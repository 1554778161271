import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { bool_type, list_type, union_type, string_type, unit_type, array_type, int32_type, record_type, class_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { Common_str, Common_parseIntOrZero, Plans_YearPlan_AddOrUpdateYearPlan, Plans_PeriodPlan_Create, Plans_YearPlan, Students_getYearPlanOrGroupOrDefault, Calendar_WeekInfo_get_Current, Students_StudentUpdatePlanRequest, Auth_SignedInUser$reflection, Plans_YearPlan$reflection } from "../../Shared/Shared.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.1/Choice.js";
import { Cmd_fromAsync, View_Cmd_toastSuccess, DeferredResult$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, DeferredResult$1$reflection } from "../Extensions.fs.js";
import { singleton } from "../.fable/fable-library.3.2.1/AsyncBuilder.js";
import { groupsApi, studentsApi } from "../Communication.fs.js";
import { Validator$1__Test, Validator$1__End_Z5E18B1E2, validateSync } from "../.fable/Fable.Validation.0.2.1/Validation.fs.js";
import { empty } from "../.fable/fable-library.3.2.1/Map.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { sum, sumBy, indexed, map, mapIndexed } from "../.fable/fable-library.3.2.1/Array.js";
import { int32ToString, equals, createObj, round } from "../.fable/fable-library.3.2.1/Util.js";
import { collect, reverse, map as map_1, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { View_renderSelectOption, borderStylePrimary, Form_Field_fieldStyleOnError } from "../Common.fs.js";
import { createElement } from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { empty as empty_2, singleton as singleton_2, ofArray, cons } from "../.fable/fable-library.3.2.1/List.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { rangeDouble } from "../.fable/fable-library.3.2.1/Range.js";
import { padLeft, join } from "../.fable/fable-library.3.2.1/String.js";
import { Route, toPath } from "../Router.fs.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { loader } from "../Loader.fs.js";

export class Types_Form extends Record {
    constructor(Id, YearPlan) {
        super();
        this.Id = Id;
        this.YearPlan = YearPlan;
    }
}

export function Types_Form$reflection() {
    return record_type("StudentPlan.Types.Form", [], Types_Form, () => [["Id", class_type("System.Guid")], ["YearPlan", Plans_YearPlan$reflection()]]);
}

export class Types_State extends Record {
    constructor(CurrentYear, User, Form, OriginalValue, StudentYearPlans, GroupYearPlans, Saving, FormErrors, IsNeedValidation) {
        super();
        this.CurrentYear = (CurrentYear | 0);
        this.User = User;
        this.Form = Form;
        this.OriginalValue = OriginalValue;
        this.StudentYearPlans = StudentYearPlans;
        this.GroupYearPlans = GroupYearPlans;
        this.Saving = Saving;
        this.FormErrors = FormErrors;
        this.IsNeedValidation = IsNeedValidation;
    }
}

export function Types_State$reflection() {
    return record_type("StudentPlan.Types.State", [], Types_State, () => [["CurrentYear", int32_type], ["User", Auth_SignedInUser$reflection()], ["Form", Types_Form$reflection()], ["OriginalValue", Types_Form$reflection()], ["StudentYearPlans", array_type(Plans_YearPlan$reflection())], ["GroupYearPlans", array_type(Plans_YearPlan$reflection())], ["Saving", DeferredResult$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["IsNeedValidation", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["YearChanged", "YearTotalHoursChanged", "PeriodTotalHoursChanged", "PeriodWeekHoursChanged", "DistributeYearHours", "DistributePeriodsHours", "Save"];
    }
}

export function Types_Msg$reflection() {
    return union_type("StudentPlan.Types.Msg", [], Types_Msg, () => [[["Item", int32_type]], [["Item", int32_type]], [["Item1", int32_type], ["Item2", int32_type]], [["Item1", int32_type], ["Item2", int32_type], ["Item3", int32_type]], [], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]]]);
}

export function Cmd_save(state) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const request = new Students_StudentUpdatePlanRequest(state.Form.Id, state.Form.YearPlan);
        return singleton.Bind(Remoting_handleNonAuth(studentsApi().updatePlan(request)), (_arg1) => {
            const response = _arg1;
            return (response.tag === 1) ? singleton.Return(new Types_Msg(6, new AsyncOperationStatus$1(1, new FSharpResult$2(1, response.fields[0])))) : singleton.Return(new Types_Msg(6, new AsyncOperationStatus$1(1, new FSharpResult$2(0, void 0))));
        });
    }), (_arg2) => singleton.Return(new Types_Msg(6, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Validation_validate(formInfo) {
    return validateSync(true, (t) => (new Types_Form(formInfo.Id, Validator$1__End_Z5E18B1E2(t, Validator$1__Test(t, "TotalHours", formInfo.YearPlan)))));
}

export function Validation_validateIf(needValidate, x) {
    if (needValidate) {
        const matchValue = Validation_validate(x);
        if (matchValue.tag === 1) {
            return [x, matchValue.fields[0]];
        }
        else {
            return [matchValue.fields[0], empty()];
        }
    }
    else {
        return [x, empty()];
    }
}

export function State_init(studentPlans, group, user) {
    const year = Calendar_WeekInfo_get_Current().PeriodInfo.PeriodYear | 0;
    const form = new Types_Form(studentPlans.Id, Students_getYearPlanOrGroupOrDefault(studentPlans.YearPlans, group.YearPlans, year));
    return [new Types_State(year, user, form, form, studentPlans.YearPlans, group.YearPlans, new DeferredResult$1(0), empty(), false), Cmd_none()];
}

export function State_withYearPlan(state, newYearPlan) {
    const patternInput = Validation_validateIf(state.IsNeedValidation, new Types_Form(state.Form.Id, newYearPlan));
    return new Types_State(state.CurrentYear, state.User, patternInput[0], state.OriginalValue, state.StudentYearPlans, state.GroupYearPlans, state.Saving, patternInput[1], state.IsNeedValidation);
}

export function State_update(msg, state) {
    let inputRecord, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5;
    if (msg.tag === 1) {
        const patternInput = Validation_validateIf(state.IsNeedValidation, new Types_Form(state.Form.Id, (inputRecord = state.Form.YearPlan, new Plans_YearPlan(inputRecord.Year, msg.fields[0], inputRecord.PeriodPlans))));
        return [new Types_State(state.CurrentYear, state.User, patternInput[0], state.OriginalValue, state.StudentYearPlans, state.GroupYearPlans, state.Saving, patternInput[1], state.IsNeedValidation), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [State_withYearPlan(state, (inputRecord_2 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_2.Year, inputRecord_2.TotalHours, mapIndexed((periodIndex, period) => {
            if (periodIndex === msg.fields[0]) {
                return Plans_PeriodPlan_Create(msg.fields[1], period.WeekHours);
            }
            else {
                return period;
            }
        }, state.Form.YearPlan.PeriodPlans)))), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [State_withYearPlan(state, (inputRecord_3 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_3.Year, inputRecord_3.TotalHours, mapIndexed((pIndex, p) => {
            if (pIndex === msg.fields[0]) {
                return Plans_PeriodPlan_Create(p.TotalHours, mapIndexed((i, v) => {
                    if (i === msg.fields[1]) {
                        return msg.fields[2] | 0;
                    }
                    else {
                        return v;
                    }
                }, p.WeekHours));
            }
            else {
                return p;
            }
        }, state.Form.YearPlan.PeriodPlans)))), Cmd_none()];
    }
    else if (msg.tag === 4) {
        const hoursPerPeriod = (~(~round(state.Form.YearPlan.TotalHours / 13))) | 0;
        return [State_withYearPlan(state, (inputRecord_4 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_4.Year, inputRecord_4.TotalHours, map((x_5) => Plans_PeriodPlan_Create(hoursPerPeriod, x_5.WeekHours), state.Form.YearPlan.PeriodPlans)))), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [State_withYearPlan(state, (inputRecord_5 = state.Form.YearPlan, new Plans_YearPlan(inputRecord_5.Year, inputRecord_5.TotalHours, map((periodPlan) => {
            const hoursPerWeek = (~(~round(periodPlan.TotalHours / 4))) | 0;
            return Plans_PeriodPlan_Create(periodPlan.TotalHours, new Int32Array([hoursPerWeek, hoursPerWeek, hoursPerWeek, hoursPerWeek]));
        }, state.Form.YearPlan.PeriodPlans)))), Cmd_none()];
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(state.CurrentYear, state.User, state.Form, state.OriginalValue, state.StudentYearPlans, state.GroupYearPlans, new DeferredResult$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.FormErrors, state.IsNeedValidation), Cmd_none()];
            }
            else {
                return [new Types_State(state.CurrentYear, state.User, state.Form, state.Form, Plans_YearPlan_AddOrUpdateYearPlan(state.Form.YearPlan, state.StudentYearPlans), state.GroupYearPlans, new DeferredResult$1(2, new FSharpResult$2(0, void 0)), state.FormErrors, state.IsNeedValidation), View_Cmd_toastSuccess("Planen har sparats")];
            }
        }
        else {
            const matchValue = Validation_validate(state.Form);
            if (matchValue.tag === 1) {
                return [new Types_State(state.CurrentYear, state.User, state.Form, state.OriginalValue, state.StudentYearPlans, state.GroupYearPlans, state.Saving, matchValue.fields[0], true), Cmd_none()];
            }
            else {
                return [new Types_State(state.CurrentYear, state.User, matchValue.fields[0], state.OriginalValue, state.StudentYearPlans, state.GroupYearPlans, new DeferredResult$1(1), empty(), true), Cmd_fromAsync(Cmd_save(state))];
            }
        }
    }
    else {
        return [State_withYearPlan(state, Students_getYearPlanOrGroupOrDefault(state.StudentYearPlans, state.GroupYearPlans, msg.fields[0])), Cmd_none()];
    }
}

export function View_totalSumIndicator(totalValue, originalValue) {
    const props = toList(delay(() => append(singleton_1(["className", "is-small"]), delay(() => append(singleton_1(["disabled", true]), delay(() => append((Math.abs(totalValue - originalValue) > 10) ? singleton_1(["style", createObj(Form_Field_fieldStyleOnError)]) : empty_1(), delay(() => singleton_1(["value", totalValue])))))))));
    return createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props))));
}

export function View_view(state, dispatch) {
    let props_125, elms_1, props_9, props_7, elms, patternInput, value_18, props_67, props_65, props_26, props_24, props_22, props_16, props_14, value_49, props_20, props_55, props_63, props_61, props_59, props_123, elms_10;
    const yearPlan = state.Form.YearPlan;
    const props_127 = ofArray([["className", "pt-4"], ["children", Interop_reactApi.Children.toArray([(props_125 = ofArray([borderStylePrimary, ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_2((props_9 = ofArray([["className", "is-vcentered"], ["style", {
        display: "flex",
    }], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "is-size-5"], ["className", "has-text-primary"], ["children", "Ändra egenskaper"]])))), (props_7 = ofArray([["className", "pl-4"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_2((patternInput = Helpers_extractClasses(ofArray([["className", "is-small"], ["className", "has-text-right"], (value_18 = (yearPlan.Year | 0), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_18)) : false) {
            e.value = (value_18 | 0);
        }
    }]), ["onChange", (ev) => {
        dispatch(new Types_Msg(0, Common_parseIntOrZero(ev.target.value)));
    }], ["children", Interop_reactApi.Children.toArray(Array.from(map_1((x_2) => View_renderSelectOption(x_2, x_2), map_1((x_1) => Common_str(x_1), reverse(map_1((x) => (x + state.CurrentYear), toList(rangeDouble(0, 1, 1))))))))]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_9))))), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_67 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_65 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "Träningstimmar per år:"]])))), (props_26 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray([(props_24 = ofArray([["className", "pt-5"], ["children", Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-grouped"], ["children", Interop_reactApi.Children.toArray([(props_16 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-small"], (value_49 = (yearPlan.TotalHours | 0), ["ref", (e_1) => {
        if ((!(e_1 == null)) ? (!equals(e_1.value, value_49)) : false) {
            e_1.value = (value_49 | 0);
        }
    }]), ["onChange", (ev_1) => {
        const value_53 = ev_1.target.valueAsNumber;
        if ((!(value_53 == null)) ? (value_53 !== NaN) : false) {
            dispatch(new Types_Msg(1, round(value_53)));
        }
    }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_14)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_16)))), (props_20 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-success"], ["children", "Fördela"], ["onClick", (_arg1) => {
        dispatch(new Types_Msg(4));
    }]]))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_24))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_26)))), createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "py-1"], ["className", "has-text-weight-bold"], ["children", "Träningstimmar per period:"]])))), (props_55 = ofArray([["className", "is-vcentered"], ["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(collect((matchValue) => {
        let elms_4, props_38, props_36, elms_2, props_30, elms_3, props_33, value_92;
        const index = matchValue[0] | 0;
        return singleton_1((elms_4 = singleton_2((props_38 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_36 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([(elms_2 = singleton_2((props_30 = ofArray([["className", "has-text-left"], ["className", "has-text-weight-normal"], ["children", index + 1]]), createElement("label", createObj(Helpers_combineClasses("label", props_30))))), createElement("div", {
            className: "field-label",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (elms_3 = singleton_2((props_33 = ofArray([["className", "is-small"], (value_92 = (matchValue[1].TotalHours | 0), ["ref", (e_2) => {
            if ((!(e_2 == null)) ? (!equals(e_2.value, value_92)) : false) {
                e_2.value = (value_92 | 0);
            }
        }]), ["onChange", (ev_2) => {
            const value_96 = ev_2.target.valueAsNumber;
            if ((!(value_96 == null)) ? (value_96 !== NaN) : false) {
                dispatch(new Types_Msg(2, index, round(value_96)));
            }
        }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_33)))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_36))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_38))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        })));
    }, indexed(yearPlan.PeriodPlans)), delay(() => {
        let props_43;
        return append(singleton_1((props_43 = ofArray([["className", "pt-5"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "="]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_43))))), delay(() => {
            let props_53, props_51, props_49, elms_5, elms_6;
            return singleton_1((props_53 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_51 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_49 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_2(createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "has-text-left"], ["className", "has-text-weight-normal"], ["children", "Total"]]))))), createElement("div", {
                className: "field-label",
                children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
            })), (elms_6 = singleton_2(View_totalSumIndicator(sumBy((x_4) => x_4.TotalHours, yearPlan.PeriodPlans, {
                GetZero: () => 0,
                Add: (x_5, y) => (x_5 + y),
            }), yearPlan.TotalHours)), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
            }))])]), createElement("div", createObj(Helpers_combineClasses("control", props_49))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_51))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_53)))));
        }));
    }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_55)))), (props_63 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray([(props_61 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_59 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-small"], ["className", "is-success"], ["children", "Fördela"], ["onClick", (_arg2) => {
        dispatch(new Types_Msg(5));
    }]]))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_59))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_61))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_63))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_65))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_67)))), (props_123 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_10 = toList(delay(() => append(singleton_1(createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "Träningstimmar per vecka:"]]))))), delay(() => {
        let props_77;
        return append(singleton_1((props_77 = ofArray([["className", "is-variable"], ["className", join(" ", ["is-1"])], ["className", "mb-0"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(collect((matchValue_1) => {
            let props_71;
            return singleton_1((props_71 = singleton_2(["children", "v." + padLeft(int32ToString(((matchValue_1[0] * 4) + 17) % 52), 2, "0")]), createElement("div", createObj(Helpers_combineClasses("column", props_71)))));
        }, indexed(yearPlan.PeriodPlans)), delay(() => append(singleton_1(createElement("div", createObj(Helpers_combineClasses("column", empty_2())))), delay(() => singleton_1(createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-1"])))))))))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_77))))), delay(() => append(map_1((i) => {
            const props_90 = ofArray([["className", "is-vcentered"], ["className", "is-variable"], ["className", join(" ", ["is-1"])], ["className", "is-size-7"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(collect((matchValue_2) => {
                let elms_7, props_83, props_81, props_79, value_172;
                return singleton_1((elms_7 = singleton_2((props_83 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_81 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([(props_79 = ofArray([["className", "is-small"], (value_172 = (matchValue_2[1].WeekHours[i - 1] | 0), ["ref", (e_3) => {
                    if ((!(e_3 == null)) ? (!equals(e_3.value, value_172)) : false) {
                        e_3.value = (value_172 | 0);
                    }
                }]), ["onChange", (ev_3) => {
                    const value_176 = ev_3.target.valueAsNumber;
                    if ((!(value_176 == null)) ? (value_176 !== NaN) : false) {
                        dispatch(new Types_Msg(3, matchValue_2[0], i - 1, round(value_176)));
                    }
                }]]), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_79)))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_81))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_83))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                })));
            }, indexed(yearPlan.PeriodPlans)), delay(() => append(singleton_1(createElement("div", createObj(Helpers_combineClasses("column", empty_2())))), delay(() => singleton_1(createElement("div", createObj(Helpers_combineClasses("column", singleton_2(["className", "is-1"])))))))))))))]]);
            return createElement("div", createObj(Helpers_combineClasses("columns", props_90)));
        }, toList(rangeDouble(1, 1, 4))), delay(() => {
            let props_108;
            return append(singleton_1((props_108 = ofArray([["className", "is-vcentered"], ["className", "is-variable"], ["className", join(" ", ["is-1"])], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(map_1((periodPlan_2) => {
                let props_94, props_92;
                const elms_8 = singleton_2((props_94 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_92 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([View_totalSumIndicator(sum(periodPlan_2.WeekHours, {
                    GetZero: () => 0,
                    Add: (x_7, y_1) => (x_7 + y_1),
                }), periodPlan_2.TotalHours)])]]), createElement("div", createObj(Helpers_combineClasses("control", props_92))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_94)))));
                return createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                });
            }, yearPlan.PeriodPlans), delay(() => {
                let props_99;
                return append(singleton_1((props_99 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-weight-bold"], ["children", "="]]))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_99))))), delay(() => {
                    let props_106, props_104, props_102, elms_9;
                    return singleton_1((props_106 = ofArray([["className", "is-1"], ["children", Interop_reactApi.Children.toArray([(props_104 = singleton_2(["children", Interop_reactApi.Children.toArray([(props_102 = singleton_2(["children", Interop_reactApi.Children.toArray([(elms_9 = singleton_2(View_totalSumIndicator(sumBy((x_8) => sum(x_8.WeekHours, {
                        GetZero: () => 0,
                        Add: (x_9, y_2) => (x_9 + y_2),
                    }), yearPlan.PeriodPlans, {
                        GetZero: () => 0,
                        Add: (x_10, y_3) => (x_10 + y_3),
                    }), yearPlan.TotalHours)), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                    }))])]), createElement("div", createObj(Helpers_combineClasses("control", props_102))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_104))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_106)))));
                }));
            }))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_108))))), delay(() => {
                let props_120, props_114, props_118, props_116;
                return singleton_1((props_120 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_114 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let props_110, props_112;
                    return (state.User.Role === 2) ? singleton_1((props_110 = ofArray([["className", "has-text-danger"], ["className", "is-text"], ["children", "Tillbaka till utövarna"], ["href", toPath(new Route(10))]]), createElement("a", createObj(Helpers_combineClasses("button", props_110))))) : singleton_1((props_112 = ofArray([["className", "has-text-danger"], ["className", "is-text"], ["children", "Tillbaka till min profil"], ["href", toPath(new Route(5))]]), createElement("a", createObj(Helpers_combineClasses("button", props_112)))));
                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_114)))), (props_118 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_116 = ofArray([["className", "is-success"], ["className", "mr-0"], ["children", "Spara"], ["onClick", (_arg3) => {
                    dispatch(new Types_Msg(6, new AsyncOperationStatus$1(0)));
                }], ["disabled", equals(state.Form, state.OriginalValue)]]), createElement("a", createObj(Helpers_combineClasses("button", props_116))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_118))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_120)))));
            }));
        }))));
    })))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_123))))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_125))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_127)));
}

export function Component_RenderForm(component_RenderFormInputProps) {
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(component_RenderFormInputProps.plans, component_RenderFormInputProps.group, component_RenderFormInputProps.user), (msg, state) => State_update(msg, state), []);
    return View_view(patternInput[0], patternInput[1]);
}

export function Component_ResolveStudentData(component_ResolveStudentDataInputProps) {
    const user = component_ResolveStudentDataInputProps.user;
    const studentSettings = component_ResolveStudentDataInputProps.studentSettings;
    const matchValue = [useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(studentsApi().getPlans(studentSettings.Id)), [studentSettings.Id]), useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(groupsApi().get(studentSettings.GroupId)), [studentSettings.GroupId])];
    let pattern_matching_result, group_1, studentPlans_1, error;
    if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 2) {
            pattern_matching_result = 0;
            group_1 = matchValue[1].fields[0];
            studentPlans_1 = matchValue[0].fields[0];
        }
        else if (matchValue[1].tag === 3) {
            pattern_matching_result = 1;
            error = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue[0].tag === 3) {
        pattern_matching_result = 1;
        error = matchValue[0].fields[0];
    }
    else if (matchValue[1].tag === 3) {
        pattern_matching_result = 1;
        error = matchValue[1].fields[0];
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            return createElement(Component_RenderForm, {
                plans: studentPlans_1,
                group: group_1,
                user: user,
            });
        }
        case 1: {
            const value = error.message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            return loader("big");
        }
    }
}

export function Component_ResolveStudent(component_ResolveStudentInputProps) {
    const user = component_ResolveStudentInputProps.user;
    const userId = component_ResolveStudentInputProps.userId;
    const studentSettings = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(studentsApi().getSettings(userId)), [userId]);
    if (studentSettings.tag === 2) {
        if (studentSettings.fields[0].tag === 1) {
            return createElement("div", {
                children: [studentSettings.fields[0].fields[0]],
            });
        }
        else {
            return createElement(Component_ResolveStudentData, {
                studentSettings: studentSettings.fields[0].fields[0],
                user: user,
            });
        }
    }
    else {
        return loader("big");
    }
}

export function Component_Render(component_RenderInputProps) {
    const user = component_RenderInputProps.user;
    const id = component_RenderInputProps.id;
    return createElement(Component_ResolveStudent, {
        userId: id,
        user: user,
    });
}

