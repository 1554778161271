import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { createElement } from "react";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { ofArray, singleton as singleton_1 } from "../.fable/fable-library.3.2.1/List.js";
import { PlanFact_PlanFact, DayPlanFact_DayPlanFact, Workout_getWorkoutTotalDuration, DurationModule_toHMSString } from "../../Shared/Shared.fs.js";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Render as Render_1 } from "../Components/DayFactEdit.fs.js";
import { startImmediate } from "../.fable/fable-library.3.2.1/Async.js";
import { deletePlanFact, updatePlanFact } from "../Components/DayPlanFactBox.fs.js";

export function Render(renderInputProps) {
    const onDelete = renderInputProps.onDelete;
    const onSave = renderInputProps.onSave;
    const daySegment = renderInputProps.daySegment;
    const studentDayKey = renderInputProps.studentDayKey;
    const dayFact = renderInputProps.dayFact;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setIsEdit = patternInput[1];
    const children_2 = toList(delay(() => {
        let props_9;
        return append(singleton((props_9 = ofArray([["className", "mb-0"], ["onClick", (_arg1) => {
            setIsEdit(true);
        }], ["className", "has-text-primary"], ["className", "has-text-centered"], ["style", {
            border: (((1 + "px ") + "dashed") + " ") + "#808080",
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_1, elms;
            if (dayFact == null) {
                return append(singleton((elms_1 = singleton_1(createElement("i", {
                    className: join(" ", ["fa", "fa-plus"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))), delay(() => singleton(createElement("p", {
                    className: "",
                    children: "Lägg till träning",
                }))));
            }
            else {
                const dayFact_1 = dayFact;
                return append(singleton((elms = singleton_1(createElement("i", {
                    className: join(" ", ["fa", "fa-pen"]),
                })), createElement("span", {
                    className: "icon",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))), delay(() => {
                    let props_4;
                    return singleton((props_4 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("span", {
                        children: [dayFact_1.Workout.Title],
                    }), createElement("span", {
                        style: {
                            opacity: 0.7,
                        },
                        className: "ml-2",
                        children: DurationModule_toHMSString(Workout_getWorkoutTotalDuration(dayFact_1.Workout)),
                    })])]), createElement("p", createObj(Helpers_combineClasses("", props_4)))));
                }));
            }
        }))))]]), createElement("div", createObj(Helpers_combineClasses("box", props_9))))), delay(() => (patternInput[0] ? singleton(createElement(Render_1, {
            dayFact: dayFact,
            sourceDayPlan: void 0,
            daySegment: daySegment,
            onSave: (dayFact_2) => {
                startImmediate(updatePlanFact(studentDayKey, new DayPlanFact_DayPlanFact(1, dayFact_2), () => {
                    setIsEdit(false);
                    onSave(dayFact_2);
                }, () => {
                    setIsEdit(false);
                }));
            },
            onDelete: () => {
                startImmediate(deletePlanFact(studentDayKey, daySegment, new PlanFact_PlanFact(1), () => {
                    setIsEdit(false);
                    onDelete();
                }, () => {
                    setIsEdit(false);
                }));
            },
            onCancel: () => {
                setIsEdit(false);
            },
        })) : empty())));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

