import { Union, Record } from "../.fable/fable-library.3.2.1/Types.js";
import { Calendar_getCalendarDayFromWeekInfo, StudentDays_StudentDayInfo_Empty, StudentDays_StudentDayListRequest, Calendar_getMonthTitle, Calendar_getWeekDayTitle, Calendar_getDayDate, DaySegment_list, DaySegment_strDaySegment, DaySegment_isLatestDaySegment, DayHelpers_tryFindFact, DayHelpers_tryFindPlan, StudentDays_StudentDayKey, Calendar_DayInfo_FromDate_7F9DDECF, DayHelpers_deleteFact, StudentDays_StudentDayInfo, DayHelpers_addUpdateFact, DayData_DayData$reflection, DaySegment_DaySegment$reflection, DayFact_DayFact$reflection, StudentDays_StudentDayInfo$reflection, Calendar_DayInfo$reflection } from "../../Shared/Shared.fs.js";
import { union_type, class_type, record_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { Cmd_none } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Route, navigateTo } from "../Router.fs.js";
import { map, empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { borderStyleBottomPrimary, borderStylePrimary, borderStyleBottomGrey } from "../Common.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { tryHead, singleton as singleton_1, ofArray } from "../.fable/fable-library.3.2.1/List.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.1/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { Render as Render_1 } from "./DayPlanBlock.fs.js";
import { Render as Render_2 } from "./DayFactBlock.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { join } from "../.fable/fable-library.3.2.1/String.js";
import { month as month_1, addDays } from "../.fable/fable-library.3.2.1/Date.js";
import { format as format_2 } from "date-fns";
import { Render as Render_3 } from "./DayDataEdit.fs.js";
import { Locale, ClassName, OnChange, Value, DateFormat, AlwaysOpen, custom, flatpickr } from "../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "../.fable/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.fs.js";
import { studentDaysApi } from "../Communication.fs.js";
import { loader } from "../Loader.fs.js";

export class State extends Record {
    constructor(DayInfo, StudentDay) {
        super();
        this.DayInfo = DayInfo;
        this.StudentDay = StudentDay;
    }
}

export function State$reflection() {
    return record_type("Activity.State", [], State, () => [["DayInfo", Calendar_DayInfo$reflection()], ["StudentDay", StudentDays_StudentDayInfo$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateSelectedDate", "UpdatedFact", "DeletedFact", "UpdatedDayData"];
    }
}

export function Msg$reflection() {
    return union_type("Activity.Msg", [], Msg, () => [[["Item", class_type("System.DateTime")]], [["Item", DayFact_DayFact$reflection()]], [["Item", DaySegment_DaySegment$reflection()]], [["Item", DayData_DayData$reflection()]]]);
}

export function init(dayInfo, studentDay) {
    return [new State(dayInfo, studentDay), Cmd_none()];
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2;
    switch (msg.tag) {
        case 1: {
            return [new State(state.DayInfo, (inputRecord = state.StudentDay, new StudentDays_StudentDayInfo(inputRecord.StudentId, inputRecord.CalendarDay, inputRecord.DayPlans, DayHelpers_addUpdateFact(msg.fields[0], state.StudentDay.DayFacts), inputRecord.DayData))), Cmd_none()];
        }
        case 2: {
            return [new State(state.DayInfo, (inputRecord_1 = state.StudentDay, new StudentDays_StudentDayInfo(inputRecord_1.StudentId, inputRecord_1.CalendarDay, inputRecord_1.DayPlans, DayHelpers_deleteFact(msg.fields[0], state.StudentDay.DayFacts), inputRecord_1.DayData))), Cmd_none()];
        }
        case 3: {
            return [new State(state.DayInfo, (inputRecord_2 = state.StudentDay, new StudentDays_StudentDayInfo(inputRecord_2.StudentId, inputRecord_2.CalendarDay, inputRecord_2.DayPlans, inputRecord_2.DayFacts, msg.fields[0]))), Cmd_none()];
        }
        default: {
            const dayInfo = Calendar_DayInfo_FromDate_7F9DDECF(msg.fields[0]);
            return [new State(dayInfo, state.StudentDay), navigateTo(new Route(26, dayInfo))];
        }
    }
}

export function RenderDaySegment(renderDaySegmentInputProps) {
    const dispatch = renderDaySegmentInputProps.dispatch;
    const state = renderDaySegmentInputProps.state;
    const daySegment = renderDaySegmentInputProps.daySegment;
    const studentDayKey = new StudentDays_StudentDayKey(state.StudentDay.StudentId, state.StudentDay.CalendarDay);
    const dayPlan = DayHelpers_tryFindPlan(daySegment, state.StudentDay.DayPlans);
    const dayFact = DayHelpers_tryFindFact(daySegment, state.StudentDay.DayFacts);
    const props_6 = toList(delay(() => append(singleton(["className", "is-gapless"]), delay(() => append(singleton(["className", "is-vcentered"]), delay(() => append(singleton(["className", "py-4"]), delay(() => append(singleton(["className", "mb-0"]), delay(() => append(singleton(["className", "is-centered"]), delay(() => append((!DaySegment_isLatestDaySegment(daySegment)) ? singleton(borderStyleBottomGrey) : empty(), delay(() => {
        let props, props_2, props_4;
        return singleton(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-2"], ["children", DaySegment_strDaySegment(daySegment)]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["className", "mx-2"], ["children", Interop_reactApi.Children.toArray([createElement(Render_1, {
            dayPlan: dayPlan,
            isDayFactExists: dayFact != null,
            studentDayKey: studentDayKey,
            daySegment: daySegment,
            onSave: (arg) => {
                dispatch(new Msg(1, arg));
            },
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["className", "mx-2"], ["children", Interop_reactApi.Children.toArray([createElement(Render_2, {
            dayFact: dayFact,
            studentDayKey: studentDayKey,
            daySegment: daySegment,
            onSave: (arg_1) => {
                dispatch(new Msg(1, arg_1));
            },
            onDelete: () => {
                dispatch(new Msg(2, daySegment));
            },
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]);
    }))))))))))))));
    return createElement("div", createObj(Helpers_combineClasses("columns", props_6)));
}

export function DayCalendar(dayCalendarInputProps) {
    const dispatch = dayCalendarInputProps.dispatch;
    const state = dayCalendarInputProps.state;
    const props_8 = ofArray([["className", "px-2"], ["className", "pb-0"], borderStylePrimary, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_6;
        return append(singleton((props_6 = ofArray([["className", "has-text-centered"], ["className", "is-gapless"], ["className", "pb-3"], ["className", "mb-0"], borderStyleBottomPrimary, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["className", "is-2"]))))), delay(() => map((flanFact) => {
            const props_4 = ofArray([["className", "mx-2"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-black"], ["className", "is-size-5"], ["className", "has-text-weight-bold"], ["children", flanFact]]))))])]]);
            return createElement("div", createObj(Helpers_combineClasses("column", props_4)));
        }, ["Planerat", "Utfört"])))))))]]), createElement("div", createObj(Helpers_combineClasses("columns", props_6))))), delay(() => map((daySegment) => createElement(RenderDaySegment, {
            daySegment: daySegment,
            state: state,
            dispatch: dispatch,
        }), DaySegment_list)));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_8)));
}

export function DayData(dayDataInputProps) {
    const dispatch = dayDataInputProps.dispatch;
    const state = dayDataInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setVisible = patternInput[1];
    const isVisible = patternInput[0];
    const selectedDate = Calendar_getDayDate(state.DayInfo.WeekInfo, state.DayInfo.WeekDay);
    const props_38 = ofArray([["className", "px-2"], ["className", "pb-0"], borderStylePrimary, ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props;
        return append(singleton((props = ofArray([["className", "has-text-centered"], ["className", "is-size-6"], ["className", "pb-3"], ["children", defaultArg(Calendar_getWeekDayTitle(state.DayInfo.WeekDay), "")]]), createElement("p", createObj(Helpers_combineClasses("", props))))), delay(() => {
            let props_28, props_5, children_1, elms, props_21, elms_1, props_12, props_8, props_10, props_18, props_14, props_16, props_26, children_5, elms_2;
            return append(singleton((props_28 = ofArray([["className", "is-vcentered"], ["style", {
                cursor: "pointer",
            }], ["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "ml-1"], ["className", join(" ", ["hoverable"])], ["className", "is-narrow"], ["onClick", (_arg1) => {
                dispatch(new Msg(0, addDays(selectedDate, -1)));
            }], ["children", Interop_reactApi.Children.toArray([(children_1 = singleton_1((elms = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-chevron-left"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))), createElement("a", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5)))), (props_21 = ofArray([["className", join(" ", ["hoverable"])], ["onClick", (_arg2) => {
                setVisible(!isVisible);
            }], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_12 = ofArray([["className", "has-text-right"], ["children", Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "has-text-primary"], ["className", "pr-5"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: join(" ", ["far", "fa-calendar-alt", "fa-2x"]),
            })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_8)))), (props_10 = ofArray([["className", "has-text-primary"], ["className", "has-text-weight-bold"], ["className", "is-size-2"], ["children", format_2(selectedDate, "dd")]]), createElement("span", createObj(Helpers_combineClasses("", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_12)))), (props_18 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_14 = toList(delay(() => append(singleton(["className", "has-text-primary"]), delay(() => append(singleton(["className", "has-text-weight-bold"]), delay(() => append(singleton(["className", "is-size-5"]), delay(() => singleton(["children", defaultArg(Calendar_getMonthTitle(month_1(selectedDate)), "")]))))))))), createElement("p", createObj(Helpers_combineClasses("", props_14)))), (props_16 = ofArray([["className", "has-text-primary"], ["className", "is-size-5"], ["children", format_2(selectedDate, "yyyy")]]), createElement("span", createObj(Helpers_combineClasses("", props_16))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_18))))]), createElement("div", {
                className: "columns",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_21)))), (props_26 = ofArray([["className", "mr-1"], ["className", join(" ", ["hoverable"])], ["onClick", (_arg3) => {
                dispatch(new Msg(0, addDays(selectedDate, 1)));
            }], ["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([(children_5 = singleton_1((elms_2 = singleton_1(createElement("i", {
                className: join(" ", ["fa", "fa-chevron-right"]),
            })), createElement("span", {
                className: "icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))), createElement("a", {
                children: Interop_reactApi.Children.toArray(Array.from(children_5)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_28))))), delay(() => {
                const studentDayKey = new StudentDays_StudentDayKey(state.StudentDay.StudentId, state.StudentDay.CalendarDay);
                return append(singleton(createElement(Render_3, {
                    dayData: state.StudentDay.DayData,
                    studentDayKey: studentDayKey,
                    onSave: (arg) => {
                        dispatch(new Msg(3, arg));
                    },
                })), delay(() => {
                    let props_36;
                    return singleton((props_36 = toList(delay(() => append(isVisible ? singleton(["className", "is-active"]) : empty(), delay(() => {
                        let props_34, props_32;
                        return singleton(["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton_1(["onClick", (_arg4) => {
                            setVisible(!isVisible);
                        }])))), (props_34 = ofArray([["style", {
                            display: "flex",
                            justifyContent: "center",
                        }], ["children", Interop_reactApi.Children.toArray([(props_32 = ofArray([["className", "has-text-centered"], ["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([custom("locale", {
                            firstDayOfWeek: 1,
                        }, true), AlwaysOpen(true), DateFormat("d M Y"), Value(selectedDate), OnChange((x) => {
                            dispatch(new Msg(0, x));
                            setVisible(false);
                        }), ClassName("input"), Locale(Swedish)]))])]]), createElement("div", createObj(Helpers_combineClasses("box", props_32))))])]]), createElement("div", createObj(Helpers_combineClasses("modal-content", props_34))))])]);
                    })))), createElement("div", createObj(Helpers_combineClasses("modal", props_36)))));
                }));
            }));
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_38)));
}

export function RenderView(renderViewInputProps) {
    let props_7, elms, props_5;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(init(renderViewInputProps.dayInfo, renderViewInputProps.studentDay), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const props_9 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_7 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms = ofArray([createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Planerat"]])))), createElement(DayCalendar, {
        state: state_1,
        dispatch: dispatch,
    })]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_5 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("box", ofArray([["className", "py-2"], ["className", "has-background-primary"], ["className", "has-text-white"], ["children", "Dag"]])))), createElement(DayData, {
        state: state_1,
        dispatch: dispatch,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_5))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_7))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_9)));
}

export function RenderWithLoad(renderWithLoadInputProps) {
    const user = renderWithLoadInputProps.user;
    const dayInfo = renderWithLoadInputProps.dayInfo;
    const query = new StudentDays_StudentDayListRequest(user.UserId, dayInfo.WeekInfo.PeriodInfo.PeriodYear, dayInfo.WeekInfo.PeriodInfo.Period, dayInfo.WeekInfo.PeriodWeek, dayInfo.WeekDay);
    const studentDays = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(studentDaysApi().list(query)), [dayInfo]);
    switch (studentDays.tag) {
        case 3: {
            const value = studentDays.fields[0].message;
            return createElement("div", {
                children: [value],
            });
        }
        case 2: {
            const studentDay = defaultArg(tryHead(studentDays.fields[0]), StudentDays_StudentDayInfo_Empty(user.UserId, Calendar_getCalendarDayFromWeekInfo(dayInfo.WeekInfo, dayInfo.WeekDay)));
            return createElement(RenderView, {
                dayInfo: dayInfo,
                studentDay: studentDay,
            });
        }
        default: {
            return loader("big");
        }
    }
}

export function Render(renderInputProps) {
    const user = renderInputProps.user;
    const dayInfo = renderInputProps.dayInfo;
    return createElement(RenderWithLoad, {
        dayInfo: dayInfo,
        user: user,
    });
}

