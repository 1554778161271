import { Calendar_getWeekInfo, Calendar_getWeekStartDate, Calendar_getWeekOfYear, Calendar_PeriodInfo_get_Current, Calendar_PeriodInfo__ShiftN_Z524259A4 } from "../../Shared/Shared.fs.js";
import { cons, ofArray, append, head, tryFind, map } from "../.fable/fable-library.3.2.1/List.js";
import { join, padLeft, interpolate, toText } from "../.fable/fable-library.3.2.1/String.js";
import { createObj, equals, int32ToString } from "../.fable/fable-library.3.2.1/Util.js";
import { toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { rangeDouble } from "../.fable/fable-library.3.2.1/Range.js";
import { View_renderSelectOption } from "../Common.fs.js";
import { map as map_1, defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { useReact_useEffect_Z101E1A95 } from "../.fable/Feliz.1.51.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { Helpers_combineClasses, Helpers_extractClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { createElement } from "react";

export const startPeriodShift = -3;

export const endPeriodShift = 13;

export const defaultPeriod = Calendar_PeriodInfo__ShiftN_Z524259A4(Calendar_PeriodInfo_get_Current(), startPeriodShift);

export function Render(renderInputProps) {
    let patternInput;
    const selectOptions = renderInputProps.selectOptions;
    const onChange = renderInputProps.onChange;
    const value = renderInputProps.value;
    const periods = map((periodShift) => {
        let copyOfStruct;
        const period = Calendar_PeriodInfo__ShiftN_Z524259A4(Calendar_PeriodInfo_get_Current(), periodShift);
        const periodStartYearWeek = Calendar_getWeekOfYear(Calendar_getWeekStartDate(Calendar_getWeekInfo(period, 0)));
        const label = toText(interpolate("%P()/%P() P. %P() (v. %P() - %P())", [period.PeriodYear, (period.PeriodYear + 1) % 100, period.Period + 1, padLeft(int32ToString(periodStartYearWeek.Week), 2, "0"), padLeft((copyOfStruct = (periodStartYearWeek.Week + 3), int32ToString(copyOfStruct)), 2, "0")]));
        return [period, label, label];
    }, toList(rangeDouble(startPeriodShift, 1, endPeriodShift)));
    const getId = (tupledArg) => tupledArg[1];
    const getPeriod = (tupledArg_1) => tupledArg_1[0];
    const periodOptions = map((tupledArg_2) => View_renderSelectOption(tupledArg_2[1], tupledArg_2[2]), periods);
    const selectedPeriodId = defaultArg(map_1(getId, tryFind((tupledArg_3) => equals(tupledArg_3[0], value), periods)), getId(head(periods)));
    useReact_useEffect_Z101E1A95(() => {
        onChange(getPeriod(head(periods)));
    }, []);
    const props_4 = ofArray([["className", "is-expanded"], ["children", Interop_reactApi.Children.toArray([(patternInput = Helpers_extractClasses(append(ofArray([["className", "is-fullwidth"], ["className", "has-text-right"], ["value", selectedPeriodId], ["onChange", (ev) => {
        const selectedPeriodId_1 = ev.target.value;
        onChange(defaultArg(map_1(getPeriod, tryFind((tupledArg_4) => (tupledArg_4[1] === selectedPeriodId_1), periods)), getPeriod(head(periods))));
    }], ["children", Interop_reactApi.Children.toArray(Array.from(periodOptions))]]), selectOptions)), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("control", props_4)));
}

