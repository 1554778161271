import { Record } from "../.fable/fable-library.3.2.1/Types.js";
import { float64_type, list_type, record_type, option_type, int32_type, string_type } from "../.fable/fable-library.3.2.1/Reflection.js";
import { Statistics_StatisticsExportXlsxPath, Statistics_StatisticsExportApiBasePath, Common_parseIntOrZero, Calendar_WeekInfo_get_Current, Calendar_getYearWeekIndex, Calendar_getWeekInfo, Calendar_WeekInfo__NextN_Z524259A4, Statistics_StatisticRequest, Statistics_StatisticType, Calendar_getCalendarDayDate, CalendarDay_CalendarDay, Calendar_getCalendarDayFromDate, Calendar_getPeriodInfo, Calendar_PeriodInfo__NextN_Z524259A4, StatisticHelpers_calcRest, StatisticHelpers_calcForm, StatisticHelpers_calcDayDataSummary, EnduranceIntensity_list, EnduranceIntensity_getEnduranceIntensityColor, EnduranceIntensity_strEnduranceIntensity, StatisticHelpers_facts, StatisticHelpers_calcEnduranceIntensityDuration, StatisticHelpers_mapWorkoutParts, StatisticHelpers_foldDurationOpt, StatisticHelpers_calcStrength, StatisticHelpers_calcFactSummary, Common_str, DurationModule_intToHMSString } from "../../Shared/Shared.fs.js";
import { cons, filter, reverse as reverse_1, ofArray, singleton as singleton_1, append as append_1, head, empty, tryFind, toArray, sumBy, map } from "../.fable/fable-library.3.2.1/List.js";
import { RenderFiltersAndCharts, renderOrLoader, PieSlice } from "./Statistic.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.51.0/Interop.fs.js";
import { Legend, Bar, Tooltip, YAxis, XAxis, CartesianGrid, BarChart as BarChart_1, Cell } from "recharts";
import { padLeft, interpolate, toText, join } from "../.fable/fable-library.3.2.1/String.js";
import { map as map_1 } from "../.fable/fable-library.3.2.1/Array.js";
import { createObj, equals, round, int32ToString } from "../.fable/fable-library.3.2.1/Util.js";
import { map as map_2, defaultArg } from "../.fable/fable-library.3.2.1/Option.js";
import { collect, reverse, empty as empty_1, map as map_3, singleton, append, delay, toList } from "../.fable/fable-library.3.2.1/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Helpers_extractClasses, Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.17.0/ElementBuilders.fs.js";
import { rangeDouble } from "../.fable/fable-library.3.2.1/Range.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Remoting_handleNonAuth } from "../Extensions.fs.js";
import { studentsApi, statisticsApi } from "../Communication.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useState_FCFD9EF } from "../.fable/Feliz.1.51.0/React.fs.js";
import { loader } from "../Loader.fs.js";

export class ChartColorItem extends Record {
    constructor(Label, Value, ValueOpt, Color, DisplayValue) {
        super();
        this.Label = Label;
        this.Value = (Value | 0);
        this.ValueOpt = ValueOpt;
        this.Color = Color;
        this.DisplayValue = DisplayValue;
    }
}

export function ChartColorItem$reflection() {
    return record_type("StudentStatistics.ChartColorItem", [], ChartColorItem, () => [["Label", string_type], ["Value", int32_type], ["ValueOpt", option_type(int32_type)], ["Color", string_type], ["DisplayValue", string_type]]);
}

export class StackChartColorItem extends Record {
    constructor(StackLabel, ChartColorItems) {
        super();
        this.StackLabel = StackLabel;
        this.ChartColorItems = ChartColorItems;
    }
}

export function StackChartColorItem$reflection() {
    return record_type("StudentStatistics.StackChartColorItem", [], StackChartColorItem, () => [["StackLabel", string_type], ["ChartColorItems", list_type(ChartColorItem$reflection())]]);
}

export const tickFormatter = (x) => DurationModule_intToHMSString(x);

export function BarChart(barChartInputProps) {
    const items = barChartInputProps.items;
    const chartData = map((x) => (new PieSlice(x.Label, x.Value)), items);
    const colors = map((x_1) => Interop_reactApi.createElement(Cell, {
        fill: x_1.Color,
    }), items);
    const sum = sumBy((x_2) => x_2.Value, items, {
        GetZero: () => 0,
        Add: (x_3, y) => (x_3 + y),
    }) | 0;
    return Interop_reactApi.createElement(BarChart_1, {
        width: 600,
        height: 400,
        data: toArray(chartData),
        children: Interop_reactApi.Children.toArray([Interop_reactApi.createElement(CartesianGrid, {
            strokeDasharray: join(" ", map_1((value_9) => int32ToString(value_9), new Int32Array([1, 3]))),
            vertical: false,
        }), Interop_reactApi.createElement(XAxis, {
            dataKey: (x_7) => x_7.name,
        }), Interop_reactApi.createElement(YAxis, {
            tickFormatter: tickFormatter,
        }), Interop_reactApi.createElement(Tooltip, {
            separator: " ",
            formatter: (value_1) => toText(interpolate("%P() (%P()%%)", [defaultArg(map_2((x_6) => x_6.DisplayValue, tryFind((x_5) => (x_5.Value === value_1), items)), Common_str(value_1)), (sum === 0) ? 0 : round((value_1 * 100) / sum)])),
        }), Interop_reactApi.createElement(Bar, {
            children: ["children", Interop_reactApi.Children.toArray(Array.from(colors))],
            dataKey: (x_8) => x_8.value,
        })]),
    });
}

export function StackBarChart(stackBarChartInputProps) {
    const stackChartDatas = stackBarChartInputProps.stackChartDatas;
    return Interop_reactApi.createElement(BarChart_1, {
        width: 600,
        height: 400,
        data: toArray(stackChartDatas),
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(Interop_reactApi.createElement(CartesianGrid, {
            strokeDasharray: join(" ", map_1((value_5) => int32ToString(value_5), new Int32Array([1, 3]))),
            vertical: false,
        })), delay(() => append(singleton(Interop_reactApi.createElement(XAxis, {
            dataKey: (x_1) => x_1.StackLabel,
            type: "category",
        })), delay(() => append(singleton(Interop_reactApi.createElement(YAxis, {
            tickFormatter: tickFormatter,
        })), delay(() => append(singleton(Interop_reactApi.createElement(Tooltip, {
            separator: " ",
            formatter: (x) => DurationModule_intToHMSString(x),
        })), delay(() => append((!equals(stackChartDatas, empty())) ? map_3((chartColorItem) => Interop_reactApi.createElement(Bar, {
            dataKey: (y) => defaultArg(map_2((x_3) => x_3.Value, tryFind((x_2) => (x_2.Label === chartColorItem.Label), y.ChartColorItems)), 0),
            fill: chartColorItem.Color,
            stackId: "a",
            name: chartColorItem.Label,
        }), head(stackChartDatas).ChartColorItems) : empty_1(), delay(() => singleton(Interop_reactApi.createElement(Legend, {
            align: "center",
            layout: "horizontal",
            verticalAlign: "top",
            iconType: "circle",
            margin: {
                top: defaultArg(0, 0),
                right: defaultArg(10, 0),
                left: defaultArg(50, 0),
                bottom: defaultArg(100, 0),
            },
        })))))))))))))))),
    });
}

export class StudentTableData extends Record {
    constructor(ChartItems, TotalDurationSeconds, IsSickCount, IsDamagedCount, IsTravelDayCount, FormAvg, RestIsPrimaryCount) {
        super();
        this.ChartItems = ChartItems;
        this.TotalDurationSeconds = (TotalDurationSeconds | 0);
        this.IsSickCount = (IsSickCount | 0);
        this.IsDamagedCount = (IsDamagedCount | 0);
        this.IsTravelDayCount = (IsTravelDayCount | 0);
        this.FormAvg = FormAvg;
        this.RestIsPrimaryCount = (RestIsPrimaryCount | 0);
    }
}

export function StudentTableData$reflection() {
    return record_type("StudentStatistics.StudentTableData", [], StudentTableData, () => [["ChartItems", list_type(ChartColorItem$reflection())], ["TotalDurationSeconds", int32_type], ["IsSickCount", int32_type], ["IsDamagedCount", int32_type], ["IsTravelDayCount", int32_type], ["FormAvg", float64_type], ["RestIsPrimaryCount", int32_type]]);
}

export function createStudentTableData(daysList) {
    let strengthChartItem;
    const durationSec_1 = map_2((x_2) => x_2.DurationSeconds, StatisticHelpers_calcFactSummary((workoutPart_1) => StatisticHelpers_calcStrength(workoutPart_1), daysList));
    strengthChartItem = (new ChartColorItem("Styrka", defaultArg(durationSec_1, 0), durationSec_1, "#63a2d9", defaultArg(map_2((x_3) => DurationModule_intToHMSString(x_3), durationSec_1), "-")));
    const chartItems = append_1(map((x) => {
        const durationSec = StatisticHelpers_foldDurationOpt(StatisticHelpers_mapWorkoutParts((workoutPart) => StatisticHelpers_calcEnduranceIntensityDuration(x, workoutPart), StatisticHelpers_facts(daysList)));
        return new ChartColorItem(EnduranceIntensity_strEnduranceIntensity(x), defaultArg(durationSec, 0), durationSec, EnduranceIntensity_getEnduranceIntensityColor(x), defaultArg(map_2((x_1) => DurationModule_intToHMSString(x_1), durationSec), "-"));
    }, EnduranceIntensity_list), singleton_1(strengthChartItem));
    const totalSum = sumBy((x_4) => x_4.Value, chartItems, {
        GetZero: () => 0,
        Add: (x_5, y) => (x_5 + y),
    }) | 0;
    const patternInput = StatisticHelpers_calcDayDataSummary(daysList);
    return new StudentTableData(chartItems, totalSum, patternInput[0], patternInput[1], patternInput[2], StatisticHelpers_calcForm(daysList), defaultArg(map_2((x_6) => x_6.IsPrimaryCount, StatisticHelpers_calcFactSummary((workoutPart_2) => StatisticHelpers_calcRest(workoutPart_2), daysList)), 0));
}

export function strDuration(x) {
    if (x === 0) {
        return "-";
    }
    else {
        return DurationModule_intToHMSString(x);
    }
}

export function RenderSelectedIntervalTotalTable(renderSelectedIntervalTotalTableInputProps) {
    let props_30, props, props_28, props_26, children, children_4, children_14, value_25, children_6, children_8, children_10, children_12;
    const data = createStudentTableData(renderSelectedIntervalTotalTableInputProps.daysList);
    const elms = singleton_1((props_30 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = singleton_1(["children", Interop_reactApi.Children.toArray([createElement(BarChart, {
        items: data.ChartItems,
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_28 = ofArray([["style", {
        overflowX: "auto",
    }], ["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["className", "is-bordered"], ["className", "is-fullwidth"], ["className", "is-size-7"], ["children", Interop_reactApi.Children.toArray([createElement("thead", {
        className: "has-background-primary",
        children: Interop_reactApi.Children.toArray([(children = ofArray([createElement("th", {
            className: "has-text-white",
            children: "Intensitet",
        }), createElement("th", {
            className: "has-text-white",
            children: "Årets säsong",
        })]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]),
    }), (children_4 = toList(delay(() => map_3((chartItem) => {
        let value_21;
        const children_2 = ofArray([createElement("th", {
            children: [chartItem.Label],
        }), (value_21 = strDuration(chartItem.Value), createElement("td", {
            children: [value_21],
        }))]);
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }, reverse(data.ChartItems)))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    })), (children_14 = ofArray([createElement("tr", {
        className: "has-background-primary-light",
        children: Interop_reactApi.Children.toArray([createElement("th", {
            children: ["Totaltid:"],
        }), (value_25 = strDuration(data.TotalDurationSeconds), createElement("th", {
            children: [value_25],
        }))]),
    }), (children_6 = ofArray([createElement("th", {
        children: ["Form"],
    }), createElement("td", {
        children: [data.FormAvg],
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    })), (children_8 = ofArray([createElement("th", {
        children: ["Sjuk"],
    }), createElement("td", {
        children: [data.IsSickCount],
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    })), (children_10 = ofArray([createElement("th", {
        children: ["Skada"],
    }), createElement("td", {
        children: [data.IsDamagedCount],
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    })), (children_12 = ofArray([createElement("th", {
        children: ["Vila"],
    }), createElement("td", {
        children: [data.RestIsPrimaryCount],
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_12)),
    }))]), createElement("tfoot", {
        children: Interop_reactApi.Children.toArray(Array.from(children_14)),
    }))])]]), createElement("table", createObj(Helpers_combineClasses("table", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_28))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_30)))));
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function RenderPeriodsTable(renderPeriodsTableInputProps) {
    let children, children_4, children_14, children_6, children_8, children_10, children_12;
    const tableDatas = renderPeriodsTableInputProps.tableDatas;
    const periodNames = renderPeriodsTableInputProps.periodNames;
    const props_24 = ofArray([["className", "is-bordered"], ["className", "is-fullwidth"], ["className", "is-size-7"], ["children", Interop_reactApi.Children.toArray([createElement("thead", {
        className: "has-background-primary",
        children: Interop_reactApi.Children.toArray([(children = toList(delay(() => append(singleton(createElement("th", {
            className: "has-text-white",
            children: "Intensitet",
        })), delay(() => map_3((periodName) => createElement("th", {
            className: "has-text-white",
            children: periodName,
        }), periodNames))))), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]),
    }), (children_4 = toList(delay(() => ((!equals(tableDatas, empty())) ? map_3((i) => {
        const children_2 = toList(delay(() => append(singleton(createElement("th", {
            children: [i.Label],
        })), delay(() => collect((tableData) => {
            let value_19;
            return singleton((value_19 = defaultArg(map_2((x_1) => x_1.DisplayValue, tryFind((x) => (x.Label === i.Label), tableData.ChartItems)), "-"), createElement("td", {
                children: [value_19],
            })));
        }, tableDatas)))));
        return createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }, reverse_1(head(tableDatas).ChartItems)) : empty_1()))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    })), (children_14 = ofArray([createElement("tr", {
        className: "has-background-primary-light",
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("th", {
            children: ["Totaltid:"],
        })), delay(() => map_3((tableData_1) => {
            const value_23 = strDuration(tableData_1.TotalDurationSeconds);
            return createElement("td", {
                children: [value_23],
            });
        }, tableDatas))))))),
    }), (children_6 = toList(delay(() => append(singleton(createElement("th", {
        children: ["Form"],
    })), delay(() => map_3((tableData_2) => createElement("td", {
        children: [tableData_2.FormAvg],
    }), tableDatas))))), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    })), (children_8 = toList(delay(() => append(singleton(createElement("th", {
        children: ["Sjuk"],
    })), delay(() => map_3((tableData_3) => createElement("td", {
        children: [tableData_3.IsSickCount],
    }), tableDatas))))), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    })), (children_10 = toList(delay(() => append(singleton(createElement("th", {
        children: ["Skada"],
    })), delay(() => map_3((tableData_4) => createElement("td", {
        children: [tableData_4.IsDamagedCount],
    }), tableDatas))))), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_10)),
    })), (children_12 = toList(delay(() => append(singleton(createElement("th", {
        children: ["Vila"],
    })), delay(() => map_3((tableData_5) => createElement("td", {
        children: [tableData_5.RestIsPrimaryCount],
    }), tableDatas))))), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_12)),
    }))]), createElement("tfoot", {
        children: Interop_reactApi.Children.toArray(Array.from(children_14)),
    }))])]]);
    return createElement("table", createObj(Helpers_combineClasses("table", props_24)));
}

export function RenderPeriodsToYearEndTable(renderPeriodsToYearEndTableInputProps) {
    let props_4, props, props_2;
    const daysList = renderPeriodsToYearEndTableInputProps.daysList;
    const calendarDayTo = renderPeriodsToYearEndTableInputProps.calendarDayTo;
    const calendarDayFrom = renderPeriodsToYearEndTableInputProps.calendarDayFrom;
    const periodDatas = map((periodIndex) => {
        const period = Calendar_PeriodInfo__NextN_Z524259A4(Calendar_getPeriodInfo(calendarDayFrom.Year, calendarDayFrom.Period), periodIndex);
        const periodList = filter((day) => {
            if (day.CalendarDay.Year === period.PeriodYear) {
                return day.CalendarDay.Period === period.Period;
            }
            else {
                return false;
            }
        }, daysList);
        return [toText(interpolate("%P() p%P()", [period.PeriodYear, period.Period + 1])), createStudentTableData(periodList)];
    }, toList(rangeDouble(0, 1, (((13 + calendarDayTo.Period) - calendarDayFrom.Period) % 13) + ((calendarDayTo.Year - calendarDayFrom.Year) * 13))));
    const periodNames = map((tuple) => tuple[0], periodDatas);
    const tableDatas = map((tuple_1) => tuple_1[1], periodDatas);
    const stackChartData = map((x) => (new StackChartColorItem(x[0], x[1].ChartItems)), periodDatas);
    const elms = singleton_1((props_4 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = singleton_1(["children", Interop_reactApi.Children.toArray([createElement(StackBarChart, {
        stackChartDatas: stackChartData,
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["style", {
        overflowX: "auto",
        width: 0,
    }], ["children", Interop_reactApi.Children.toArray([createElement(RenderPeriodsTable, {
        periodNames: periodNames,
        tableDatas: tableDatas,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_4)))));
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function RenderPeriodsToYearEndTableWithLoad(renderPeriodsToYearEndTableWithLoadInputProps) {
    const dates = renderPeriodsToYearEndTableWithLoadInputProps.dates;
    const studentId = renderPeriodsToYearEndTableWithLoadInputProps.studentId;
    const calendarDayDateFrom = Calendar_getCalendarDayFromDate(dates.DateFrom);
    const calendarDayFrom = new CalendarDay_CalendarDay(calendarDayDateFrom.Year, calendarDayDateFrom.Period, 0, 0);
    const dateFrom = Calendar_getCalendarDayDate(calendarDayFrom);
    const calendarDayTo = new CalendarDay_CalendarDay(Calendar_getCalendarDayFromDate(dates.DateTo).Year, 12, 3, 6);
    const query = new Statistics_StatisticRequest(new Statistics_StatisticType(2, studentId), dateFrom, Calendar_getCalendarDayDate(calendarDayTo));
    return renderOrLoader(useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(statisticsApi().daysList(query)), [studentId, dates.DateFrom, dates.DateTo]), (daysList) => createElement(RenderPeriodsToYearEndTable, {
        calendarDayFrom: calendarDayFrom,
        calendarDayTo: calendarDayTo,
        daysList: daysList,
    }));
}

export function RenderSelectedIntervalWeeksTable(renderSelectedIntervalWeeksTableInputProps) {
    let props_4, props, props_2;
    const daysList = renderSelectedIntervalWeeksTableInputProps.daysList;
    const calendarDayTo = renderSelectedIntervalWeeksTableInputProps.calendarDayTo;
    const calendarDayFrom = renderSelectedIntervalWeeksTableInputProps.calendarDayFrom;
    const weekDatas = map((weekIndex) => {
        const week = Calendar_WeekInfo__NextN_Z524259A4(Calendar_getWeekInfo(Calendar_getPeriodInfo(calendarDayFrom.Year, calendarDayFrom.Period), calendarDayFrom.Week), weekIndex);
        const weekList = filter((day) => {
            if ((day.CalendarDay.Year === week.PeriodInfo.PeriodYear) ? (day.CalendarDay.Period === week.PeriodInfo.Period) : false) {
                return day.CalendarDay.Week === week.PeriodWeek;
            }
            else {
                return false;
            }
        }, daysList);
        return [toText(interpolate("v%P()", [padLeft(Common_str(Calendar_getYearWeekIndex(week)), 2, "0")])), createStudentTableData(weekList)];
    }, toList(rangeDouble(0, 1, ((((4 + calendarDayTo.Week) - calendarDayFrom.Week) % 4) + ((((13 + calendarDayTo.Period) - calendarDayFrom.Period) % 13) * 4)) + (((calendarDayTo.Year - calendarDayFrom.Year) * 13) * 4))));
    const periodNames = map((tuple) => tuple[0], weekDatas);
    const tableDatas = map((tuple_1) => tuple_1[1], weekDatas);
    const stackChartData = map((x_1) => (new StackChartColorItem(x_1[0], x_1[1].ChartItems)), weekDatas);
    const elms = singleton_1((props_4 = singleton_1(["children", Interop_reactApi.Children.toArray([(props = singleton_1(["children", Interop_reactApi.Children.toArray([createElement(StackBarChart, {
        stackChartDatas: stackChartData,
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["style", {
        overflowX: "auto",
        width: 0,
    }], ["children", Interop_reactApi.Children.toArray([createElement(RenderPeriodsTable, {
        periodNames: periodNames,
        tableDatas: tableDatas,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_2))))])]), createElement("div", createObj(Helpers_combineClasses("columns", props_4)))));
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function RenderSelectedIntervalWeeksTableWithLoad(renderSelectedIntervalWeeksTableWithLoadInputProps) {
    const dates = renderSelectedIntervalWeeksTableWithLoadInputProps.dates;
    const studentId = renderSelectedIntervalWeeksTableWithLoadInputProps.studentId;
    const calendarDayDateFrom = Calendar_getCalendarDayFromDate(dates.DateFrom);
    const calendarDayFrom = new CalendarDay_CalendarDay(calendarDayDateFrom.Year, calendarDayDateFrom.Period, calendarDayDateFrom.Week, 0);
    const dateFrom = Calendar_getCalendarDayDate(calendarDayFrom);
    const calendarDayDateTo = Calendar_getCalendarDayFromDate(dates.DateTo);
    const calendarDayTo = new CalendarDay_CalendarDay(calendarDayDateTo.Year, calendarDayDateTo.Period, calendarDayDateTo.Week, 6);
    const query = new Statistics_StatisticRequest(new Statistics_StatisticType(2, studentId), dateFrom, Calendar_getCalendarDayDate(calendarDayTo));
    return renderOrLoader(useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(statisticsApi().daysList(query)), [studentId, dates.DateFrom, dates.DateTo]), (daysList) => createElement(RenderSelectedIntervalWeeksTable, {
        calendarDayFrom: calendarDayFrom,
        calendarDayTo: calendarDayTo,
        daysList: daysList,
    }));
}

export function RenderStudentTables(renderStudentTablesInputProps) {
    const dates = renderStudentTablesInputProps.dates;
    const studentId = renderStudentTablesInputProps.studentId;
    return react.createElement(react.Fragment, {}, renderOrLoader(renderStudentTablesInputProps.daysList, (daysList_1) => createElement(RenderSelectedIntervalTotalTable, {
        daysList: daysList_1,
    })), createElement(RenderPeriodsToYearEndTableWithLoad, {
        studentId: studentId,
        dates: dates,
    }), createElement(RenderSelectedIntervalWeeksTableWithLoad, {
        studentId: studentId,
        dates: dates,
    }));
}

export function RenderExcelButton(renderExcelButtonInputProps) {
    let props_9, patternInput_1, value_14, props_7;
    const studentId = renderExcelButtonInputProps.studentId;
    const patternInput = useReact_useState_FCFD9EF(() => (Calendar_WeekInfo_get_Current().PeriodInfo.PeriodYear - 1));
    const year = patternInput[0] | 0;
    const options = useReact_useMemo_CF4EA67(() => {
        const year_1 = Calendar_WeekInfo_get_Current().PeriodInfo.PeriodYear | 0;
        return map((x_1) => createElement("option", {
            value: x_1,
            children: toText(interpolate("%P()/%P()", [x_1, (x_1 + 1) % 100])),
        }), map((x) => (year_1 + x), reverse_1(toList(rangeDouble(-8, 1, 1)))));
    });
    const props_11 = ofArray([["className", "mt-5"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("", singleton_1(["children", "Exportera statistiken till en excelrapport. Välj säsongsperiod och sedan skapa excelrapport."])))), (props_9 = ofArray([["className", "mt-2"], ["children", Interop_reactApi.Children.toArray([(patternInput_1 = Helpers_extractClasses(ofArray([["className", "is-small"], ["className", "has-text-right"], (value_14 = (year | 0), ["ref", (e) => {
        if ((!(e == null)) ? (!equals(e.value, value_14)) : false) {
            e.value = (value_14 | 0);
        }
    }]), ["onChange", (ev) => {
        patternInput[1](Common_parseIntOrZero(ev.target.value));
    }], ["children", Interop_reactApi.Children.toArray(Array.from(options))]])), createElement("div", {
        className: join(" ", cons("select", patternInput_1[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
    })), (props_7 = ofArray([["className", "ml-1"], ["className", "is-small"], ["children", "Skapa excelrapport"], ["href", toText(interpolate("%P()%P()?year=%P()\u0026studentId=%P()", [Statistics_StatisticsExportApiBasePath, Statistics_StatisticsExportXlsxPath, year, studentId]))], ["target", "_blank"]]), createElement("a", createObj(Helpers_combineClasses("button", props_7))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_9))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_11)));
}

export function RenderMainLayout(student) {
    return createElement(RenderFiltersAndCharts, {
        title: student.FullName,
        statisticType: new Statistics_StatisticType(2, student.Id),
        excelRender: createElement(RenderExcelButton, {
            studentId: student.Id,
        }),
        tablesRender: (dates) => ((daysList) => createElement(RenderStudentTables, {
            studentId: student.Id,
            dates: dates,
            daysList: daysList,
        })),
    });
}

export function renderMainLayoutWithLoad(studentId) {
    const student = useFeliz_React__React_useDeferred_Static_2344FC52(Remoting_handleNonAuth(studentsApi().getSettings(studentId)), [studentId]);
    if (student.tag === 3) {
        const value = student.fields[0].message;
        return createElement("div", {
            children: [value],
        });
    }
    else if (student.tag === 2) {
        if (student.fields[0].tag === 0) {
            return createElement(RenderMainLayout, student.fields[0].fields[0]);
        }
        else {
            return createElement("div", {
                children: [student.fields[0].fields[0]],
            });
        }
    }
    else {
        return loader("big");
    }
}

export function Render(renderInputProps) {
    const _arg1 = renderInputProps._arg1;
    return renderMainLayoutWithLoad(renderInputProps.studentId);
}

